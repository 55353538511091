import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {
  Checkbox,
  Table as TableMui,
  TableBody,
  TableCell,
  TableRow, Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {makeStyles} from '@material-ui/core/styles';
import ButtonForm from '../../Components/Forms/ButtonForm';
import AddDomainForm from '../Forms/AddDomainForm';
import {ServiceRender} from '../Service';
import Table from '../../Components/Table';
import {displayConfirmation} from '../../ui-components/displayMsg';
import {RenderDomainLink} from '../Domain';
import {RenderCategory} from '../Category';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import {DeleteDomains} from '../Tables/GlobalDomains';
import CommentsButtonList from '../CommentsButtonList';
import {
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';


const TIMESTAMP_FIELD = '@timestamp';
const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';


/**
 *
 * @param{JSX.Element|string} title
 * @param{url} url
 * @param{object} urlData
 * @param{function} setRefresh
 * @param{string} exporter
 * @param{boolean|object} importer
 * @return {JSX.Element}
 * @constructor
 */
function PermanentListTable({
  title,
  url,
  url_data: urlData,
  setRefresh,
  exporter,
  importer,
}) {
  const {
    columns,
    extraData,
    xs,
    data,
    setOpen,
    deleting,
    ids,
    setDeleting,
  } = useTableState(urlData);

  return (
    <>
      <Table
        columns={columns}
        title={title}
        url={url}
        extradata={extraData}
        exporter={!xs && exporter}
        row_height={34}
        importer={!xs && importer}
      />
      {data && (
        <DetailDomainDialog
          url={url}
          data={data}
          setOpen={setOpen}
          setRefresh={setRefresh}
        />
      )}
      {deleting && (
        <DeleteDomains
          url={url}
          ids={ids}
          setDeleting={setDeleting}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
}

PermanentListTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  url: PropTypes.string,
  url_data: PropTypes.object,
  setRefresh: PropTypes.func,
  exporter: PropTypes.string,
  importer: Table.propTypes.importer,
};


/**
 *
 * @param{object} urlData
 * @return {{
 * setOpen: function,
 * deleting: boolean,
 * data: boolean,
 * setDeleting: function,
 * extraData: object,
 * columns: [object]}
 * }
 */
function useTableState(urlData) {
  const [selectedService, services] = useSelector((state) => [
    state.selectedService,
    state.services,
  ]);
  const [ids, setDomains] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [data, setOpen] = useState(false);
  const [extraData, setExtraData] = useState({
    ...urlData,
    clientname: selectedService,
  });

  useEffect(() => {
    setExtraData(urlData);
  }, [urlData]);

  useEffect(() => {
    setExtraData((o) => {
      if (o.clientname !== selectedService) {
        return {...o, page: 1, clientname: selectedService};
      }
      return o;
    });
  }, [selectedService]);

  const onChange = (id) => ({target: {checked}}) => {
    if (checked) {
      setDomains((previousIds) => [...previousIds, id]);
    } else {
      setDomains((previousIds) => previousIds.filter((d) => d !== id));
    }
  };

  const onClick = (selectedIds) => () => {
    if (selectedIds.length) {
      displayConfirmation(
          'By click Delete you will exclude the domain from the list.' +
        ' Are your sure?',
          'DELETE',
          'CANCEL',
          () => setDeleting(true),
      );
    }
  };

  const md = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const xs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const columns = [
    {
      name: '',
      data: '',
      render: function CommentCell(_, row) {
        return <CommentsButtonList {...row} />;
      },
      sortable: false,
      searchable: false,
      styles: {
        width: 54,
      },
    },
    {
      name: '',
      data: 'warning',
      render: function WarningCell(_, row) {
        return row.warning &&
          <Tooltip title={'Domain in 1 or more Categories'}>
            <span>
              <FontAwesomeIcon icon={faExclamationTriangle} color={'#BE131A'}/>
            </span>
          </Tooltip>;
      },
      sortable: false,
      searchable: false,
      styles: {
        width: 27,
      },
    },
    {
      name: <b>Domain</b>,
      data: 'domain',
      render: function DomainCell(domain, row) {
        return <RenderDomainLink
          domain={domain}
          style={{
            textDecoration: (() => {
              const {_id: rowId} = row;
              const deletingItem =
                ids.some((id) => id === rowId) && deleting;
              const removingItem = row.tags.indexOf('removed') !== -1;
              return deletingItem || removingItem ?
                'line-through' :
                'none';
            })(),
          }}
        />;
      },
      sortable: true,
      searchable: true,
      exporter: {
        label: 'Domain',
        value: 'domain',
      },
    },
    {
      name: <b>Date</b>,
      data: TIMESTAMP_FIELD,
      render: (el) => moment(el).format(DATE_FORMAT),
      sortable: true,
      styles: {
        width: 140,
      },
      hide: xs,
      exporter: {
        label: 'Date',
        value: (row) => moment(row[TIMESTAMP_FIELD]).format(DATE_FORMAT),
      },
    },
    {
      name: <b>Added by</b>,
      data: 'username',
      render: function AddedCell(el) {
        return <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
          {el}
        </div>;
      },
      hide: xs || md,
      exporter: {
        label: 'Added by',
        value: 'username',
      },
    },
    {
      name: <b>Service</b>,
      data: 'client_name',
      render: function ServiceCell(el) {
        return <ServiceRender hash={el}/>;
      },
      styles: {
        width: 115,
      },
      hide:
        xs ||
        md ||
        (extraData.clientname !== 'all' && extraData.clientname?.length) ||
        services.length <= 1,
      exporter: {
        label: 'Service',
        value: (row) =>
          services.find((s) => s.hash === row.client_name).servicename,
      },
    },
    {
      name: 'Comment',
      data: 'usercomment',
      hide: true,
      exporter: {
        label: 'Last Comment',
        value: 'usercomment',
      },
    },
    {
      title: (
        <div style={{width: '100%', textAlign: 'center'}}>
          <RedIconButton
            size="small"
            TooltipProps={{title: 'Delete'}}
            color="default"
            onClick={onClick(ids)}
          >
            <DeleteIcon fontSize="small"/>
          </RedIconButton>
        </div>
      ),
      data: '_id',
      render: function CheckCell(el, row) {
        return <div style={{width: '100%', textAlign: 'center'}}>
          <Checkbox
            color="default"
            checked={ids.some((id) => id === el)}
            style={{padding: 0}}
            size="small"
            disabled={deleting || row.tags.indexOf('removed') !== -1}
            onChange={onChange(el)}
          />
        </div>;
      },
      styles: {
        width: 67,
      },
    },
    {
      name: '',
      render: function CellButton(_, row) {
        return <RedIconButton onClick={() => setOpen(row)} size="small">
          <FontAwesomeIcon icon={faInfoCircle}/>
        </RedIconButton>;
      },
      styles: {
        width: 56,
      },
      hide: !(md || xs),
    },
  ];

  return {
    columns,
    extraData,
    xs,
    data,
    setOpen,
    deleting,
    ids,
    setDeleting,
  };
}


const extraCommentLabel = 'Add additional comment to each entry';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Lists() {
  const [domain, setDomain] = useState('');
  const [[refreshBlack, refreshWhite], setRefresh] = useState([0, 0]);
  const setRefreshBlack = () => setRefresh(([r1, r2]) => [r1 + 1, r2]);
  const setRefreshWhite = () => setRefresh(([r1, r2]) => [r1, r2 + 1]);

  const urlData = {domain};

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();


  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <AddDomainForm
          domain={domain}
          setDomain={setDomain}
          setRefresh={{
            whitelist: setRefreshWhite,
            blacklist: setRefreshBlack,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: 29}}/>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <PermanentListTable
          title={<RenderCategory list="whitelist"/>}
          url="list/whitelist"
          url_data={{...urlData, refresh_white: refreshWhite}}
          setRefresh={setRefreshWhite}
          exporter="Whitelist"
          importer={{
            onSuccess: () => setRefreshWhite(),
            dropzoneAreaProps: {
              acceptedFiles: [
                'application/vnd.openxmlformats-officedocument.spreadsheetml' +
                '.sheet',
                'text/csv',
                'application/csv',
                'text/x-csv',
                'application/x-csv',
                'text/x-comma-separated-values',
                'text/comma-separated-values',
                'application/vnd.ms-excel',
                'application/vnd.oasis.opendocument.spreadsheet',
              ],
            },
            modalTitle: <>
              Import file to &nbsp;&nbsp;
              <RenderCategory
                list="whitelist"
                style={{display: 'inline-flex'}}
              />
            </>,
            childrenBeforeFileDrop: <ul>
              <li>First column must have domains</li>
              <li>Remaining columns will be added as comment</li>
            </ul>,
            BtnComponent: ButtonForm,
            btnProps: {
              className: 'sec',
              variant: 'outlined',
              children: <>
                Add to&nbsp;&nbsp;<RenderCategory list="whitelist"/>
              </>,
            },
            additionalComment: {
              label: extraCommentLabel,
              placeholder: extraCommentLabel,
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <PermanentListTable
          title={<RenderCategory list="blacklist"/>}
          url="list/blacklist"
          url_data={{...urlData, refresh_black: refreshBlack}}
          setRefresh={setRefreshBlack}
          exporter="Blacklist"
          importer={{
            onSuccess: () => setRefreshWhite(),
            dropzoneAreaProps: {
              acceptedFiles: [
                'application/vnd.openxmlformats-officedocument.spreadsheetml' +
                '.sheet',
                'text/csv',
                'application/csv',
                'text/x-csv',
                'application/x-csv',
                'text/x-comma-separated-values',
                'text/comma-separated-values',
                'application/vnd.ms-excel',
                'application/vnd.oasis.opendocument.spreadsheet',
              ],
            },
            modalTitle: <>
              Import file to &nbsp;&nbsp;
              <RenderCategory
                list="blacklist"
                style={{display: 'inline-flex'}}
              />
            </>,
            childrenBeforeFileDrop: <ul>
              <li>First column must have domains</li>
              <li>Remaining columns will be added as comment</li>
            </ul>,
            BtnComponent: ButtonForm,
            btnProps: {
              variant: 'outlined',
              children: <>
                Add to&nbsp;&nbsp;<RenderCategory
                  list="blacklist"
                  style={{color: 'inherit'}}/>
              </>,
            },
            additionalComment: {
              label: extraCommentLabel,
              placeholder: extraCommentLabel,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} style={{height: padding, margin: 'auto'}}/>
    </Grid>
  );
}


/**
 *
 * @param{object} data
 * @param{string} url
 * @param{function} setOpen
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function DetailDomainDialog({data, url, setOpen, setRefresh}) {
  const [deleting, setDeleting] = useState(false);
  const {_id: dataId} = data;
  return (
    <Dialog open={!!data}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <RenderCategory list={data.list}/>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Domain</b>
              </TableCell>
              <TableCell>
                <RenderDomainLink domain={data.domain}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Service</b>
              </TableCell>
              <TableCell>
                <ServiceRender hash={data.client_name}/>
              </TableCell>
            </TableRow>
            <TableRow>
              {!data.usercomment && (
                <>
                  <TableCell style={{textAlign: 'right'}}>
                    <b>Added by</b>
                  </TableCell>
                  <TableCell>{data.username}</TableCell>
                </>
              )}
              {data.usercomment && (
                <>
                  <TableCell style={{textAlign: 'right'}}>
                    <b>Motive</b>
                  </TableCell>
                  <TableCell>
                    {data.usercomment}
                    <br/>
                    <div style={{fontSize: 12}}>
                      by <b>{data.username}</b>
                    </div>
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Added at</b>
              </TableCell>
              <TableCell>
                {moment(data[TIMESTAMP_FIELD]).format(DATE_FORMAT)}
              </TableCell>
            </TableRow>
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}}/>
          Close
        </ButtonForm>
        <ButtonForm
          variant="outlined"
          className="sec"
          type="button"
          onClick={(_) => setDeleting(true)}
        >
          <DeleteIcon/> Delete
        </ButtonForm>
        {deleting && (
          <DeleteDomains
            url={url}
            ids={[dataId]}
            setDeleting={setDeleting}
            setRefresh={() => setOpen(false) || setRefresh()}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

DetailDomainDialog.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  setOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};
