import React, {useCallback, useEffect, useRef, useState} from 'react';

import {
  FormControl, Grid, MenuItem, SwipeableDrawer, FormLabel,
} from '@material-ui/core';
import RedTextField from '../../core/components/inputs/RedTextField';

import moment from 'moment';
import {useSelector} from 'react-redux';
import {ServiceRender} from '../../DNS8Components/Service';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {getFetch} from '../../ApiBackend';
import fileDownload from 'js-file-download';
import {displayError} from '../../ui-components/displayMsg';
import PropTypes from 'prop-types';
import DatePicker from '../../Components/DatePicker';

moment.locale('en', {week: {dow: 1}});

const propsRedTextField = {
  size: 'small', variant: 'outlined', style: {
    backgroundColor: 'white', width: '100%',
  }, SelectProps: {
    displayEmpty: true, MenuProps: {
      anchorOrigin: {
        vertical: 'bottom', horizontal: 'left',
      }, transformOrigin: {
        vertical: 'top', horizontal: 'left',
      }, getContentAnchorEl: null,
    },
  },
};


/**
 * @param {boolean} open
 * @param {function} close
 * @return {Element}
 * @constructor
 */
const ReportsDownloader = ({open, close}) => {
  const [selectedService, services] =
    useSelector((state) => {
      if (!state.operator?.personify) {
        return [state.selectedService, state.services];
      }
      const entries = Object.entries(state.operator.personify);
      const services = entries.map(
          ([hash, servicename]) => ({hash, servicename}));
      if (entries.length > 1) {
        return [state.selectedService, services];
      }
      return [entries[0][0], services];
    });
  console.log(services);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'day').toDate());
  const [endDate, setEndDate] = useState(startDate);
  const [report, setReport] = useState({
    service: selectedService,
    date: moment(startDate).format('x'),
  });
  const [downloading, setDownload] = useState(false);
  const [hoverDate, setHoverDate] = useState(startDate);
  const highlightDays = [
    {
      'react-datepicker__day_hover': (hoverDate ?
        Array(7).fill(new Date(hoverDate)).map((el, idx) =>
          new Date(el.setDate(el.getDate() - el.getDay() + idx + 1))) :
        []),
    },
  ];
  const ref = useRef();
  const canDownload = () => {
    return (services.length <= 1 || (services.length > 1 && report.service)) &&
      report.period && report.type && report.date;
  };

  const fileName = ({service, date, type, period}) => {
    const clientService = services.filter(({hash}) => hash === service);
    let servicename = '';
    if (clientService.length !== 0) {
      servicename = clientService[0]?.servicename;
    } else if (service === 'all' || !!service) {
      servicename = services[0]?.servicename ?? '';
    }
    const dateFunction = {
      daily: () => moment(date, 'x').format('YYYY-MM-DD'),
      weekly: () => moment(date, 'x').format('\\W\\e\\e\\k #WW YYYY'),
      monthly: () => moment(date, 'x').format('YYYY-MM'),
    };
    const nameType = type[0].toUpperCase() + type.slice(1);
    return `[${servicename}] ${nameType} Report` +
      ` - ${dateFunction[period]?.() ?? dateFunction['daily']?.()}.pdf`;
  };

  const maxDate = useCallback(() => {
    const date = moment();
    if (report.period === 'weekly') {
      return date.startOf('week').subtract(1, 'day').toDate();
    } else if (report.period === 'monthly') {
      return date.startOf('month').subtract(1, 'day').toDate();
    }
    return date.subtract(1, 'day').toDate();
  }, [report.period]);

  const formatDate = () => {
    let format = 'dd-MM-yyyy';
    if (report.period === 'weekly') {
      format = '#II yyyy';
    } else if (report.period === 'monthly') {
      format = 'MM-yyyy';
    }
    return format;
  };

  useEffect(() => {
    if (report.period && report.date) {
      if (moment(report.date, 'x').startOf('day') > maxDate()) {
        setReport((report) => ({
          ...report, date: moment(maxDate()).format('x'),
        }));
      }
      if (report.period === 'daily') {
        setStartDate(moment(report.date, 'x').toDate());
        setEndDate(moment(report.date, 'x').toDate());
      } else if (report.period === 'weekly') {
        setEndDate(moment(report.date, 'x').endOf('week').toDate());
        setStartDate(moment(report.date, 'x').startOf('week').toDate());
      } else if (report.period === 'monthly') {
        setEndDate(moment(report.date, 'x').endOf('month').toDate());
        setStartDate(moment(report.date, 'x').startOf('month').toDate());
      }
    }
  }, [report, maxDate]);

  const downloadReport = () => {
    getFetch(`reports/download`, report).then(async (response) => {
      return await response.blob();
    }).then((file) => {
      const downloadName = fileName(report);
      fileDownload(file, downloadName ?? 'report.pdf');
      setDownload(false);
    }).catch(async (error) => {
      console.log(error);
      let message = 'Unknown error';
      if (error?.headers?.get('Content-Type')?.includes('json')) {
        message = await error.json();
      } else if (error?.text) {
        message = await error.text();
      }
      displayError(`Error downloading the report:`, [message]);


      setDownload(false);
    });
  };

  useEffect(() => {
    setReport((report) => {
      if (selectedService === 'all') {
        delete report.service;
        return report;
      }
      return {
        ...report, service: selectedService,
      };
    });
  }, [selectedService]);

  return (<SwipeableDrawer
    anchor="right"
    onClose={() => close()}
    onOpen={() => false}
    open={open}
    data-test="top-bar-faq-menu"
  >
    <div
      style={{
        width: 360, height: '100%', display: 'flex', flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid #E4E4E4',
          background: '#BE131A',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            height: 60, color: '#E4E4E4',
          }}
        >
          <h3>Download Report</h3>
        </div>
      </div>
      <div>
        <div style={{padding: 25}}>

          <Grid container spacing={1}>
            {selectedService === 'all' && <Grid item xs={12}>

              <FormControl style={{marginTop: 10, width: '100%'}}>
                <FormLabel
                  style={{
                    fontSize: '14px',
                    color: '#464646',
                    padding: '.5rem 0',
                    lineHeight: '1.5rem',
                  }}
                >
                  Select Service
                </FormLabel>
                <RedTextField
                  {...propsRedTextField}
                  select
                  placeholder={'Select Service'}
                  value={report.service ?? ''}
                  onChange={({target: {value}}) => setReport((report) => ({
                    ...report, service: value,
                  }))}
                >
                  {services.map((service) => {
                    return <MenuItem key={service.hash} value={service.hash}>
                      <ServiceRender hash={service.hash}/>
                    </MenuItem>;
                  })}
                </RedTextField>
              </FormControl>
            </Grid>}
            <Grid item xs={12}>
              <FormControl style={{margin: 0, width: '100%'}}>
                <FormLabel
                  style={{
                    fontSize: '14px',
                    color: '#464646',
                    padding: '.5rem 0',
                    lineHeight: '1.5rem',
                  }}
                >
                  Report Period
                </FormLabel>
                <RedTextField
                  {...propsRedTextField}
                  select
                  placeholder={'Select a period'}
                  value={report.period ?? ''}
                  onChange={({target: {value}}) => setReport((report) => ({
                    ...report, period: value,
                  }))}
                >
                  {['Daily', 'Weekly', 'Monthly'].map((value) => {
                    return <MenuItem key={value} value={value.toLowerCase()}>
                      {value}
                    </MenuItem>;
                  })}

                </RedTextField>

              </FormControl>
            </Grid>
            <Grid item xs={12}>

              <FormControl style={{marginTop: 10, width: '100%'}}>
                <FormLabel
                  style={{
                    fontSize: '14px',
                    color: '#464646',
                    padding: '.5rem 0',
                    lineHeight: '1.5rem',
                  }}
                >
                  Report Type
                </FormLabel>
                <RedTextField
                  {...propsRedTextField}
                  select
                  placeholder={'Select Report Type'}
                  value={report.type ?? ''}
                  onChange={({target: {value}}) => setReport((report) => ({
                    ...report, type: value,
                  }))}
                >
                  {['Executive', 'Detailed'].map((value) => {
                    return <MenuItem key={value} value={value.toLowerCase()}>
                      {value}
                    </MenuItem>;
                  })}

                </RedTextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{marginTop: 10, width: '100%'}}>
                <FormLabel
                  style={{
                    fontSize: '14px',
                    color: '#464646',
                    padding: '.5rem 0',
                    lineHeight: '1.5rem',
                  }}
                >
                  {report.period === 'weekly' ?
                    'Select a week' : report.period === 'monthly' ?
                      'Select a month' : 'Select a day'}
                </FormLabel>
                <DatePicker
                  disabledKeyboardNavigation={true}
                  dateFormat={formatDate()}
                  calendarStartDay={1}
                  showPopperArrow={false}
                  showWeekNumbers={report.period === 'weekly'}
                  showMonthYearPicker={report.period === 'monthly'}
                  onWeekSelect={(date) => {
                    const startDate = moment(date).startOf('week').toDate();
                    const endDate = moment(date).endOf('week').toDate();
                    setStartDate(startDate);
                    setEndDate(endDate);
                    setReport((report) => {
                      return {
                        ...report, date: moment(date).format('x'),
                      };
                    });
                  }}
                  onDayMouseEnter={(date) => {
                    setHoverDate(date);
                  }}
                  highlightDates={report.period === 'weekly' ?
                    highlightDays :
                    []}
                  onChange={(date) => {
                    let startDate = date;
                    let endDate = startDate;

                    if (report.period === 'weekly') {
                      startDate = moment(date).startOf('week').toDate();
                      endDate = moment(date).endOf('week').toDate();
                    } else if (report.period === 'monthly') {
                      startDate = moment(date).startOf('month').toDate();
                      endDate = moment(date).endOf('month').toDate();
                    }
                    setStartDate(startDate);
                    setEndDate(endDate);
                    setReport((report) => {
                      return {
                        ...report, date: moment(startDate).format('x'),
                      };
                    },
                    );
                  }}
                  startDate={startDate}
                  endDate={maxDate() > endDate ? endDate : maxDate()}
                  selected={startDate}
                  customInput={<RedTextField
                    ref={ref}
                    {...propsRedTextField}
                  />
                  }
                  minDate={moment(new Date()).subtract(3, 'month').toDate()}
                  maxDate={maxDate()}

                />
              </FormControl>

            </Grid>

            <Grid item xs={12}>
              <FormControl style={{marginTop: 30, width: '100%'}}>
                <ButtonForm style={{padding: 0, margin: 0}}
                  className={'noUpper'}
                  disabled={!canDownload() || downloading}
                  onClick={() => setDownload(true) || downloadReport()}
                >
                  {!downloading && 'Download Report'}
                  {downloading && 'Downloading...'}
                </ButtonForm>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div style={{}}>
                {!['daily', 'weekly', 'monthly'].includes(report.period) &&
                  'Report Period is based in UTC Time'}
                {report.period === 'daily' &&
                  'The Daily Report evaluates the scope of DNS events' +
                  ' that occur between 00:00 and 23:59 in UTC Time.'}
                {report.period === 'weekly' &&
                  'The Weekly Report evaluates the scope of DNS events' +
                  ' that occur between Monday and Sunday in UTC Time.'}
                {report.period === 'monthly' &&
                  'The Monthly Report evaluates the scope of DNS events' +
                  ' that occur between the first day and the last day of' +
                  ' the month in UTC time.'}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  </SwipeableDrawer>);
};
ReportsDownloader.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ReportsDownloader;
