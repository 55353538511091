import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBug,
  faBullhorn,
  faCircle,
  faFish, faGlobe,
  faGlobeEurope,
  faList,
  faListAlt,
  faMedal,
  faRobot,
  faSlash,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import {List, ListItem, Tooltip} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import {ServiceRender} from './Service';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import PropTypes from 'prop-types';

export const colorMap = {
  // 'tunneling': {
  //   text: 'Tunneling',
  //   color: '#800080',
  //   icon: <FontAwesomeIcon icon={faArrowRight} style={{margin: 'auto'}}/>,
  //   contrastText: '#000',
  // },
  'adware': {
    text: 'Adware',
    color: '#1870B4',
    icon: <FontAwesomeIcon icon={faBullhorn} style={{margin: 'auto'}}/>,
    contrastText: '#FFF',
  },
  'anonymizers': {
    text: 'Anonymizer',
    color: '#E1872B',
    icon: <FontAwesomeIcon icon={faUserSecret} style={{margin: 'auto'}}/>,
    contrastText: '#FFF',
  },
  'anonymizers-validated': {
    text: 'Anonymizer',
    color: '#E1872B',
    icon: <FontAwesomeIcon icon={faUserSecret} style={{margin: 'auto'}}/>,
    contrastText: '#FFF',
  },
  'blacklist': {
    text: <b>Blacklist</b>,
    color: '#000',
    icon: <FontAwesomeIcon icon={faListAlt} style={{margin: 'auto'}}/>,
    contrastText: '#FFF',
  },

  'blacklist-root': {
    text: <b>Blacklist</b>,
    color: '#000',
    icon: <FontAwesomeIcon icon={faListAlt} style={{margin: 'auto'}}/>,
    contrastText: '#FFF',
  },

  'dga': {
    text: 'DGA',
    color: '#EDAE30',
    icon: <FontAwesomeIcon icon={faRobot} style={{margin: 'auto'}}/>,
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  'malware': {
    color: '#BE131A',
    icon: <FontAwesomeIcon icon={faBug} style={{margin: 'auto'}}/>,
    text: 'Malware',
    contrastText: '#FFF',
  },
  'malware-validated': {
    color: '#BE131A',
    icon: <FontAwesomeIcon icon={faBug} style={{margin: 'auto'}}/>,
    text: 'Malware',
    contrastText: '#FFF',
  },
  'phishing': {
    color: '#CA3A20',
    icon: <FontAwesomeIcon icon={faFish} style={{margin: 'auto'}}/>,
    text: 'Phishing',
    contrastText: '#FFF',
  },
  'phishing-validated': {
    color: '#CA3A20',
    icon: <FontAwesomeIcon icon={faFish} style={{margin: 'auto'}}/>,
    text: 'Phishing',
    contrastText: '#FFF',
  },
  'ignore': {
    color: 'rgba(70, 70, 70, 0.6)',
    icon: (
      <span
        style={{
          fontFamily: 'MagistralC',
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'center',
        }}
      >
        C
      </span>
    ),
    text: 'Clean',
    contrastText: '#FFF',
  },
  'vt': {
    color: '#1870B4',
    icon: (
      <span
        style={{
          fontFamily: 'MagistralC',
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'center',
        }}
      >
        VT
      </span>
    ),
    text: 'WL VirusTotal',
    contrastText: '#FFF',
  },
  'reputation': {
    color: '#D66125',
    text: 'Reputation',
    icon: (
      <FontAwesomeIcon
        icon={faSlash}
        mask={faMedal}
        fixedWidth
        style={{margin: 'auto'}}
      />
    ),
    contrastText: '#FFF',
  },
  'reputation-validated': {
    color: '#D66125',
    text: 'Reputation',
    icon: (
      <FontAwesomeIcon
        icon={faSlash}
        mask={faMedal}
        fixedWidth
        style={{margin: 'auto'}}
      />
    ),
    contrastText: '#FFF',
  },

  'falsedga': {
    color: '#EDAE30',
    text: 'False DGA',
    icon: (
      <FontAwesomeIcon
        icon={faSlash}
        mask={faRobot}
        fixedWidth
        style={{margin: 'auto'}}
      />
    ),
    contrastText: '#FFF',
  },

  'whitelist': {
    text: 'Whitelist',
    color: '#000',
    icon: <FontAwesomeIcon icon={faList} style={{margin: 'auto'}}/>,
    contrastText: '#000',
  },
};

/**
 *
 * @param{string} list
 * @param{boolean} withLabel
 * @param{object} style
 * @return {JSX.Element}
 * @constructor
 */
export function RenderCategory({list = '', withLabel = true, style}) {
  const [l, service] = list.split('-');
  const {color, icon, text} = colorMap[l] || {
    color: 'rgba(70, 70, 70, 0.6)',
    icon: false,
    text: '-',
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: 5,
        color,
        ...style,
      }}
    >
      <div style={{width: 18, display: 'flex'}}>
        <div style={{margin: 'auto'}}>{icon}</div>
      </div>
      {withLabel && <div style={{margin: 'auto 0 auto 5px'}}>{text}</div>}
      {service === 'root' && (
        <Tooltip title="Global" style={{margin: 'auto 0 auto 5px'}}>
          <span>
            <FontAwesomeIcon icon={faGlobeEurope}/>
          </span>
        </Tooltip>
      )}
      {service === 'validated' && (
        <Tooltip title="Validated" style={{margin: 'auto 0 auto 5px'}}>
          <span>
            <FontAwesomeIcon icon={faCheck}/>
          </span>
        </Tooltip>
      )}
    </div>
  );
}

RenderCategory.propTypes = {
  list: PropTypes.string,
  withLabel: PropTypes.bool,
  style: PropTypes.object,
};

/**
 *
 * @param{string} list
 * @param{[]} clientnames
 * @param{boolean} withLabel
 * @param{object} style
 * @return {JSX.Element}
 * @constructor
 */
export function RenderCategoryChip({
  list,
  clientnames = [],
  withLabel = true,
  style,
}) {
  let [l, service] = list.split('-');

  if (service === 'root' && l === 'blacklist') {
    l = 'blacklist-root';
  }

  let {color, icon, text} = colorMap[l] || {
    color: 'rgba(70, 70, 70, 0.6)',
    icon: <FontAwesomeIcon icon={faCircle} style={{margin: 'auto'}}/>,
    text: '-',
    contrastText: '#fff',
  };

  const uniqueClientNames = [...new Set(clientnames)];

  if (uniqueClientNames.length > 1) {
    text = (
      <>
        {text} ({uniqueClientNames.length})
      </>
    );
  }

  if (service === 'root') {
    text = (
      <>
        {text} <FontAwesomeIcon icon={faGlobeEurope}/>
      </>
    );
  }

  let avatar = (
    <div
      style={{
        color,
        margin: 'auto 0px auto 6px',
        fontSize: '1rem',
        display: 'flex',
      }}
    >
      {icon}
    </div>
  );
  if (!withLabel) {
    text = icon;
    avatar = null;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Chip
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        avatar={avatar}
        label={text}
        variant="outlined"
        style={{
          border: `1px solid ${color}`,
          backgroundColor: 'white',
          color,
          ...style,
        }}
      />

      {uniqueClientNames.length > 1 && (
        <Popover
          id="mouse-over-popover"
          style={{pointerEvents: 'none'}}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <List dense>
            {uniqueClientNames.map((hash) => (
              <ListItem key={hash}>
                <ServiceRender hash={hash}/>
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
    </>
  );
}

RenderCategoryChip.propTypes = {
  list: PropTypes.string,
  clientnames: PropTypes.array,
  withLabel: PropTypes.bool,
  style: PropTypes.object,
};

