const authenticateReducer = (state = false, action) => {
  switch (action.type) {
    case 'LOGGED_IN':
      return true;
    case 'LOGOUT':
      return false;
    default:
      return state;
  }
};

export default authenticateReducer;
