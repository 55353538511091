import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {withRouter} from 'react-router';
import Grid from '@material-ui/core/Grid';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  useMediaQuery,
  useTheme,
  styled,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import TextField from '@material-ui/core/TextField';
import punycode from 'punycode/punycode';
import Chip from '@material-ui/core/Chip';
import {faCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import DeleteIcon from '@material-ui/icons/Delete';
import {ServiceRender} from '../Service';
import CheckForm from '../../Components/Forms/CheckForm';
import BoxInfo from '../../Components/BoxInfo';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';
import Spinner from '../../Components/Spinner';
import {
  useDeleteDataBackend,
  useGetDataBackend,
  usePostDataBackend,
  usePutDataBackend,
} from '../../ApiBackend';
import {colorMap, RenderCategory} from '../Category';
import ButtonForm from '../../Components/Forms/ButtonForm';
import RedTextField from '../../core/components/inputs/RedTextField';
import {setPath} from '../../actions/pathAction';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import {
  Paragraph,
  defaultColor,
  ExternalValidationDomainInfo,
  WhoIsTable,
} from './DomainInfoUtils/DomainInfoUtils';
import RedButton from '../../core/components/buttons/RedButton';


const [whitelistRoot, blacklistRoot, ignoreRoot] =
  ['whitelist-root', 'blacklist-root', 'ignore-root'];

const inlineFlex = 'inline-flex';

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function DomainInfoAdmin(props) {
  const externalDomain = props?.match?.params?.domain;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Domain Info'));
    return () => null;
  }, [dispatch]);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'overlay',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return <Grid
    container
    className={classes.grid}
  >
    <Grid
      item
      xs={12}
      style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
    >
      <DomainAdmin domain={externalDomain}/>
    </Grid>
  </Grid>;
}

DomainInfoAdmin.propTypes = {
  match: PropTypes.object,
};


const Source = styled(({children, ...props}) => {
  return <span {...props}>{children ?? ''}</span>;
})({
  'cursor': 'pointer',
  'whiteSpace': 'nowrap',
  'overflow': 'hidden',
  'textOverflow': 'ellipsis',
  '&:hover': {
    background: 'white',
    overflow: 'unset',
    padding: '5px 5px 5px 0px',
  },
});


/**
 *
 * @param{string} d
 * @return {JSX.Element}
 * @constructor
 */
function DomainAdmin({domain: d = ''}) {
  const [domain, setDomain] = useState(d);
  const [searchedDomain, setSearchedDomain] = useState(d);
  const [cats, setCats] = useState([]);
  const [refresh, setRefresh] = useState(0);

  return <BoxInfo title={<b>Search Domain</b>}>
    <div
      style={{
        margin: '1rem',
        display: 'grid',
        gridGap: '1rem',
        gridTemplateColumns: 'repeat(auto-fit,minmax(240px,1fr))',
      }}
    >
      <div style={{alignSelf: 'flex-start', paddingTop: 6}}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <RedIconButton
              TooltipProps={{title: 'Search'}}
              size="small"
              style={{width: 40, height: 40}}
              onClick={() => setSearchedDomain(domain)}
            >
              <FontAwesomeIcon icon={faSearch}/>
            </RedIconButton>
          </Grid>
          <Grid item style={{flex: 1}}>
            <RedTextField
              value={domain}
              onChange={({target: {value}}) => setDomain(value)}
              size="small"
              variant="outlined"
              style={{width: '100%', height: 40}}
              placeholder="example.com"
              onKeyPress={({target: {value}, key}) =>
                key === 'Enter' && setSearchedDomain(value)
              }
              onBlur={({target: {value}}) => setSearchedDomain(value)}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{alignSelf: 'center'}}>
        <AddDomainFormAlt
          searchedDomain={searchedDomain}
          domainCats={cats}
          setRefresh={() => setRefresh((_) => _ + 1)}
        />
      </div>
    </div>
    <div style={{padding: '1rem'}}>
      <div
        style={{
          margin: '0',
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: 'repeat(auto-fit,minmax(170px,1fr))',
        }}
      >
        <div>
          {searchedDomain && <DomainCategories
            domain={searchedDomain}
            setCats={setCats}
            refresh={refresh}
            cats={cats}
            setRefresh={() => setRefresh((_) => _ + 1)}
          />}
          {!searchedDomain && <>
            <h3> DNS8 Categories</h3>
            <Paragraph>Search a Domain</Paragraph>
          </>}
          <h3>External Validation</h3>
          <ExternalValidationDomainInfo domain={searchedDomain}/>
          {!domain && (
            <Paragraph>
              Search a Domain
            </Paragraph>
          )}
        </div>
        <div style={{gridColumn: 'auto / span 2'}}>
          {searchedDomain && <WhoIsTable domain={searchedDomain}/>}
          {!searchedDomain && <>
            <h3>Who Is</h3>
            <Paragraph>Search a Domain </Paragraph>
          </>}
        </div>
      </div>
    </div>
    <div style={{padding: '1rem'}}/>
  </BoxInfo>;
}

DomainAdmin.propTypes = {
  domain: PropTypes.string,
};

/**
 *
 * @param{string} domain
 * @param{any[]} cats
 * @param{function} setCats
 * @param{function} setRefresh
 * @param{number} refresh
 * @return {JSX.Element}
 * @constructor
 */
function DomainCategories({domain, cats, setCats, setRefresh, refresh}) {
  const {
    data: {categories = []},
    loading,
    error,
  } = useGetDataBackend('domain/category/root', {domain, refresh});
  useEffect(() => {
    let update = true;
    if (update && !loading && !error) {
      const newCats = Object.entries(
          categories.reduce((acc, cat) => {
            if (!acc[cat.list]) {
              acc[cat.list] = [];
            }
            acc[cat.list].push(cat);
            return acc;
          }, {}),
      );
      setCats(newCats);
    }
    return () => {
      update = false;
    };
  }, [loading, error, categories, setCats]);

  const xs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      <h3>DNS8 Categories</h3>
      <List>
        {!loading &&
          !error &&
          !!cats.length &&
          cats.map(([list, data], i) => {
            const listItemKey = 'listitem-' + i;
            if (data.deleted) {
              return <></>;
            }
            return <>
              <ListItem key={listItemKey}>
                <ListItemAvatar>
                  <RenderCategoryChip
                    data={data}
                    list={list}
                    withLabel={!xs}
                    setRefresh={setRefresh}
                  />
                </ListItemAvatar>
                <ListItemSecondaryAction>
                  <RenderFalsePositiveButton
                    list={list}
                    data={data}
                    setRefresh={setRefresh}
                  />
                </ListItemSecondaryAction>

                &nbsp;<Source title={'Click to copy'}
                  onClick={() => {
                    navigator.clipboard.writeText(data?.[0].source);
                  }}>
                  {data?.[0].source}
                </Source>

              </ListItem>
            </>;
          })}
        {!loading && !error && !cats.length && (
          <ListItem>
            <Chip
              label="No information"
              variant="outlined"
              style={{color: 'black', borderColor: 'black'}}
            />
          </ListItem>
        )}
        {(loading || error) && (
          <ListItem>
            <Spinner/>
          </ListItem>
        )}
      </List>
    </>
  );
}

DomainCategories.propTypes = {
  domain: PropTypes.string,
  cats: PropTypes.array,
  setCats: PropTypes.func,
  setRefresh: PropTypes.func,
  refresh: PropTypes.number,
};

/**
 *
 * @param{string} list
 * @param{object[]} data
 * @param{function} setRefresh
 * @return {JSX.Element|boolean}
 * @constructor
 */
function RenderFalsePositiveButton({list, data, setRefresh}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [l, service] = list.split('-');
  if (['blacklist', 'whitelist'].includes(l) && service === 'root') {
    return <>
      <ButtonForm onClick={() => setShowConfirm(true)}>
        <FontAwesomeIcon style={{marginRight: '.5rem'}}
          icon={faTrash}/>{' '}
        REMOVE
      </ButtonForm>
      {showConfirm && (
        <ConfirmDeleteDomainDialog
          data={{...data[0], client_name: data[0].clientname}}
          setOpen={setShowConfirm}
          setRefresh={setRefresh}
        />
      )}
    </>;
  }
  return false;
}

RenderFalsePositiveButton.propTypes = {
  list: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{object} data
 * @param{func} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function ConfirmReplaceListDomainDialog({data, setRefresh}) {
  const [list, setList] = useState('');
  const [comment, setComment] = useState('');

  const [submitting, setSubmitting] = useState();
  const mapReplace = {
  };
  const onClickCategory = (selectedList) => () => {
    setList(selectedList);
    displayConfirmation(
        <>
        Do you really want to change the domain {data.domain} classification
        to{' '}
          {
            <RenderCategory
              style={{display: inlineFlex, lineHeight: '14px'}}
              list={mapReplace[selectedList]}
            />
          }
        ?
          <TextField
            style={{marginTop: 20}}
            placeholder="Please insert here a motive for this decision."
            label="Insert a motive for this decision."
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            onBlur={({target: {value}}) => setComment(value)}
          />
        </>,

        'REPLACE',
        'CANCEL',
        () => setSubmitting(true),
    );
  };
  const text =
    data.list === ignoreRoot ? 'Add to LAYER8 Intel' : 'Add to Clean';
  return <>
    <ButtonForm onClick={onClickCategory(data.list)}>{text}</ButtonForm>
    {submitting && (
      <ChangeClassification
        doc={{domain: data.domain, list, comment}}
        setRefresh={setRefresh}
        setSubmitting={setSubmitting}
      />
    )}
  </>;
}

ConfirmReplaceListDomainDialog.propTypes = {
  data: PropTypes.object,
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{object} doc
 * @param{function} setSubmitting
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function ChangeClassification({doc, setSubmitting, setRefresh}) {
  const {loading, error} = usePostDataBackend('list/root/replace', doc);
  useEffect(() => {
    if (!loading && error) {
      displayError('Error changing domain:', error);
      setSubmitting(false);
    } else if (!loading) {
      setSubmitting(false);
      setRefresh();
    }
  }, [loading, error, setSubmitting, setRefresh]);

  return <></>;
}

ChangeClassification.propTypes = {
  doc: PropTypes.object,
  setSubmitting: PropTypes.func,
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{object} data
 * @param{function} setOpen
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function ConfirmDeleteDomainDialog({data, setOpen, setRefresh}) {
  const [deleting, setDeleting] = useState(false);
  return (
    <Dialog open={!!data}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          Remove {data.domain} from &nbsp;
          <RenderCategory list={data.list}/> ?
        </div>
      </DialogTitle>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}}/>
          Close
        </ButtonForm>
        <ButtonForm
          variant="outlined"
          className="sec"
          type="button"
          onClick={(_) => setDeleting(true)}
        >
          <DeleteIcon/> Remove
        </ButtonForm>
        {deleting && (
          <DeleteDomains
            ids={[data.id]}
            setDeleting={setDeleting}
            setRefresh={() => setOpen(false) || setRefresh()}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDeleteDomainDialog.propTypes = {
  data: PropTypes.object,
  setOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{string[]} ids
 * @param{function} setDeleting
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function DeleteDomains({ids, setDeleting, setRefresh}) {
  const {loading, error} = useDeleteDataBackend('list/root', ids);
  useEffect(() => {
    if (!loading && error) {
      displayError('Error deleting domain:', error);
      setDeleting(false);
    } else if (!loading) {
      setDeleting(false);
      setRefresh();
    }
  }, [loading, error, setDeleting, setRefresh]);

  return <></>;
}

DeleteDomains.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  setDeleting: PropTypes.func,
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{any[]} data
 * @param{string} list
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function RenderCategoryChip({data, list, setRefresh}) {
  let [l, servicename] = list.split('-');
  if (['root', 'validated'].includes(servicename)) {
    l = list;
  }
  const [showConfirm, setShowConfirm] = useState(false);

  const {color} = colorMap[l] || {
    defaultColor,
    icon: <FontAwesomeIcon icon={faCircle} style={{margin: 'auto'}}/>,
    text: '-',
    contrastText: '#fff',
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <div
      style={{
        border: `1px solid ${color}`,
        padding: '.25rem .5rem',
        borderRadius: '1rem',
        width: 'fit-content',
        display: inlineFlex,
        cursor: 'pointer',
      }}
      onClick={data.some((d) => d.clientname !== 'root') ?
        handleClickOpen :
        undefined}
    >
      <RenderCategory list={l}/>
      {data.some((d) => d.clientname !== 'root') && (
        <Avatar
          style={{
            width: '1rem',
            height: '1rem',
            fontSize: '.75rem',
            margin: 'auto',
          }}
        >
          {data.length}
        </Avatar>
      )}
    </div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`${list}-services`}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id={`${list}-services`}>
        {' '}
        <RenderCategory list={list} style={{display: inlineFlex}}/> in
        services
      </DialogTitle>
      <DialogContent>
        <List>
          {data.map((d, i) => {
            const listItemKey = 'list-item-' + i;
            return (
              <ListItem key={listItemKey}>
                <ListItemAvatar style={{padding: '.5rem'}}>
                  <ServiceRender hash={d.clientname}/>
                </ListItemAvatar>
                <ListItemSecondaryAction>
                  <ButtonForm onClick={() => setShowConfirm(true)}>
                    <FontAwesomeIcon
                      style={{marginRight: '.5rem'}}
                      icon={faTrash}
                    />{' '}
                    REMOVE
                  </ButtonForm>
                  {showConfirm && (
                    <ConfirmDeleteDomainDialog
                      data={{
                        ...data[0],
                        client_name: data[0].clientname,
                      }}
                      url="list/root"
                      setOpen={setShowConfirm}
                      setRefresh={setRefresh}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <ButtonForm onClick={handleClose} color="primary">
          Close
        </ButtonForm>
      </DialogActions>
    </Dialog>
  </>;
}

RenderCategoryChip.propTypes = {
  data: PropTypes.array,
  list: PropTypes.string,
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{string} domain
 * @param{any[]} domainCats
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function AddDomainFormAlt({searchedDomain: domain, domainCats, setRefresh}) {
  const list = useRef('');
  const [submitting, setSubmitting] = useState();
  const [open, setOpen] = useState(false);
  const onClickCategory = (currentList) => () => {
    list.current = currentList;
    setOpen(true);
  };

  return (
    <div
      style={{
        margin: '0',
        display: 'grid',
        gridGap: '1rem',
        gridTemplateColumns: 'repeat(auto-fit,minmax(170px,1fr))',
      }}
    >
      <ButtonForm
        disabled={
          !domain ||
          submitting ||
          domainCats.some((cat) => cat[0] === whitelistRoot)
        }
        variant="outlined"
        className="sec"
        onClick={onClickCategory(whitelistRoot)}
      >
        <RenderCategory list={whitelistRoot}/>
      </ButtonForm>
      <ButtonForm
        disabled={
          !domain ||
          submitting ||
          domainCats.some((cat) => cat[0] === blacklistRoot)
        }
        variant="outlined"
        onClick={onClickCategory(blacklistRoot)}
      >
        <RenderCategory list={blacklistRoot} style={{color: 'white'}}/>
      </ButtonForm>
      <ButtonForm
        disabled={
          !domain || submitting || domainCats.some((cat) => cat[0] === 'vt')
        }
        variant="outlined"
        className="vt"
        onClick={onClickCategory('vt')}
      >
        <RenderCategory list="vt"/>
      </ButtonForm>
      <ButtonForm
        disabled={true}
        variant="outlined"
        className="false-dga"
        onClick={onClickCategory('falsedga')}
      >
        <RenderCategory list="falsedga"/>
      </ButtonForm>
      <ShowModalAddDomain
        open={open}
        setOpen={setOpen}
        setSubmitting={setSubmitting}
        list={list.current}
        domain={domain}
      />
      {submitting && <AddDomain
        domain={domain}
        list={list.current}
        subDomain={submitting.subDomain}
        setSubmitting={setSubmitting}
        setRefresh={setRefresh}
        comment={submitting.comment}
      />}
    </div>
  );
}

AddDomainFormAlt.propTypes = {
  searchedDomain: PropTypes.string,
  domainCats: PropTypes.array,
  setRefresh: PropTypes.func,
};

/**
 *
 * @param{boolean} open
 * @param{function} setSubmitting
 * @param{function} setOpen
 * @param{string} list
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
function ShowModalAddDomain({open, setSubmitting, setOpen, list, domain}) {
  const [comment, setComment] = useState('');

  const [subDomain, setSubDomain] = useState(false);
  const closeAndUpdate = () => {
    setOpen(false);
    setSubmitting({comment, subDomain});
  };

  return <Dialog open={open} maxWidth={'xs'} fullWidth={false}
    onClose={() => setOpen(false) || setSubmitting(false)}>
    <DialogTitle>
      Do you really want to add the domain {domain} in{' '}
      {
        <RenderCategory
          style={{display: inlineFlex, lineHeight: '14px'}}
          list={list}
        />
      }?
    </DialogTitle>
    <DialogContent>
      {[whitelistRoot, blacklistRoot].includes(list) && (
        <CheckForm
          style={{marginTop: 20}}
          label="Include Sub Domains"
          checked={subDomain}
          sideEffect={setSubDomain}
          labelPlacement="start"
        />
      )}
      <RedTextField
        style={{marginTop: 20}}
        placeholder="Please insert a motive or Ticket
         Number for this decision."
        label="Please insert a motive or Ticket Number for this decision."
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        onChange={({target: {value}}) => setComment(value)}
      />
      <div style={{display: 'flex'}}>
        <RedButton
          variant={'contained'}
          style={{margin: '20px 10px 20px 0px', flex: 1}}
          onClick={closeAndUpdate}
          disabled={!comment.length}
        >
          ADD
        </RedButton>

        <RedButton
          variant={'outlined'}
          style={{margin: '20px 0px 20px 10px', flex: 1}}
          onClick={() => setOpen(false) || setSubmitting(false)}
        >
          CANCEL
        </RedButton>
      </div>

    </DialogContent>

  </Dialog>;
}

ShowModalAddDomain.propTypes = {
  open: PropTypes.bool,
  setSubmitting: PropTypes.func,
  setOpen: PropTypes.func,
  list: PropTypes.string,
  domain: PropTypes.string,
};

/**
 *
 * @param{function} setSubmitting
 * @param{function} setRefresh
 * @param{string} list
 * @param{string} domain
 * @param{boolean} subDomain
 * @param{string} comment
 * @return {boolean}
 * @constructor
 */
function AddDomain({
  setSubmitting,
  setRefresh,
  list,
  domain,
  subDomain,
  comment,
}) {
  const asciiDomain = punycode.toASCII(domain);
  const {loading, error} = usePutDataBackend(`list/root/${list}`, {
    domain: asciiDomain,
    sub_domain: subDomain,
    comment,
  });

  useEffect(() => {
    if (!loading && error) {
      displayError('Error adding domain:', error);
      setSubmitting(false);
    } else if (!loading) {
      setSubmitting(false);
      setRefresh();
    }
  }, [loading, error, setSubmitting, setRefresh]);

  return false;
}

AddDomain.propTypes = {
  setSubmitting: PropTypes.func,
  setRefresh: PropTypes.func,
  list: PropTypes.string,
  domain: PropTypes.string,
  subDomain: PropTypes.bool,
  comment: PropTypes.string,

};


export default withRouter(DomainInfoAdmin);
