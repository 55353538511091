import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import {MenuItem} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';

import {usePutDataBackend} from '../../ApiBackend';
import RedTextField from '../../core/components/inputs/RedTextField';

export default function AddReportForm({sideEffect = () => null}) {
  const [service, userMail] = useSelector((state) => [
    state.selectedService,
    state.keycloak.tokenParsed.email,
  ]);
  const [mails, setMails] = useState(userMail);
  const [period, setPeriod] = useState('');
  const [report, setReport] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const activeForm = !!period;

  function CreateReport(props) {
    const {loading, error} = usePutDataBackend('reports/', {...props});
    useEffect(() => {
      if (error) {
        displayError('Error creating report:', error);
        setSubmitting(false);
      } else if (!loading) {
        setSubmitting(false);
        sideEffect();
      }
    }, [loading, error]);

    return <></>;
  }

  const typeOptions = [
    {label: 'Executive', value: 'executive'},
    {label: 'Detailed', value: 'detailed'},
  ];

  const periodOptions = [
    {label: 'Daily', value: 'daily'},
    {label: 'Weekly', value: 'weekly'},
    {label: 'Monthly', value: 'monthly'},
  ];

  const propsRedTextField = {
    variant: 'outlined',
    size: 'small',
    style: {
      backgroundColor: 'white',
    },
    SelectProps: {
      displayEmpty: true,
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      },
    },
  };

  return (
    <>
      <form
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          margin: '3px -3px -6px',
          flexWrap: 'wrap',
          minHeight: '78px',
        }}
      >
        <FormControl style={{margin: 3}}>
          <FormLabel
            style={{fontSize: '14px', color: '#464646', padding: '.5rem 0'}}
          >
              Report type
          </FormLabel>
          <RedTextField
            {...propsRedTextField}
            select={true}
            style={{width: 170, ...propsRedTextField.style}}
            placeholder="Select a report type"
            value={report}
            onChange={({target: {value}}) => setReport(value)}
          >
            {typeOptions.map(({label, value}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </RedTextField>
        </FormControl>
        <FormControl style={{margin: 3}}>
          <FormLabel
            style={{fontSize: '14px', color: '#464646', padding: '.5rem 0'}}
          >
              Send to
          </FormLabel>
          <RedTextField
            {...propsRedTextField}
            placeholder="ex: test@test.pt"
            value={mails}
            onChange={({target: {value}}) => setMails(value)}
          />
        </FormControl>
        <FormControl style={{margin: 3}}>
          <FormLabel
            style={{fontSize: '14px', color: '#464646', padding: '.5rem 0'}}
          >
              Report period
          </FormLabel>
          <RedTextField
            {...propsRedTextField}
            select={true}
            style={{width: 170, ...propsRedTextField.style}}
            placeholder="Select a Period"
            value={period}
            onChange={({target: {value}}) => setPeriod(value)}
          >
            {periodOptions.map(({label, value}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </RedTextField>
        </FormControl>
        {/* <SelectForm label={"Report period"} */}
        {/*            value={period} setValue={setPeriod} options={periodOptions} placeholder={"Select a Period"} */}
        {/*            width={170}/> */}

        <FormControl style={{margin: 3, marginLeft: 'auto'}}>
          <ButtonForm
            variant="outlined"
            disabled={!activeForm}
            style={{margin: 0}}
            onClick={(_) => {
              if (activeForm) {
                displayConfirmation(
                    'Do you  want to add the report?',
                    'ADD',
                    'CANCEL',
                    () => setSubmitting({report, service, period, mails}),
                );
              }
            }}
            type="button"
          >
              Add
          </ButtonForm>
        </FormControl>
      </form>
      {submitting && <CreateReport {...submitting} />}
    </>
  );
}
