import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import {
  faBell,
  faCircle,
  faComment,
  faPowerOff,
  faSignOutAlt,
  faSuitcase,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Badge,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {NavLink, withRouter} from 'react-router-dom';
import {setTimeRange} from '../actions/timeRangeAction';
import * as TIME_CONSTS from './TopMenu/time_options';

import {useGetDataBackend, usePostDataBackend} from '../ApiBackend';
import {setSelectedService} from '../actions/servicesAction';
import Helper from './TopMenu/Helper';
import MuiSelectForm from '../Components/Forms/MuiSelectForm';
import {personifyOperator} from '../actions/operatorAction';
import RedIconButton from '../core/components/buttons/RedIconButton';
import RedTextField from '../core/components/inputs/RedTextField';
import {displayError} from '../ui-components/displayMsg';
import RedButton from '../core/components/buttons/RedButton';
import {SuperDateTimePickerRange} from './Inputs/SuperDateTimePickerRange';
import PropTypes from 'prop-types';

const UserMenuRouter = withRouter(UserMenu);

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function NewToolBar() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
        flexWrap: 'nowrap',
      }}
    >
      <div
        style={{display: 'flex', flexDirection: 'row', marginRight: 'auto'}}
      >
        <Title />
      </div>
      <div style={{display: 'flex', flexDirection: 'row', marginLeft: 0}}>
        <ServiceSelection is_small={isSmall} />
        <TimeSelection is_small={isSmall} />
        <Divider
          orientation="vertical"
          flexItem
          style={{margin: '2px 10px'}}
        />
        <Helper />
        <UserMenuRouter />
      </div>
    </div>
  );
}

/**
 *
 * @return {unknown}
 */
function useUserNotifications() {
  const [notes] = useSelector((state) => [state.notifications]);

  const [refresh, setRefresh] = useState(0);
  const [numberNotes, setNumberNotes] = useState(notes.length);

  const {
    data: {notifications = []},
    loading,
    error,
  } = useGetDataBackend('users/notifications', {refresh});
  useEffect(() => {
    const t = setTimeout(() => setRefresh((r) => r + 1), 30 * 1000);
    return () => clearTimeout(t);
  }, [refresh]);

  useEffect(() => {
    if (!loading && !error) {
      setNumberNotes(notifications.length);
    }
  }, [loading, error, notifications]);

  useEffect(() => {
    setNumberNotes(notes.length);
  }, [notes]);

  return numberNotes;
}

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function UserMenu(props) {
  const dispatch = useDispatch();
  const [operator, keycloak, services] = useSelector((state) => [
    state.operator,
    state.keycloak,
    state.services,
  ]);

  let singleServiceName = false;
  if (services.length === 1) {
    singleServiceName = services[0].servicename;
  } else if (operator && Object.values(operator.personify).length === 1) {
    [singleServiceName] = Object.values(operator.personify);
  }

  const countNotifications = useUserNotifications();

  const [anchorEl, setAnchorEl] = useState(null);
  const [contactOpen, setContactOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const {name, email} = keycloak.tokenParsed;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{alignSelf: 'center'}}>
      <Badge
        variant="standard"
        badgeContent={countNotifications}
        color="secondary"
        overlap="circular"
      >
        <RedIconButton onClick={handleClick}>
          <FontAwesomeIcon icon={faUser} />
        </RedIconButton>
      </Badge>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        variant="menu"
      >
        {singleServiceName && [
          <ListItem key="aux-1">
            <ListItemText
              style={{textAlign: 'center'}}
              primary={singleServiceName}
            />
          </ListItem>,
          <Divider key="aux-2" />,
        ]}
        <ListItem>
          <ListItemIcon style={{marginTop: 11, alignSelf: 'flex-start'}}>
            <RedIconButton>
              <FontAwesomeIcon icon={faUser} />
            </RedIconButton>
          </ListItemIcon>
          <ListItemText
            primary={<b>{name || email}</b>}
            secondary={
              <Typography component="span" variant="body2">
                <Link
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    color: '#444',
                    cursor: 'pointer',
                    marginTop: 6,
                  }}
                  onClick={() => keycloak.accountManagement()}
                >
                  <b>Manage Your account</b>
                </Link>
              </Typography>
            }
          />
        </ListItem>
        {operator.personify && (
          <MenuItem
            dense
            onClick={() => {
              dispatch(personifyOperator());
              props.history.push('/');
            }}
          >
            <ListItemIcon style={{minWidth: 28}}>
              <FontAwesomeIcon icon={faPowerOff} />
            </ListItemIcon>
            <ListItemText primary="Back to Admin" />
          </MenuItem>
        )}
        <Divider />
        {/* <MenuItem dense component={NavLink} to={"/settings"}> */}
        {/*    <ListItemIcon style={{minWidth: 28}}> */}
        {/*        <FontAwesomeIcon icon={faCog}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText> Settings</ListItemText> */}
        {/* </MenuItem> */}
        <MenuItem dense component={NavLink} to="/notifications">
          <ListItemIcon style={{minWidth: 28}}>
            <Badge
              variant="dot"
              color="secondary"
              badgeContent={countNotifications}
            >
              <FontAwesomeIcon icon={faBell} />
            </Badge>
          </ListItemIcon>

          <ListItemText>
            My Notifications{' '}
            {!!countNotifications && <>({countNotifications})</>}
          </ListItemText>
        </MenuItem>
        <MenuItem dense onClick={() => setContactOpen(true)}>
          <ListItemIcon style={{minWidth: 28}}>
            <FontAwesomeIcon icon={faComment} />
          </ListItemIcon>
          <ListItemText>My Feedback</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          dense
          component={NavLink}
          to="/logout"
          onClick={() => localStorage.clear()}
        >
          <ListItemIcon style={{minWidth: 28}}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </ListItemIcon>
          <ListItemText> Logout</ListItemText>
        </MenuItem>
      </Menu>
      <ContactDialog
        open={contactOpen}
        handleClose={() => setContactOpen(false)}
      />
    </div>
  );
}

UserMenu.propTypes = {
  history: PropTypes.any,
};

/**
 *
 * @param{boolean} open
 * @param{function} handleClose
 * @return {JSX.Element}
 * @constructor
 */
function ContactDialog({open, handleClose}) {
  const [formData, setFormData] = useState({
    text_field: 'send_feedback',
    value: '',
  });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setFormData((f) => ({...f, value: ''}));
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle id="alert-dialog-title">We value your
        feedback</DialogTitle>
      <DialogContent>
        Your open and honest feedback is important to us. Your input will
        assist
        us as we improve current features and develop future versions. Thank
        you
        for taking the time to provide us with your suggestion for enhancing
        <span style={{fontFamily: 'MagistralC'}}> DNS</span>
        <span style={{fontFamily: 'MagistralC', color: 'red'}}>8</span>.
        <p>If you have any suggestions, please send us through this form.</p>
        <RedTextField
          fullWidth
          label="My feedback"
          multiline
          autoFocus
          placeholder="Write your feedback here"
          value={formData.value}
          onChange={({target: {value}}) =>
            setFormData((currentFormData) => ({
              ...currentFormData,
              value,
            }))
          }
          rows={4}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <RedButton
          onClick={() => setSubmitting(true)}
          disabled={!formData.value || submitting}
          color="primary"
        >
          Send to DNS8
        </RedButton>
        {submitting && (
          <SendText
            form_data={formData}
            onSuccess={() => setSubmitting(false) || handleClose()}
            onError={() => setSubmitting(false)}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

ContactDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

/**
 *
 * @param{any} formData
 * @param{function} onSuccess
 * @param{function} onError
 * @return {null}
 * @constructor
 */
function SendText({form_data: formData, onSuccess, onError}) {
  const [time, service, path] = useSelector((state) => [
    state.time_range,
    state.selectedService,
    state.path,
  ]);
  const {data, loading, error} = usePostDataBackend('contact', {
    ...formData,
    service,
    time,
    path,
  });

  useEffect(() => {
    if (!loading && (error || !data.ok)) {
      displayError(`Error to notify:`, data.msg || 'Unknown Error.');
      onError();
    } else if (!loading && !error && data.ok) {
      onSuccess();
    }
  }, [onError, onSuccess, loading, error, data]);

  return null;
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function Title() {
  const [path] = useSelector((state) => [state.path]);

  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'bold',
        fontSize: 16,
        margin: 'auto 0',
      }}
    >
      {path}
    </div>
  );
}

/**
 *
 * @param{boolean} isSmall
 * @return {JSX.Element}
 * @constructor
 */
function ServiceSelection({is_small: isSmall}) {
  let [selectedService, operator, allServices] = useSelector((state) => [
    state.selectedService,
    state.operator,
    state.services,
  ]);

  if (selectedService.length === 0) {
    selectedService = 'all';
  }
  const dispatch = useDispatch();
  let services = [
    {
      value: 'all',
      label: 'All services',
      icon: <FontAwesomeIcon fixedWidth icon={faSuitcase} />,
    },
  ];

  if (allServices.length) {
    allServices.forEach(({hash, servicename}) =>
      services.push({
        value: hash,
        label: servicename,
        icon: (
          <div className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} color="#444" />
            <span
              className="fa-layers-text"
              style={{color: 'white', fontSize: '60%'}}
            >
              {servicename[0].toUpperCase()}
            </span>
          </div>
        ),
      }),
    );
  }

  if (operator && operator.personify) {
    const hashes = Object.keys(operator.personify);
    hashes.push('all');
    services = services.filter((s) => hashes.indexOf(s.value) !== -1);
  }

  const changeSelectedService = (option) => {
    dispatch(setSelectedService(option.value));
  };

  if (services.length <= 2) {
    return <></>;
  }

  if (isSmall) {
    return (
      <>
        <SmallServiceSelection
          services={services}
          onChange={changeSelectedService}
          current={selectedService}
        />
      </>
    );
  }
  return (
    <>
      <Divider orientation="vertical" flexItem style={{margin: '2px 10px'}} />
      <div style={{minWidth: 178}}>
        <BigServiceSelection
          services={services}
          onChange={changeSelectedService}
          current={selectedService}
        />
      </div>
    </>
  );
}

ServiceSelection.propTypes = {
  is_small: PropTypes.bool,
};

/**
 *
 * @param{array} services
 * @param{function} onChange
 * @param{*} current
 * @return {JSX.Element}
 * @constructor
 */
function BigServiceSelection({services, onChange, current}) {
  return (
    <MuiSelectForm
      disableUnderline
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      value={current}
      onChange={({target: {value}}) =>
        onChange(services.find((s) => s.value === value))
      }
      displayEmpty
      fullWidth
      renderValue={(selected) => {
        const service =
          services.find((s) => s.value === selected) || services[0];

        return (
          <MenuItem>
            <ListItemIcon
              style={{fontSize: 24}}>{service.icon}</ListItemIcon>
            <ListItemText>{service.label}</ListItemText>
          </MenuItem>
        );
      }}
    >
      {services.map((service, i) => {
        const key = `menu-item-${i}`;
        return (
          <MenuItem key={key} value={service.value}>
            <ListItemIcon
              style={{fontSize: 24}}>{service.icon}</ListItemIcon>
            <ListItemText>{service.label}</ListItemText>
          </MenuItem>
        );
      })}
    </MuiSelectForm>
  );
}

BigServiceSelection.propTypes = {
  services: PropTypes.array,
  onChange: PropTypes.func,
  current: PropTypes.any,
};

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function SmallServiceSelection(/* {services, onChange, current} */) {
  return <></>;
}

/**
 *
 * @param{boolean} isSmall
 * @return {JSX.Element}
 * @constructor
 */
function TimeSelection({is_small: isSmall}) {
  const [timeRange, operator] = useSelector((state) => [
    state.time_range,
    state.operator,
  ]);
  const dispatch = useDispatch();
  const changeTimeRange = (option) => {
    dispatch(setTimeRange(option.value || TIME_CONSTS.TIME_DEFAULT));
  };
  if (isSmall) {
    return (
      <>
        <SmallTimeSelection
          onChange={changeTimeRange}
          current={timeRange}
          operator={operator}
        />
      </>
    );
  }
  return (
    <>
      <Divider orientation="vertical" flexItem style={{margin: '2px 10px'}} />
      <SuperDateTimePickerRange
        style={{color: 'rgba(0, 0, 0, 0.87)', margin: 'auto'}}
        period={Array.isArray(timeRange) ? timeRange : [timeRange]}
        setPeriod={(p) => {
          return changeTimeRange({value: p});
        }} />
    </>
  );
}

TimeSelection.propTypes = {
  is_small: PropTypes.bool,
};

/**
 *
 * @param{*} current
 * @param{function} onChange
 * @param{*} operator
 * @return {JSX.Element}
 * @constructor
 */
function SmallTimeSelection({current, onChange, operator}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const timeOption = {...TIME_CONSTS.TIME_OPTIONS};

  if (!operator) {
    delete timeOption.l30d;
  }
  const list = Object.values(timeOption);

  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <Chip
          variant="outlined"
          avatar={<FontAwesomeIcon icon={faClock} style={{width: 20}} />}
          label={timeOption[current].sm_label}
        />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Select the time filter
        </DialogTitle>
        <List>
          {list.map((t, i) => {
            const key = `list-item-${i}`;
            return (
              <ListItem
                button
                onClick={() => onChange(t) || setOpen(false)}
                key={key}
                selected={t.value === current}
                alignItems="center"
              >
                <ListItemText>{t.label}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </>
  );
}
SmallTimeSelection.propTypes = {
  current: PropTypes.any,
  onChange: PropTypes.func,
  operator: PropTypes.any,
};

