import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import DynamicDashboard from './DynamicDashboard';
import {setPath} from '../../actions/pathAction';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function DynamicDashPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Dashboard'));
  }, [dispatch]);

  return <DynamicDashboard />;
}
