import {combineReducers} from 'redux';
import {reducer as toastr} from 'react-redux-toastr';
// parts of states
import authenticated from './authenticateReducer';
import keycloak from './keycloakReducer';
import keycloakError from './keycloakErrorReducer';
import lateralMenuCollapsed from './lateralMenuToggleReducer';
import path from './pathReducer';
import operator from './operatorReducer';
import pageMode from './pageModeReducer';
import timeRange from './timeRangeReducer';
import services from './servicesReducer';
import selectedService from './selectedServiceReducer';
import notifications from './notificationsReducer';

// assembly all parts of states
const allReducers = combineReducers({
  authenticated,
  keycloak,
  keycloak_error: keycloakError,
  lateral_menu_collapsed: lateralMenuCollapsed,
  path,
  operator,
  page_mode: pageMode,
  time_range: timeRange,
  services,
  selectedService,
  notifications,
  toastr,
});

export default allReducers;
