import {useSelector} from 'react-redux';
import moment from 'moment';
import {Alert} from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    'marginTop': 29,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

/**
 *
 * @param{number} padding
 * @return {JSX.Element|null}
 * @constructor
 */
export default function ServiceExpireAlert({padding}) {
  const [services, {personify}, service] = useSelector((state) => [
    state.services,
    state.operator,
    state.selectedService,
  ]);
  const classes = useStyles();
  const servicesAlerts = services.reduce((acc, v) => {
    if (personify && !personify[v.hash]) {
      return acc;
    }

    if (service.length && service !== 'all' && v.hash !== service) {
      return acc;
    }
    const exp = moment(v.expire, 'DD-MM-YYYY');
    const now = moment();
    const diff = exp.diff(now, 'days');

    if (diff < 0) {
      acc.push(
          <Alert severity="error" key={v.hash} variant="outlined">
            Service {v.servicename} expired (grace period 30 days)
          </Alert>,
      );
    }
    acc.push();
    return acc;
  }, []);
  if (!servicesAlerts.length) {
    return null;
  }
  return (
    <Grid
      item
      sm={12}
      style={{
        paddingLeft: padding / 2,
        paddingRight: padding / 2,
        flexBasis: 'unset',
      }}
    >
      <div className={classes.root}>{servicesAlerts}</div>
    </Grid>
  );
}
ServiceExpireAlert.propTypes = {
  padding: PropTypes.number,
};
