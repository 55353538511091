import * as type from '../actions/actionTypes';

const selectedServiceReducer = (state = [], action) => {
  switch (action.type) {
    case type.SET_SELECTED_SERVICE:
      return action.payload;
    default:
      return state;
  }
};

export default selectedServiceReducer;
