import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  IconButton,
  MenuItem,
  Table as TableMui,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {RenderCategory} from '../Category';
import Table from '../../Components/Table';
import {RenderDomainLink} from '../Domain';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {useDeleteDataBackend} from '../../ApiBackend';
import CommentsButtonList from '../CommentsButtonList';
import RedTextField from '../../core/components/inputs/RedTextField';
import {RenderCategoryValue} from './PublicRequestsTable';


const dateFormat = 'DD-MM-YYYY HH:mm:ss';
const dateField = '@timestamp';

/**
 *
 * @param{string} el
 * @return {JSX.Element}
 * @constructor
 */
function ColumnCategory(el) {
  return <RenderCategory list={el} />;
}

/**
 *
 * @param{any} _
 * @param{Object} row
 * @return {JSX.Element}
 * @constructor
 */
function CommentColumn(_, row) {
  return <CommentsButtonList {...row} />;
}

/**
 *
 * @param{array} value
 * @param{function} onChange
 * @param{boolean} xs
 * @return {JSX.Element}
 * @constructor
 */
function CategorySelection({value, onChange, xs}) {
  return <RedTextField
    select={true}
    SelectProps={
      {
        displayEmpty: true,
        autoWidth: true,
        multiple: true,
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
        renderValue: RenderCategoryValue,
      }
    }
    value={value}
    onChange={onChange}
  >
    {[
      'blacklist-root',
      'ignore-root',
      'whitelist-root',
      'vt',
    ].map((list) => (
      <MenuItem key={list} value={list}>
        <RenderCategory list={list} />
      </MenuItem>
    ))}
  </RedTextField>;
}
CategorySelection.propTypes={
  value: PropTypes.array,
  onChange: PropTypes.func,
  xs: PropTypes.bool,
};
/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function GlobalDomains() {
  const url = 'list/root';

  const setRefresh = (_) => false;

  const [ids, setDomains] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [data, setOpen] = useState(false);

  const onChange = (id) => ({target: {checked}}) => {
    if (checked) {
      setDomains((oldIds) => [...oldIds, id]);
    } else {
      setDomains((oldIds) => oldIds.filter((d) => d !== id));
    }
  };

  const onClick = () => {
    if (ids.length) {
      displayConfirmation(
          'By click Delete you will exclude the domain from the list.' +
        ' Are your sure?',
          'DELETE',
          'CANCEL',
          () => setDeleting(true),
      );
    }
  };

  const md = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const xs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [cat, setCat] = useState([]);

  const selection = <CategorySelection xs={xs}
    onChange={({target: {value}}) => setCat(value)}
    value={cat}
  />;

  const title = <div style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
  >
    <b style={{margin: 'auto 0'}}>Global Domains</b>
    {selection}
  </div>;
  const exporter = !xs && 'Global Domains';

  const columns = [
    {
      name: '',
      data: '',
      render: CommentColumn,
      sortable: false,
      searchable: false,
      styles: {
        width: 54,
      },
    },
    {
      name: <b>Domain</b>,
      data: 'domain',
      render: ColumnDomainLink,
      sortable: true,
      searchable: true,
      exporter: {
        label: 'Domain',
        value: 'domain',
      },
    },
    {
      name: <b>Date</b>,
      data: dateField,
      render: (el) => moment(el).format(dateFormat),
      sortable: true,
      styles: {
        width: 140,
      },
      hide: xs,
      exporter: {
        label: 'Date',
        value: (row) => moment(row[dateField]).format(dateFormat),
      },
    },
    {
      name: <b>Added by</b>,
      data: 'username',
      render: (el) => el || 'dns8@layer8.pt',
      hide: xs || md,
      exporter: {
        label: 'Added by',
        value: 'username',
      },
    },
    {
      name: <b>Category</b>,
      data: 'list',
      render: ColumnCategory,
      styles: {
        width: 130,
      },
      hide: xs || md,
      exporter: {
        label: 'Category',
        value: (row) => row.list,
      },
    },
    {
      name: 'Comment',
      data: 'usercomment',
      hide: true,
      exporter: {
        label: 'Last Comment',
        value: 'usercomment',
      },
    },
    {
      title: (
        <div style={{width: '100%', textAlign: 'center'}}>
          <IconButton color="default" onClick={onClick}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
      data: '_id',
      render: ColumnCheck,
      styles: {
        width: 67,
      },
    },
    {
      name: '',
      render: ColumnInfo,
      styles: {
        width: 56,
      },
      hide: !(md || xs),
    },
  ];

  /**
   *
   * @param{any} _
   * @param{object} row
   * @return {JSX.Element}
   * @constructor
   */
  function ColumnInfo(_, row) {
    return <IconButton onClick={() => setOpen(row)} size="small">
      <FontAwesomeIcon icon={faInfoCircle} />
    </IconButton>;
  }

  /**
   *
   * @param{string} el
   * @param{object} row
   * @return {JSX.Element}
   * @constructor
   */
  function ColumnCheck(el, row) {
    return <div style={{width: '100%', textAlign: 'center'}}>
      <Checkbox
        color="default"
        checked={ids.some((id) => id === el)}
        style={{padding: 0}}
        size="small"
        disabled={deleting || row.tags.indexOf('removed') !== -1}
        onChange={onChange(el)}
      />
    </div>;
  }


  /**
   *
   * @param{string} domain
   * @param{object} row
   * @return {JSX.Element}
   * @constructor
   */
  function ColumnDomainLink(domain, row) {
    const {_id: rowId} = row;
    return <div style={{display: 'flex', flexDirection: 'row'}}>
      <RenderDomainLink
        domain={domain}
        style={{
          margin: 'auto 0',
          textDecoration: (() => {
            const deletingItem =
                ids.some((id) => id === rowId) && deleting;
            const removingItem = row.tags.indexOf('removed') !== -1;
            return deletingItem || removingItem ?
                'line-through' :
                'none';
          })(),
        }}
      />
    </div>;
  }

  return <>
    <Table
      columns={columns}
      title={title}
      url={url}
      extradata={{lists: cat}}
      exporter={exporter}
      row_height={34}
    />
    {data && <DetailDomainDialog
      url={url}
      data={data}
      setOpen={setOpen}
      setRefresh={setRefresh}
    />}
    {deleting && (
      <DeleteDomains
        url={url}
        ids={ids}
        setDeleting={setDeleting}
        setRefresh={setRefresh}
      />
    )}
  </>;
}

/**
 *
 * @param{object} data
 * @param{string} url
 * @param{function} setOpen
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function DetailDomainDialog({data, url, setOpen, setRefresh}) {
  const [deleting, setDeleting] = useState(false);
  const {_id: dataId} = data;
  return (
    <Dialog open={!!data}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <RenderCategory list={data.list} />
        </div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Domain</b>
              </TableCell>
              <TableCell>
                <RenderDomainLink domain={data.domain} />
              </TableCell>
            </TableRow>
            <TableRow>
              {!data.usercomment && (
                <>
                  <TableCell style={{textAlign: 'right'}}>
                    <b>Added by</b>
                  </TableCell>
                  <TableCell>{data.username}</TableCell>
                </>
              )}
              {data.usercomment && (
                <>
                  <TableCell style={{textAlign: 'right'}}>
                    <b>Motive</b>
                  </TableCell>
                  <TableCell>
                    {data.usercomment}
                    <br />
                    <div style={{fontSize: 12}}>
                          by <b>{data.username}</b>
                    </div>
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Added at</b>
              </TableCell>
              <TableCell>
                {moment(data[dateField]).format(dateFormat)}
              </TableCell>
            </TableRow>
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}} />
            Close
        </ButtonForm>
        <ButtonForm
          variant="outlined"
          className="sec"
          type="button"
          onClick={(_) => setDeleting(true)}
        >
          <DeleteIcon /> Delete
        </ButtonForm>
        {deleting && (
          <DeleteDomains
            url={url}
            ids={[dataId]}
            setDeleting={setDeleting}
            setRefresh={() => setOpen(false) || setRefresh()}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
DetailDomainDialog.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  setOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};
/**
 *
 * @param{string} url
 * @param{*[]} ids
 * @param{function} setDeleting
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
export function DeleteDomains({url, ids, setDeleting, setRefresh}) {
  const {loading, error} = useDeleteDataBackend(url, ids);
  useEffect(() => {
    if (!loading && error) {
      displayError('Error deleting domain:', error);
      setDeleting(false);
    } else if (!loading) {
      setDeleting(false);
      setRefresh();
    }
  }, [loading, error, setDeleting, setRefresh]);

  return <></>;
}
DeleteDomains.propTypes={
  url: PropTypes.string,
  ids: PropTypes.arrayOf(PropTypes.any),
  setDeleting: PropTypes.func,
  setRefresh: PropTypes.func,
};
