import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {ReactComponent as ExternalIcon} from '../../../icons/external view.svg';
import {useGetDataBackend} from '../../../ApiBackend';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import ReadMore from '../../../ui-components/ReadMore';
import Spinner from '../../../Components/Spinner';

const dateFormats = ['DD/MM/YYYY HH:mm:ss', moment.ISO_8601];

export const defaultColor = 'rgba(70, 70, 70, 0.6)';

const noInfo = 'No information available';

const dateFormat = 'DD-MM-YYYY';

/**
 *
 * @param{boolean} hasError
 * @param{string} date
 * @return {string}
 */
export function getCreatedColor(hasError, date) {
  if (!hasError && date) {
    if (moment().subtract(1, 'month') < moment(date, dateFormats)) {
      return '#ED1C24';
    }
  }
  return 'inherit';
}

/**
 *
 * @param{boolean} hasError
 * @param{string} date
 * @return {string}
 */
export function getExpireColor(hasError, date) {
  if (!hasError && date) {
    if (moment(date, dateFormats).subtract(1, 'month') < moment()) {
      return '#ED1C24';
    }
  }
  return 'inherit';
}


/**
 *
 * @param{any} children
 * @return {JSX.Element}
 * @constructor
 */
export function Paragraph({children}) {
  return <p
    style={{
      padding: '8px 0 16px',
      color: defaultColor,
      fontSize: 16,
    }}
  >
    {children}
  </p>;
}

Paragraph.propTypes = {
  children: PropTypes.any,
};


/**
 *
 * @param{string[]} e
 * @return {JSX.Element}
 * @constructor
 */
function CreateListItem(e) {
  return <li key={e[0]}>{e[0]}: {e[1]}</li>;
}

CreateListItem.propTypes = {
  e: PropTypes.arrayOf(PropTypes.string),
};


/**
 *
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
export function ExternalValidationDomainInfo({domain}) {
  const encodedDomain = encodeURIComponent(`http://${domain}`);


  const doubleEncodedDomain = encodeURIComponent(encodedDomain);

  const linkVt = `https://www.virustotal.com/gui/search/${doubleEncodedDomain}`;


  return (
    <div>

      {domain && (
        <div style={{padding: 16}}>
          You can validate the domain here:
          <br />
          <br />
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <a
              rel="noopener noreferrer"
              style={{display: 'flex', flexDirection: 'row', height: 20}}
              href={`https://www.google.com/transparencyreport/safebrowsing/diagnostic/#url=${domain}`}
              target="_blank"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/google-eval-logo.png`}
                width={80}
                alt="Google"
              />
              <ExternalIcon
                style={{
                  height: 20,
                  marginLeft: 10,
                  color: defaultColor,
                }}
              />
            </a>
            <a
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 20,
                marginLeft: 30,
              }}
              href={linkVt}
              target="_blank"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/vt-logo.svg`}
                width={80}
                alt="VirusTotal"
              />
              <ExternalIcon
                style={{
                  height: 20,
                  marginLeft: 10,
                  color: defaultColor,
                }}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

ExternalValidationDomainInfo.propTypes = {
  domain: PropTypes.string,
};

const styleHeaders = {color: '#464646', opacity: 0.8, fontSize: 14};

/**
 *
 * @param{string} title
 * @param{string} date
 * @param{boolean} loading
 * @param{boolean} error
 * @param{string} color
 * @return {JSX.Element}
 * @constructor
 */
export function DateTitle({title, date, loading, error, color}) {
  return <div>
    <div style={styleHeaders}>{title}</div>
    {loading && <div style={{height: 14, width: 113}}>
      <Spinner />
    </div>}
    {!loading && (error || !date) && <div style={{width: 113}}>-</div>}
    {!loading && !error && date && <div style={{width: 113, color}}>
      {moment(date, dateFormats).format(
          dateFormat,
      )}
    </div>}
  </div>;
}

DateTitle.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  color: PropTypes.string,
};

export const whoIsTableFields = {
  registrar: {
    title: 'Registrar',
    render: function RegistrarCell(v) {
      const registrarData = Object.entries(v?.registrar ?? []).
          filter(([, e]) => Boolean(e));
      if (!registrarData.length) {
        return noInfo;
      }
      return <ul> {registrarData.map(CreateListItem)}</ul>;
    },
  },
  registrant: {
    title: 'Registrant',
    render: function RegistrantCell(v) {
      const registrantData = Object.entries(v?.registrant ?? []).
          filter(([, e]) => Boolean(e));
      if (!registrantData.length) {
        return noInfo;
      }
      return <ul>{registrantData.map(CreateListItem)}</ul>;
    },
  },
  administrative: {
    title: 'Administrative',
    render: function AdministrativeCell(v) {
      const administrativeData = Object.entries(v?.administrative ?? []).
          filter(([, e]) => Boolean(e));
      if (!administrativeData.length) {
        return '';
      }
      return <ul>{administrativeData.map(CreateListItem)}</ul>;
    },
  },
  billing: {
    title: 'Billing',
    render: function BillingCell(v) {
      const billingData = Object.entries(v?.billing ?? []).
          filter(([, e]) => Boolean(e));
      if (!billingData.length) {
        return '';
      }
      return <ul>{billingData.map(CreateListItem)}</ul>;
    },
  },
  technical: {
    title: 'Technical',
    render: function TechnicalCell(v) {
      const technicalData = Object.entries(v?.technical ?? []).
          filter(([, e]) => Boolean(e));
      if (!technicalData.length) {
        return '';
      }
      return <ul>{technicalData.map(CreateListItem)}</ul>;
    },
  },
  ips: {
    title: 'IPs Info',
    render: (v) => {
      return (v?.ips ?? []).map((ipInfo, key) => {
        const ipsData = Object.entries(ipInfo).filter(([, e]) => Boolean(e));
        const ulKey = 'ulKey-' + key;
        return <ul key={ulKey}>{ipsData.map(CreateListItem)}</ul>;
      });
    },
  },
  name_servers: {
    title: 'Other info',
    render: (v) => {
      const {id, dnssec, name_servers: nameServers, status} =
      v?.domain || {};
      const otherData = Object.entries({
        id,
        dnssec,
        'name servers': (nameServers ?? []).join(', '),
        'status': (status ?? []).join(', '),
      }).filter((e) => e[1]);
      if (!otherData.length) {
        return '';
      }
      return <ul> {otherData.map(CreateListItem)} </ul>;
    },
  },
  resolutions: {
    title: 'Resolutions',
    render: (v1) => {
      const v = v1.resolutions?.slice().reverse();
      if (v) {
        return <ReadMore lines={4}>
          {v.reduce((
              acc,
              {ip_address: ipAddress, last_resolved: lastResolved},
          ) => {
            acc.push([
              ipAddress,
              moment(lastResolved).format(dateFormat),
            ].join('@'));
            return acc;
          }, []).join(', ')}
        </ReadMore>;
      }
      return '';
    },
  },
  subdomains: {
    title: 'Subdomains',
    render: (v1) => {
      const v = v1.subdomains;
      if (v) {
        return <ReadMore lines={4}>
          {Array.isArray(v) ? v.join(', ') : v}
        </ReadMore>;
      }
      return '-';
    },
  },
};

/**
 *
 * @param{string} domain
 * @return {{data: {}, loading: boolean, error: boolean}}
 */
function useGetDataWhoIs(domain) {
  const [d, setDomain] = useState(domain);
  const [otherdata, setOtherData] = useState(null);
  const [dataCombined, setDataCombined] = useState({});
  useEffect(() => {
    setDomain(domain);
  }, [domain]);

  const {data, loading} = useGetDataBackend(
      `domain/who_is/${d}`);

  useEffect(() => {
    fetch(
        'https://www.threatcrowd.org/searchApi/v2/domain/report/?domain=' +
      d).
        then((response) => response.json()).
        then((r) => {
          setOtherData({resolutions: r.resolutions, subdomains: r.subdomains});
        }).catch(() => {
          setOtherData({resolutions: undefined, subdomains: undefined});
        });
  }, [d]);
  useEffect(() => {
    setDataCombined( {...otherdata, ...data.who_is});
  }, [data, otherdata]);
  return {data: dataCombined, loading: !!loading, error: false};
}

/**
 *
 * @param{string} domain
 * @return {{
 * entries: [(string|*), string][],
 * data: (*|{}),
 * error: boolean,
 * loading: boolean
 * }}
 */
export function useWhoIsData(domain) {
  const [d, setDomain] = useState(domain);

  const {data = {}, loading, error} = useGetDataWhoIs(d);

  useEffect(() => {
    setDomain(domain);
  }, [domain]);

  let entries = Object.entries(whoIsTableFields).map(([_, fieldInfo]) => [
    fieldInfo.title,
    '',
  ]);
  if (loading) {
    entries = Object.entries(whoIsTableFields).map(([_, fieldInfo]) => [
      fieldInfo.title,
      <div key={fieldInfo.title}
        className="data-placeholder"
        style={{height: 14}} />,
    ]);
  } else if (!(loading || error)) {
    entries = Object.entries(whoIsTableFields).map(([_, fieldInfo]) => [
      fieldInfo.title,
      fieldInfo.render(data),
    ]);
    entries = entries.filter((e) => e[1]);
  }
  return {
    data,
    entries,
    error: Boolean(error),
    loading,
  };
}

/**
 *
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
export function WhoIsTable({domain = ''}) {
  const [d, setDomain] = useState(domain);
  const {data = {}, entries, error, loading} = useWhoIsData(d);

  useEffect(() => {
    setDomain(domain);
  }, [domain]);

  const createdColor = getCreatedColor(error, data?.domain?.created_date);

  const expireColor = getExpireColor(error, data?.domain?.expiration_date);
  return <div>
    <h3>
      {' '}
      Who Is {data?.domain?.domain}{' '}
      {data.domain &&
      data.domain.whois_server &&
      `@ ${data.domain.whois_server}`}{' '}
    </h3>

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <DateTitle color={createdColor}
        title={'Created'}
        date={data?.domain?.created_date}
        error={error}
        loading={loading}
      />
      <DateTitle color={'inherit'}
        title={'Updated'}
        date={data?.domain?.updated_date}
        error={error}
        loading={loading}
      />
      <DateTitle color={expireColor}
        title={'Expire'}
        date={data?.domain?.expiration_date}
        error={error}
        loading={loading}
      />

    </div>
    <DisplayWhoIsData
      loading={loading}
      error={error}
      entries={entries}
    />
  </div>;
}

WhoIsTable.propTypes = {
  domain: PropTypes.string,
};

/**
 *
 * @param{boolean} loading
 * @param{any} error
 * @param{[(string|*), string][]} entries,
 * @return {JSX.Element}
 * @constructor
 */
export function DisplayWhoIsData({loading, error, entries}) {
  const sm = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return <Table className="body-sm">
    <TableBody>
      {entries.map(([title, value], i) => {
        const rowKey = 'row-' + i;
        return (
          <TableRow key={rowKey}>
            {!sm && <TableCell style={{whiteSpace: 'nowrap'}}>
              <b>{title}</b>
            </TableCell>}
            <TableCell>
              {sm && <>
                <b>{title}</b>
                <br />
              </>}
              {loading && <div className="data-placeholder"
                style={{height: 14}} />}
              {!(loading || error) && value}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>;
}

DisplayWhoIsData.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  entries: PropTypes.array,
};
