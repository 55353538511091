import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import configs from '../../../configs';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function DefaultPage() {
  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const token = keycloak.tokenParsed;

  const roles =
      token.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];

  return (
    <>
      {!operator.personify && roles.includes('root') && (
        <Redirect to="/dns8status" />
      )}
      {!operator.personify &&
        !roles.includes('root') &&
        roles.includes('ms-operator') && <Redirect to="/dns8status" />}
      {!operator.personify &&
        !roles.includes('root') &&
        roles.includes('soc-operator') && <Redirect to="/domains" />}
      {(!operator || operator.personify || roles.includes('user')) && (
        <Redirect to="/dashboard" />
      )}
    </>
  );
}

export default DefaultPage;
