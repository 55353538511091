import React from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Table, Tooltip, useTheme} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import {makeStyles} from '@material-ui/core/styles';
import {StyledTableCell} from '../../Components/Table';
import Spinner from '../../Components/Spinner';
import BoxInfo from '../../Components/BoxInfo';
import {ServiceBlockInfo} from './ServiceBlockInfo';
import {ServiceHeader} from './ServiceHeader';
import {useGetDataBackend} from '../../ApiBackend';

function isPrivateIP(ip) {
  const parts = ip.split('.');
  return (
    parts[0] === '10' ||
      (parts[0] === '172' &&
          parseInt(parts[1], 10) >= 16 &&
          parseInt(parts[1], 10) <= 31) ||
      (parts[0] === '192' && parts[1] === '168')
  );
}

export default function InternalServiceInfo() {
  const [selectedService] = useSelector((state) => [state.selectedService]);
  let {
    data: {info},
    error,
    loading,
  } = useGetDataBackend('services/info/all');

  if (info && selectedService.length && selectedService !== 'all') {
    info = info.filter((i) => i.hash === selectedService);
  }
  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      {(error || loading) && (
        <>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceHeader />
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceBlockInfo title={<b>Internal DNS</b>}
              loading={loading} />
          </Grid>
        </>
      )}
      {!(error || loading) &&
        info.map(({client_hash: clientHash, ...e}, i) => (
          <React.Fragment key={clientHash}>
            <Grid
              item
              xs={12}
              style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
            >
              <ServiceHeader {...e} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
            >
              <InternalServiceInfoTable
                title="Client DNS IPS"
                service={clientHash}
                info={e}
              />
            </Grid>
          </React.Fragment>
        ))}
      <Grid item xs={12} style={{height: padding, margin: 'auto'}} />
    </Grid>
  );
}

function InternalServiceInfoTable({service, info: {dnsips}}) {
  const {
    loading,
    data: {slave_status: slaveStatus = {}},
    error,
  } = useGetDataBackend('internal_requests/slaves_status', {
    clientname: service,
  });

  dnsips.forEach((dns) => delete slaveStatus[dns]);
  // TODO: if the slave only communicates with DNS8 slaves or
  //  internal slaves that only communicates between then and DNS8 slaves, the service is correctly configure
  //  this check is not implemented

  const data = Object.entries(slaveStatus).reduce(
      (acc, [internalDns, {has_agent: hasAgent}]) => {
        if (isPrivateIP(internalDns)) {
          acc.push({
            dns: internalDns,
            plugin: !!hasAgent.length, // has installed plugin
            agent: hasAgent, // communicates to other dns'
            external: hasAgent.filter(
                (ip) => !isPrivateIP(ip) && dnsips.indexOf(ip) === -1,
            ), // access to external dns'
            // external: has_agent.filter(ip => !isPrivateIP(ip))
          });
        }
        return acc;
      },
      [],
  );

  const columns = [
    {
      props: {style: {textAlign: 'center', width: 60}},
      data_props: {style: {textAlign: 'center', width: 60}},
      title: <span>Status</span>,
      data: 'external',
      render: (externalDaya, row) => {
        let icon;
        let label;
        if (externalDaya.length && row.plugin) {
          icon = <FontAwesomeIcon icon={faExclamationCircle} color="#BE131A" />;
          label = 'Danger';
        } else if (row.plugin) {
          icon = <FontAwesomeIcon icon={faCheckCircle} color="green" />;
          label = 'OK';
        } else {
          icon = (
            <FontAwesomeIcon icon={faExclamationTriangle} color="#DD7A29" />
          );
          label = 'Warning';
        }
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>{icon}</div>
            <div style={{marginLeft: 5}}>{label}</div>
          </div>
        );
      },
    },
    {
      props: {style: {textAlign: 'center', width: 110}},
      data_props: {style: {textAlign: 'center', width: 110}},
      title: <span>Internal DNS server</span>,
      data: 'dns',
    },
    {
      props: {style: {textAlign: 'center', width: 110}},
      data_props: {style: {textAlign: 'center'}},
      data: 'plugin',
      title: <span>Has Plugin installed</span>,
      render: (externalData) => {
        let icon;
        let label;
        if (externalData) {
          icon = <FontAwesomeIcon icon={faCheck} color="green" />;
          label = 'Yes';
        } else {
          icon = <FontAwesomeIcon icon={faTimes} color="#BE131A" />;
          label = 'No';
        }
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{margin: 'auto', marginRight: 0}}>{icon}</div>
            <div style={{margin: 'auto', marginLeft: 5}}>{label}</div>
          </div>
        );
      },
    },
    {
      data_props: {
        style: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      title: <span>Notes</span>,
      data: 'external',
      render: (externalData, row) => {
        let text = '';

        if (externalData.length && row.plugin) {
          text = `This server does dns requests to external public ips: ${externalData.join(
              ', ',
          )}`;
        } else if (row.plugin) {
          text = `This server does dns requests only to internal dns server (internal
            resolution?) and the DNS8 slaves.`;
        } else {
          text = `Requests may skip DNS8 protection.`;
        }
        return <SpanTitle>{text}</SpanTitle>;
      },
    },
  ];
  return (
    <BoxInfo title={<b>Internal DNS</b>}>
      <Table style={{width: '100%', tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => {
              const rowKey = `header${index}`;
              return (
                <StyledTableCell key={rowKey} style={col?.props?.style}>
                  {col.title || ''}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading || error) && (
            <TableRow style={{border: '1px solid #F9F9F9'}}>
              <StyledTableCell colSpan={columns.length}>
                <Spinner />
              </StyledTableCell>
            </TableRow>
          )}
          {!loading && !error && !data.length && (
            <TableRow style={{border: '1px solid #F9F9F9'}}>
              <StyledTableCell colSpan={columns.length}>
                    No Data
              </StyledTableCell>
            </TableRow>
          )}
          {!loading &&
            !error &&
            !!data.length &&
            data.map((d, rIndex) => {
              const row = d || {};
              const rowKey = `row-${rIndex}`;
              return (
                <TableRow
                  key={rowKey}
                  className={
                        rIndex % 2 ? 'platinum-bg  opacity-30' : 'white-bg'
                  }
                  style={{border: '1px solid #F9F9F9'}}
                >
                  {columns.map(function(column, cIndex) {
                    const cellKey = `data_${rIndex}_${cIndex}`;
                    let cellData;
                    if (row[cIndex]) {
                      cellData = row[cIndex];
                    } else if (column.render) {
                      cellData = column.render(row[column.data], row);
                    } else {
                      cellData = row[column.data];
                    }
                    return (
                      <StyledTableCell
                        key={cellKey}
                        style={column?.data_props?.style}
                      >
                        {cellData}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </BoxInfo>
  );
}

function SpanTitle({children}) {
  return (
    <Tooltip title={children}>
      <span>{children}</span>
    </Tooltip>
  );
}
