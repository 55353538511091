import * as type from '../actions/actionTypes';

const servicesReducer = (state = [], action) => {
  switch (action.type) {
    case type.SET_SERVICES_INFO:
      return action.payload;
    default:
      return state;
  }
};

export default servicesReducer;
