import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import {useMediaQuery} from '@material-ui/core';
import moment from 'moment';
import Spinner from '../../Components/Spinner';
import {DarkBoxInfo} from '../../Components/BoxInfo';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  serviceHeader: {
    'display': 'flex',
    'flexDirection': 'row',
    '& > *:first-child': {
      'flex': 3,
      'color': 'white',
      'fontSize': 'inherit',
      'margin': 'auto 0',
      'borderLeft': 'none',
      '& > *': {
        margin: 'auto 0',
      },
    },
    '& > *': {
      'flex': 2,
      'display': 'flex',
      'flexDirection': 'column',
      'fontSize': 12,
      'color': '#C5C5C5',
      'borderLeft': '1px solid #C5C5C5',
      '& > *': {
        margin: 'auto',
      },
    },
  },
}));

/**
 *
 * @param{number|string} limit
 * @param{string} servicename
 * @param{string} serviceExpireDate
 * @return {JSX.Element}
 * @constructor
 */
export function ServiceHeader({limit, servicename, serviceExpireDate}) {
  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const title = (
    <div className={classes.serviceHeader}>
      <div>
        {(!servicename && (
          <span>
            Service <Spinner color="white"/>
          </span>
        )) ||
          servicename}
      </div>
      {!sm && (
        <div>
          <div>Max Queries/sec</div>
          <div>{limit}</div>
        </div>
      )}
      <div>
        <div>License Expire {!sm && <span>Date</span>}</div>
        <div>
          {(serviceExpireDate &&
              moment(serviceExpireDate, 'DD-MM-YYYY').format('DD-MM-YYYY')) ||
            '-'}
        </div>
      </div>
    </div>
  );
  return <DarkBoxInfo title={title}/>;
}

ServiceHeader.propTypes = {
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  servicename: PropTypes.string,
  serviceExpireDate: PropTypes.string,
};
