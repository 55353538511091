import moment from 'moment';

export const TIME_OPTIONS = [
  {value: 'l15m', label: 'Last 15 minutes', sm_label: '15 min'},
  {value: 'l30m', label: 'Last 30 minutes', sm_label: '30 min'},
  {value: 'l1h', label: 'Last 1 hour', sm_label: '1 hour'},
  {value: 'l4h', label: 'Last 4 hours', sm_label: '4 hours'},
  {value: 'l12h', label: 'Last 12 hours', sm_label: '12 hours'},
  {value: 'l24h', label: 'Last 24 hours', sm_label: '24 hours'},
  {value: 'today', label: 'Today', sm_label: 'Today'},
  {value: 'yesterday', label: 'Yesterday', sm_label: 'Yesterday'},
  {value: 'l7d', label: 'Last 7 days', sm_label: '7 days'},
  // {value: "l15d", label: "Last 15 days",  sm_label: "15 days"},
  // {value: "l30d", label: "Last 30 days",  sm_label: "30 days"},
].reduce((acc, value) => {
  acc[value.value] = value;
  return acc;
}, {});

export const TIME_DEFAULT = 'l15m';

export const TIME_OPTIONS_FUNCTIONS = {
  get today() {
    return [
      moment().startOf('day').format(),
      moment().startOf('minute').format(),
    ];
  },
  get yesterday() {
    return [
      moment().add(-1, 'day').startOf('day').format(),
      moment().startOf('day').format(),
    ];
  },
};

export const PREV_TIME_OPTIONS = {
  l15m: ['l30m', 'l15m'],
  l30m: ['l60m', 'l30m'],
  l1h: ['l2h', 'l1h'],
  l4h: ['l8h', 'l4h'],
  l12h: ['l24h', 'l12h'],
  l24h: ['l48h', 'l24h'],
  get today() {
    return [
      moment().add(-1, 'day').startOf('day').format(),
      moment().add(-1, 'day').format(),
    ];
  },
  l7d: ['l14d', 'l7d'],
  l30d: ['l60d', 'l30d'],
  get yesterday() {
    return [
      moment().add(-2, 'day').startOf('day').format(),
      moment().add(-1, 'day').startOf('day').format(),
    ];
  },
};
