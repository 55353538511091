import {Grid, Box} from '@material-ui/core';
import {Text} from '@l8/storybook';
import React from 'react';
import PropTypes from 'prop-types';
import {ServiceRender} from '../../../DNS8Components/Service';

/**
 *
 * @param{Object} alert
 * @param{string} [alert.category]
 * @param{string} [alert.channel]
 * @param{[string]} [alert.domain]
 * @param{[string]} [alert.ip]
 * @param{string} [alert.events]
 * @param{string} [alert.frequency]
 * @param{string} [alert.type]
 * @param{[string]} [alert.emails]
 * @param{number} step
 * @return {JSX.Element}
 * @constructor
 */
export function AlertDescription({alert, step}) {
  return <>
    <Grid container justifyContent="flex-start" direction="column">
      <Grid item>
        {step === 1 && <AlertDescriptionStepSelectType alert={alert}/>}
        {step === 2 && <AlertDescriptionStepSelectRateTrigger alert={alert}/>}
        {step === 3 && <AlertDescriptionStepSelectChannel alert={alert}/>}
        {step === 4 && <AlertDescriptionStepConfirmation/>}
        <br/>

        <Text
          label={`Step ${step}/4`}
          textWeight="bold"
          textSize="20px"
        />
        <Text
          label="Current alert configuration"
          textSize="14px"
          textColor="secondary"
        />
        <br/>
        {Object.entries(alert).map(([key, value]) => {
          if (key === 'service') {
            const v = Array.isArray(value) ? value[0] : value;
            return <Box key={`alert-${key}`} style={{marginBottom: 10}}>
              <Text textWeight="bold" label={key + ':'}
                style={{display: 'inline-block'}}
                textSize="14px"/>&nbsp;
              <ServiceRender style={{display: 'inline'}} hash={v}/>
            </Box>;
          } else if (key === 'active') {
            value = value ? 'true' : 'false';
          } else if (key === '_id') {
            key = 'id';
          }
          return <Box key={`alert-${key}`} style={{marginBottom: 10}}>
            <Text textWeight="bold" label={key + ':'}
              style={{display: 'inline-block'}}
              textSize="14px"/>&nbsp;
            <Text label={Array.isArray(value) ? value.join(', ') : value}
              style={{display: 'inline-block'}}
              textSize="14px"/>
          </Box>;
        })}
      </Grid>
    </Grid>
  </>;
}

AlertDescription.propTypes = {
  alert: PropTypes.shape(
      {
        category: PropTypes.string,
        channel: PropTypes.string,
        domain: PropTypes.arrayOf(PropTypes.string),
        emails: PropTypes.arrayOf(PropTypes.string),
        events: PropTypes.string,
        frequency: PropTypes.string,
        ip: PropTypes.arrayOf(PropTypes.string),
        team: PropTypes.string,
        tokenA: PropTypes.string,
        tokenB: PropTypes.string,
        tokenC: PropTypes.string,
        type: PropTypes.string,
      },
  ),
  step: PropTypes.number,
};


/**
 *
 * @param{obj} alert
 * @param{string} alert.events
 * @param{string} alert.frequency
 * @return {JSX.Element}
 * @constructor
 */
function AlertDescriptionStepSelectRateTrigger({alert}) {
  const numberEvents = alert?.events || <u>the number of</u>;
  const frequency = alert?.frequency || <u>frequency</u>;
  return <>
    <Text label="Define alert rate trigger" textWeight="bold"
      textSize="20px"/>
    <Text
      label="Define the event rate for the alert to be triggered"
      textSize="14px"
      textColor="secondary"
    />
    <p>
      You can use these fields to define an alert when {numberEvents} events
      per {frequency} is exceeded.
    </p>
    <p>
      The alert will be triggered when the number of events exceeds the
      threshold defined.
    </p>
  </>;
}

AlertDescriptionStepSelectRateTrigger.propTypes = {
  alert: PropTypes.shape(
      {
        events: PropTypes.string,
        frequency: PropTypes.string,
      },
  ),
};

/**
 *
 * @param{obj} alert
 * @param{string} alert.channel
 * @return {JSX.Element}
 * @constructor
 */
function AlertDescriptionStepSelectChannel({alert}) {
  return <>
    {!alert?.channel && <>
      <Text label={`Specify the Alert Channel`} textWeight="bold"
        textSize="20px"/>
      <Text
        label={`Choose and configure the alert channel`}
        textSize="14px"
        textColor="secondary"
      />
    </>}
    {alert?.channel === 'teams' && <>
      <Text label={`Configure Alert for MSTeams`} textWeight="bold"
        textSize="20px"/>
      <Text
        label={'Specify the MSTeams Team and the 3 Tokens for the alert'}
        textSize="14px"
        textColor="secondary"
      />
      <p>
        To find the team id and tokens, you must go to the channel where you
        want
        to receive the alert and select ••• icon (More options) from the top
        navigation bar.
      </p>
      <p>Search for Incoming Webhook and select Add.</p>
      <p>This will generated a URL that looks like this:</p>

      <code style={{overflowWrap: 'break-word'}}>
        {'https://{team}.office.com/webhook/{tokenA}/IncomingWebhook/{tokenB}/{tokenC}'}
      </code>
    </>}
    {alert?.channel === 'email' && <>
      <Text label={`Set Emails`} textWeight="bold"
        textSize="20px"/>
      <Text
        label={'Specify the emails that will receive the alert'}
        textSize="14px"
        textColor="secondary"
      />
      <p>
        You can set multiple emails by separating them with a comma
        (&quot;,&quot;).
      </p>
    </>}
    {alert?.channel === 'telegram' && <>
      <Text label={`Configure Alert for Telegram`} textWeight="bold"
        textSize="20px"/>
      <Text
        label={'Specify the BotToken and the ChatId for the alert'}
        textSize="14px"
        textColor="secondary"
      />
      <p>
        To find the botToken, you must open the telegram messenger
        and:</p>
      <ul>
        <li> If you dont have a bot you must create one:</li>
        <ol>
          <li>Enter @Botfather in the search tab and choose this bot.<br/>
            <small>Note: the bot will have a blue checkmark beside their
              name</small><br/>
            Click &quot;Start&quot; to activate the bot.
          </li>
          <li>
            Enter /newbot and send it.
          </li>
          <li>
            Choose a name for your bot and send it.
          </li>
          <li>
            The BotFather will send you a token to access the HTTP API.
          </li>
        </ol>

        <li>
          If you already have a bot you must get the botToken:
        </li>
        <ol>
          <li>Enter @Botfather in the search tab and choose this bot.<br/>
            <small>Note: the bot will have a blue checkmark beside their
              name</small><br/>
            Click &quot;Start&quot; to activate the bot.
          </li>
          <li>
            Enter /token and send it.
          </li>
          <li>
            Choose the bot you want to get the token.
          </li>
          <li>
            The BotFather will send you a token to access the HTTP API.
          </li>
        </ol>
      </ul>
      <p>
        To find the ChatId, you must open the telegram messenger and:
      </p>
      <ol>
        <li>Create or select the group that will receive the alert</li>
        <li>Add the bot @RawDataBot to the group</li>
        <li>Check the message that the bot wrote and find the chatId</li>
        <pre>{`
    ...
    },
    "chat": {
      "id": chatID,
      ...
    ...
    `}</pre>
        <li>Remove the bot @RawDataBot from the group by clicking in the group
          name, right click in the bot and click in
          &quot;Remove from group&quot;
        </li>
        <li>Add the bot that will send the alert - that has the botId previous
          collected - to the group
        </li>
      </ol>
    </>}
  </>;
}

AlertDescriptionStepSelectChannel.propTypes = {
  alert: PropTypes.shape(
      {
        channel: PropTypes.string,
      },
  ),
};

/**
 *
 * @param{obj} alert
 * @param{string} alert.type
 * @param{string} alert.category
 * @param{string} alert.domain
 * @param{string} alert.ip
 * @return {JSX.Element}
 * @constructor
 */
function AlertDescriptionStepSelectType({alert}) {
  return <>
    {!alert?.type && <>
      <Text label="New Alert" textWeight="bold" textSize="20px"/>
      <Text
        label="Create a new alert"
        textSize="14px"
        textColor="secondary"
      />

      <p>
        You can create a alert or multiple alerts to be notify
        when your service requests a categorized domains or configured
        ips/domains.
      </p>
      <p>
        You can set a alert to notify you through email, msteams or other
        forms.
      </p>
    </>}

    {alert?.type === 'category' && <>
      <Text label="Alert Category Selection" textWeight="bold"
        textSize="20px"/>
      <Text
        label="Select the category for the alert will be triggered"
        textSize="14px"
        textColor="secondary"
      />
      <p>
        You can create an alert to be notify when your service requests
        {['a', 'e', 'i', 'o', 'u'].includes(alert?.category?.[0]) ? 'an' : 'a'}
        {alert?.category ?? 'categorized'} domain.
      </p>
      <p>
        You can set a alert to notify you through email, msteams or other
        forms.
      </p>
    </>}

    {alert?.type === 'domain' && <>
      <Text label="Alert Domain Insertion" textWeight="bold"
        textSize="20px"/>
      <Text
        label="Insert the domain for the alert will be triggered"
        textSize="14px"
        textColor="secondary"
      />
      <p>
        You can create an alert to be notify
        when your service
        requests the {alert?.domain ?? 'specify'} domain.
      </p>
      <p>
        You can set a alert to notify you through email, msteams or other
        forms.
      </p>
    </>}
    {alert?.type === 'ip' && <>
      <Text label="Alert Ip Insertion" textWeight="bold"
        textSize="20px"/>
      <Text
        label="Insert the ip for the alert will be triggered"
        textSize="14px"
        textColor="secondary"
      />
      <p>
        You can create an alert to be notify
        when your service
        responds the {alert?.ip ?? 'specify'} ip.
      </p>
      <p>
        You can set a alert to notify you through email, msteams or other
        forms.
      </p>
    </>}
  </>;
}

AlertDescriptionStepSelectType.propTypes = {
  alert: PropTypes.shape(
      {
        category: PropTypes.string,
        domain: PropTypes.arrayOf(PropTypes.string),
        ip: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
      },
  ),
};

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function AlertDescriptionStepConfirmation() {
  return <>
    <Text label="Alert Confirmation" textWeight="bold" textSize="20px"/>
    <Text
      label="Confirm the alert configuration"
      textSize="14px"
      textColor="secondary"
    />
    <p>
      Confirm the alert configuration. If you want to make changes go back to
      the previous steps.
    </p>
  </>;
}

AlertDescriptionStepConfirmation.propTypes = {};


