export const appendPath = (path) => {
  return {
    type: 'APPEND_PATH',
    payload: path,
  };
};

export const setPath = (path = 'Home') => {
  return {
    type: 'SET_PATH',
    payload: path,
  };
};
