import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

/**
 *
 * @param{string} hash
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export function ServiceRender({hash, ...props}) {
  const services = useSelector((state) => state.services);
  let s = services.filter((service) => service.hash === hash);
  s = s.length && s[0];
  if (s) {
    return (
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {s.servicename}
      </div>
    );
  }
  return (
    <div
      className="data-placeholder"
      {...props}
      style={{height: 16, background: '#eee'}}
    >
      {' '}
    </div>
  );
}
ServiceRender.propTypes = {
  hash: PropTypes.string,
};
/**
 *
 * @param{string} hash
 * @return {*|string}
 * @constructor
 */
export function ServiceName({hash}) {
  const services = useSelector((state) => state.services);
  const s = services.filter((service) => service.hash === hash);
  return (s.length && s[0].servicename) || '-';
}
