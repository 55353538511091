import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import reduxStore from './store';
import configs from './configs';

/**
 *
 * @param{object} keycloak
 */
function updateToken(keycloak) {
  keycloak.updateToken(29 * 60).then((_) => {
    if (_ === true) {
      // eslint-disable-next-line no-console
      console.log(
          'Expired Token at',
          new Date(keycloak.tokenParsed.exp * 1000),
      );
      // eslint-disable-next-line no-console
      console.log(
          'Expired Refresh Token at',
          new Date(keycloak.refreshTokenParsed?.exp * 1000),
      );
    }
  });
}

const fetchAuthenticated = (
    url,
    body,
    method,
    store = reduxStore.getState(),
    controller = new AbortController(),
) => {
  const {
    keycloak,
    operator: {personify},
  } = store;
  const strBody = body && JSON.stringify(body);
  updateToken(keycloak);
  const {signal} = controller;
  const init = {
    method,
    headers: {
      'Authorization': `Bearer ${keycloak.token}`,
      'Content-type': 'application/json',
    },
    body: strBody,
    signal,
  };
  if (method === 'GET') {
    delete init.body;
  }
  if (personify) {
    init.headers.Personify = btoa(JSON.stringify(personify));
  }
  return fetch(configs.DNS8_BACKEND.url + url, init)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        if (response.headers?.get('content-type')?.includes('json')) {
          return response.json();
        }
        return response;
      });
};

const useBackend = (url, body, method) => {
  const [{data, loading, error}, setRequestState] = useState({
    data: {},
    loading: true,
    error: null,
  });

  const [kc, operator] = useSelector((state) => [
    state.keycloak, state.operator,
  ]);

  const keycloak = useRef(kc);
  const fetchBody = useRef(body);

  useEffect(() => {
    if (kc.isTokenExpired()) {
      updateToken(kc.current);
    }
  }, [kc]);


  useEffect(() => {
    let update = true;

    const controller = new AbortController();
    if (update) {
      setRequestState((reqState) => {
        if (reqState.loading) {
          return reqState;
        }
        return {...reqState, loading: true, error: null};
      });
    }

    if (!url && update) {
      setRequestState((reqState) => ({
        ...reqState,
        loading: false,
        data: [],
      }));
    }
    if (!url) {
      return () => {
        controller.abort();
        update = false;
      };
    }

    /**
     *
     * @param{any} v
     */
    function updateRequestStateInError(v) {
      if (update) {
        setRequestState((reqState) => ({
          ...reqState,
          loading: false,
          error: v,
        }));
      }
    }

    fetchAuthenticated(url,
        fetchBody.current,
        method,
        {keycloak: keycloak.current, operator},
        controller,
    ).then((json) => {
      if (update) {
        setRequestState((reqState) => ({
          ...reqState,
          loading: false,
          data: {...json},
        }));
      }
    }).catch((e) => {
      const contentType = e?.headers?.get('content-type');
      if (!contentType) {
        // canceled ??
        if (update) {
          setRequestState((reqState) => ({
            ...reqState,
            loading: false,
            error: null,
          }));
        }
      } else if (contentType.indexOf('json') !== -1) {
        e.json().then(updateRequestStateInError);
      } else {
        e.text().then(updateRequestStateInError);
      }
    });

    return () => {
      controller.abort();
      update = false;
    };
  }, [url, method, operator]);
  return {data, loading, error};
};

export const usePostDataBackend = (url, body) => {
  return useBackend(url, body, 'POST');
};

export const useGetDataBackend = (url, body = '') => {
  const queryString = body && `?${new URLSearchParams(body).toString()}`;
  const urlWithQueryString = url ? url + queryString : null;
  return useBackend(urlWithQueryString, '', 'GET');
};

export const usePutDataBackend = (url, body) => {
  return useBackend(url, body, 'PUT');
};

export const useDeleteDataBackend = (url, body) => {
  return useBackend(url, body, 'DELETE');
};

export const postFetch = (url, body) => {
  return fetchAuthenticated(url, body, 'POST');
};

export const getFetch = (url, body) => {
  const queryString = body ? `?${new URLSearchParams(body).toString()}`: '';
  const urlWithQueryString = url ? url + queryString : null;
  return fetchAuthenticated(urlWithQueryString, '', 'GET');
};

export const putFetch = (url, body) => {
  return fetchAuthenticated(url, body, 'PUT');
};

export const deleteFetch = (url, body) => {
  return fetchAuthenticated(url, body, 'DELETE');
};
