import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Tooltip} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCertificate} from '@fortawesome/free-solid-svg-icons/faCertificate';
import React from 'react';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';
import {useGetDataBackend} from '../../ApiBackend';
import {RenderCategory} from '../Category';
import BoxInfo from '../../Components/BoxInfo';
import {TableWithList} from '../../Components/Table';

export default function TopCategories() {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };

  const {data, loading, error} = useGetDataBackend(
      'requests/topCategories',
      requestBody,
  );
  const cols = [
    {
      title: <b>Category</b>,
      render: (c) => {
        let [list, service] = c.split('-');
        list = service === 'root' ? `${list}-${service}` : list;
        const link = `/requests?list=${list}`;
        return (
          <Link style={{textDecoration: 'none'}} to={link}>
            <Tooltip title="More Information">
              <div>
                <RenderCategory list={c} />
              </div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: <b>Requests</b>,
      styles: {
        width: 80,
        textAlign: 'right',
      },
      render: (d) => (
        <div style={{width: 'auto', textAlign: 'right'}}>{d}</div>
      ),
    },
  ];
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon icon={faCertificate} /> Top Categories
        </b>
      }
    >
      <TableWithList
        cols={cols}
        loading={loading}
        list={data?.topCategories}
        error={error}
      />
    </BoxInfo>
  );
}
