import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import RedTabs from '../../Components/RedTabs';

import {setPath} from '../../actions/pathAction';
import {useGetDataBackend} from '../../ApiBackend';
import PublicRequestsTable from '../../../src/Pages/requests/NewPublicRequestsTable';
import AllRequestsChart from './AllRequestsChart';
import DomainInfoUser from './DomainInfoUser';
import InternalRequestsTable from '../Tables/InternalRequestsTable';
import AllInternalRequestsChart from './AllInternalRequestsChart';

/**
 *
 * @param{object} params
 * @return {JSX.Element}
 * @constructor
 */
export default function DomainInfo({match: {params = {}}}) {
  const currentTab = +!!params.i;
  const [clientname] = useSelector((state) => [state.selectedService]);
  const disabled =
      'This info is only available to clients with the DNS8 plugin installed.';
  const {data, error, loading} = useGetDataBackend('services/has_internal', {
    clientname,
  });

  const tabs = [
    {
      label: 'Public',
      page: PublicDomainInfo,
    },
    {
      label: 'Internal',
      page: InternalDomainInfo,
      disabled: (loading || error || !data.total) && disabled,
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Domains'));
    return () => null;
  }, [dispatch]);

  return <RedTabs tabs={tabs} tabId={currentTab} />;
}
DomainInfo.propTypes={
  match: PropTypes.object,
};

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function PublicDomainInfo(props) {
  return <DomainInfoUser
    {...props}
    RequestsTable={PublicRequestsTable}
    RequestChart={AllRequestsChart}
  />;
}

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function InternalDomainInfo(props) {
  return <DomainInfoUser
    {...props}
    RequestsTable={InternalRequestsTable}
    RequestChart={AllInternalRequestsChart}
  />;
}
