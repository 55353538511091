import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBan,
  faCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {faChartArea} from '@fortawesome/free-solid-svg-icons/faChartArea';
import moment from 'moment';
import {useGetDataBackend} from '../../ApiBackend';
import BoxInfo from '../../Components/BoxInfo';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';

export default function AllInternalRequestsChart({domain}) {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };
  delete requestBody.domain;
  if (domain) {
    requestBody.domain = domain;
  }

  const [body, setBody] = useState(requestBody);
  useEffect(() => {
    let update = true;
    const newRequestBody = {
      t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
      clientname: selectedService,
    };
    delete newRequestBody.domain;
    if (domain) {
      newRequestBody.domain = domain;
    }

    if (update) {
      setBody(newRequestBody);
    }
    return () => {
      update = false;
    };
  }, [timeRange, selectedService, domain]);

  const {data, loading, error} = useGetDataBackend(
      'internal_requests/histogram',
      body,
  );
  if (data.overtime) {
    data.overtime = data.overtime.map((d) => {
      return {...d, time_format: moment(d.time).format(d.time_format)};
    });
  }
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon icon={faChartArea} /> Requests Overtime
        </b>
      }
    >
      <div style={{display: 'flex', flex: 1, height: 315}}>
        {(loading || error) && (
          <div style={{display: 'flex', margin: 'auto'}}>
            <div style={{margin: 'auto', color: '#BE131A'}}>
              <FontAwesomeIcon icon={faSpinner} spin
                style={{fontSize: 40}} />
            </div>
          </div>
        )}

        {!(loading || error) &&
          !(data.count && data.count.requests && data.count.requests.total) && (
          <div style={{display: 'flex', flex: 1, height: '100%'}}>
            <div
              style={{
                margin: 'auto',
                fontSize: 16,
                color: 'rgba(70, 70, 70, 0.6)',
              }}
            >
                  There are no Requests
            </div>
          </div>
        )}

        {!(loading || error) &&
          !!(
            data.count &&
              data.count.requests &&
              data.count.requests.total
          ) && (
          <>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '80%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  width: '75%',
                  padding: '10px 10px 0',
                }}
              >
                <Link
                  to="/requests"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <div
                    style={{
                      margin: 'auto 5px auto auto',
                      fontSize: 22,
                      color: '#1870B4',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      mask={faCircle}
                      transform="shrink-6"
                    />
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '.7rem'}}>Requests</div>
                    <div style={{fontSize: '1.1rem'}}>
                      {data.count.requests.total}
                    </div>
                  </div>
                </Link>
                <Link
                  to="/requests?action=blocked"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <div
                    style={{
                      margin: 'auto 5px auto auto',
                      fontSize: 22,
                      color: '#BE131A',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBan}
                      mask={faCircle}
                      transform="shrink-6"
                    />
                  </div>
                  <div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div style={{fontSize: '.7rem'}}>
                            Blocked Requests
                      </div>
                      <div style={{fontSize: '1.1rem'}}>
                        {data.count.requests.blocked}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '75%', flex: 1}}>
                  <ResponsiveContainer>
                    <AreaChart
                      data={data.overtime || []}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 10,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3"
                        vertical={false} />
                      <defs>
                        <linearGradient
                          id="colorBlue"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#1870B4"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#1870B4"
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorRed"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#BE131A"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#BE131A"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="time_format" />
                      <YAxis />
                      <Tooltip />
                      {/* <Legend verticalAlign="top" height={36}/> */}
                      <Area
                        type="monotone"
                        name="Requested"
                        stroke="#1870B4"
                        dataKey="requested"
                        fill="url(#colorBlue)"
                      />
                      <Area
                        type="monotone"
                        name="Blocked"
                        stroke="#BE131A"
                        dataKey="blocked"
                        fill="url(#colorRed)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div style={{width: '130px'}}>
                      Unique Domains
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div
                      style={{
                        margin: 'auto 5px',
                        width: 25,
                        fontSize: '1.1rem',
                        color: '#1870B4',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        mask={faCircle}
                        transform="shrink-6"
                      />
                    </div>
                    <div>
                      <div style={{fontSize: '1.1rem'}}>
                        {data.count.domains.total}
                      </div>
                    </div>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div
                      style={{
                        margin: 'auto 5px',
                        width: 25,
                        fontSize: '1.1rem',
                        color: '#BE131A',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBan}
                        mask={faCircle}
                        transform="shrink-6"
                      />
                    </div>
                    <div>
                      <div style={{fontSize: '1.1rem'}}>
                        {data.count.domains.blocked}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </BoxInfo>
  );
}
