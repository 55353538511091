import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import {faEdit, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {setPath} from '../../actions/pathAction';
import RedTextField from '../../core/components/inputs/RedTextField';
import {
  useDeleteDataBackend,
  usePutDataBackend,
} from '../../ApiBackend';
import {displayError} from '../../ui-components/displayMsg';
import {
  TimeLineEvent,
  TimeLineEvents,
} from '../../core/components/timeline/TimeLine';
import {useReleases} from './ReleasePage';
import RedButton from '../../core/components/buttons/RedButton';

const spaceBetween = 'space-between';

const c = makeStyles((theme) => ({
  lateralSpace: {
    paddingLeft: theme.spacing(3) / 2,
    paddingRight: theme.spacing(3) / 2,
  },
  grid: {
    flex: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(3) / 2,
    paddingRight: theme.spacing(3) / 2,
    flexDirection: 'row',
    alignContent: 'baseline',
  },
}));


/**
 *
 * @return {{setUpdate: function,
 * update: {},
 * dialogTitle: string}}
 */
function useActionAdminReleasePage() {
  const [update, setUpdate] = useState({});
  const [title, setTitle] = useState('Update');

  useEffect(() => {
    const {_id: updateId, delete: updateDelete} = update;
    if (!updateId) {
      setTitle(`New update`);
    } else if (updateDelete) {
      setTitle('Delete update');
    } else {
      setTitle('Edit update');
    }
  }, [update]);

  return {
    dialogTitle: title,
    update,
    setUpdate,
  };
}

/**
 *
 * @param{boolean} open
 * @param{string} dialogTitle
 * @param{object} update
 * @param{function} setUpdate
 * @param{function} setOpen
 * @param{function} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
function AddUpdateModal({
  open,
  dialogTitle,
  update,
  setUpdate,
  setOpen,
  setRefresh,
}) {
  const closeAndUpdate = () => {
    setOpen(false);
    setUpdate({});
    setRefresh((s) => s + 1);
  };
  return <Dialog open={open}
    onClose={() => setOpen(false) || setUpdate({})}>
    <DialogTitle>{dialogTitle}</DialogTitle>
    <DialogContent>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          listStyle: 'none',
          width: '270px',
        }}
      >
        <RedTextField
          label="Update Name"
          variant="outlined"
          value={update.title ?? ''}
          onChange={({target: {value}}) =>
            setUpdate((o) => ({...o, title: value}))
          }
        />
        <RedTextField
          label="Update description"
          variant="outlined"
          multiline
          style={{margin: '20px 0'}}
          rows={4}
          value={update.description ?? ''}
          onChange={({target: {value}}) =>
            setUpdate((o) => ({
              ...o,
              description: value,
            }))
          }
        />
        <RedTextField
          locale="en"
          variant="outlined"
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          value={moment(update['@timestamp'] || moment(), [
            'x',
            'YYYY-MM-DDTHH:mm',
          ]).format('YYYY-MM-DDTHH:mm')}
          onChange={({target: {value}}) => {
            setUpdate((o) => ({...o, '@timestamp': value}));
          }
          }
        />
        {!update.delete && (
          <RedButton
            variant={'contained'}
            style={{margin: '20px 0'}}
            onClick={() => setUpdate(
                (o) => ({...o, saving: true}))}
            disabled={update.saving}
          >
            SAVE
          </RedButton>
        )}
        {update.delete && (
          <RedButton
            variant={'contained'}
            style={{margin: '20px 0'}}
            onClick={() =>
              setUpdate((o) => ({...o, deleting: true}))
            }
            disabled={update.deleting}
          >
            DELETE
          </RedButton>
        )}
        {update.saving && (
          <SavingReleaseNote
            releasenote={update}
            setReleaseNote={setUpdate}
            sideEffect={closeAndUpdate}
          />
        )}
        {update.deleting && (
          <DeletingReleaseNote
            releasenote={update}
            setReleaseNote={setUpdate}
            sideEffect={closeAndUpdate}
          />
        )}
      </div>
    </DialogContent>
  </Dialog>;
}
AddUpdateModal.propTypes={
  open: PropTypes.bool,
  dialogTitle: PropTypes.string,
  update: PropTypes.object,
  setUpdate: PropTypes.func,
  setOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};
/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function AdminReleasePage() {
  const [refresh, setRefresh] = useState(0);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Release notes'));
    return () => null;
  }, [dispatch]);

  const classes = c();

  const {
    dataByDate,
  } = useReleases(refresh);

  const {
    dialogTitle,
    update,
    setUpdate,
  } = useActionAdminReleasePage();


  return (
    <Grid
      container
      className={classes.grid}
      style={{overflow: 'overlay'}}
    >
      <Grid item
        xs={12}
        className={classes.lateralSpace}
      >
        <div style={{
          height: 180,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div style={{
            fontSize: 24,
            color: '#333333',
            marginTop: 'auto',
          }}>
            <b>What&apos;s new on DNS8</b>
          </div>
          <p style={{color: '#464646'}}>
            We update the platform regularly. Check out this page to lean more
            about the last features.
          </p>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.lateralSpace} >
        <Divider style={{margin: '14px 0 0 0'}} />
      </Grid>
      <Grid item xs={12} className={classes.lateralSpace} >
        <div style={{height: 70,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'}} >
          <RedButton variant={'contained'}
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Add new Update
          </RedButton>
          <AddUpdateModal
            open={open}
            setOpen={setOpen}
            dialogTitle={dialogTitle}
            update={update}
            setUpdate={setUpdate}
            setRefresh={setRefresh}
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.lateralSpace} >
        <div
          style={{
            overflowY: 'overlay',
            padding: 0,
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: spaceBetween,
            flexWrap: 'wrap',
          }}
        >
          {dataByDate &&
          dataByDate.map(([date, releases]) => {
            return <TimeLineEvents key={date} date={date}>
              {releases.map((r) => {
                const {title, description, _id} = r;
                return <TimeLineEvent
                  key={_id}
                  title={title}
                  content={description}>
                  <RedButton
                    variant={'contained'}
                    onClick={() => {
                      setOpen(true);
                      setUpdate({
                        ...r,
                        delete: true,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} /> &nbsp;DELETE
                  </RedButton>
                  &nbsp;
                  <RedButton
                    variant={'contained'}
                    onClick={() => {
                      setOpen(true);
                      setUpdate(r);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />&nbsp;EDIT
                  </RedButton>
                </TimeLineEvent>;
              })}
            </TimeLineEvents>;
          })}
        </div>
      </Grid>
    </Grid>
  );
}


/**
 *
 * @param{object} releasenote
 * @param{function} setReleaseNote
 * @param{function} sideEffect
 * @return {JSX.Element}
 * @constructor
 */
function SavingReleaseNote({
  releasenote,
  setReleaseNote,
  sideEffect = (_) => false,
}) {
  const {loading, error} = usePutDataBackend('releases/', releasenote);
  useEffect(() => {
    if (error) {
      displayError('Error adding new update:', error);
      setReleaseNote((o) => ({...o, saving: false}));
    } else if (!loading) {
      sideEffect();
    }
  }, [loading, error, setReleaseNote, sideEffect]);

  return <></>;
}
SavingReleaseNote.propTypes={
  releasenote: PropTypes.object,
  setReleaseNote: PropTypes.func,
  sideEffect: PropTypes.func,
};

/**
 *
 * @param{object} releasenote
 * @param{function} setReleaseNote
 * @param{function} sideEffect
 * @return {JSX.Element}
 * @constructor
 */
function DeletingReleaseNote({
  releasenote,
  setReleaseNote,
  sideEffect = (_) => false,
}) {
  const {loading, error} = useDeleteDataBackend('releases/', releasenote);
  useEffect(() => {
    if (error) {
      displayError('Error deleting new update:', error);
      setReleaseNote((o) => ({...o, deleting: false}));
    } else if (!loading) {
      sideEffect();
    }
  }, [loading, error, setReleaseNote, sideEffect]);

  return <></>;
}
DeletingReleaseNote.propTypes = SavingReleaseNote.propTypes;
