import React, {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    'border': '1px solid rgb(196,196,196)',
    'borderRadius': 5,
    'padding': '0 7px 0 16px',
    'background': 'white',
    'height': 37.63,
    'margin': 0,
    '&:hover': {
      border: '1px solid rgba(0,0,0,.87)',
    },
    '&.MuiFormControlLabel-labelPlacementTop': {
      'padding': 'unset',
      '& .MuiFormControlLabel-label ': {
        marginBottom: 6,
      },
    },
    '& .MuiFormControlLabel-label ': {
      fontSize: 14,
      color: '#464646',
    },
  },
}));

function CheckForm({
  label,
  checked = false,
  sideEffect = () => null,
  ...props
}) {
  const classes = useStyles();
  const [check, setCheck] = useState(checked);

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          color="default"
          size="small"
          checked={check}
          onChange={({target: {checked: isChecked}}) =>
            setCheck(isChecked) || sideEffect(isChecked)
          }
        />
      }
      label={label}
      labelPlacement="start"
      props={props}
    />
  );
}

export default CheckForm;
