import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RedTabs from '../../Components/RedTabs';

import InternalRequests from './InternalRequests';
import PublicRequests from './PublicRequests';
import {setPath} from '../../actions/pathAction';
import {useGetDataBackend} from '../../ApiBackend';

export default function Requests() {
  const [clientname] = useSelector((state) => [state.selectedService]);
  const disabled =
      'This info is only available to clients with the DNS8 plugin installed.';
  const {data, error, loading} = useGetDataBackend('services/has_internal', {
    clientname,
  });
  const tabs = [
    {
      label: 'Public',
      page: PublicRequests,
    },
    {
      label: 'Internal',
      page: InternalRequests,
      disabled: (loading || error || !data.total) && disabled,
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Requests'));
    return () => null;
  }, [dispatch]);
  return <RedTabs tabs={tabs} />;
}
