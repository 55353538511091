import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faCircle} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';
import {useGetDataBackend} from '../../ApiBackend';
import {RenderDomainLink} from '../Domain';
import BoxInfo from '../../Components/BoxInfo';
import {TableWithList} from '../../Components/Table';

export default function TopRequests() {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };
  const {data, loading, error} = useGetDataBackend(
      'requests/topDomains',
      requestBody,
  );
  const style = {color: '#464646'};
  const cols = [
    {
      title: <b>Domain</b>,
      render: (d) => {
        return (
          <RenderDomainLink
            domain={d}
            tooltip={`More info: ${d}`}
            style={style}
          />
        );
      },
    },
    {
      title: <b>Requests</b>,
      styles: {
        width: 80,
        textAlign: 'right',
      },
      render: (d) => (
        <div style={{width: 'auto', textAlign: 'right'}}>{d}</div>
      ),
    },
  ];
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon
            icon={faArrowRight}
            mask={faCircle}
            transform="shrink-6"
          />{' '}
              Top Requests
        </b>
      }
    >
      <TableWithList
        cols={cols}
        loading={loading}
        list={data?.topDomains}
        error={error}
      />
    </BoxInfo>
  );
}
