import React from 'react';
import {useMediaQuery, useTheme} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import PublicRequestsTable from './NewPublicRequestsTable';

/**
 *
 * @param{*} action
 * @param{string} title
 * @param{string} location
 * @param{*} props
 * @return {Element}
 * @constructor
 */
function Requests2({
  action = '',
  title = 'Requests',
  location,
  ...props
}) {
  const newProps = {...props};
  if (location?.search) {
    const params = new URLSearchParams(location?.search);

    if (params.get('list')) {
      newProps.extradata = newProps.extradata || {};
      newProps.extradata.list = params.get('list');
    }
    if (params.get('action')) {
      newProps.extradata = newProps.extradata || {};
      newProps.extradata.action = params.get('action');
    }
  }

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  const small = useMediaQuery((t) => t.breakpoints.down('sm'));
  const top = small ? 20 : 104;

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: top}}/>
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <PublicRequestsTable {...{action, title, ...newProps}} />
      </Grid>

    </Grid>
  );
}

export default withRouter(Requests2);
