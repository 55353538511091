import {makeStyles} from '@material-ui/core';


export const selectionButtonStyles = {
  width: '350px',
};
export const useStyles = makeStyles(() => ({
  headerMenu: {
    display: 'flex', width: '100%',
  }, menuWrapper: {
    flex: '0 0 auto', flexShrink: '0', width: '240px', whiteSpace: 'nowrap',
  }, section: {
    width: '100%', display: 'flex', flexDirection: 'column', gap: '15px',
  }, mainContainer: {
    marginTop: '56px',
    padding: '32px 24px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  }, progressButtonsWrapper: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'height': 'fit-content',
    'justifyContent': 'space-between',
    'alignSelf': 'flex-end',
    'width': '100%',
    'gap': '15px',
    '& .MuiButton-root': {
      width: '150px',
    },
  },

}));


export const useStylesCheckBox = makeStyles((theme) =>
  ({
    root: {
      'width': '100%',
      'backgroundColor': '#f5f5f5',
      'borderRadius': 5,
      'gap': 10,
      '&.Mui-selected': {
        'outline': 'solid 2px #333',
        'outline-offset': -2,
        '&:hover': {'outline': 'solid 2px #333', 'outline-offset': -2},
      },
      '&:hover': {
        'backgroundColor': '#f5f5f5',
        'outline': 'solid 2px #c2c1c1',
        'outline-offset': -2,
      },
      '& + .MuiListItem-root': {marginTop: 5},
      '& .MuiListItemIcon-root': {minWidth: 'unset'},
      '& .MuiIconButton-edgeStart': {marginLeft: 0},
      '& .MuiRadio-colorSecondary': {

        'color': '#d9d9d9', '&.Mui-checked': {
          'color': '#333', '&:hover': {
            backgroundColor: 'rgba(51, 51, 51, 0.04)',

          },
        }, '&:hover': {
          backgroundColor: 'rgba(51, 51, 51, 0.04)',

        },
      },

    },
  }));
