import {IconButton, Tooltip, withStyles} from '@material-ui/core';
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

const RedIconButtonBase = withStyles({
  root: {
    'fontSize': '18px',
    'color': '#464646',
    '&:hover': {
      color: '#BE131A',
    },
    'width': 48,
    'height': 48,
    'padding': 0,
    '& .MuiIconButton-label': {},
    '&.MuiIconButton-sizeSmall': {
      width: 30,
      height: 30,
    },
  },
})(IconButton);

const RedIconButton = forwardRef(
    function RedIconButton(
        {TooltipProps, alwaysTooltip = false, ...props},
        ref) {
      if (TooltipProps && props.disabled && alwaysTooltip) {
        return <Tooltip {...TooltipProps}>
          <span>
            <RedIconButtonBase ref={ref} {...props} />
          </span>
        </Tooltip>;
      }
      if (TooltipProps && !props.disabled) {
        return (
          <Tooltip {...TooltipProps}>
            <RedIconButtonBase ref={ref} {...props} />
          </Tooltip>
        );
      }
      return <RedIconButtonBase ref={ref} {...props} />;
    },
);

RedIconButton.defaultProps = {
  color: 'default',
  disabled: false,
  disableFocusRipple: false,
  disableRipple: false,
  edge: false,
  size: 'medium',
  TooltipProps: null,
  alwaysTooltip: false,
};
RedIconButton.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The color of the component. It supports those theme colors that make sense
   * for this component.
   * @default 'default'
   */
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the  keyboard focus ripple is disabled.
   * @default false
   */
  disableFocusRipple: PropTypes.bool,
  /**
   * If `true`, the ripple effect is disabled.
   *
   * ⚠️ Without a ripple there is no styling for :focus-visible by default.
   * Be sure to highlight the element by applying separate styles with the
   * `.Mui-focusedVisible` class.
   * @default false
   */
  disableRipple: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on one
   * side (this is often helpful for aligning the left or right
   * side of the icon with content above or below, without ruining the border
   * size and shape).
   * @default false
   */
  edge: PropTypes.oneOf(['end', 'start', false]),
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'medium'
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * Props applied to the Tooltip element.
   * only if the button is not disabled.
   */
  TooltipProps: PropTypes.object,
  /**
   * Flag to indicate tooltip in disable RedButton.
   */
  alwaysTooltip: PropTypes.bool,
};

export default RedIconButton;
