import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';
import {useGetDataBackend} from '../../ApiBackend';
import {RenderDomainWithList} from '../Domain';
import BoxInfo from '../../Components/BoxInfo';
import {TableWithList} from '../../Components/Table';

export default function TopThreats() {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };
  const {data, loading, error} = useGetDataBackend(
      'threats/top',
      requestBody,
  );
  const cols = [
    {
      title: <b>Domain</b>,
      render: (d, row) => {
        let row2 = row[2];
        if (!Array.isArray(row[2])) {
          row2 = [row[2]];
        }
        const list = row2[0];
        return <RenderDomainWithList list={list} domain={[d]} domains={[d]} />;
      },
    },
    {
      title: <b>Requests</b>,
      styles: {
        width: 80,
        textAlign: 'right',
      },
      render: (d) => (
        <div style={{width: 'auto', textAlign: 'right'}}>{d}</div>
      ),
    },
  ];
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon icon={faCalendar} /> Top Threats
        </b>
      }
    >
      <TableWithList
        cols={cols}
        loading={loading}
        list={data?.list}
        error={error}
      />
    </BoxInfo>
  );
}
