import React from 'react';
import {useMediaQuery, useTheme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import InternalRequestsTable from '../Tables/InternalRequestsTable';

export default function InternalRequests({extradata = {}, ...props}) {
  const padding = useTheme().spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  const small = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const top = small ? 20 : 104;

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: top}} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <InternalRequestsTable extradata={extradata} {...props} />
      </Grid>
    </Grid>
  );
}
