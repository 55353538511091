import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import punycode from 'punycode';
import {useMediaQuery} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import CheckForm from '../../Components/Forms/CheckForm';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {RenderCategory, RenderCategoryChip} from '../Category';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';
import {useGetDataBackend, usePutDataBackend} from '../../ApiBackend';
import RedTextField from '../../core/components/inputs/RedTextField';
import PropTypes from 'prop-types';

/**
 *
 * @param{function} setDomain
 * @param{string} domain
 * @param{{}} setRefresh
 * @return {JSX.Element}
 * @constructor
 */
export default function AddDomainForm({setDomain, domain = '', setRefresh}) {
  const [list, setList] = useState();
  const [comment, setComment] = useState('');

  const [subDomain, setSubDomain] = useState(false);
  const [submitting, setSubmitting] = useState();
  const [service] = useSelector((state) => [state.selectedService]);
  const {
    data: {categories = []},
  } = useGetDataBackend('domain/category', {
    domain,
    clientname: service,
  });

  const cats = Object.values(
      categories.reduce((acc, {clientname, list}) => {
        if (!acc[list]) {
          acc[list] = {list, clientname: []};
        }
        acc[list].clientname.push(clientname);
        return acc;
      }, {}),
  );

  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const onSubmit = (e) => {
    e.preventDefault();
    if (domain && list) {
      displayConfirmation(
          <>
          Do you really want to add the domain {domain} in{' '}
            {
              <RenderCategory
                style={{display: 'inline-flex', lineHeight: '14px'}}
                list={list}
              />
            }
          ?
            {!!cats.length && <><br /> <br />Present in categories:<br/>{
              cats.map(({clientname, list}, i) => {
                const categoryKey = 'category-' + i;
                return (
                  <RenderCategoryChip
                    key={categoryKey}
                    list={list}
                    clientnames={clientname}
                    withLabel={true}
                  />
                );
              })}
            </>}
            <RedTextField
              style={{marginTop: 20}}
              placeholder="Please insert here a motive for this decision."
              label="Insert a motive for this decision."
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              onBlur={({target: {value}}) => setComment(value)}
            />
          </>,
          'ADD',
          'CANCEL',
          () => setSubmitting(true),
      );
    }
  };
  const subdomainsLabel = isXsmall ? 'Sub Domains' : 'Include Sub Domains';
  const subdomainsLabelPosition = isXsmall ? 'top' : 'start';

  const propsRedTextField = {
    variant: 'outlined',
    size: 'small',
    style: {
      backgroundColor: 'white',
    },
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          margin: '3px -3px 0px',
          flexWrap: 'wrap',
          minHeight: '78px',
        }}
      >
        <FormControl style={{margin: 3}}>
          <FormLabel
            style={{fontSize: '14px', color: '#464646', padding: '.5rem 0'}}
          >
            Insert/Search Domain
          </FormLabel>
          <RedTextField
            {...propsRedTextField}
            placeholder="ex: example.pt"
            defaultValue={domain}
            onKeyPress={(e) =>
              e.key === 'Enter' &&
              (setDomain(e.target.value || '') || e.preventDefault())
            }
            onBlur={({target: {value}}) => setDomain(value || '')}
          />
        </FormControl>
        <FormControl style={{margin: 3}}>
          <CheckForm
            label={subdomainsLabel}
            checked={subDomain}
            sideEffect={setSubDomain}
            labelPlacement={subdomainsLabelPosition}
          />
        </FormControl>
        <FormControl style={{margin: 3, marginLeft: 'auto'}}>
          <ButtonForm
            variant="outlined"
            style={{margin: 0}}
            className="sec"
            disabled={!domain}
            onClick={() => setList('whitelist')}
            type={domain ? 'submit' : 'button'}
          >
            <RenderCategory list="whitelist"/>
          </ButtonForm>
        </FormControl>
        <FormControl style={{margin: 3}}>
          <ButtonForm
            variant="outlined"
            style={{margin: 0}}
            disabled={!domain}
            onClick={() => setList('blacklist')}
            type={domain ? 'submit' : 'button'}
          >
            <RenderCategory list="blacklist" style={{color: 'white'}}/>
          </ButtonForm>
        </FormControl>
      </form>
      {submitting && (
        <AddDomain
          {...{
            domain,
            service,
            list,
            sub_domain: subDomain,
            setSubmitting,
            setRefresh,
            comment,
          }}
        />
      )}
    </>
  );
}
AddDomainForm.propTypes = {
  setDomain: PropTypes.func,
  setRefresh: PropTypes.object,
  domain: PropTypes.string,
};

function AddDomain({setSubmitting, setRefresh, list, ...props}) {
  const {loading, error} = usePutDataBackend(`list/${list}`, {
    ...props,
    domain: punycode.toASCII(props.domain),
  });

  useEffect(() => {
    if (!loading && !error) {
      if (setRefresh && setRefresh[list]) {
        setRefresh[list]();
      }
      setSubmitting(false);
    } else if (error) {
      displayError('Error adding domain:', error);
      setSubmitting(false);
    }
  }, [loading, error, setSubmitting, setRefresh, list]);

  return <></>;
}
