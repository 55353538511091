import React, {useEffect, useState} from 'react';
import {
  Avatar,
  Chip,
  MenuItem,
  Table as TableMui,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEdit,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import validator from 'validator';
import IPCIDR from 'ip-cidr';
import ListSubheader from '@material-ui/core/ListSubheader';
import RedTextField from '../../core/components/inputs/RedTextField';
import SelectMultipleSearch from '../Inputs/SelectMultipleSearch';
import ButtonForm from '../../Components/Forms/ButtonForm';
import Table from '../../Components/Table';
import {RenderCategory, RenderCategoryChip} from '../Category';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';
import {ServiceRender} from '../Service';
import InactiveActiveSwitchState
from '../../Components/InactiveActiveSwitchState';
import {
  useDeleteDataBackend,
  useGetDataBackend,
  usePostDataBackend,
} from '../../ApiBackend';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Alerts page
 * @param{string} url
 * @return {JSX.Element}
 * @constructor
 */
export default function Alerts({url = ''}) {
  const [refreshing, setRefreshing] = useState(0);

  const theme = useTheme();
  const padding = theme.spacing(3);

  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  useEffect(() => {
    if (url) {
      window.history.replaceState(null, null, url);
    }
  }, [url]);

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: 104}}/>
      </Grid>

      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <AlertsTable
          title={
            <>
              <b>Alerts</b>
              <Link to={'/settings/alerts/create'}>
                <RedIconButton>
                  <FontAwesomeIcon icon={faPlus}/>
                </RedIconButton>
              </Link>
            </>
          }
          refresh={refreshing}
          forceRefresh={() => setRefreshing((s) => s + 1)}
        />
      </Grid>
      <Grid item xs={12} style={{height: padding, margin: 'auto'}}/>
    </Grid>
  );
}
Alerts.propTypes = {
  url: PropTypes.string,
};

/**
 *
 * @param{Element|string} title
 * @param{number} refresh
 * @param{function} forceRefresh
 * @return {Element}
 * @constructor
 */
function AlertsTable({title, refresh, forceRefresh = () => null}) {
  const [extra, setExtraData] = useState({size: 10});
  const [deleting, setDelete] = useState();
  const [updating, setUpdating] = useState();
  const [toggling, setToggling] = useState();
  const [selectedService, services] = useSelector((state) => [
    state.selectedService,
    state.services,
  ]);

  const {
    data: {total = 0},
  } = useGetDataBackend('services/has_internal', {
    clientname: selectedService,
  });

  const small = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const onClick = (doc) => () => {
    displayConfirmation(
        'By click Delete you will exclude the alert. Are your sure?',
        'DELETE',
        'CANCEL',
        () => {
          setDelete(doc);
        },
    );
  };

  const DeleteAlert = ({sideEffect=() => null}) => {
    const {loading, error} = useDeleteDataBackend('alerts/', {...deleting});
    useEffect(() => {
      if (!loading && !error) {
        setDelete(false);
        sideEffect();
      } else if (error) {
        setDelete(false);
      }
    }, [loading, error]);

    return <></>;
  };

  const freqOptions = {
    'minutes: 1': 'per Minute',
    'hours: 1': 'per Hour',
  };

  useEffect(() => {
    setExtraData((prev) => ({
      ...prev,
      clientname: selectedService,
      ...refresh,
    }));
  }, [selectedService, refresh]);

  const columns = [
    {
      title: <b>Service</b>,
      data: 'clientname',
      render: (el) => el.map((e) => <ServiceRender key={e} hash={e}/>),
      hide:
        small ||
        services.length <= 1 ||
        !(selectedService === 'all' || selectedService.length === 0),
    },
    {
      sortable: true,
      searchable: true,
      name: <b>Alert type</b>,
      data: 'list',
      render: (el, row) => {
        if (['domain', 'ip'].includes(el)) {
          return (
            <Tooltip title={row.domain_ip.join(', ')}>
              <Chip
                style={{maxWidth: '100%'}}
                avatar={
                  <Avatar style={{color: '#FFF'}}>
                    {el === 'domain' ? 'D' : 'IP'}
                  </Avatar>
                }
                label={
                  row.domain_ip.length === 1 ?
                    row.domain_ip.join(', ') :
                    `(${row.domain_ip.length} ${el}s)`
                }
                variant="outlined"
              />
            </Tooltip>
          );
        }
        return <RenderCategoryChip list={el}/>;
      },

      styles: {
        width: 200,
      },
    },
    {
      name: <b>Mail Trigger</b>,
      data: '_',
      render: (el, row) => {
        if (['domain', 'ip'].includes(row.list)) {
          return (
            <div style={{display: 'flex'}}>
              <span style={{margin: 'auto 0'}}>
                {row.num_events} event{row.num_events > 1 && 's'}{' '}
                {freqOptions[row.time]}
              </span>
            </div>
          );
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {' '}
            {row.num_events} event{row.num_events > 1 && 's'}{' '}
            {freqOptions[row.time]}
          </div>
        );
      },

      styles: {
        width: 150,
      },
    },
    {
      name: <b>Email</b>,
      data: 'email',
      sortable: true,
    },
    {
      name: '',
      data: 'active',
      render: (el, row) => (
        <InactiveActiveSwitchState
          checked={el}
          sideEffect={() => setToggling({...row, active: !row.active})}
        />
      ),
      styles: {
        width: 150,
      },
    },
    {
      name: '',
      data: '_id',
      render: (el, row) => (
        <>
          <Link to={{pathname: '/settings/alerts/edit', state: row}}>
            <RedIconButton
              TooltipProps={{title: 'Edit'}}
            >
              <FontAwesomeIcon icon={faEdit}/>
            </RedIconButton>
          </Link>
          <RedIconButton
            TooltipProps={{title: 'Delete'}}
            onClick={onClick(row)}
          >
            <FontAwesomeIcon icon={faTrash}/>
          </RedIconButton>
        </>
      ),
      styles: {
        width: 120,
      },
    },
  ];
  return (
    <>
      <Table
        row_height={52}
        title={title}
        url="alerts"
        columns={columns}
        extradata={extra}
        page_groups={[10, 50, 100]}

      />
      {deleting && <DeleteAlert doc={deleting} sideEffect={
        () => forceRefresh((o) => o + 1)}/>}
      {updating && (
        <UpdateAlert
          {...updating}
          hasInternal={!!total}
          negativeSideEffect={() => setUpdating(false)}
          sideEffect={() => setUpdating(false) ||
            forceRefresh((o) => o + 1)}
        />
      )}
      {toggling && (
        <ToggleAlert {...toggling} sideEffect={() => setToggling(false)}/>
      )}
    </>
  );
}

AlertsTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  refresh: PropTypes.number,
  forceRefresh: PropTypes.func,
};


const redTextFieldProps = {
  fullWidth: true,
  select: true,
  size: 'small',
  variant: 'outlined',
  SelectProps: {
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    },
  },
};

/**
 *
 * @param{string} domain
 * @return {boolean}
 */
function domainValidation(domain) {
  const cleanDomain = domain.trim().toLowerCase();
  // eslint-disable-next-line max-len
  const domainRegex = /^(\*\.)?((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]))\.)+((xn--[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?)|([a-zA-Z]{2,}))$/;
  return domainRegex.test(cleanDomain);
}

/**
 *
 * @param{function} negativeSideEffect
 * @param{function} sideEffect
 * @param{boolean} hasInternal
 * @param{object} doc
 * @param{string} doc.list
 * @param{[string]} [doc.domain_ip]
 * @param{string} doc.email
 * @param{string} doc.time
 * @param{string} doc.num_events
 * @return {Element}
 * @constructor
 */
function UpdateAlert({negativeSideEffect, sideEffect, hasInternal, ...doc}) {
  const [open, setOpen] = useState(true);
  const [updating, setUpdate] = useState(false);
  const [numberTrigger, setNumberTrigger] = useState(doc.num_events);
  const [domains, setDomains] = useState(
    doc.list === 'domain' ? doc.domain_ip : [],
  );
  const [ips, setIPs] = useState(doc.list === 'ip' ? doc.domain_ip : []);
  const [email, setEmail] = useState(doc.email.split(','));
  const [frequency, setFrequency] = useState(doc.time);
  const [list, setList] = useState(doc.list);

  const listOptions = [
    {value: 'adware', label: <RenderCategory list="adware"/>},
    {value: 'anonymizers', label: <RenderCategory list="anonymizers"/>},
    {value: 'blacklist', label: <RenderCategory list="blacklist"/>},
    {
      value: 'blacklist-root',
      label: <RenderCategory list="blacklist-root"/>,
    },
    {value: 'dga', label: <RenderCategory list="dga"/>},
    {value: 'malware', label: <RenderCategory list="malware"/>},
    {value: 'phishing', label: <RenderCategory list="phishing"/>},
    {value: 'reputation', label: <RenderCategory list="reputation"/>},
  ];

  const groupOptions = [
    {label: 'Query Categories'},
    ...listOptions,
    {
      label: 'Internal Domain/IPs',
    },
    {
      value: 'domain',
      label: (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Avatar
            style={{
              height: '1rem',
              width: '1rem',
              color: '#FFF',
              fontSize: '.75rem',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '.5rem',
            }}
          >
            D
          </Avatar>
          <div style={{margin: 'auto 0'}}>Domain</div>
        </div>
      ),
    },
    {
      value: 'ip',
      label: (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Avatar
            style={{
              height: '1.25rem',
              width: '1.25rem',
              color: '#FFF',
              fontSize: '.75rem',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '.5rem',
            }}
          >
            IP
          </Avatar>
          <div style={{margin: 'auto 0'}}>IP</div>
        </div>
      ),
    },
  ];

  const freqOptions = [
    {label: 'per Minute', value: 'minutes: 1'},
    {label: 'per Hour', value: 'hours: 1'},
  ];

  const {_id: docId} = doc;

  return (
    <Dialog open={open}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>Edit Alert</div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Trigger number</b>
              </TableCell>
              <TableCell>
                <RedTextField
                  {...redTextFieldProps}
                  select={false}
                  value={numberTrigger}
                  onChange={({target: {value}}) => setNumberTrigger(value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Send To</b>
              </TableCell>
              <TableCell>
                <SelectMultipleSearch
                  value={email}
                  onChange={({target: {value}}) => setEmail(value)}
                  tokenValidator={validator.isEmail}
                  placeholder="ex: user@example.pt"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Alert type</b>
              </TableCell>
              <TableCell>
                <RedTextField
                  {...redTextFieldProps}
                  placeholder={!hasInternal ?
                    'Select category' :
                    'Select type'}
                  value={list}
                  onChange={({target: {value}}) => setList(value)}
                >
                  {!!hasInternal &&
                    groupOptions.map(({label, value}) => {
                      if (typeof value === 'undefined') {
                        return (
                          <ListSubheader key={label}>{label}</ListSubheader>
                        );
                      }
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  {!hasInternal &&
                    listOptions.map(({label, value}, index) => {
                      if (typeof value === 'undefined') {
                        return <ListSubheader key={`header-${index}`}>
                          {label}
                        </ListSubheader>;
                      }
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                </RedTextField>
              </TableCell>
            </TableRow>
            {list === 'domain' && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>Domain to Alert</b>
                </TableCell>
                <TableCell>
                  <SelectMultipleSearch
                    value={domains}
                    tokenValidator={domainValidation}
                    onChange={({target: {value}}) => setDomains(value)}
                    limit={256}
                    placeholder="ex: *.example.pt"
                  />
                </TableCell>
              </TableRow>
            )}
            {list === 'ip' && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>Ips to Alerts</b>
                </TableCell>
                <TableCell>
                  <SelectMultipleSearch
                    value={ips}
                    tokenValidator={(t) =>
                      validator.isIP(t) || validator.isIPRange(t)
                    }
                    onChange={({target: {value}}) => setIPs(value)}
                    limit={256}
                    countValues={(v) =>
                      v.reduce((acc, value) => {
                        const [, mask] = value.split('/');
                        if (validator.isIP(value)) {
                          return acc + 1;
                        }
                        if (validator.isIPRange(value) && mask * 1 >
                          23) {
                          const cidr = new IPCIDR(value);
                          return acc + cidr.toArray().length;
                        }
                        return acc;
                      }, 0)
                    }
                    placeholder="ex: 127.0.0.1/30"
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Frequency</b>
              </TableCell>
              <TableCell>
                <RedTextField
                  {...redTextFieldProps}
                  value={frequency}
                  onChange={({target: {value}}) => setFrequency(value)}
                >
                  {freqOptions.map(({label, value}) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </RedTextField>
              </TableCell>
            </TableRow>
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <ButtonForm
          variant="outlined"
          className="sec"
          type="button"
          onClick={(_) => setUpdate(doc)}
          disabled={!!updating}
        >
          Save
        </ButtonForm>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false) || negativeSideEffect()}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}}/>
          Close
        </ButtonForm>

        {updating && (
          <UpdatingAlert
            doc={{
              id: docId,
              number: numberTrigger,
              domain_ip: (list === 'ip' ? ips : domains).join(','),
              mails: email.join(','),
              frequency,
              list,
            }}
            negativeSideEffect={(_) => setUpdate(false)}
            sideEffect={sideEffect}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

UpdateAlert.propTypes = {
  negativeSideEffect: PropTypes.func,
  sideEffect: PropTypes.func,
  hasInternal: PropTypes.bool,
  doc: PropTypes.shape(
      {
        _id: PropTypes.string,
        num_events: PropTypes.number,
        domain_ip: PropTypes.arrayOf(PropTypes.string),
        email: PropTypes.string,
        time: PropTypes.string,
        list: PropTypes.string,
      },
  ),
};

/**
 * @param{function} negativeSideEffect
 * @param{function} sideEffect
 * @param{obj} doc
 * @return {Element}
 * @constructor
 */
function UpdatingAlert({negativeSideEffect, sideEffect, doc}) {
  const [selectedService] = useSelector((state) => [state.selectedService]);
  const {loading, error} = usePostDataBackend('alerts/', {
    ...doc,
    service: selectedService,
  });
  useEffect(() => {
    if (!loading && error) {
      displayError(`Error to update alert:`, error);
      negativeSideEffect();
    } else if (!loading) {
      sideEffect();
    }
  }, [negativeSideEffect, sideEffect, loading, error]);

  return <></>;
}

UpdatingAlert.propTypes = {
  negativeSideEffect: PropTypes.func,
  sideEffect: PropTypes.func,
  doc: PropTypes.object,
};

/**
 * @param{function} negativeSideEffect
 * @param{function} sideEffect
 * @param{obj} doc
 * @return {Element}
 * @constructor
 */
function ToggleAlert({negativeSideEffect, sideEffect, ...doc}) {
  const {active} = doc;
  const {loading, error} = usePostDataBackend('alerts/toggle/', {...doc});
  useEffect(() => {
    if (!loading && error) {
      const action = active ? 'activate' : 'disable';
      displayError(`Error to ${action} alert:`, error);
      sideEffect();
    } else if (!loading) {
      sideEffect();
    }
  }, [negativeSideEffect, sideEffect, loading, error, active]);

  return <></>;
}

ToggleAlert.propTypes = {
  negativeSideEffect: PropTypes.func,
  sideEffect: PropTypes.func,
  doc: PropTypes.object,
};
