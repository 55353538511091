import * as ACTION_TYPE from './actionTypes';

export const setServicesInfo = (services) => {
  return {
    type: ACTION_TYPE.SET_SERVICES_INFO,
    payload: services,
  };
};

export const setSelectedService = (service) => {
  return {
    type: ACTION_TYPE.SET_SELECTED_SERVICE,
    payload: service,
  };
};
