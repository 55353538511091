import React, {useEffect} from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import moment from 'moment/moment';
import {setPath} from '../../actions/pathAction';
import {useGetDataBackend} from '../../ApiBackend';
import {TimeLineEvent,
  TimeLineEvents} from '../../core/components/timeline/TimeLine';


const spaceBetween = 'space-between';

/**
 *
 * @param{number} [refresh]
 * @return {{dataByDate: (boolean|[string, unknown][]),
 * loading: boolean,
 * error: null}}
 */
export function useReleases(refresh) {
  const {
    loading,
    error,
    data = [],
  } = useGetDataBackend('releases/', {refresh});
  const dataByDate =
    !loading &&
    !error &&
    Object.entries(
        data.releases.reduce((acc, d) => {
          const date = moment(d['@timestamp'], 'x').format('D MMM YYYY');
          acc[date] = acc[date] || [];
          acc[date].push(d);
          return acc;
        }, {}),
    );
  return {
    loading,
    error,
    dataByDate,
  };
}

const c = makeStyles((theme) => ({
  lateralSpace: {
    paddingLeft: theme.spacing(3) / 2,
    paddingRight: theme.spacing(3) / 2,
  },
  grid: {
    flex: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(3) / 2,
    paddingRight: theme.spacing(3) / 2,
    flexDirection: 'row',
    alignContent: 'baseline',
  },
}));


/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function ReleasePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Release notes'));
    return () => null;
  }, [dispatch]);

  const classes = c();

  const {
    dataByDate,
  }= useReleases();


  return (
    <Grid
      container
      className={classes.grid}
      style={{overflow: 'overlay'}}
    >
      <Grid item
        xs={12}
        className={classes.lateralSpace}
      >
        <div style={{
          height: 132,
          fontSize: 24,
          color: '#333333',
          display: 'flex',
          alignItems: 'flex-end',
        }}>
          <b>What&apos;s new on DNS8</b>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.lateralSpace}>
        <p style={{color: '#464646'}}>
            We update the platform regularly. Check out this page to lean more
            about the last features.
        </p>
      </Grid>
      <Grid item xs={12} className={classes.lateralSpace} >
        <Divider style={{margin: '14px 0px 70px 0px'}} />
      </Grid>
      <Grid item xs={12} className={classes.lateralSpace} >
        <div
          style={{
            overflowY: 'overlay',
            padding: 0,
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: spaceBetween,
            flexWrap: 'wrap',
          }}
        >
          {dataByDate &&
              dataByDate.map(([date, releases]) => {
                return <TimeLineEvents key={date} date={date}>
                  {releases.map(({title, description, _id}) => {
                    return <TimeLineEvent
                      key={_id}
                      title={title}
                      content={description} />;
                  })}
                </TimeLineEvents>;
              })}
        </div>
      </Grid>
    </Grid>
  );
}


