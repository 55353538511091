import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExport} from '@fortawesome/free-solid-svg-icons';
import ReactExport from 'react-data-export';
import {getFetch} from '../../ApiBackend';

import RedIconButton from '../../core/components/buttons/RedIconButton';

const {ExcelFile} = ReactExport;
const {ExcelSheet, ExcelColumn} = ExcelFile;

/**
 *
 * @param{string} exporter
 * @param{array} columns
 * @param{string} url
 * @return {JSX.Element}
 * @constructor
 */
export default function ExcelExporter({exporter, columns, url}) {
  const [data, setData] = useState(null);
  const [
    fetchData,
    setFetchData,
  ] = useExcelExporterFetchData(setData, url, columns);
  return (
    <>
      <RedIconButton
        TooltipProps={{title: fetchData ? 'Exporting' : 'Export to Excel'}}
        alwaysTooltip={true}
        onClick={() => setData(null) || setFetchData(true)}
        disabled={fetchData}
      >
        <FontAwesomeIcon icon={faFileExport} style={{fontSize: '1.25rem'}} />
      </RedIconButton>
      {!Number.isNaN(Number(data?.length)) && (
        <ExcelFile hideElement={true} filename={exporter}>
          <ExcelSheet data={data} name={exporter}>
            {columns.reduce((acc, c) => {
              if (c.exporter) {
                acc.push(<ExcelColumn key={c.name} {...c.exporter} />);
              }
              return acc;
            }, [])}
          </ExcelSheet>
        </ExcelFile>
      )}
    </>
  );
}
ExcelExporter.propTypes ={
  exporter: PropTypes.string,
  columns: PropTypes.array,
  url: PropTypes.string,
};

/**
 *
 * @param{function} setData
 * @param{string} url
 * @param{array} columns
 * @return {[boolean, function]}
 */
function useExcelExporterFetchData(setData, url, columns) {
  const [fetchData, setFetchData] = useState(false);
  const partialData = useRef([]);
  const [page, setPage] = useState(0);
  const total = useRef(10 ** 4);

  useEffect(() => {
    if (!fetchData) {
      return;
    }
    const tableState = {
      page,
      size: 1000,
      sort_column: columns[0].data,
      sort_direction: 'asc',
    };
    if ((total.current > partialData.current.length) &&
      (page * tableState.size <= 10 ** 4)) {
      getFetch(url, tableState).then((json) => {
        const {list, total: t} = json;
        total.current = t;
        partialData.current = partialData.current.concat(list);
        setPage((currentPage) => currentPage + 1);
      }).catch(() => {
        setFetchData(false);
      });
    } else {
      setData(partialData.current);
      setFetchData(false);
    }
  }, [page, url, columns, setData, setFetchData, fetchData]);

  return [fetchData, setFetchData];
}
