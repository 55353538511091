import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';
import store from '../../store';

const unsubscribe = store.subscribe(() => {
  localStorage.setItem('appState', JSON.stringify(store.getState()));
  return () => localStorage.clear();
});

function Logout(props) {
  const [keycloak] = useSelector((state) => [state.keycloak]);

  useEffect(() => {
    props.history.push('/');

    keycloak.logout();
    return () => {
      unsubscribe();
      localStorage.clear();
    };
  });

  return null;
}

export default withRouter(Logout);
