import {Box, Grid} from '@material-ui/core';
import {SelectionButton, Text, TextInput} from '@l8/storybook';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {useStyles} from '../styles';
import PropTypes from 'prop-types';
import {ReactComponent as SVGTeams} from '../../../../src/icons/teams.svg';
import {
  ReactComponent as SVGTelegram,
} from '../../../../src/icons/telegram.svg';
import {useSelector} from 'react-redux';
import SelectMultipleSearch
from '../../../DNS8Components/Inputs/SelectMultipleSearch';
import validator from 'validator';


const channelStyle = {
  width: 350,
};

/**
 *
 * @param{obj} alert
 * @param{function} setAlert
 * @return {JSX.Element}
 * @constructor
 */
export function AlertCreateStepSelectChannel({alert, setAlert}) {
  const [userMail] = useSelector((state) => [
    state.keycloak.tokenParsed.email,
  ]);
  const classes = useStyles();
  return <>
    <Grid container style={{overflow: 'auto'}}>
      <Grid item className={classes.section}>
        <Box>
          <Text label="Channel" textWeight="bold" textSize="20px"/>
          <Text
            label="Choose the channel for your alert."
            textSize="14px"
            textColor="secondary"
          />
        </Box>
        <Box>
          <Grid container direction="row" style={{gap: 16}}>
            <Grid item style={channelStyle}>
              <SelectionButton
                icon={<FontAwesomeIcon icon={faEnvelope}/>}
                text="Send the alert to the emails you indicate."
                title="Email"
                active={alert?.channel === 'email'}
                onClick={() => setAlert((currentAlert) => ({
                  ...currentAlert,
                  channel: 'email',
                  emails: currentAlert.emails ?? [userMail],

                }))}
              />
            </Grid>
            <Grid item style={channelStyle}>
              <SelectionButton
                icon={<SVGTeams style={{fontSize: '2rem'}}/>}
                text="Send the alert to the msteam channel you configure."
                title="MS Teams"
                active={alert?.channel === 'teams'}
                locked={true}
                onClick={() => false && setAlert((currentAlert) => ({
                  ...currentAlert, channel: 'teams',

                }))}
              />
            < /Grid>
            <Grid item style={channelStyle}>
              <SelectionButton
                icon={<SVGTelegram/>}
                text="Send the alerts to the telegram channel you configure."
                title="Telegram"
                locked={true}
                active={alert?.channel === 'telegram'}
                onClick={() => false && setAlert((currentAlert) => ({
                  ...currentAlert, channel: 'telegram',

                }))}
              />
            < /Grid>
          </Grid>
        </Box>
        {alert?.channel === 'email' && <>
          <Box>
            <Text label="Emails to alert" textWeight="bold"
              textSize="20px"/>
            <Text
              label="Write the emails you want to alert"
              textSize="14px"
              textColor="secondary"
            />
          </Box>
          <Box>
            <SelectMultipleSearch
              value={alert.emails ?? []}
              onChange={({target: {value}}) => setAlert((currentAlert) => ({
                ...currentAlert, emails: value,
              }))}
              tokenValidator={validator.isEmail}
              placeholder="ex: user@example.pt" />
          </Box>
        </>}
        {alert?.channel === 'teams' && <>
          {Object.entries({
            'team': 'Indicate the team you want to alert',
            'tokenA': 'Indicate the first part of 3 tokens provided to you after creating a incoming-webhook',
            'tokenB': 'Indicate the second part of 3 tokens provided to you after creating a incoming-webhook',
            'tokenC': 'Indicate the last part of 3 tokens provided to you after creating a incoming-webhook',
          }).map(([key, value]) => {
            return (<>
              <Box>
                <Text label={`${key.charAt(0).toUpperCase() + key.slice(1)}`}
                  textWeight="bold"
                  textSize="20px"/>
                <Text
                  label={value}
                  textSize="14px"
                  textColor="secondary"
                />
              </Box>
              <Box>
                <TextInput
                  size={'small'}
                  onChange={(e) => {
                    setAlert((currentAlert) => ({
                      ...currentAlert, [key]: e.target.value,
                    }));
                  }}
                />
              </Box>
            </>
            );
          })}
        </>}
        {alert?.channel === 'telegram' && <>
          {Object.entries({
            'botToken': 'The token that identifies the bot you created through the BotFather.',
            'chatId': 'Identify the users you want your bot to deliver your notifications to. You must specify at least 1 chat_id. If you do not specify a chat_id, the notification script will attempt to detect the bot owner\'s (you) chat_id and use that.',
          }).map(([key, value]) => {
            return (<>
              <Box>
                <Text label={`${key.charAt(0).toUpperCase() + key.slice(1)}`}
                  textWeight="bold"
                  textSize="20px"/>
                <Text
                  label={value}
                  textSize="14px"
                  textColor="secondary"
                />
              </Box>
              <Box>
                <TextInput
                  size={'small'}
                  onChange={(e) => {
                    setAlert((currentAlert) => ({
                      ...currentAlert, [key]: e.target.value,
                    }));
                  }}
                />
              </Box>
            </>
            );
          })}
        </>}
      </Grid>
    </Grid>
  </>;
}

AlertCreateStepSelectChannel.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
};
