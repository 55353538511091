import PropTypes from 'prop-types';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const c = makeStyles(() => ({
  timeLineEventsInDate: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  timeLineEventsList: {
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    padding: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
    margin: '0 -13px 0 0',
  },
  timeLineEvent: {
    margin: '23px 23px 23px 0', flex: 1, minWidth: 300,
  },
  timeLineEventTitle: {
    borderBottom: '2px solid #e4e4e4',
    margin: 0,
    fontSize: 20,
    fontWeight: 'bold',
  },
  timeLineEventContent: {
    width: '100%',
    minHeight: 95,
    marginRight: 10,
    marginTop: 14,
    textAlign: 'justify',
  },
  timeLineDateContainer: {
    width: 80,
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8, // 23-15,
  },
  timeLineDate: {
    'width': 80,
    'height': 80,
    'borderRadius': '50%',
    'padding': '20px 10px',
    'boxSizing': 'border-box',
    'margin': '0 auto',
    'border': '1px solid white',
    'textAlign': 'center',
    'background': '#e4e4e4',
    '& div': {
      color: '#BE131A',
      fontWeight: 'bold',
      fontSize: '16px',
    },
    '& div + div': {
      color: '#464646',
      fontSize: '12px',
    },
  },
  timeLineDateConnector: {
    width: 3,
    flex: 1,
    margin: '5px auto',
    backgroundColor: '#e4e4e4',
    borderRadius: '100%',
  },
}));

/**
 *
 * @param{string} date
 * @return {JSX.Element}
 * @constructor
 */
export function TimeLineDate({date=''}) {
  const [day, month, year] = date.split(' ');
  const classes = c();
  return <div className={classes.timeLineDateContainer}>
    <div className={classes.timeLineDate}>
      <div>{day} {month}</div>
      <div>{year}</div>
    </div>
    <div className={classes.timeLineDateConnector}
    />
  </div>;
}
TimeLineDate.propTypes={
  date: PropTypes.string,
};

/**
 *
 * @param{string} title
 * @param{any} content
 * @param{any} children
 * @return {JSX.Element}
 * @constructor
 */
export function TimeLineEvent({title, content, children}) {
  const classes = c();
  return (
    <li className={classes.timeLineEvent}>
      <div className={classes.timeLineEventTitle}>
        {title}
      </div>
      <div className={classes.timeLineEventContent} >
        {content}
      </div>
      {children}
    </li>
  );
}
TimeLineEvent.propTypes={
  title: PropTypes.string,
  content: PropTypes.any,
  children: PropTypes.any,
};

/**
 *
 * @param{string} date
 * @param{TimeLineEvent[]} children
 * @return {JSX.Element}
 * @constructor
 */
export function TimeLineEvents({date, children}) {
  const classes = c();
  return <div className={classes.timeLineEventsInDate}>
    <ul className={classes.timeLineEventsList}>
      {children}
    </ul>
    <TimeLineDate date={date} />
  </div>;
}
TimeLineEvents.propTypes={
  date: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.shape(TimeLineEvent.propTypes)),
};


