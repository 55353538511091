import React from 'react';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((_) => ({
  'toastr-custom': {
    '& .rrt-confirm-holder': {
      'display': 'flex',
      '& .rrt-confirm': {
        'width': 400,
        'margin': 'auto',
        'position': 'unset',
        'top': 0,
        'left': 0,
        'borderRadius': 'none',
        '& .rrt-message': {
          padding: '42px 42px 21px',
          fontFamily: 'Open Sans',
          textAlign: 'left',
          fontSize: 16,
          color: 'rgba(70, 70, 70, .8)',
        },
        '& .rrt-buttons-holder': {
          'padding': '21px 21px 42px',
          '& .rrt-button': {
            'borderRadius': 4,
            'flex': 1,
            'height': 40,
            'border': '1px solid #464646',
            'color': '#464646',
            'backgroundColor': 'white',
            'margin': '0 21px',
            '&:hover': {
              backgroundColor: '#BE131A',
              border: '1px solid #BE131A',
              color: 'white',
            },
            '&.rrt-ok-btn': {
              'backgroundColor': '#464646',
              'color': 'white',
              '&:active': {
                backgroundColor: '#BE131A',
              },
              '&:hover': {
                backgroundColor: '#BE131A',
                border: '1px solid #BE131A',
              },
            },
          },
        },
      },
      '& .shadow': {
        position: 'absolute',
      },
    },
  },
}));

export default function Toastr() {
  const classes = useStyles();
  return (
    <ReduxToastr
      className={classes['toastr-custom']}
      timeOut={0}
      newestOnTop={true}
      preventDuplicates
      position="top-right"
      getState={(state) => state.toastr} // This is the default
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar={false}
      closeOnToastrClick
    />
  );
}
