import React, {useState} from 'react';
import BoxInfo from '../../../Components/BoxInfo';
import {useSelector} from 'react-redux';
import configs from '../../../configs';
import PropTypes from 'prop-types';
import {
  FormControl, FormLabel,
  Table,
} from '@material-ui/core';
import {deleteFetch, postFetch, useGetDataBackend} from '../../../ApiBackend';
import RedTextField from '../../../core/components/inputs/RedTextField';
import ButtonForm from '../../../Components/Forms/ButtonForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {StyledTableCell} from '../../../Components/Table';
import TableBody from '@material-ui/core/TableBody';
import Spinner from '../../../Components/Spinner';
import RedIconButton from '../../../core/components/buttons/RedIconButton';
import {displayError} from '../../../ui-components/displayMsg';


/**
 *
 * @param{string} s
 * @constructor
 */
export default function EndpointBypassTable({service: s}) {
  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const [domainIp, setDomainIp] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const {
    data: {bypass: data = []},
    loading,
    error,
  } = useGetDataBackend(`service/${s}/endpoint/bypass`, {refresh});
  const token = keycloak.tokenParsed;

  const roles =
    token.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];

  const canEdit = !(roles.includes('user') || operator.personify);

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    postFetch(`service/${s}/endpoint/bypass`, {domainIp}).then(() => {
      setRefresh(refresh + 1);
      setSubmitting(false);
      setDomainIp('');
    }).catch((e) => {
      e.text().then((data) => {
        setSubmitting(false);
        displayError('Error adding bypass:', data);
      });
    });
  };

  const onDelete = (row) => () => {
    setSubmitting(true);
    deleteFetch(`service/${s}/endpoint/bypass`, {domainIp: row}).then(() => {
      setRefresh(refresh + 1);
      setSubmitting(false);
    }).catch((e) => {
      e.text().then((data) => {
        setSubmitting(false);
        displayError('Error deleting bypass:', data);
      });
    });
  };

  const columns = [
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Domain or Ip DNS Server</b>,
      data: '-',
      render: (_, row) => {
        return row;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 110}},
      data_props: {style: {textAlign: 'center', width: 110}},
      title: null,
      data: '-',
      render: (_, row) => {
        return <RedIconButton
          TooltipProps={{title: 'Remove Bypass'}}
          onClick={onDelete(row)}
        >
          <FontAwesomeIcon icon={faTrash}/>
        </RedIconButton>;
      },
    },
  ];
  return canEdit && !loading && !error && <BoxInfo
    title={<b>
      {'Endpoint Agent Bypass Servers'}
    </b>}
  >
    <form
      onSubmit={onSubmit}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        margin: '3px 6px -6px',
        flexWrap: 'wrap',
        minHeight: '78px',
      }}
    >
      <FormControl style={{margin: 3}}>
        <FormLabel
          style={{fontSize: '14px', color: '#464646', padding: '.5rem 0'}}
        >
          Add Domain or Ip DNS Server that endpoint will not
          intercept.
        </FormLabel>
        <RedTextField
          variant="outlined"
          size="small"
          style={{background: 'white'}}
          placeholder="ex: 127.0.0.1"
          value={domainIp}
          disabled={submitting}
          onChange={({target: {value}}) => setDomainIp(value)}
        >

        </RedTextField>
      </FormControl>
      <FormControl style={{margin: 3, marginLeft: 'auto'}}>
        <ButtonForm
          variant="outlined"
          style={{margin: 0}}
          type="submit"
          disabled={submitting}
        >
          Add Domain
        </ButtonForm>
      </FormControl>
    </form>
    <br/>
    <Table style={{width: '100%', tableLayout: 'fixed'}}>
      <TableHead>
        <TableRow>
          {columns.map((col, index) => {
            const columnKey = `header${index}`;
            return (
              <StyledTableCell key={columnKey}
                style={col?.props?.style}>
                {col.title || ''}
              </StyledTableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {(loading || error) && (
          <TableRowStyled>
            <StyledTableCell colSpan={columns.length}>
              <Spinner/>
            </StyledTableCell>
          </TableRowStyled>
        )}
        {!loading && !error && !data.length && (
          <TableRowStyled>
            <StyledTableCell colSpan={columns.length}>
              No Data
            </StyledTableCell>
          </TableRowStyled>
        )}
        {!loading &&
          !error &&
          !!data.length &&
          data.map((d, rIndex) => {
            const row = d || {};
            const rowKey = `row${rIndex}`;
            return (
              <TableRowStyled
                key={rowKey}
                className={
                  rIndex % 2 ?
                    'platinum-bg  opacity-30' :
                    'white-bg'
                }
              >
                {columns.map(function(column, cIndex) {
                  let cellData;
                  if (column.render) {
                    cellData = column.render(row[column.data], row);
                  } else if (column.data) {
                    cellData = row[column.data];
                  } else {
                    cellData = row[cIndex];
                  }
                  const cellKey = `data_${rIndex}_${cIndex}`;
                  return (
                    <StyledTableCell
                      key={cellKey}
                      style={column?.data_props?.style}
                    >
                      {cellData}
                    </StyledTableCell>
                  );
                })}
              </TableRowStyled>
            );
          })}
      </TableBody>
    </Table>
  </BoxInfo>
  ;
}

EndpointBypassTable.propTypes = {
  service: PropTypes.string,
};


/**
 *
 * @param{object} style
 * @param{{}} props
 * @return {JSX.Element}
 * @constructor
 */
function TableRowStyled({style = {}, ...props}) {
  return <TableRow {...props}
    style={{
      border: '1px solid #F9F9F9',
      height: '38px', ...style,
    }}/>;
}

TableRowStyled.propTypes = {
  style: PropTypes.object,
};
