import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileImport} from '@fortawesome/free-solid-svg-icons';
import XLSX from 'xlsx';
import {postFetch} from '../../ApiBackend';

import RedIconButton from '../../core/components/buttons/RedIconButton';
import {DropzoneArea, DropzoneAreaBase} from 'material-ui-dropzone';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {displayError, displaySuccess} from '../../ui-components/displayMsg';
import RedButton from '../../core/components/buttons/RedButton';
import RedTextField from '../../core/components/inputs/RedTextField';


const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      'root': {
        'minHeight': 158,
        'marginBottom': 16,
        'border': 'solid',
        'borderWidth': '1px',
        'borderColor': 'rgba(0,0,0,0.12)',

        '&&:hover': {
          borderColor: 'rgba(0,0,0,0.87)',
        },
        '&&:focus': {
          'outline-color': '#BE131A',
        },
        '&&:active': {
          'outline-color': '#BE131A',
          'border-color': '#BE131A',
        },
      },
      'text': {
        marginRight: 16,
        marginLeft: 16,
        marginTop: 16,
        marginBottom: 8,
      },
    },
    MuiDropzonePreviewList: {
      root: {
        marginBottom: 8,
      },
      imageContainer: {
        margin: 'auto',
      },
    },
    MuiDropzoneSnackbar: {
      errorAlert: {
        backgroundColor: '#AFA',
        color: '#000',
      },
      successAlert: {
        backgroundColor: '#FAA',
        color: '#000',
      },
    },
  },
});

/**
 *
 * @param{File} file
 * @return {{data: null, cols: null}}
 */
function useHandleFile(file) {
  const [state, setState] = useState({data: null, cols: null});


  useEffect(() => {
    if (!file) {
      setState({data: null, cols: null});
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {header: 1});
      /* Update state */
      setState({data: data, fileName: file.name});
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  return state;
}

const color='rgba(70,70,70,.8)';

/**
 *
 * @param{object} props
 * @param{string} props.url
 * @param{boolean} props.open
 * @param{function} props.onClose
 * @param{function} props.onSuccess
 * @param{string|JSX.Element} props.successMessage
 * @param{string|JSX.Element} props.errorMessage
 * @param{string|JSX.Element} props.importDescription
 * @param{string|JSX.Element} props.BtnComponent
 * @param{string|JSX.Element} props.modalTitle
 * @param{object} props.BtnProps
 * @return {JSX.Element}
 * @constructor
 */
function ModalDropzoneArea(props) {
  const {
    open,
    onClose,
    onSuccess=() => false,
    url,
    modalTitle = 'Import File',
    successMessage = 'File Added:',
    errorMessage='Error adding file:',
    childrenBeforeFileDrop='',
    childrenAfterFileDrop='',
    BtnComponent = RedButton,
    btnProps = {
      children: 'Add',
    },
    dropzoneAreaProps={},
    additionalComment=false,
  } = props;
  const [files, setFiles] = useState([]);
  const [uploadData, setUploadData] = useState(false);
  const [comment, setComment] = useState(additionalComment && '');
  const data = useHandleFile(files[0]);
  useEffect(() => {
    if (!open) {
      setFiles([]);
      setComment('');
    }
  }, [open]);


  useEffect( () => {
    if (!uploadData) {
      return;
    }
    const postData = comment ? {...uploadData, comment}:uploadData;
    postFetch(url, postData)
        .then((json) => {
          setUploadData(false);
          if (json.ok) {
            displaySuccess(successMessage, json.message || 'OK');
            onClose();
            onSuccess();
          } else {
            displayError(errorMessage, json.message || errorMessage);
          }
        }).catch((error) =>{
          setUploadData(false);
          displayError(errorMessage, error.message);
        });
  }, [uploadData, comment]);

  const onFileSelected = (addedFiles) => {
    setFiles(addedFiles);
  };
  return <Dialog open={open}>
    <DialogTitle style={{padding: '42px 42px 21px'}}>
      {modalTitle}
    </DialogTitle>
    {childrenBeforeFileDrop && <DialogContent style={{
      padding: '10.5px 42px',
      color,
    }}>
      {childrenBeforeFileDrop}
    </DialogContent>}
    <DialogContent style={{
      padding: '10.5px 42px',
      color,
    }}>
      <MuiThemeProvider theme={theme}>
        <DropzoneArea
          showPreviews={false}
          showPreviewsInDropzone={true}
          useChipsForPreview
          filesLimit={1}
          showAlerts={false}
          {...dropzoneAreaProps}
          onChange={onFileSelected}
          onDelete={onFileSelected}
        />
      </MuiThemeProvider>
    </DialogContent>
    {additionalComment && <DialogContent style={{
      padding: '10.5px 42px 21px',
      color,
    }}>
      <RedTextField
        fullWidth={true}
        variant={'outlined'}
        rows={4}
        value={comment}
        onChange={({target: {value}}) => setComment(value)}
        multiline={true}
        {...additionalComment}
      />
    </DialogContent>}
    {childrenAfterFileDrop && <DialogContent style={{
      padding: '10.5px 42px 21px',
      color,
    }}>
      {childrenAfterFileDrop}
    </DialogContent>}
    <DialogActions style={{padding: '21px 42px 42px'}}>
      <RedButton variant={'outlined'}
        onClick={onClose}
        disabled={!!uploadData}
        style={{marginRight: 'auto'}}
      >
        Cancel
      </RedButton>
      <BtnComponent
        {...btnProps}
        disabled={!data.data?.length || !!uploadData}
        onClick={() => setUploadData(data)}
        style={{...btnProps.style} }
      />
    </DialogActions>
  </Dialog>;
}

ModalDropzoneArea.propTypes={
  open: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  childrenBeforeFileDrop: PropTypes.oneOfType(
      [PropTypes.string, PropTypes.element]
  ),
  childrenAfterFileDrop: PropTypes.oneOfType(
      [PropTypes.string, PropTypes.element]
  ),
  BtnComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  btnProps: PropTypes.shape(RedButton.propTypes),
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dropzoneAreaProps: PropTypes.shape(DropzoneAreaBase.propTypes),
  additionalComment: PropTypes.oneOfType(
      [PropTypes.bool, PropTypes.shape(RedTextField.propTypes)]
  ),
};

/**
 *
 * @param{ExcelImporter.propTypes} props
 * @return {JSX.Element}
 * @constructor
 */
export default function ExcelImporter(props) {
  const {importer, url} = props;
  const [open, setOpen] = useState(false);


  const onClosedModal = () => setOpen(false);

  return <>
    {importer && <RedIconButton
      TooltipProps={{title: 'Import from Excel'}}
      alwaysTooltip={true}
      onClick={() => setOpen(true)}
    >
      <FontAwesomeIcon icon={faFileImport} style={{fontSize: '1.25rem'}} />
    </RedIconButton>}
    {importer && <ModalDropzoneArea
      url={url}
      open={open}
      onClose={onClosedModal}
      {...importer}
    />}
  </>;
}
ExcelImporter.propTypes ={
  importer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(ModalDropzoneArea.propTypes),
  ]),
  url: PropTypes.string,
};

