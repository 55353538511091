import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import DefaultPage from '../core/Content/Pages/DefaultPage';

import NotFoundPage from '../core/Content/Pages/NotFoundPage';

import Logout from '../Components/Pages/Logout';
import SandBoxPage from '../Components/Pages/SandBoxPage';
import Requests from './Pages/Requests';
import Threats from './Pages/Threats';
import Policy from './Pages/Policy';
import DomainInfo from './Pages/DomainInfo';
import Settings from './Pages/Settings';
import Services from './Pages/Services';
import ReleasePage from './Pages/ReleasePage';
import GlobalPolicy from './Pages/GlobalPolicy';
import DomainInfoAdmin from './Pages/DomainInfoAdmin';
import AdminReleasePage from './Pages/AdminReleasePage';
import UsersManagement from './Pages/UsersManagement';
import ESStatus from './Pages/ESStatus';
import ServicesManagement from './Pages/ServicesManagement';
import DNS8Status2 from './Pages/DNS8Status2';
import DynamicDashboard from './Pages/DynamicDashPage';
import configs from '../configs';
import SimplePolicy from './Pages/SimplePolicy';
import Notifications from './Pages/Notifications';
import AlertCreate from '../Pages/alerts/AlertCreate';
import Requests2 from '../Pages/requests/Requests2';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function DNS8Routing() {
  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const token = keycloak.tokenParsed;

  const roles =
    token.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];

  return (
    <>
      {!operator.personify && roles.includes('root') && <RootSwitch/>}
      {!operator.personify &&
        !roles.includes('root') &&
        roles.includes('ms-operator') && <MSSwitch/>}
      {!operator.personify &&
        !roles.includes('root') &&
        roles.includes('soc-operator') && <SOCSwitch/>}
      {(!operator || operator.personify || roles.includes('user')) && (
        <UserSwitch/>
      )}
    </>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function MSSwitch() {
  // Manage Service
  return (
    <Switch>
      <Redirect from="/v2/:path?/:domain?/:i?" to="/:path?/:domain?/:i?"/>
      <Route path="/" exact strict component={DefaultPage}/>
      <Route path="/dns8status" exact strict component={DNS8Status2}/>
      <Route path="/global_policy" exact strict component={GlobalPolicy}/>
      <Route
        path="/domains/:domain?/:i?"
        exact
        strict
        component={DomainInfoAdmin}
      />
      {/* <Route path="/clients" exact strict component={ClientsPage} /> */}
      <Route path="/releases" exact strict component={AdminReleasePage}/>
      <Route
        path="/users_management"
        exact
        strict
        component={UsersManagement}
      />
      <Route
        path="/services_management"
        exact
        strict
        component={ServicesManagement}
      />
      <Route path="/es_status" exact strict component={ESStatus}/>
      <Route path="/logout" exact strict component={Logout}/>
      <Route path="/settings/:subPath?" exact strict component={Settings}/>
      <Route path="/settings/alerts/create" exact strict
        component={AlertCreate}/>
      <Route path="/settings/alerts/edit" exact strict
        component={AlertCreate}/>
      <Route path="/notifications" exact strict component={Notifications}/>
      <Route component={NotFoundPage}/>
    </Switch>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function SOCSwitch() {
  return (
    <Switch>
      <Redirect from="/v2/:path?/:domain?/:i?" to="/:path?/:domain?/:i?"/>
      <Route path="/" exact strict component={DefaultPage}/>
      <Route path="/global_policy" exact strict component={GlobalPolicy}/>
      <Route
        path="/domains/:domain?/:i?"
        exact
        strict
        component={DomainInfoAdmin}
      />
      <Route path="/policy" exact strict component={SimplePolicy}/>
      <Route path="/releases" exact strict component={AdminReleasePage}/>
      <Route path="/logout" exact strict component={Logout}/>
      <Route path="/settings/:subPath?" exact strict component={Settings}/>
      <Route path="/settings/alerts/create" exact strict
        component={AlertCreate}/>
      <Route path="/settings/alerts/edit" exact strict
        component={AlertCreate}/>
      <Route path="/notifications" exact strict component={Notifications}/>
      <Route component={NotFoundPage}/>
    </Switch>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function RootSwitch() {
  return (
    <Switch>
      <Redirect from="/v2/:path?/:domain?/:i?" to="/:path?/:domain?/:i?"/>
      <Route path="/" exact strict component={DefaultPage}/>
      <Route path="/dns8status" exact strict component={DNS8Status2}/>
      <Route path="/global_policy" exact strict component={GlobalPolicy}/>
      <Route
        path="/domains/:domain?/:i?"
        exact
        strict
        component={DomainInfoAdmin}
      />
      {/* <Route path="/clients" exact strict component={ClientsPage} /> */}
      <Route path="/releases" exact strict component={AdminReleasePage}/>
      <Route
        path="/users_management"
        exact
        strict
        component={UsersManagement}
      />
      <Route
        path="/services_management"
        exact
        strict
        component={ServicesManagement}
      />
      <Route path="/es_status" exact strict component={ESStatus}/>
      <Route
        path="/operators"
        exact
        strict
        render={() => <h1> Operators </h1>}
      />
      <Route path="/policy" exact strict component={Policy}/>
      <Route path="/logout" exact strict component={Logout}/>
      <Route path="/settings/:subPath?" exact strict component={Settings}/>
      <Route path="/settings/alerts/create" exact strict
        component={AlertCreate}/>
      <Route path="/settings/alerts/edit" exact strict
        component={AlertCreate}/>
      <Route path="/notifications" exact strict component={Notifications}/>
      <Route path="/requests" exact strict component={Requests2}/>
      <Route component={NotFoundPage}/>
    </Switch>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function UserSwitch() {
  return (
    <Switch>
      <Redirect from="/v2/:path?/:domain?/:i?" to="/:path?/:domain?/:i?"/>
      <Route path="/" exact strict component={DefaultPage}/>
      <Route path="/sandbox" exact strict component={SandBoxPage}/>
      <Route path="/dashboard" exact strict component={DynamicDashboard}/>
      <Route path="/policy" exact strict component={Policy}/>
      <Route path="/domains/:domain?/:i?" exact strict
        component={DomainInfo}/>
      <Route path="/requests" exact strict component={Requests}/>
      <Route path="/threats" exact strict component={Threats}/>
      <Route path="/services" exact strict component={Services}/>
      <Route path="/releases" exact strict component={ReleasePage}/>
      <Route path="/settings/:subPath?" exact strict component={Settings}/>
      <Route path="/settings/alerts/create" exact strict
        component={AlertCreate}/>
      <Route path="/settings/alerts/edit" exact strict
        component={AlertCreate}/>
      <Route path="/logout" exact strict component={Logout}/>
      <Route path="/notifications" exact strict component={Notifications}/>
      <Route component={NotFoundPage}/>
    </Switch>
  );
}
