import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBan,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';

export function RenderAction({action, withLabel = true}) {
  const colorMap = {
    blocked: {
      color: 'red',
      icon: <FontAwesomeIcon icon={faBan} color="#BE131A" />,
      label: 'Blocked',
    },
    PASSTHRU: {
      color: 'green',
      icon: <FontAwesomeIcon icon={faCheckCircle} color="green" />,
      label: 'Allowed',
    },
    allowed: {
      color: 'green',
      icon: <FontAwesomeIcon icon={faCheckCircle} color="green" />,
      label: 'Allowed',
    },
    resolved: {
      color: 'green',
      icon: <FontAwesomeIcon icon={faCheckCircle} color="green" />,
      label: 'Resolved',
    },
    no_answers: {
      color: '#1870B4',
      icon: <FontAwesomeIcon icon={faTimesCircle} color="#1870B4" />,
      label: 'No answer',
    },
    requested: {
      color: '#1870B4',
      icon: <FontAwesomeIcon icon={faArrowRight} color="#1870B4" />,
      label: 'Requested',
    },
  };

  const {icon, label} = colorMap[action] || colorMap.requested;
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div>{icon}</div>
      {withLabel && <div style={{marginLeft: 5}}>{label}</div>}
    </div>
  );
}
