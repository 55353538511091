import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import RedTabs from '../../Components/RedTabs';

import PublicServiceInfo from './PublicServiceInfo';
import InternalServiceInfo from './InternalServiceInfo';
import {setPath} from '../../actions/pathAction';

export default function Services() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Service Info'));
  });

  const tabs = [
    {
      label: 'Public',
      page: PublicServiceInfo,
    },
    {
      label: 'Internal',
      page: InternalServiceInfo,
    },
  ];
  return <RedTabs tabs={tabs} />;
}
