import {Box, Grid} from '@material-ui/core';
import {SelectionButton, Text, TextInput} from '@l8/storybook';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import {selectionButtonStyles, useStyles} from '../styles';
import PropTypes from 'prop-types';


/**
 *
 * @param{obj} alert
 * @param{function} setAlert
 * @return {JSX.Element}
 * @constructor
 */
export function AlertCreateStepSelectRateTrigger({alert, setAlert}) {
  const classes = useStyles();
  return <>
    <Grid container style={{overflow: 'auto'}}>


      <Grid item className={classes.section}>

        <Box>
          <Text label="Number of events" textWeight="bold" textSize="20px"/>
          <Text
            label="Choose how many events you want to trigger the alert"
            textSize="14px"
            textColor="secondary"
          />
        </Box>
        <Box>
          <TextInput
            size={'small'}
            onChange={(e) => {
              setAlert((currentAlert) => ({
                ...currentAlert, events: e.target.value,
              }));
            }}
            value={alert?.events ?? ''}
          />
        </Box>
        <Box>
          <Text label="Time Frequency" textWeight="bold" textSize="20px"/>
          <Text
            label="Choose an interval of time to trigger the alert"
            textSize="14px"
            textColor="secondary"

          />
        </Box>
        <Box>
          <Grid container direction="row" style={{gap: 16}}>
            <Grid item>
              <SelectionButton style={selectionButtonStyles}
                icon={<span className="fa-layers fa-fw">
                  <span style={{
                    fontSize: '1.5rem',
                    margin: '0 0 0 2rem',
                    fontWeight: 'bold',
                  }}>M</span>
                  <FontAwesomeIcon icon={faClock} size={'xs'}/>
                </span>}
                text="If a number of events are found in a minute, it will trigger the alert."
                title="By Minute"
                active={alert?.frequency === 'minute'}
                onClick={() => setAlert((currentAlert) => ({
                  ...currentAlert, frequency: 'minute',

                }))}
              />
            </Grid>
            <Grid item>
              <SelectionButton style={selectionButtonStyles}
                icon={<span className="fa-layers fa-fw">
                  <span style={{
                    fontSize: '1.5rem',
                    margin: '0 0 0 2rem',
                    fontWeight: 'bold',
                  }}>H</span>
                  <FontAwesomeIcon icon={faClock} size={'xs'}/>
                </span>}
                text="If a number of events are found in an hour, it will trigger the alert."
                title="By Hour"
                active={alert?.frequency === 'hour'}
                onClick={() => setAlert((currentAlert) => ({
                  ...currentAlert, frequency: 'hour',

                }))}
              />
            < /Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </>;
}

AlertCreateStepSelectRateTrigger.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
};
