import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  faEdit,
  faPlus,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Fab, Tooltip, useMediaQuery} from '@material-ui/core';
import {Responsive} from 'react-grid-layout';
import sizeMe from 'react-sizeme';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import {faList} from '@fortawesome/free-solid-svg-icons/faList';
import {faChartArea} from '@fortawesome/free-solid-svg-icons/faChartArea';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import TopThreats from '../Dashboard/TopThreats';
import TopCategories from '../Dashboard/TopCategories';
import TopRequests from '../Dashboard/TopRequests';
import TopInvalid from '../Dashboard/TopInvalid';
import TopBlocked from '../Dashboard/TopBlocked';
import Quickbar from '../Dashboard/Quickbar';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import AllCategoriesChart from './AllCategoriesChart';
import AllRequestsChart from './AllRequestsChart';
import {useGetDataBackend, usePostDataBackend} from '../../ApiBackend';
import {displayError} from '../../ui-components/displayMsg';
import MaxQueriesPerSecondChart from '../Dashboard/MaxQueriesPerSecondChart';
import ServiceAlert from './ServiceExpireAlert';
import {makeStyles} from '@material-ui/core/styles';

/**
 *
 * @param{object} size
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function WidthResponsive({size, ...props}) {
  return <Responsive width={size.width} {...props} />;
}
WidthResponsive.propTypes={
  size: PropTypes.object,
};

const ResponsiveGridLayout = sizeMe({monitorWidth: true, refreshRate: 500})(
    WidthResponsive,
);

const Components = {
  Quickbar: {
    f: Quickbar,
    d: {w: 4, h: 1},
    display_name: 'Search/add Domain Bar',
    icon: <FontAwesomeIcon icon={faSearch} />,
  },
  AllRequestsChart: {
    f: AllRequestsChart,
    d: {w: 2, h: 5},
    display_name: 'Requests Overtime',
    icon: <FontAwesomeIcon icon={faChartArea} />,
  },
  AllCategoriesChart: {
    f: AllCategoriesChart,
    d: {w: 2, h: 5},
    display_name: 'Categories Requests Overtime',
    icon: <FontAwesomeIcon icon={faChartArea} />,
  },
  MaxQueriesPerSecondChart: {
    f: MaxQueriesPerSecondChart,
    d: {w: 2, h: 5},
    display_name: 'Max Requests per second',
    icon: <FontAwesomeIcon icon={faChartArea} />,
  },
  TopBlocked: {
    f: TopBlocked,
    d: {w: 1, h: 5},
    display_name: 'Top Blocked Requests',
    icon: <FontAwesomeIcon icon={faList} />,
  },
  TopRequests: {
    f: TopRequests,
    d: {w: 1, h: 5},
    display_name: 'Top Requests',
    icon: <FontAwesomeIcon icon={faList} />,
  },
  TopCategories: {
    f: TopCategories,
    d: {w: 1, h: 5},
    display_name: 'Top Category Requests',
    icon: <FontAwesomeIcon icon={faList} />,
  },
  TopInvalid: {
    f: TopInvalid,
    d: {w: 1, h: 5},
    display_name: 'Top Invalid Requests',
    icon: <FontAwesomeIcon icon={faList} />,
  },
  TopThreats: {
    f: TopThreats,
    d: {w: 1, h: 5},
    display_name: 'Top Threats',
    icon: <FontAwesomeIcon icon={faList} />,
  },
};

/**
 *
 * @param{object[]} l
 * @param{number} l.x
 * @param{number} l.y
 * @return {object[]}
 */
function sortLayout(l) {
  return l.sort((l1, l2) => {
    if (l1.y === l2.y) {
      return l1.x - l2.x;
    }
    return l1.y - l2.y;
  });
}

const useStyles = makeStyles(() => ({
  scrollAuto: {
    overflow: 'auto',
  },
}));

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function DynamicDashboard() {
  const p = {
    className: 'layout',
    rowHeight: 56,
    cols: {md: 4, xxs: 1},
    isResizable: false,
    useCSSTransforms: true,
    margin: [24, 24],
    containerPadding: [0, 0],
    breakpoints: {md: 600, xxs: 0},
  };
  const [state, setState] = useState({
    currentBreakpoint: 'md',
    compactType: 'vertical',
    mounted: false,
    layouts: {md: []},
  });

  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [originalLayout, setOriginalLayout] = useState([]);

  const {
    data: {dashboard: l = []},
  } = useGetDataBackend('users/dashboard');

  useEffect(() => {
    setState((o) => ({...o, mounted: true}));
  }, []);

  useEffect(() => {
    if (l.length) {
      const layout = l.map(({i, x, y}) => {
        const {w, h} = Components[i].d;
        return {i, x, y, w, h};
      });
      setState((o) => ({...o, layouts: {md: layout}}));
      setOriginalLayout(layout);
    }
  }, [l]);

  const classes = useStyles();

  const addToLayout = (vis) => {
    const layout = state.layouts.md;
    if (!layout.length) {
      setState((o) => ({...o, layouts: {md: [{...vis, x: 0, y: 0}]}}));
      return;
    }
    let {x, y, w, h} = layout[layout.length - 1];
    x += w;
    if (x >= 4 || x + vis.w > 4) {
      x = 0;
      y += h;
    }
    setState((o) => ({...o, layouts: {md: [...layout, {...vis, x, y}]}}));
  };

  const editable = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      <ServiceAlert padding={29 * 2} />
      <div
        className={classes.scrollAuto}
        style={{padding: '25px 29px', overflow: 'overlay'}}>
        <ResponsiveGridLayout
          isDraggable={edit && editable}
          layouts={state.layouts}
          onBreakpointChange={(bp) =>
            setState((o) => ({...o, currentBreakpoint: bp}))
          }
          onLayoutChange={(layouts, ls) => {
            if (state.currentBreakpoint === 'md' && !ls.xxs) {
              setState((o) => ({...o, layouts: {md: sortLayout(layouts)}}));
            }
          }}
          {...p}
        >
          {state.layouts.md.map((layouts) => {
            const {i} = layouts;
            const {x, y, w, h, i: id} = layouts;
            const Component = Components[i].f;
            return (
              <div key={i}>
                <div style={{marginTop: '-29px'}}>
                  <Component />
                  {edit && editable && (
                    <>
                      <div
                        style={{
                          background: '#fff',
                          opacity: 0,
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          position: 'absolute',
                          cursor: 'move',
                        }}
                      >
                        {Object.entries({x, y, w, h, id}).join(' - ')}
                      </div>

                      <RedIconButton
                        TooltipProps={{title: 'Remove Widget'}}
                        style={{position: 'absolute', top: 5, right: 5}}
                        onClick={() =>
                          setState((currentState) => {
                            const newLayouts = currentState.layouts.md.filter(
                                (layout) => layout.i !== i,
                            );
                            return {
                              ...currentState,
                              layouts: {md: sortLayout(newLayouts)},
                            };
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </RedIconButton>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </ResponsiveGridLayout>
        {!edit && editable && (
          <Fab
            variant="extended"
            style={{position: 'absolute', bottom: 29, right: 24}}
            onClick={() => setEdit((toEdit) => !toEdit)}
          >
            <FontAwesomeIcon icon={faEdit} style={{fontSize: '1.25rem'}} />
                Edit Dashboard
          </Fab>
        )}
        {edit && editable && (
          <>
            <Tooltip title="Cancel">
              <Fab
                style={{position: 'absolute', bottom: 29, right: 24}}
                onClick={() =>
                  setEdit((toEdit) => !toEdit) ||
                          setState((o) => ({
                            ...o,
                            layouts: {md: originalLayout},
                          }))
                }
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{fontSize: '1.25rem'}}
                />
              </Fab>
            </Tooltip>
            <Tooltip title="Save Dashboard">
              <Fab
                disabled={saving}
                style={{
                  position: 'absolute',
                  bottom: 29,
                  right: 24 + 12 + 56,
                }}
                onClick={() => setSaving(true)}
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{fontSize: '1.25rem'}}
                />
              </Fab>
            </Tooltip>
            <AddComponentDashboardDrawer
              layout={state.layouts.md}
              addToLayout={addToLayout}
            />
          </>
        )}
        {saving && (
          <SaveDashboard
            layout={state.layouts.md}
            setSaving={setSaving}
            cleanFunction={() => {
              setEdit(false);
              setOriginalLayout(state.layouts.md);
            }}
          />
        )}
      </div>
    </>
  );
}

/**
 *
 * @param{object[]} layout
 * @param{function} setSaving
 * @param{function} cleanFunction
 * @return {null}
 * @constructor
 */
function SaveDashboard({layout, setSaving, cleanFunction = (_) => _}) {
  const {loading, error} = usePostDataBackend('users/dashboard', {
    dashboard: layout,
  });

  useEffect(() => {
    if (!loading && error) {
      displayError(`Error to saving dashboard:`, error.msg);
    }
    if (!loading) {
      setSaving(false);
    }
    return cleanFunction;
  }, [loading, error, setSaving, cleanFunction]);
  return null;
}

/**
 *
 * @param{object[]} layout
 * @param{function} addToLayout
 * @return {JSX.Element}
 * @constructor
 */
function AddComponentDashboardDrawer({layout, addToLayout}) {
  const [open, setOpen] = useState(false);

  const usedVisualizations = layout.map((l) => l.i);
  const unusedVisualizations = Object.entries(Components).filter(
      ([c]) => !usedVisualizations.includes(c),
  );

  return (
    <>
      <Tooltip title="Add Widget">
        <Fab
          style={{
            position: 'absolute',
            bottom: 29,
            right: 24 + 2 * 12 + 2 * 56,
          }}
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} style={{fontSize: '1.25rem'}} />
        </Fab>
      </Tooltip>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <List style={{width: 315}}>
          <ListItem>
            <ListItemText>
              <b>Add Widgets</b>
            </ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          {!!unusedVisualizations.length &&
            unusedVisualizations.map(
                ([c, {display_name: displayName, description, d, icon}], i) => {
                  const listItemKey = `component-${i}`;
                  return (
                    <ListItem
                      button
                      key={listItemKey}
                      onClick={() => addToLayout({i: c, ...d})}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText
                        primary={displayName || c}
                        secondary={description}
                      />
                    </ListItem>
                  );
                },
            )}
          {!unusedVisualizations.length && (
            <ListItem>
              <ListItemText primary="All Widgets are in Dashboard" />
            </ListItem>
          )}
        </List>
      </SwipeableDrawer>
    </>
  );
}
AddComponentDashboardDrawer.propTypes={
  layout: PropTypes.arrayOf(PropTypes.object),
  addToLayout: PropTypes.func,
};

export default DynamicDashboard;
