import {createStore} from 'redux';
import allReducers from './reducers';
import moment from 'moment';

const validateTimeRange = (range) => {
  if (!Array.isArray(range)) {
    return range;
  }
  if (!range.length) {
    return range;
  }
  const m = moment(range[0], moment.defaultFormat, true);
  if (!m.isValid()) {
    return 'l15m';
  }

  if (m < moment().subtract(7, 'days')) {
    return 'l15m';
  }
  return range;
};

const persistedState = () => {
  let storage = localStorage.getItem('appState');
  if (storage) {
    storage = JSON.parse(localStorage.getItem('appState'));
    delete storage.keycloak;
    delete storage.authenticated;
    delete storage.toastr;
    // delete storage.operator;
    storage.time_range = validateTimeRange(storage.time_range);
  }
  return storage || {};
};

const {__REDUX_DEVTOOLS_EXTENSION__} = window;

const store = createStore(
    allReducers,
    persistedState(),
    __REDUX_DEVTOOLS_EXTENSION__ &&
  __REDUX_DEVTOOLS_EXTENSION__({
    serialize: true,
    trace: true,
  }),
);

export default store;
