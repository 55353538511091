import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router';

import {
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';
import AddDomainForm from '../Forms/AddDomainForm';
import BoxInfo, {DarkBoxInfo} from '../../Components/BoxInfo';
import {useGetDataBackend} from '../../ApiBackend';

import {RenderCategoryChip} from '../Category';
import {
  getCreatedColor, getExpireColor, DateTitle, defaultColor,
  ExternalValidationDomainInfo, useWhoIsData, DisplayWhoIsData,
} from './DomainInfoUtils/DomainInfoUtils';

/**
 *
 * @param{function} RequestChart
 * @param{function} RequestsTable
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function DomainInfoUser({RequestChart, RequestsTable, ...props}) {
  const [domain, setDomain] = useState(props?.match?.params?.domain ?? '');
  const [selectedService] = useSelector((state) => [state.selectedService]);


  useEffect(() => {
    if (props.match?.params?.domain) {
      setDomain(props.match.params.domain);
    }
  }, [props.match?.params?.domain]);

  const urlData = {domain};
  if (selectedService !== 'all') {
    urlData.clientname = selectedService;
  }
  const theme = useTheme();
  const padding = theme.spacing(3);

  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <AddDomainForm domain={domain} setDomain={setDomain} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: 29}} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        {!domain && (
          <DarkBoxInfo title={domain ? `Domain: ${domain}` : 'Domains'} />
        )}
        {domain &&
          <DomainsCats title={`Domain: ${domain}`} domain={domain} />}
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <RequestChart domain={domain} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <RequestsTable extradata={{domain}} title="Last Access" />
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        {!domain && (
          <BoxInfo title={<b>Whois</b>}>
            <div
              style={{
                padding: 16,
                textAlign: 'center',
                color: defaultColor,
                fontSize: 16,
              }}
            >
                  Please Insert a domain.
            </div>
          </BoxInfo>
        )}
        {domain && <WhoIsTable domain={domain} />}
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <ExternalDomainInfoBox domain={domain} />
      </Grid>
      <Grid item xs={12} style={{height: padding, margin: 'auto'}} />
    </Grid>
  );
}
DomainInfoUser.propTypes={
  RequestChart: PropTypes.func,
  RequestsTable: PropTypes.func,
  match: PropTypes.object,
};

export default withRouter(DomainInfoUser);

/**
 *
 * @param{string|JSX.Element} title
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
export function DomainsCats({title, domain}) {
  const [selectedService] = useSelector((state) => [state.selectedService]);
  const {
    data: {categories = []},
    loading,
    error,
  } = useGetDataBackend('domain/category', {
    domain,
    clientname: selectedService,
  });
  const cats = Object.values(
      categories.reduce((acc, {clientname, list}) => {
        if (!acc[list]) {
          acc[list] = {list, clientname: []};
        }
        acc[list].clientname.push(clientname);
        return acc;
      }, {}),
  );
  const xs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const newTitle = (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{margin: 'auto 0'}}>{title}</div>
      <div
        style={{
          margin: 'auto 0 auto auto',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!loading &&
          !error &&
          !!cats.length &&
          cats.map(({clientname, list}, i) => {
            const categoryKey = 'category-' + i;
            return (
              <RenderCategoryChip
                key={categoryKey}
                list={list}
                clientnames={clientname}
                withLabel={!xs}
              />
            );
          })}
        {!loading && !error && !cats.length && (
          <Chip
            label="No information"
            variant="outlined"
            style={{color: 'white', borderColor: 'white'}}
          />
        )}
      </div>
    </div>
  );
  return <DarkBoxInfo title={newTitle} />;
}
DomainsCats.propTypes={
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  domain: PropTypes.string,
};

/**
 *
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
export function WhoIsTable({domain = ''}) {
  const sm = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const {data={}, entries, error, loading} = useWhoIsData(domain);

  const dataDomain = data?.domain;
  const createdColor = getCreatedColor(error, dataDomain?.created_date);

  const expireColor = getExpireColor(error, dataDomain?.expiration_date);


  const title = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        className="title-md"
        style={{minWidth: 130, marginTop: 'auto', marginBottom: 'auto'}}
      >
        <b
          title={dataDomain?.whois_server && `by ${dataDomain.whois_server}`}
        >
          Who Is {dataDomain?.domain}
        </b>

      </div>
      {(!sm || createdColor !== 'inherit') && (
        <>
          <div className="vertical-delimiter" />
          <div
            className="vertical-delimiter"
            style={{borderColor: 'transparent'}}
          />
          <DateTitle color={createdColor}
            title={'Created'}
            date={dataDomain?.created_date}
            error={error}
            loading={loading}
          />

        </>
      )}
      {!sm && (
        <>
          <div className="vertical-delimiter" />
          <div className="vertical-delimiter" />
          <DateTitle color={'inherit'}
            title={'Updated'}
            date={dataDomain?.updated_date}
            error={error}
            loading={loading}
          />

        </>
      )}
      {(!sm || expireColor !== 'inherit') && (
        <>
          <div className="vertical-delimiter" />
          <div className="vertical-delimiter" />
          <DateTitle color={expireColor}
            title={'Expire'}
            date={dataDomain?.expiration_date}
            error={error}
            loading={loading}
          />
        </>
      )}
      <div className="vertical-delimiter" />
    </div>
  );

  return (
    <BoxInfo title={title}>
      <DisplayWhoIsData loading={loading}
        error={error}
        entries={entries} />
    </BoxInfo>
  );
}
WhoIsTable.propTypes={
  domain: PropTypes.string,
};

/**
 *
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
export function ExternalDomainInfoBox({domain}) {
  return (
    <BoxInfo title={<b>External Validation</b>}>
      {!domain && (
        <div
          style={{
            padding: 16,
            textAlign: 'center',
            color: defaultColor,
            fontSize: 16,
          }}
        >
              Please Insert a domain.
        </div>
      )}
      <ExternalValidationDomainInfo domain={domain} />
    </BoxInfo>
  );
}
ExternalDomainInfoBox.propTypes={
  domain: PropTypes.string,
};
