import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(() => ({
  root: {
    'marginTop': 29,
    '&>.MuiCardHeader-root': {
      'height': 56,
      'backgroundColor': '#E4E4E4',
      '& .MuiCardHeader-content': {
        fontSize: 16,
      },
    },
    '&>.MuiCardContent-root': {
      padding: 0,
    },
    '&>.MuiCardActions-root': {
      height: 56,
      padding: 16,
      backgroundColor: '#E4E4E4',
      justifyContent: 'center',
    },
  },
  dark: {
    'marginTop': 29,
    'color': 'white',
    '&>.MuiCardHeader-root': {
      'height': 56,
      'backgroundColor': '#464646',
      '& .MuiCardHeader-content': {
        fontSize: 16,
      },
    },
  },
}));

export default function BoxInfo({
  icon,
  title,
  pagination,
  width,
  height,
  ...props
}) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined" square>
      <CardHeader disableTypography avatar={icon} title={title} />
      <CardContent>{props.children}</CardContent>
      {pagination && <CardActions>{pagination}</CardActions>}
    </Card>
  );
}

export function DarkBoxInfo({
  icon,
  title,
  pagination,
  width,
  height,
  ...props
}) {
  const classes = useStyles();
  return (
    <Card className={classes.dark} variant="outlined" square>
      <CardHeader disableTypography avatar={icon} title={title} />
      {props.children && <CardContent>{props.children}</CardContent>}
      {pagination && <CardActions>{pagination}</CardActions>}
    </Card>
  );
}
