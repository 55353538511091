import {makeStyles} from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  SwipeableDrawer,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLightbulb, faSuitcase} from '@fortawesome/free-solid-svg-icons';
import RedIconButton from '../../core/components/buttons/RedIconButton';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Helper() {
  const [helper, setHelper] = useState(false);

  const toggleDrawer = (helperPage) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setHelper(helperPage);
  };
  return (
    <React.Fragment key="help">
      <RedIconButton
        onClick={toggleDrawer(true)}
        TooltipProps={{title: 'FAQ'}}
        style={{alignSelf: 'center'}}
      >
        <FontAwesomeIcon icon={faLightbulb}/>
      </RedIconButton>
      <SwipeableDrawer
        anchor="right"
        onClose={toggleDrawer(false)}
        onOpen={() => false}
        open={helper}
        PaperProps={{style: {backgroundColor: 'rgb(70, 70, 70)'}}}
      >
        <HelperPanel helper={helper}/>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function HelperPanel() {
  const small = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <div
      style={{
        width: small ? '100%' : 360,
        height: '100%',
        background: '#464646',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SimpleExpansionPanel/>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '40px 25px 0',
  },
  panel: {
    background: 'none',
    border: 'none',
    borderTop: '1px solid #E4E4E4',
    // borderBottom: "1px solid #E4E4E4",

    color: 'white',
    boxShadow: 'none',
    borderRadius: 0,
  },
  summary: {
    padding: 0,
  },
  button: {
    color: '#E4E4E4',
    width: 50,
  },
  heading: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    padding: '0 9px 0 0',
  },
}));

/**
 *
 * @param{array} path
 * @return {
 * {question: string, answer: JSX.Element}[]|
 * ({question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element})[]|
 * ({question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element})[]|
 * ({question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element}|
 * {question: string, answer: JSX.Element})[]
 * }
 */
function faqItems(path) {
  const [p] = path;
  switch (p) {
    case 'Dashboard':
      return [
        {
          question: 'Dashboard',
          answer: (
            <>
              In the dashboard section, you have a quick overview of the DNS
              requests made to DNS8 service overtime.
              <br/>
              All widgets are bound to the selected time window on time
              selection button positioned at the right side of top bar.
            </>
          ),
        },
        {
          question: 'What are invalid domains?',
          answer: (
            <>
              Domains which do not fall under a Top Level Domain or Global
              Top Level Domain (like "home", "lan", "local", "intranet",
              "internal", "private", "corp" or "int")
              should be resolved internally and not forwarded to upstream DNS
              Resolvers.
              Domains which start with "_" (undescore) may indicate some service
              misconfiguration.
              When DNS8 is queried for any of these
              domains, it categorizes them as invalid.
            </>
          ),
        },
      ];
    case 'Service Policy':
      return [
        {
          question: 'Policy Section',
          answer: (
            <>
              In DNS8 Policy Section, you can search, add, delete or export
              the
              whitelisted and blacklisted domains and configure actions of a
              range of categories in differents services
            </>
          ),
        },
        {
          question: 'How do Black and White lists work?',
          answer: (
            <>
              When a domain is inserted on the whitelist, DNS8 domain
              categorization rules are bypassed, allowing for the domain
              resolution to be requested.
              <br/>
              When a domain is inserted on the blacklist, DNS8 blocks the DNS
              resolution for the domain, regarding of DNS8 domain
              categorization
              rules.
            </>
          ),
        },
        {
          question: 'How much time do the Black and White' +
            ' listing actions need to have effect?',
          answer: (
            <>DNS8 processes black and white listing actions every 15
              minutes.</>
          ),
        },
        {
          question: 'How does DNS blocking work on DNS8?',
          answer: (
            <>
              DNS8 works based on DNS Response Policy Zones. This means that
              when a domain is blocked, the responses from the requested
              domain&apos;s Authoritative server, will be switched/altered
              with
              the DNS8 webserver IP address that matches to
              blocked.dns8.online.
              <br/>
              The Policy Zones are checked by this order:
              <br/>
              <ol>
                <li>Whitelist</li>
                <li>Blacklist</li>
                <li>Global Blacklist</li>
                <li>Malware</li>
                <li>Phishing</li>
                <li>Reputation</li>
                <li>Anonymizer</li>
                <li>DGA</li>
                <li>Adware</li>
              </ol>
            </>
          ),
        },
        {
          question: 'How can block/allow a domain for specific service?',
          answer: (
            <>
              If you see <b><FontAwesomeIcon
                icon={faSuitcase}/> All services </b>, you can
              filter this view per service.
              <br/>
              After select a service you can add a domain with the respective
              action to the selected service.
              <br/><br/>
              Note: If you add a domain with <b><FontAwesomeIcon
                icon={faSuitcase}/> All services </b> selected,
              the system
              will add a entry per service that you account see.
            </>
          ),
        },
        {
          question: 'What is the difference between adding a domain in' +
            ' Domain Info and Service Policy with all services selected?',
          answer: (
            <>
              The domain added in Domain Info will affect all services in DNS8
              (current and future services).
              Adding a domain in Service Policy while <b><FontAwesomeIcon
                icon={faSuitcase}/> All services</b>
              is selected,
              it will affect only the services that you can see/access
              currently.
            </>
          ),
        },
      ];
    case 'Global Policy':
      return [
        {
          question: 'Global Policy Section',
          answer: (
            <>
              In DNS8 Global Policy Section, you can search, delete or export
              the
              whitelisted and blacklisted domains.
            </>
          ),
        },
        {
          question: 'How much time do the Black and White' +
            ' listing actions need to have effect?',
          answer: (
            <>DNS8 processes black and white listing actions every 15
              minutes.</>
          ),
        },
        {
          question: 'How does DNS blocking work on DNS8?',
          answer: (
            <>
              DNS8 works based on DNS Response Policy Zones. This means that
              when a domain is blocked, the responses from the requested
              domain&apos;s Authoritative server, will be switched/altered
              with
              the DNS8 webserver IP address that matches to
              blocked.dns8.online.
              <br/>
              The Policy Zones are checked by this order:
              <br/>
              <ol>
                <li>Whitelist</li>
                <li>Blacklist</li>
                <li>Global Blacklist</li>
                <li>Malware</li>
                <li>Phishing</li>
                <li>Reputation</li>
                <li>Anonymizer</li>
                <li>DGA</li>
                <li>Adware</li>
              </ol>
            </>
          ),
        },
      ];
    case 'Policy':
      return [
        {
          question: 'Policy Section',
          answer: (
            <>
              In DNS8 Policy Section, you can search, add, delete or export
              the
              whitelisted and blacklisted domains and configure actions of a
              range of categories.
            </>
          ),
        },
        {
          question: 'How do Black and White lists work?',
          answer: (
            <>
              When a domain is inserted on the whitelist, DNS8 domain
              categorization rules are bypassed, allowing for the domain
              resolution to be requested.
              <br/>
              When a domain is inserted on the blacklist, DNS8 blocks the DNS
              resolution for the domain, regarding of DNS8 domain
              categorization
              rules.
            </>
          ),
        },
        {
          question: 'How much time do the Black and White' +
            ' listing actions need to have effect?',
          answer: (
            <>DNS8 processes black and white listing actions every 15
              minutes.</>
          ),
        },
        {
          question: 'How does DNS8 block advertising domains?',
          answer: (
            <>
              DNS8 blocks advertising domains by altering the domain&apos;s
              authoritative server response to the IP address 127.0.0.1
              (localhost). By using the localhost IP, browsers will try to
              reach
              a non existing server, which will result in the ad rendering as
              a
              white space.
            </>
          ),
        },
        {
          question: 'How does DNS blocking work on DNS8?',
          answer: (
            <>
              DNS8 works based on DNS Response Policy Zones. This means that
              when a domain is blocked, the responses from the requested
              domain&apos;s Authoritative server, will be switched/altered
              with
              the DNS8 webserver IP address that matches to
              blocked.dns8.online.
              <br/>
              The Policy Zones are checked by this order:
              <br/>
              <ol>
                <li>Whitelist</li>
                <li>Blacklist</li>
                <li>Global Blacklist</li>
                <li>L8 Intel</li>
                <li>Malware</li>
                <li>Phishing</li>
                <li>Reputation</li>
                <li>Anonymizer</li>
                <li>DGA</li>
                <li>Adware</li>
              </ol>
            </>
          ),
        },
        {
          question: 'How can configure the categories policy in DNS8?',
          answer: (
            <>
              In DNS8 there is 3 levels of protection:
              <ul>
                <li><b>Monitor Only</b> - in this mode the domain in
                  category <b>will be detected</b> by DNS8 but <u>will not
                    blocked</u> the domain.
                </li>
                <li><b>Blocked Validated</b> - in this mode the domain in
                  category <b>will be blocked</b> if the domain was <u>validated
                    by DNS8 Intel</u></li>
                <li><b>Blocked All</b> - in this mode the domain in category
                  <b>will be blocked</b> if <u>any source</u> that DNS8 uses
                  refers the domain.
                </li>
              </ul>
              The Best Protection is <b>Blocked All</b> but you can use
              <b>Blocked Validated</b> if you want to avoid false positives.
            </>
          ),
        },
      ];
    case 'Domain Info':
      return [
        {
          question: 'Domain Info Section',
          answer: (
            <>
              In DNS8 Domain Info Section, you can investigate domains and
              configure <b>global
              actions </b> about a domain.
              You can also see if the domain is already flagged by DNS8.
            </>
          ),
        },
        {
          question: 'How do Black and White lists work?',
          answer: (
            <>
              When a domain is inserted on the whitelist, DNS8 domain
              categorization rules are bypassed, allowing for the domain
              resolution to be requested.
              <br/>
              When a domain is inserted on the blacklist, DNS8 blocks the DNS
              resolution for the domain, regarding of DNS8 domain
              categorization
              rules.
            </>
          ),
        },
        {
          question: 'How much time do the Black and White' +
            ' listing actions need to have effect?',
          answer: (
            <>DNS8 processes black and white listing actions every 15
              minutes.</>
          ),
        },
        {
          question: 'How can block/allow a domain in a specific service?',
          answer: (
            <>In this Section you only can allow/block a
              domain <u>globally</u> in DNS8 plataform.
              To block/allow a domain in specific service you must go to
              the <b>Service Policy</b>, after selecting a service
              in top menu.</>
          ),
        },
      ];
    case 'Domains':
      return [
        {
          question: 'Domains Section',
          answer: (
            <>
              In DNS8 Domains Section, you can investigate domains and
              configure
              actions about a domain.
            </>
          ),
        },
        {
          question: 'What do Domain Actions mean?',
          answer: (
            <ul>
              <li>
                Allowed - Domains that are explicitly allowed by Whitelist and
                policies (only in Public)
              </li>
              <li>
                Blocked - Domains that are explicitly blocked by Global
                Blacklist, by Blacklist and by policies
              </li>
              <li>
                Blocked by <i>CNAME/NS/...</i> -
                Response Domains <i>CNAME/NS/...</i> that are explicitly
                on Global Blacklist,
                Blacklist and policies
                blocking the requested Domain (only in Public).
              </li>
              <li>
                Resolved - Requests with resolution information (only in
                Internal).
              </li>
              <li>
                No answer - DNS Requests without resolution information (only
                in Internal).
              </li>
            </ul>
          ),
        },
        {
          question: 'How can I search in domains?',
          answer: (
            <>
              To find a specific domain, must insert the domain in the field.
              <br/>
              However if you want to search the part of domain you must insert
              the symbol `&apos;*`&apos; before or/and after the word. <br/>
              Examples:
              <ul>
                <li>
                  `&apos;google.com`&apos; will match the requests that were
                  made to google.com, only.
                </li>
                <li>
                  `&apos;*.google.com`&apos; will match the requests to all
                  sub
                  domains of google.com.
                </li>
                <li>
                  `&apos;*google*`&apos; will match the requests to all
                  domains
                  that contains google.
                </li>
              </ul>
            </>
          ),
        },
      ];
    case 'Requests':
      return [
        {
          question: 'Requests Section',
          answer: (
            <>
              In DNS8 Requests Section, you can search all requests done to
              DNS8.
              <br/>
              If the service has the <u>internal plugin</u>, you all can see
              the
              clients ips that request DNS queries internally to your network.
            </>
          ),
        },
        {
          question: 'What do Domain Actions mean?',
          answer: (
            <ul>
              <li>
                Allowed - Domains that are explicitly allowed by Whitelist and
                policies (only in Public)
              </li>
              <li>
                Blocked - Domains that are explicitly blocked by Global
                Blacklist, by Blacklist and by policies
              </li>
              <li>
                Blocked by <i>CNAME/NS/...</i> -
                Response Domains <i>CNAME/NS/...</i> that are explicitly
                on Global Blacklist,
                Blacklist and policies
                blocking the requested Domain (only in Public).
              </li>
              <li>
                Resolved - Requests with resolution information (only in
                Internal).
              </li>
              <li>
                No answer - DNS Requests without resolution information (only
                in
                Internal).
              </li>
            </ul>
          ),
        },
        {
          question: 'How can I search in domains?',
          answer: (
            <>
              To find a specific domain, must insert the domain in the field.
              <br/>
              However if you want to search the part of domain you must insert
              the symbol `&apos;*`&apos; before or/and after the word. <br/>
              Examples:
              <ul>
                <li>
                  `&apos;google.com`&apos; will match the requests that were
                  made to google.com, only.
                </li>
                <li>
                  `&apos;*.google.com`&apos; will match the requests to all
                  sub
                  domains of google.com.
                </li>
                <li>
                  `&apos;*google*`&apos; will match the requests to all
                  domains
                  that contains google.
                </li>
              </ul>
            </>
          ),
        },
      ];
    case 'Threats':
      return [
        {
          question: 'Threats Section',
          answer: (
            <>
              In DNS8 Threats Section, you can see all requests considered as
              a
              threat at the current time. Threat lists are updated each 15
              minutes.
            </>
          ),
        },
        {
          question: 'What is a threat?',
          answer: (
            <>
              A DNS8 Threat is a domain that belongs to one (or several) of
              the
              categories below:
              <ol>
                <li>L8 Intel</li>
                <li>Malware</li>
                <li>Phishing</li>
                <li>Reputation</li>
                <li>Anonymizer</li>
                <li>DGA</li>
              </ol>
              Blacklists and the category Adware are not considered a Threat
            </>
          ),
        },
      ];
    case 'Release notes':
      return [
        {
          question: 'Release notes Section',
          answer: (
            <>
              In Release notes Section, you can review all improvements
              that were implemented in DNS8.
            </>
          ),
        },
      ];
    case 'Settings':
      return [
        {
          question: 'Settings Section',
          answer: (
            <>
              In Settings Section, you can configure alerts and schedule
              reports.
              <br/>
              Also you can see information about your DNS8 service.
              <br/>
              Finally, you can also change you account information.
            </>
          ),
        },
        {
          question: 'Alerts Types',
          answer: (
            <>
              {' '}
              There are 2 Alert types: by Query Categories and by Internal
              Domain/IP.
              <br/>
              <br/>
              The Category alert will trigger when a requested domain is
              present
              in DNS8 categories.
              <br/>
              <br/>
              The Domain/IP alert type is exclusive for clients with DNS8
              internal plugin installed. This alert type will receive at most
              256 Domains or IPs. The Domain alert will trigger when an
              inputted
              domain is requested. In case of Domains the field can receive
              subdomains (ex. *.example.com). The IP alert will trigger when
              the
              internal DNS responds with this IP. In case of IPs the field can
              receive a range (ex. 127.0.0.1/31). In case of multiples values
              must be separated by comma - [,].
            </>
          ),
        },
      ];
    default:
      return [
        {
          question: 'Why I am empty?',
          answer: <>Because this page: {p} doesn&apos;t have any faq items.</>,
        },
      ];
  }
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function SimpleExpansionPanel() {
  const classes = useStyles();
  const [path] = useSelector((state) => [state.path]);
  const [faqsItems, setFaqItems] = useState(faqItems(path));

  useEffect(() => {
    setFaqItems(faqItems(path));
  }, [path]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid #E4E4E4',
        }}
      >
        {/* <Button
        square={"true"}
        style={{flex: 1, height: 60, color: "#E4E4E4"}}>
        WE EXPLAIN
        </Button> */}
        <Button
          square="true"
          style={{
            flex: 1,
            height: 60,
            color: '#E4E4E4',
            background: '#BE131A',
          }}
        >
          FAQ
        </Button>
      </div>
      <div className={classes.root}>
        {faqsItems.map((q, i) => {
          const accordionKey = `accordion-${i}`;
          return (
            <Accordion
              key={accordionKey}
              className={classes.panel}
              square={true}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon className={classes.button}/>}
              >
                <Typography className={classes.heading}>
                  {q.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div style={{fontSize: 14}}>{q.answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </>
  );
}
