import {IconButton, useMediaQuery, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SortIcon from '@material-ui/icons/Sort';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableUi from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faEllipsisH,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import Button from '@material-ui/core/Button';
import TableFooter from '@material-ui/core/TableFooter';
import BoxInfo from './BoxInfo';
import ExcelExporter from '../DNS8Components/Pages/ExcelExporter';
import ExcelImporter from '../DNS8Components/Pages/ExcelImporter';
import RedTextField from '../core/components/inputs/RedTextField';
import {useGetDataBackend} from '../ApiBackend';

const RedFormControl = withStyles({
  root: {
    'width': '100%',
    '& label.Mui-focused': {
      color: '#BE131A',
    },
    '& .MuiInputLabel-root': {
      'color': '#464646',
      'fontSize': 'inherit',
      '&.Mui-disabled': {
        color: '#464646',
      },
    },
    '& .MuiInputBase-root': {
      fontSize: 'inherit',
    },

    '& .MuiInput-underline': {
      '&:after': {
        borderBottomColor: '#BE131A',
      },
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
})(FormControl);

/**
 *
 * @param{string} data
 * @param{Object} tableState
 * @param{function} setTableState
 * @return {JSX.Element}
 */
function HeaderSortable({data, table_state: tableState, setTableState}) {
  const d = tableState.sort_column === data ? tableState.sort_direction : '';
  const nextState = () => {
    let newD;
    if (!d || d === 'asc') {
      newD = 'desc';
    } else {
      newD = 'asc';
    }
    setTableState((state) => ({
      ...state,
      sort_column: data,
      sort_direction: newD,
    }));
  };
  return (
    <Grid item>
      <IconButton size="small" onClick={nextState}>
        {!d && <ImportExportIcon style={{fontSize: 20}}/>}
        {d === 'asc' && (
          <SortIcon
            style={{
              transform: 'scaleY(-1)',
              color: '#BE131A',
              fontSize: 20,
            }}
          />
        )}
        {d === 'desc' && (
          <SortIcon style={{color: '#BE131A', fontSize: 20}}/>
        )}
      </IconButton>
    </Grid>
  );
}

HeaderSortable.propTypes = {
  data: PropTypes.string,
  table_state: PropTypes.object,
  setTableState: PropTypes.func,
};

/**
 *
 * @param {Object} options
 * @param {any} title
 * @param {Object} props
 * @return {JSX.Element|*}
 */
export function Header({options, title, ...props}) {
  if (options?.length) {
    return <HeaderSelect {...props} options={options}/>;
  }
  if (!title) {
    return <HeaderInput {...props} />;
  }
  return title;
}

/**
 *
 * @param{string|JSX.Element} name
 * @param{string} value
 * @param{Object} props
 * @return {JSX.Element}
 */
export function SearchableInput({name, value = '', ...props}) {
  const [focus, setFocus] = useState(false);
  const [v, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Grid container spacing={0} alignItems="flex-end" wrap="nowrap">
      <Grid item xs>
        <RedTextField
          label={name}
          InputLabelProps={{
            shrink: !!v || focus,
            className: 'withStartAdornment' || '',
          }}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{fontSize: 20}}/>
                </InputAdornment>
              ),
            } || {}
          }
          value={v}
          onChange={({target: {value: newValue}}) => setValue(newValue)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          size="small"
          fullWidth
          {...props}
        />
      </Grid>
    </Grid>
  );
}

SearchableInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
};

/**
 *
 * @param{boolean} sortable
 * @param{string|JSX.Element} name
 * @param{string} data
 * @param{boolean} searchable
 * @param{string} value
 * @param{int} width
 * @param{object} tableState
 * @param{function} setTableState
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function HeaderInput({
  sortable,
  name,
  data,
  searchable,
  value,
  width,
  table_state: tableState = {},
  setTableState = (_) => _,
  ...props
}) {
  const [v, setValue] = useState(tableState[data] || '');
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setValue(tableState[data] || '');
  }, [tableState, data]);

  /**
   *
   * @param{boolean} toAdd
   * @param{string} newValue
   */
  function updateTableState(toAdd, newValue = '') {
    if (toAdd) {
      setTableState((currentTableState) => {
        const newState = {...currentTableState, page: 1};
        newState[data] = newValue;
        return newState;
      });
    } else {
      setTableState((currentTableState) => {
        const newState = {...currentTableState, page: 1};
        delete newState[data];
        return newState;
      });
    }
  }

  const onKeyDown = ({key, target: {value: newValue}}) => {
    if (key === 'Enter') {
      updateTableState(newValue !== '', newValue);
    }
  };

  const onBlur = ({target: {value: blurValue}}) => {
    setFocus(false);
    updateTableState(blurValue !== '', blurValue);
  };

  return (
    <Grid container spacing={0} alignItems="flex-end" wrap="nowrap">
      <Grid item xs>
        <RedTextField
          label={name}
          InputLabelProps={{
            shrink: !!v || focus,
            className: (searchable && 'withStartAdornment') || '',
          }}
          InputProps={
            (searchable && {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{fontSize: 20}}/>
                </InputAdornment>
              ),
            }) ||
            {}
          }
          value={v ?? ''}
          onChange={({target: {value: valueChange}}) =>
            setValue(valueChange)
          }
          onKeyDown={onKeyDown}
          onFocus={() => setFocus(true)}
          onBlur={onBlur}
          disabled={!searchable}
          size="small"
          fullWidth
        />
      </Grid>
      {sortable && (
        <HeaderSortable
          name={name}
          {...{...props, data, table_state: tableState, setTableState}}
          x
        />
      )}
    </Grid>
  );
}

HeaderInput.propTypes = {
  sortable: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  data: PropTypes.string,
  searchable: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.number,
  table_state: PropTypes.object,
  setTableState: PropTypes.func,
};

/**
 *
 * @param{string|JSX.Element} name
 * @param{string} data
 * @param{Array} options
 * @param{object} tableState
 * @param{function} setTableState
 * @return {JSX.Element}
 * @constructor
 */
function HeaderSelect({
  name,
  data,
  options,
  table_state: tableState,
  setTableState,
}) {
  const [v, setValue] = useState(tableState[data] || '');

  const handleChange = (event) => {
    setValue(event.target.value);
    setTableState((state) => {
      const newState = {...state, page: 1};
      newState[data] = event.target.value;
      return newState;
    });
  };
  return (
    <RedFormControl>
      <InputLabel>{name}</InputLabel>
      <Select value={v} onChange={handleChange}>
        {options.map(({value, label}) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </RedFormControl>
  );
}

HeaderSelect.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  data: PropTypes.string,
  options: PropTypes.array,
  table_state: PropTypes.object,
  setTableState: PropTypes.func,
};
export const StyledTableRow = withStyles((_) => ({
  root: {
    'borderRight': 'none',
    'borderLeft': 'none',
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(228, 228, 228, .3)',
    },
    'height': 28,
  },
}))(TableRow);

const CondenseTableRow = withStyles((_) => ({
  root: {
    borderRight: 'none',
    borderLeft: 'none',
    background: 'transparent',
  },
}))(TableRow);

export const StyledTableCell = withStyles((_) => ({
  head: {
    'color': '#464646',
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 8,
    'paddingRight': 8,
    'fontSize': 14,
    '&:first-child': {
      paddingLeft: 16,
    },
    '&:last-child': {
      paddingRight: 16,
    },
  },
  body: {
    'color': 'rgba(0, 0, 0, 0.87)',
    'paddingTop': 2,
    'paddingBottom': 1,
    'paddingLeft': 8,
    'paddingRight': 8,
    'fontSize': 12,
    'height': 'auto',
    '&:first-child': {
      paddingLeft: 16,
    },
    '&:last-child': {
      paddingRight: 16,
    },
  },
}))(TableCell);

export const CondenseTableCell = withStyles((_) => ({
  head: {
    color: '#464646',
    padding: '7px 16px',
    fontSize: 14,
    border: 'none',
  },
  body: {
    color: '#464646',
    padding: '2px 16px',
    fontSize: 12,
    height: 26,
    border: 'none',
  },
}))(TableCell);

/**
 *
 * @param{string|JSX.Element} title
 * @param{object[]} columns
 * @param{string} url
 * @param{object} addData
 * @param{function} dataAccess
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export function MiniList({
  title,
  columns,
  url,
  add_data: addData = {},
  data_access: dataAccess = (d) => d,
  ...props
}) {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const {data, loading, error} = useGetDataBackend(url, {
    ...addData,
    t_r: timeRange,
    clientname: selectedService,
  });
  const list = dataAccess(data);

  return (
    <div
      className="dashboard-item-wrapper"
      style={{margin: '21px 10px', flex: '1 0 21%', ...props.style}}
    >
      <div className="small-table white-bg">
        <div className="table-super-header-wrapper">
          <div className="table-super-header">
            <b>{title}</b>
          </div>
        </div>
        <TableContainer>
          <TableUi style={{tableLayout: 'fixed'}}>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  const columnKey = `header-${index}`;
                  return (
                    <CondenseTableCell key={columnKey} style={col.styles}>
                      <Header {...col} />
                    </CondenseTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!(loading || error) && !list?.length && (
                <CondenseTableRow>
                  <CondenseTableCell
                    colSpan={columns.length}
                    style={{height: 30 * 10}}
                  >
                    <div style={{textAlign: 'center', fontSize: 16}}>
                      {' '}
                      No records found.
                    </div>
                  </CondenseTableCell>
                </CondenseTableRow>
              )}
              {!(loading || error) &&
                !!list?.length &&
                Array.from(Array(10)).map((_, rIndex) => {
                  const row = list[rIndex] || [];
                  const rowKey = `row-${rIndex}`;
                  return (
                    <CondenseTableRow key={rowKey}>
                      {columns.map(function(column, cIndex) {
                        const cellKey = `data-${rIndex}-${cIndex}`;
                        if (column.render && row[cIndex]) {
                          return (
                            <CondenseTableCell key={cellKey}>
                              {column.render(row[cIndex], row)}
                            </CondenseTableCell>
                          );
                        }
                        if (row[cIndex]) {
                          return (
                            <CondenseTableCell key={cellKey}>
                              {' '}
                              {row[cIndex]}
                            </CondenseTableCell>
                          );
                        }
                        if (column.render && row[column.data]) {
                          return (
                            <CondenseTableCell key={cellKey}>
                              {' '}
                              {column.render(row[column.data], row)}
                            </CondenseTableCell>
                          );
                        }
                        return (
                          <CondenseTableCell key={cellKey}>
                            {row[column.data]}
                          </CondenseTableCell>
                        );
                      })}
                    </CondenseTableRow>
                  );
                })}
              {(loading || error) && (
                <CondenseTableRow>
                  <CondenseTableCell
                    colSpan={columns.length}
                    style={{height: 300, display: 'table-cell'}}
                  >
                    <div style={{display: 'flex'}}>
                      <div style={{margin: 'auto', color: '#BE131A'}}>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          style={{fontSize: 40}}
                        />
                      </div>
                    </div>
                  </CondenseTableCell>
                </CondenseTableRow>
              )}
            </TableBody>
          </TableUi>
        </TableContainer>
      </div>
    </div>
  );
}

MiniList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  columns: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
  add_data: PropTypes.object,
  data_access: PropTypes.func,
  style: PropTypes.object,
};

/**
 *
 * @param{string} warning
 * @param{any[]} cols
 * @param{function} setTableState
 * @param{Object} tableState
 * @param{boolean} loading
 * @param{boolean|string} error
 * @param{Array} list
 * @param{number} rowHeight
 * @return {JSX.Element}
 * @constructor
 */
export function TableWithList({
  warning,
  cols,
  setTableState = () => null,
  tableState = {},
  loading,
  error,
  list,
  rowHeight = 28,
}) {
  const size = tableState.size || 10;
  const map = {
    'More that 10000 results.': 'This table will show the first 10000 records.',
  };
  return (
    <TableContainer>
      <TableUi style={{tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow>
            {cols.map((col, index) => {
              const colKey = `header${index}`;
              return (
                <StyledTableCell key={colKey} style={col.styles}>
                  <Header
                    {...col}
                    setTableState={setTableState}
                    table_state={tableState}
                  />
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading || error) && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={cols.length}
                style={{height: rowHeight * size}}
              >
                <div style={{display: 'flex'}}>
                  <div style={{margin: 'auto', color: '#BE131A'}}>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{fontSize: 40}}
                    />
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!(loading || error) && !list?.length && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={cols.length}
                style={{height: rowHeight * size}}
              >
                <div style={{textAlign: 'center', fontSize: 16}}>
                  {' '}
                  No records found.
                </div>
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!(loading || error) &&
            !!list?.length &&
            Array.from(Array(size)).map((_, rIndex) => {
              const row = list[rIndex];
              const colIndex = `row-${rIndex}`;
              return (
                <StyledTableRow
                  key={colIndex}
                  style={{
                    border: '1px solid #F9F9F9',
                    borderRight: 'none',
                    borderLeft: 'none',
                    height: rowHeight,
                  }}
                >
                  {cols.map(function(column, cIndex) {
                    const cellKey = `data-${rIndex}-${cIndex}`;
                    if (column.render && row) {
                      return (
                        <StyledTableCell key={cellKey}>
                          {column.render(row[cIndex] || row[column.data],
                              row)}
                        </StyledTableCell>
                      );
                    }
                    if (row) {
                      return (
                        <StyledTableCell key={cellKey}>
                          {row[cIndex] || row[column.data]}
                        </StyledTableCell>
                      );
                    }
                    return <StyledTableCell key={cellKey}/>;
                  })}
                </StyledTableRow>
              );
            })}
        </TableBody>
        {warning && (
          <TableFooter>
            <TableRow>
              <StyledTableCell
                style={{textAlign: 'center'}}
                colSpan={cols.length}
              >
                {map[warning] || warning}
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        )}
      </TableUi>
    </TableContainer>
  );
}

TableWithList.propTypes = {
  warning: PropTypes.string,
  cols: PropTypes.array,
  setTableState: PropTypes.func,
  tableState: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  list: PropTypes.array,
  rowHeight: PropTypes.number,
};
/**
 *
 * @param{Array} columns
 * @param{string} url
 * @param{string|JSX.Element} title
 * @param{object} extradata
 * @param{number} rowHeight
 * @param{boolean} exporter
 * @param{ExcelImporter.propTypes.importer} importer
 * @param{number[]} pageGroups
 * @return {JSX.Element}
 * @constructor
 */
export default function Table({
  columns = [],
  url,
  title = '',
  extradata = {},
  row_height: rowHeight = 28,
  exporter = false,
  importer = false,
  page_groups: pageGroups = [15, 50, 100],
}) {
  const [tableState, setTableState] = useState({
    page: 1,
    size: 15,
    sort_column: extradata.sort_column || '@timestamp',
    sort_direction: extradata.sort_direction || 'desc',
    ...extradata,
  });

  useEffect(() => {
    setTableState((lastState) => ({...lastState, ...extradata}));
  }, [extradata]);

  const {
    data: {list, total, warning},
    error,
    loading,
  } = useGetDataBackend(url, {...tableState});

  const cols = columns.filter((col) => !col.hide);

  const boxTitle = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {title}{' '}
      {(importer || exporter) && <div>
        <ExcelImporter importer={importer} url={url}/>
        {exporter &&
          <ExcelExporter {...{exporter, columns, url, title}} />}

      </div>}
    </div>
  );

  return (
    <BoxInfo
      title={boxTitle}
      pagination={
        <TablePagination
          total={total}
          state={tableState}
          setState={setTableState}
          pageGroups={pageGroups}
        />
      }
    >
      <TableWithList
        warning={warning}
        list={list}
        loading={loading}
        cols={cols}
        error={error}
        setTableState={setTableState}
        tableState={tableState}
        rowHeight={rowHeight}
      />
    </BoxInfo>
  );
}
Table.propTypes = {
  columns: PropTypes.array,
  url: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  extradata: PropTypes.object,
  row_height: PropTypes.number,
  exporter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  importer: ExcelImporter.propTypes.importer,
  page_groups: PropTypes.arrayOf(PropTypes.number),
};
const PaginationButton = withStyles({
  root: {
    'padding': 0,
    'margin': 0,
    'minWidth': '2.5em',
    'minHeight': '2.5em',
    'fontSize': '.75rem',
    'background': 'white',
    'outline': 'none',
    '&.active': {
      boxShadow: 'none',
      background: '#464646',
      color: 'white',
    },
  },
})(Button);

/**
 *
 * @param{number} maxPages
 * @param{number} currentPage
 * @return {array}
 */
function pagesArray(maxPages, currentPage) {
  let pages;
  if (maxPages <= 7) {
    pages = Array(maxPages).fill(0);
  } else {
    pages = Array(7).fill(0);
  }

  pages = pages.map((_, i) => i + 1);
  if (maxPages > 7 && currentPage < 5) {
    pages[pages.length - 2] = '...';
    pages[pages.length - 1] = maxPages;
  } else if (maxPages > 7 && maxPages - currentPage <= 3) {
    for (let i = 2, v = maxPages - 4; v <= maxPages; i += 1, v += 1) {
      pages[i] = v;
    }
    pages[1] = '...';
  } else if (maxPages > 7) {
    pages[1] = '...';
    pages[2] = currentPage - 1;
    pages[3] = currentPage;
    pages[4] = currentPage + 1;
    pages[pages.length - 2] = '...';
    pages[pages.length - 1] = maxPages;
  }
  return pages;
}

/**
 *
 * @param{number} total
 * @param{Object} state
 * @param{function} setState
 * @param{array} pageGroups
 * @return {JSX.Element}
 * @constructor
 */
function TablePagination({total, state, setState, pageGroups}) {
  const xs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const {size, page} = state;
  const maxPages = total ? Math.ceil(total / size) : 0;
  const pages = pagesArray(maxPages, page);
  if (!xs) {
    pages.unshift('prev');
    pages.push('next');
  }

  const onClick = (value) => () => {
    setState((currentState) => ({...currentState, page: value}));
  };

  const handleChange = (event) => {
    setState((currentState) => {
      const newState = {...currentState, page: 1};
      newState.size = event.target.value;
      return newState;
    });
  };
  const newPageGroups = pageGroups.map((e) => parseInt(e, 10));
  if (newPageGroups.indexOf(size) === -1) {
    newPageGroups.push(size);
  }
  newPageGroups.sort((e, l) => e - l);

  return <>
    {!xs && <div style={{width: 100, marginRight: 8}}/>}
    {pages.map((btn, i) => {
      let props;
      if (btn === 'next') {
        props = {
          disabled: page >= maxPages,
          onClick: onClick(page + 1),
          onDoubleClick: () => false,
          children: <FontAwesomeIcon icon={faChevronRight}/>,
          style: {marginRight: 'auto'},
        };
      } else if (btn === 'prev') {
        props = {
          disabled: page <= 1,
          onClick: onClick(page - 1),
          onDoubleClick: () => false,
          children: <FontAwesomeIcon icon={faChevronLeft}/>,
          style: {marginLeft: 'auto'},
        };
      } else if (btn === '...') {
        props = {
          disabled: true,
          children: <FontAwesomeIcon icon={faEllipsisH}/>,
        };
      } else {
        // number
        props = {
          className: page === btn ? 'active' : '',
          children: btn,
          onClick: onClick(btn),
          onDoubleClick: () => false,
        };
      }
      const pageKey = 'page-' + i;
      return (
        <PaginationButton
          size="small"
          variant="outlined"
          key={pageKey}
          {...props}
        />
      );
    })}
    {!xs && (
      <RedFormControl style={{width: 100}}>
        <InputLabel>Page Length</InputLabel>
        <Select value={size} onChange={handleChange}>
          {newPageGroups.map((newPage) => (
            <MenuItem key={`page${newPage}`} value={newPage}>
              {newPage}
            </MenuItem>
          ))}
        </Select>
      </RedFormControl>
    )}
  </>;
}

TablePagination.propTypes = {
  total: PropTypes.number,
  state: PropTypes.object,
  setState: PropTypes.func,
  pageGroups: PropTypes.array,
};
