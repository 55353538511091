import React from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ServiceHeader} from './ServiceHeader';
import {ServiceBlockInfo} from './ServiceBlockInfo';
import {useGetDataBackend} from '../../ApiBackend';

export default function PublicServiceInfo() {
  const [selectedService] = useSelector((state) => [state.selectedService]);
  let {
    data: {info},
    error,
    loading,
  } = useGetDataBackend('services/info/all');

  if (info && selectedService.length && selectedService !== 'all') {
    info = info.filter((i) => i.hash === selectedService);
  }
  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      {(error || loading) && (
        <>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceHeader />
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceBlockInfo title="Client DNS IPS" loading={loading} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceBlockInfo title="Public Client Ips" loading={loading} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceBlockInfo title="DNS8 Server" loading={loading} />
          </Grid>
        </>
      )}
      {!(error || loading) &&
        info.map(({client_hash: clientHash, ...e}, i) => (
          <React.Fragment key={clientHash}>
            <Grid
              item
              xs={12}
              style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
            >
              <ServiceHeader {...e} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
            >
              <ServiceBlockInfo
                title="Client DNS IPS"
                loading={loading}
                info={e.ips.join(', ')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
            >
              <ServiceBlockInfo
                title="Public Client Ips"
                loading={loading}
                info={e.pips.join(', ')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
            >
              <ServiceBlockInfo
                title="DNS8 Server"
                loading={loading}
                info={e.dnsips.join(', ')}
              />
            </Grid>
          </React.Fragment>
        ))}
      <Grid item xs={12} style={{height: padding, margin: 'auto'}} />
    </Grid>
  );
}
