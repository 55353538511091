import {Select, withStyles} from '@material-ui/core';

const MuiSelectForm = withStyles({
  root: {
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '&:before': {
      border: 'none',
    },
  },
  selectMenu: {
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
  },
})(Select);

export default MuiSelectForm;
