import * as ACTION_TYPE from './actionTypes';

/**
 *
 * @param{string} range
 * @return {{payload: string, type: string}}
 */
export function setTimeRange(range = 'l15m') {
  return {
    type: ACTION_TYPE.SET_TIME_RANGE,
    payload: range,
  };
}

