import React, {useEffect} from 'react';
import {useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch} from 'react-redux';
import {setPath} from '../../actions/pathAction';
import GlobalDomains from '../Tables/GlobalDomains';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function GlobalPolicy() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Global Policy'));
  }, [dispatch]);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <GlobalDomains />
      </Grid>
    </Grid>
  );
}
