const {
  _env_: {
    REACT_APP_KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_URL,
    REACT_APP_KEYCLOAK_CLIENT_ID,
    REACT_APP_BACKEND_API,
    REACT_APP_SITE_TITLE,
    PUBLIC_URL,
  },
} = window;

module.exports = {
  KEYCLOAK_CONFIGS: {
    'realm': REACT_APP_KEYCLOAK_REALM,
    'url': REACT_APP_KEYCLOAK_URL,

    'clientId': REACT_APP_KEYCLOAK_CLIENT_ID,
    'ssl-required': 'external',
    'verify-token-audience': true,
    'use-resource-role-mappings': true,
    'confidential-port': 0,
  },
  KEYCLOAK_INIT: {
    onLoad: 'login-required',
    promiseType: 'native',
    enableLogging: true,
    // flow: 'hybrid',
    checkLoginIframe: false,
  },
  DNS8_BACKEND: {
    url: REACT_APP_BACKEND_API,
  },
  DNS8_FRONTEND: {
    base_url: PUBLIC_URL ?? '',
  },
};

document.title = REACT_APP_SITE_TITLE;
