import React, {useEffect, useState} from 'react';
import {Switch, useTheme, withStyles} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faTrash} from '@fortawesome/free-solid-svg-icons';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import AddReportForm from '../Forms/AddReportForm';
import Table from '../../Components/Table';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';
import {ServiceRender} from '../Service';
import {useDeleteDataBackend, usePostDataBackend} from '../../ApiBackend';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import ReportsDownloader from '../../Pages/reports/ReportsDownloader';

export default function Reports({url}) {
  const [refreshing, setRefreshing] = useState(0);
  const [reportsWindow, setReportsWindow] = useState(false);

  const theme = useTheme();
  const padding = theme.spacing(3);

  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  useEffect(() => {
    if (url) {
      window.history.replaceState(null, null, url);
    }
  }, [url]);

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <ReportsDownloader
          open={reportsWindow}
          close={() => setReportsWindow(false)}
        />
        <AddReportForm sideEffect={() => setRefreshing((s) => s + 1)}/>
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: 29}}/>
      </Grid>

      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <ReportsTable
          title={
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}>
              <b>Reports</b>
              <RedIconButton onClick={() => setReportsWindow(true)}>
                <FontAwesomeIcon icon={faDownload}/>
              </RedIconButton>
            </div>}
          refresh={{refreshing}}
          forceRefresh={() => setRefreshing((s) => s + 1)}
        />
      </Grid>
      <Grid item xs={12} style={{height: padding, margin: 'auto'}}/>
    </Grid>
  );
}

function ReportsTable({title, refresh, forceRefresh = () => null}) {
  const [extra, setExtradata] = useState({size: 10});
  const [deleting, setDelete] = useState();
  const [updating, setUpdating] = useState();
  const [selectedService, services] = useSelector((state) => [
    state.selectedService,
    state.services,
  ]);
  const onClick = (doc) => () => {
    displayConfirmation(
        'By click Delete you will exclude the report. Are your sure?',
        'DELETE',
        'CANCEL',
        () => {
          setDelete(doc);
        },
    );
  };

  const DeleteReport = () => {
    const {loading, error} = useDeleteDataBackend('reports/', {
      ...deleting,
    });
    useEffect(() => {
      if (!loading && !error) {
        setDelete(false);
        forceRefresh();
      } else if (error) {
        setDelete(false);
      }
    }, [loading, error]);

    return <></>;
  };

  useEffect(() => {
    setExtradata((prev) => ({
      ...prev,
      clientname: selectedService,
      ...refresh,
    }));
  }, [selectedService, refresh]);

  const columns = [
    {
      title: <b>Service</b>,
      data: 'clientname',
      render: (el) => <ServiceRender hash={el}/>,
      hide:
        services.length <= 1 ||
        !(selectedService === 'all' || selectedService.length === 0),
    },
    {
      name: <b>Schedule & Type Report</b>,
      data: '',
      render: (_, row) =>
        `${
          row.time[0].toUpperCase() + row.time.slice(1)
        } ${row.reporttype[0].toUpperCase()}${row.reporttype.slice(1)}`,
    },
    {
      name: <b>Email</b>,
      data: 'email',
      render: (emails) =>
        (Array.isArray(emails) && emails.join(', ')) || emails,
    },
    {
      name: '',
      data: 'active',
      render: (el, row) => (
        <InactiveActiveSwitchState
          checked={el}
          sideEffect={() => setUpdating({...row, active: !row.active})}
        />
      ),
      styles: {
        width: 150,
      },
    },
    {
      name: '',
      data: '_id',
      render: (el, row) => (
        <RedIconButton onClick={onClick(row)}>
          <FontAwesomeIcon icon={faTrash}/>
        </RedIconButton>
      ),
      styles: {
        width: 75,
      },
    },
  ];
  return (
    <>
      <Table
        row_height={52}
        title={title}
        url="reports"
        columns={columns}
        extradata={extra}
        page_groups={[10, 50, 100]}
      />
      {deleting && <DeleteReport doc={deleting} sideEffect={forceRefresh}/>}
      {updating && (
        <UpdateReport {...updating} sideEffect={() => setUpdating(false)}/>
      )}
    </>
  );
}

const InactiveActiveSwitch = withStyles({
  switchBase: {
    'color': '#888888',
    '&$checked': {
      color: '#2C7847',
    },
    '&$checked + $track': {
      backgroundColor: '#2C7847',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#999999',
  },
})(Switch);

function InactiveActiveSwitchState({sideEffect = () => null, ...props}) {
  const [state, setState] = useState(props.checked || false);
  const onChange = (event) => {
    setState(event.target.checked);
    sideEffect();
  };

  return (
    <>
      <FormControlLabel
        onChange={onChange}
        control={<InactiveActiveSwitch {...props} checked={state}/>}
        label={state ? 'Active' : 'Inactive'}
      />
    </>
  );
}

function UpdateReport({negativeSideEffect, sideEffect, ...doc}) {
  console.log(doc);
  const {active} = doc;
  const {loading, error} = usePostDataBackend('reports/', {...doc});
  useEffect(() => {
    if (!loading && error) {
      const action = active ? 'activate' : 'disable';
      displayError(`Error to ${action} report:`, error);
      sideEffect();
    } else if (!loading) {
      sideEffect();
    }
    return () => false;
  }, [negativeSideEffect, sideEffect, loading, error, active]);

  return <></>;
}
