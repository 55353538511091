import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  FormControl,
  MenuItem,
  Table as TableMui,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  faEye,
  faPlus,
  faTrash,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import RedTextField from '../../core/components/inputs/RedTextField';

import ServiceTransferList from '../Inputs/ServiceTransferList';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import RedButton from '../../core/components/buttons/RedButton';
import {
  SearchableInput,
  StyledTableCell,
  StyledTableRow,
} from '../../Components/Table';
import BoxInfo from '../../Components/BoxInfo';
import {setPath} from '../../actions/pathAction';
import configs from '../../configs';
import {displayError, displaySuccess} from '../../ui-components/displayMsg';
import {deleteFetch, getFetch, postFetch, putFetch} from '../../ApiBackend';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function UsersManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Users Management'));
  }, [dispatch]);

  const [user, setUser] = useState('');

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <UsersPage setUser={setUser}/>
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <EventsPage defaultUser={user}/>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  width50: {
    width: '50%',
  },
}));

/**
 *
 * @param{number} count
 * @param{number} page
 * @param{number} rowsPerPage
 * @param{function} onChangePage
 * @return {JSX.Element}
 * @constructor
 */
export function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{display: 'inline-flex'}}>
      <RedIconButton
        TooltipProps={{title: 'First Page'}}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon/>
      </RedIconButton>
      <RedIconButton
        TooltipProps={{title: 'Previous Page'}}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft/>
      </RedIconButton>
      <RedIconButton
        TooltipProps={{title: 'Next Page'}}
        onClick={handleNextButtonClick}
        disabled={count !== -1 && page >= Math.ceil(count / rowsPerPage) -
          1}
        aria-label="next page"
      >
        <KeyboardArrowRight/>
      </RedIconButton>
      <RedIconButton
        TooltipProps={{title: 'Last Page'}}
        onClick={handleLastPageButtonClick}
        disabled={count === -1 || page >= Math.ceil(count / rowsPerPage) -
          1}
        aria-label="last page"
      >
        <LastPageIcon/>
      </RedIconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.any,
  onPageChange: PropTypes.func,
};

/**
 *
 * @return {{handleChangeRowsPerPage: function,
 * from: number,
 * handleChangePage: function,
 * rowsPerPage: number
 * }}
 */
function usePaginationUtils() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [from, setFrom] = useState(0);
  const [, setPage] = useState(Math.floor(from / rowsPerPage));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFrom(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return {
    handleChangeRowsPerPage,
    handleChangePage,
    from,
    rowsPerPage,
  };
}

/**
 *
 * @param{function} refreshing
 * @return {{deleting: boolean, setDeleting: function}}
 */
function useDeleteUser(refreshing) {
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    if (deleting) {
      deleteFetch('user/' + deleting.id).then((_) => {
        displaySuccess('Delete user', `${deleting.username} was deleted.`);
        setDeleting(false);
        refreshing();
      }).catch((_) => {
        displayError(
            `Error in Delete user`,
            [`${deleting.username} was not deleted.`],
        );
        setDeleting(false);
      });
    }
  }, [deleting]);
  return {
    deleting,
    setDeleting,
  };
}

/**
 *
 * @param{number} refresh
 * @param{string} searchEmail
 * @param{string} searchUsername
 * @param{number} from
 * @param{number} rowsPerPage
 * @return {{count: number, users: *[]}}
 */
function useUserList(refresh, searchEmail, searchUsername, from, rowsPerPage) {
  const [service] = useSelector((state) => [
    state.selectedService,
  ]);
  const [users, setUsers] = useState([]);

  const [count, setCount] = useState(0);


  useEffect(async () => {
    const filterUsers = (
        usersList,
        fromNumber,
        numberRowsPerPage,
        userSearchUsername,
        userSearchEmail,
    ) => {
      const finalUsers = usersList.filter(
          (u) =>
            !userSearchUsername || u.username.indexOf(userSearchUsername) !==
          -1,
      ).filter(
          (u) => !userSearchEmail || u.email.indexOf(userSearchEmail) !== -1,
      );

      setUsers(finalUsers.slice(fromNumber, fromNumber + numberRowsPerPage));
      setCount(finalUsers.length);
    };
    let url = 'user/';
    if (service.length && service !== 'all') {
      const groups = await getFetch('service/', {
        search: service,
      });
      const {id: groupId} = groups[0].subGroups.find((g) => g.name === service);
      url = 'service/' + groupId + '/members';
    }
    getFetch(url).then((data) =>
      filterUsers(data, from, rowsPerPage, searchUsername, searchEmail),
    );
  }, [service, from, rowsPerPage, searchUsername, searchEmail, refresh]);
  return {
    users,
    count,
  };
}

/**
 *
 * @param{function} setUser
 * @return {JSX.Element}
 * @constructor
 */
function UsersPage({setUser}) {
  const [updating, setUpdating] = useState(false);

  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [searchUsername, setSearchUsername] = useState('');
  const [searchEmail, setSearchEmail] = useState('');

  const refreshing = () => setRefresh((o) => o + 1);


  const {
    setDeleting,
  } = useDeleteUser(refreshing);


  const {
    handleChangeRowsPerPage,
    handleChangePage,
    from,
    rowsPerPage,
  } = usePaginationUtils();

  const {
    users,
    count,
  } = useUserList(refresh, searchEmail, searchUsername, from, rowsPerPage);


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length);


  return (
    <BoxInfo
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <b>Users</b>
          <RedIconButton
            TooltipProps={{title: 'Add new user'}}
            onClick={() => setUpdating(true)}
          >
            <FontAwesomeIcon icon={faPlus}/>
          </RedIconButton>
          <UpsertUser
            handleClose={() => setUpdating(false)}
            open={!!updating}
            user={updating}
            onCreation={refreshing}
          />
        </div>
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell/>
              <StyledTableCell className={classes.width50}>
                <SearchableInput
                  name={<b>Username</b>}
                  onKeyPress={({target: {value}, key}) =>
                    key === 'Enter' &&
                    setSearchUsername(value.toLowerCase())
                  }
                />
              </StyledTableCell>
              <StyledTableCell className={classes.width50}>
                <SearchableInput
                  name={<b>Email</b>}
                  onKeyPress={({target: {value}, key}) =>
                    key === 'Enter' && setSearchEmail(value.toLowerCase())
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <b>Roles</b>
              </StyledTableCell>
              <StyledTableCell>
                <b>OTP?</b>
              </StyledTableCell>
              <StyledTableCell colSpan={2}>
                <b>Actions</b>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {users.map((u) => (
              <StyledTableRow key={u.id}>
                <StyledTableCell>
                  <RedIconButton
                    TooltipProps={{title: 'See user events'}}
                    size="small"
                    onClick={() => setUser(
                        {id: u.id, username: u.username})}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{fontSize: 'inherit'}}
                    />
                  </RedIconButton>
                </StyledTableCell>
                <StyledTableCell>{u.username}</StyledTableCell>
                <StyledTableCell>{u.email}</StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'nowrap'}}>
                  {u.roles.join(' ,')}
                </StyledTableCell>
                <StyledTableCell>
                  {u.totp === false ? '-' : <FontAwesomeIcon
                    icon={faCheck}/>}
                </StyledTableCell>
                <StyledTableCell>
                  <RedIconButton
                    size="small"
                    TooltipProps={{title: 'Edit User'}}
                    onClick={() => setUpdating(u)}
                  >
                    <FontAwesomeIcon icon={faUserEdit}/>
                  </RedIconButton>
                </StyledTableCell>
                <StyledTableCell>
                  <RedIconButton
                    size="small"
                    TooltipProps={{title: 'Delete User'}}
                    onClick={() => setConfirmDelete(u)}
                  >
                    <FontAwesomeIcon icon={faTrash}/>
                  </RedIconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{height: 34 * emptyRows}}>
                <StyledTableCell colSpan={7}/>
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={7}
                count={count}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: {'aria-label': 'rows per page'},
                  native: true,
                }}
                page={Math.floor(from / rowsPerPage)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {confirmDelete && (
        <ConfirmAction
          action={`Delete ${confirmDelete.username}?`}
          question={`Do you really want to delete the user:
           ${confirmDelete.username} ?`}
          actionName="Delete"
          handleClose={() => setConfirmDelete(false)}
          handleAction={() =>
            setDeleting(confirmDelete) || setConfirmDelete(false)
          }
        />
      )}
    </BoxInfo>
  );
}

UsersPage.propTypes = {
  setUser: PropTypes.func,
};

/**
 *
 * @param{object|string} defaultUser
 * @return {JSX.Element}
 * @constructor
 */
function EventsPage({defaultUser = {}}) {
  const [user, setUser] = useState(defaultUser);
  const [events, setEvents] = useState([]);
  const [from, setFrom] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(Math.floor(from / rowsPerPage));

  useEffect(() => {
    setUser(defaultUser);
    setFrom(0);
    setPage(0);
  }, [defaultUser]);

  useEffect(() => {
    const payload = {
      max: rowsPerPage,
      first: from,
    };
    let url = 'user/events';
    if (user.id) {
      url = 'user/' + user.id + '/events';
    }

    getFetch(url, payload).then((data) => {
      setEvents(data);
    });
  }, [rowsPerPage, from, user]);

  const handleChangePage = (event, newPage) => {
    if (events.length === rowsPerPage || page > newPage) {
      setPage(newPage);
      setFrom(newPage * rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, events.length);

  return (
    <>
      <BoxInfo
        title={
          <>
            <b>{user.username ?
              `Events: ${user.username}` :
              'All Events'}</b>
            {user?.username && (
              <RedIconButton
                TooltipProps={{title: 'Remove user filter'}}
                onClick={() => setUser({})}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </RedIconButton>
            )}
          </>
        }
      >
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <b>At</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b>Username</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b>Type</b>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {events.map((e) => (
                <StyledTableRow key={e.time + e.type + e.details.username}>
                  <StyledTableCell>
                    {moment(e.time).format('DD-MM-YYYY HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell>{e.details.username ||
                    '-'}</StyledTableCell>
                  <StyledTableCell>{e.type}</StyledTableCell>
                </StyledTableRow>
              ))}
              {!!events.length && emptyRows > 0 && (
                <StyledTableRow style={{height: 28 * emptyRows}}>
                  <StyledTableCell colSpan={4}/>
                </StyledTableRow>
              )}
              {!events.length && emptyRows > 0 && (
                <StyledTableRow style={{height: 28 * emptyRows}}>
                  <StyledTableCell colSpan={4}>
                    <h3 style={{textAlign: 'center'}}>
                      No {page === 0 ? '' : 'more '}info.
                    </h3>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelDisplayedRows={() => ''}
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={4}
                  count={-1} // undefined count
                  rowsPerPage={rowsPerPage}
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                  page={Math.floor(from / rowsPerPage)}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </BoxInfo>
    </>
  );
}

EventsPage.propTypes = {
  defaultUser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
const redTextFieldProps = {
  fullWidth: true,
  select: true,
  size: 'small',
  variant: 'outlined',
  SelectProps: {
    multiple: true,
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    },
  },
};


/**
 *
 * @return {*[]}
 */
function useKeycloakServices() {
  const [allServices, setAllServices] = useState([]);
  useEffect(() => {
    getFetch('service').then((data) => {
      if (data[0]) {
        setAllServices(data[0].subGroups);
      }
    });
  }, []);

  return allServices;
}

/**
 *
 * @return {*[]}
 */
function useKeycloakRoles() {
  // get Roles Information
  const [allRoles, setAllRoles] = useState([]);
  useEffect(() => {
    getFetch('role').then((roles) => {
      const r = Array.isArray(roles) ? roles : [];
      setAllRoles(r);
    });
  }, []);
  return allRoles;
}

/**
 *
 * @param{string} userid
 * @param{array} allRoles
 * @param{object} upserting
 * @return {
 * {setOriginalRole: function,
 * role: *[],
 * setRole: function,
 * setServices: function,
 * originalServices: *[],
 * originalRole: *[],
 * services: *[],
 * setOriginalServices: function}
 * }
 */
function useUserInfo(userid, allRoles, upserting) {
  const [originalRole, setOriginalRole] = useState([]);
  const [role, setRole] = useState([]);
  const [originalServices, setOriginalServices] = useState([]);
  const [services, setServices] = useState([]);
  // get user groups information
  useEffect(() => {
    if (upserting) {
      return;
    }
    if (userid) {
      getFetch('user/' + userid + '/services')
          .then((data) => {
            setServices(data.map((d) => d.name));
            setOriginalServices(data.map((d) => d.name));
          });
    }
    if (!userid) {
      setServices([]);
      setOriginalServices([]);
    }
  }, [upserting, userid]);

  // get user roles information
  useEffect(() => {
    if (upserting) {
      return;
    }


    if (userid) {
      getFetch('user/' + userid + '/roles').then((roles) => {
        if (roles.length === 1) {
          const roleId = roles[0].id;
          setRole(roleId);
          setOriginalRole(roleId);
        }
      });
    }

    if (!userid) {
      setRole(allRoles.find((r) => r.name === 'user')?.id);
    }
  }, [upserting, userid, allRoles]);

  return {
    originalRole,
    setOriginalRole,
    role,
    setRole,
    originalServices,
    setOriginalServices,
    services,
    setServices,
  };
}

useUserInfo.propTypes = {
  userid: PropTypes.string,
  allRoles: PropTypes.array,
  clientId: PropTypes.string,
  upserting: PropTypes.object,
};

/**
 *
 * @param{object} user
 * @return {{setEmailAction: function, emailAction: string}}
 */
function useSendResetUser(user) {
  const [emailAction, setEmailAction] = useState('');
  useEffect(() => {
    let title;
    if (emailAction === 'UPDATE_PASSWORD') {
      title = 'Password Reset:';
    } else if (emailAction === 'CONFIGURE_TOTP') {
      title = `OTP Reset:`;
    }
    if (user.id && emailAction) {
      putFetch(`user/${user.id}/reset`,
          {
            clientId: configs.KEYCLOAK_CONFIGS.clientId,
            lifespan: 12 * 60 * 60,
            redirectUri: 'dns8.online' || document.location.host,
            action: [emailAction],
          },
      ).then((_) => {
        displaySuccess(title, `Mail was sent to ${user.username}`);
        setEmailAction('');
      }).catch((error) => {
        displayError(
            `Error in ${title}`,
            `Mail was not sent to ${user.username} (${error.response.status})`,
        );
        setEmailAction('');
      });
    }
  }, [user.id, user.username, emailAction]);
  return {emailAction, setEmailAction};
}

/**
 *
 * @param{function} handleClose
 * @param{function} onCreation
 * @param{boolean} o
 * @param{object|boolean} user
 * @return {JSX.Element}
 * @constructor
 */
function UpsertUser({handleClose, onCreation, open: o, user}) {
  const [open, setOpen] = useState(o);

  const [userid, setUserid] = useState(user.id || '');
  const [username, setUsername] = useState(user.username || '');
  const [email, setEmail] = useState(user.email || '');

  const [upserting, setUpserting] = useState(false);

  const [confirmResetOTP, setConfirmResetOTP] = useState(false);
  const [confirmResetPass, setConfirmResetPass] = useState(false);


  useEffect(() => {
    setOpen(o);
  }, [o]);

  useEffect(() => {
    if (open) {
      setUserid(user.id || '');
      setUsername(user.username || '');
      setEmail(user.email || '');
    }
  }, [user, open]);

  // get Keycloak Roles
  const allRoles = useKeycloakRoles();

  // get Services Information
  const allServices = useKeycloakServices();
  const {
    originalRole,
    setOriginalRole,
    role,
    setRole,
    originalServices,
    setOriginalServices,
    services,
    setServices,
  } = useUserInfo(userid, allRoles, upserting);

  useEffect(() => {
    if (!upserting?.updateUser) {
      return;
    }
    setUpserting((u) => ({...u, updateUser: false}));

    if (!userid) {
      postFetch('user', {
        email,
        username,
        enabled: true,
        attributes: {
          '@created': moment().format('DD-MM-YYYY HH:mm:ss'),
        },
      }).then((data) => {
        setUserid(data.id);
        setUpserting((o9) => {
          const {...newO9} = o9;
          delete newO9.updateUser;
          return newO9;
        });
      }).catch((error) => {
        displayError(
            `Error creating user`,
          error?.statusText || 'Unknown error.',
        );
        setUpserting(false);
      });
    } else {
      setUpserting((o7) => {
        const {...newO7} = o7;
        delete newO7.updateUser;
        return newO7;
      });
    }
  }, [upserting, userid, username, email]);

  // update delta from  roles
  useEffect(() => {
    if (!upserting?.updateRoles || !userid) {
      return;
    }
    setUpserting((o6) => ({...o6, updateRoles: false}));

    const removedRoles = [originalRole].filter((r) => r !== role);
    const addedRoles = [role].filter((r) => ![originalRole].includes(r));
    if (!removedRoles.length && !addedRoles.length) {
      setUpserting((o5) => {
        const {...newO5} = o5;
        delete newO5.updateRoles;
        return newO5;
      });
    }

    Promise.all([
      ...allRoles.filter((r) => addedRoles.includes(r.id)).map((r) =>
        postFetch('user/' + userid + '/role', [r]),
      ),
      ...allRoles.filter((r) => removedRoles.includes(r.id)).map((r) =>
        deleteFetch('user/' + userid + '/role', [r]),
      ),
    ]).then((_) => {
      setOriginalRole(role);
      setUpserting((o4) => {
        const {...newO4} = o4;
        delete newO4.updateRoles;
        return newO4;
      });
    }).catch((_) => {
      displayError(
          `Error setting roles`,
          `There was an error on adding services to roles.`,
      );
      setUpserting(false);
    });
  }, [upserting, userid, originalRole, role, allRoles]);

  useEffect(() => {
    if (!upserting?.updateServices || !userid) {
      return;
    }
    setUpserting((o2) => ({...o2, updateServices: false}));
    const removedServices = originalServices.filter(
        (r) => !services.includes(r),
    );
    const addedServices = services.filter((r) => !originalServices.includes(r));

    if (!removedServices.length && !addedServices.length) {
      setUpserting((o3) => {
        const {...newO3} = o3;
        delete newO3.updateServices;
        return newO3;
      });
    }
    Promise.all([
      ...allServices.filter((s) => addedServices.includes(s.name)).map((s) => {
        return putFetch('user/' + userid + '/service/' + s.id);
      }),
      ...allServices.filter((s) => removedServices.includes(s.name))
          .map((s) => {
            return deleteFetch('user/' + userid + '/service/' + s.id);
          }),
    ]).then(() => {
      setUpserting((o1) => {
        const {...newO1} = o1;
        delete newO1.updateServices;
        return newO1;
      });
      setOriginalServices([...services]);
    }).catch(() => {
      displayError(
          `Error setting services`,
          `There was an error on setting services to user.`,
      );
      setUpserting(false);
    });
  }, [upserting, userid, originalServices, services, allServices]);

  useEffect(() => {
    if (upserting && !Object.entries(upserting).length) {
      setUpserting(false);
      handleClose();
      if (!user.id) {
        onCreation();
      }
      displaySuccess(`Saving user`, `User info was saved`);
    }
  }, [upserting, handleClose, onCreation, user.id]);

  const {emailAction, setEmailAction} = useSendResetUser(user);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {user?.username && <b>Edit {user.username}</b>}
          {!user?.username && <b>New User</b>}
        </div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <RedTextField
                  {...redTextFieldProps}
                  select={false}
                  disabled={!!user.email}
                  value={email}
                  onChange={({target: {value}}) =>
                    setEmail(value.trim()) || setUsername(value.trim())
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Username</b>
              </TableCell>
              <TableCell>
                <RedTextField
                  {...redTextFieldProps}
                  select={false}
                  disabled={!!user.username}
                  value={username}
                  onChange={({target: {value}}) =>
                    setUsername(value.trim())
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Role</b>
              </TableCell>
              <TableCell>
                <RedTextField
                  {...redTextFieldProps}
                  SelectProps={{
                    ...redTextFieldProps.SelectProps,
                    multiple: false,
                  }}
                  value={role}
                  onChange={({target: {value}}) => setRole(value)}
                >
                  {allRoles.map(({id, name}) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </RedTextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <ServiceTransferList
                  currentServices={services}
                  all_services={allServices}
                  handleChange={(values) => setServices(values)}
                />
              </TableCell>
            </TableRow>
            {!!user?.id && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>Password</b>
                </TableCell>
                <TableCell>
                  <RedButton
                    variant="outlined"
                    type="button"
                    onClick={(_) => setConfirmResetPass(user.id)}
                    disabled={!!emailAction}
                  >
                    Reset Password
                  </RedButton>
                </TableCell>
              </TableRow>
            )}
            {!!user?.id && !!user?.totp && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>OTP</b>
                </TableCell>
                <TableCell>
                  <RedButton
                    variant="outlined"
                    type="button"
                    onClick={(_) => setConfirmResetOTP(user.id)}
                    disabled={!!emailAction}
                  >
                    Reset OTP
                  </RedButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <FormControl>
          <RedButton
            disabled={
              !email ||
              !username ||
              !role.length ||
              !services.length ||
              !!upserting
            }
            onClick={() =>
              setUpserting({
                updateUser: true,
                updateRoles: true,
                updateServices: true,
              })
            }
            variant="outlined"
          >
            Save
          </RedButton>
        </FormControl>
        <FormControl>
          <RedButton variant="outlined" color="secondary"
            onClick={handleClose}>
            Cancel
          </RedButton>
        </FormControl>
      </DialogActions>
      {confirmResetOTP && (
        <ConfirmAction
          action="Reset OTP User"
          question={`Do you really want to reset OTP of  ${user.username} ?`}
          actionName="Reset OTP"
          handleClose={() => setConfirmResetOTP(false)}
          handleAction={() =>
            setEmailAction('CONFIGURE_TOTP') ||
            setConfirmResetOTP(false)
          }
        />
      )}
      {confirmResetPass && (
        <ConfirmAction
          action="Reset Password User"
          question={`Do you really want to reset password of ${user.username}?`}
          actionName="Reset Password"
          handleClose={() => setConfirmResetPass(false)}
          handleAction={() =>
            setEmailAction('UPDATE_PASSWORD') ||
            setConfirmResetPass(false)
          }
        />
      )}
    </Dialog>
  );
}

UpsertUser.propTypes = {
  handleClose: PropTypes.func,
  onCreation: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

/**
 *
 * @param{string|JSX.Element} action
 * @param{string} question
 * @param{string|JSX.Element} actionName
 * @param{function} handleClose
 * @param{function} handleAction
 * @return {JSX.Element}
 * @constructor
 */
function ConfirmAction({
  action,
  question,
  actionName,
  handleClose,
  handleAction,
}) {
  return (
    <Dialog open={true}>
      <DialogTitle>{action}</DialogTitle>
      <DialogContent>{question}</DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <RedButton variant="outlined" onClick={handleAction}>
          {actionName}
        </RedButton>
        <RedButton variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </RedButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmAction.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  question: PropTypes.string,
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  handleClose: PropTypes.func,
  handleAction: PropTypes.func,
};
