import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import MUITooltip from '@material-ui/core/Tooltip';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {useSelector} from 'react-redux';

import {faChartArea} from '@fortawesome/free-solid-svg-icons/faChartArea';
import moment from 'moment';
import {useGetDataBackend} from '../../ApiBackend';
import BoxInfo from '../../Components/BoxInfo';
import {ServiceName} from '../Service';

export default function MaxQueriesPerSecondChart() {
  const [selectedService] = useSelector((state) => [state.selectedService]);
  const requestBody = {
    clientname: selectedService,
  };

  const [body, setBody] = useState(requestBody);
  useEffect(() => {
    let update = true;
    const newRequestBody = {
      clientname: selectedService,
    };
    if (update) {
      setBody(newRequestBody);
    }
    return () => {
      update = false;
    };
  }, [selectedService]);

  const {data, loading, error} = useGetDataBackend('requests/mqps', body);

  return (
    <BoxInfo
      title={
        <MUITooltip title="Not affected by time filter">
          <b>
            <FontAwesomeIcon icon={faChartArea} /> Max Requests per second -
                Last 7 days *
          </b>
        </MUITooltip>
      }
    >
      <div style={{display: 'flex', flex: 1, height: 315}}>
        {(loading || error) && (
          <div style={{display: 'flex', margin: 'auto'}}>
            <div style={{margin: 'auto', color: '#BE131A'}}>
              <FontAwesomeIcon icon={faSpinner} spin
                style={{fontSize: 40}} />
            </div>
          </div>
        )}

        {!(loading || error) && !data.mqps && (
          <div style={{display: 'flex', flex: 1, height: '100%'}}>
            <div
              style={{
                margin: 'auto',
                fontSize: 16,
                color: 'rgba(70, 70, 70, 0.6)',
              }}
            >
                  There are no Requests
            </div>
          </div>
        )}

        {!(loading || error) && data.mqps && <Chart data={data} />}
      </div>
    </BoxInfo>
  );
}

function Chart({data}) {
  const overtime = data.mqps.map((d) => {
    return {...d, time_format: moment(d.at).format('DD-MM')};
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          width: '80%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            padding: '10px 10px 0',
          }}
        />
        <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
          <div style={{width: '100%'}}>
            <ResponsiveContainer>
              <ScatterChart
                data={overtime || []}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  type="number"
                  dataKey="at"
                  domain={['auto', 'auto']}
                  tickCount={8}
                  ticks={[7, 6, 5, 4, 3, 2, 1, 0].map(
                      (d) =>
                        moment().
                            add(d * -1, 'days').
                            startOf('day').
                            format('x') * 1,
                  )}
                  tickFormatter={(x) => moment(x).format('DD-MM')}
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Scatter
                  name="mqps"
                  dataKey="events_per_second"
                  fill="rgb(24, 112, 180)"
                />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
}

function CustomTooltip({active, payload}) {
  if (active) {
    return (
      <div
        className="recharts-default-tooltip"
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: 'white',
          border: '1px solid rgb(204,204,204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="recharts-tooltip-label" style={{margin: 0}}>{`${moment(
            payload[0].value,
        ).format('DD-MM-YYYY HH:mm:ss')}`}</p>
        <ul
          className="recharts-tooltip-item-list"
          style={{padding: 0, margin: 0}}
        >
          <li
            className="recharts-tooltip-item"
            style={{
              display: 'block',
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <span className="recharts-tooltip-item-name">
              <ServiceName hash={payload[1].payload.client} /> -{' '}
              {payload[1].value} requests
            </span>
          </li>
        </ul>
      </div>
    );
  }
  return null;
}
