import {Button, withStyles} from '@material-ui/core';

const ButtonForm = withStyles({
  root: {
    'margin': '6px 3px',
    'height': 40,
    'background': '#464646',
    'borderColor': '#464646',
    'color': '#FFFFFF',
    '&.noUpper': {
      textTransform: 'inherit',
    },
    '&.Mui-disabled': {
      background: '#E4E4E4',
      borderColor: '#E4E4E4',
    },
    '&:hover': {
      background: '#BE131A',
      borderColor: '#BE131A',
    },
    '&.sec': {
      'background': 'white',
      'color': '#464646',
      '&:hover': {
        background: '#BE131A',
        color: '#FFFFFF !important',
      },
      '&:hover *': {
        color: '#FFFFFF !important',
      },
      '&.Mui-disabled': {
        'background': '#E4E4E4',
        'borderColor': '#E4E4E4',
        'color': '#FFFFFF',
        '& *': {
          color: '#FFFFFF !important',
        },
      },
    },
    '&.false-dga': {
      'background': 'white',
      'color': '#464646',
      'borderColor': '#EDAE30',
      '&:hover': {
        background: '#BE131A',
        borderColor: '#BE131A',
        color: '#FFFFFF !important',
      },
      '&:hover *': {
        color: '#FFFFFF !important',
      },
      '&.Mui-disabled': {
        'background': '#E4E4E4',
        'borderColor': '#E4E4E4',
        'color': '#FFFFFF',
        '& *': {
          color: '#FFFFFF !important',
        },
      },
    },
    '&.vt': {
      'background': 'white',
      'color': '#464646',
      'borderColor': '#1870B4',
      '&:hover': {
        background: '#BE131A',
        borderColor: '#BE131A',
        color: '#FFFFFF !important',
      },
      '&:hover *': {
        color: '#FFFFFF !important',
      },
      '&.Mui-disabled': {
        'background': '#E4E4E4',
        'borderColor': '#E4E4E4',
        'color': '#FFFFFF',
        '& *': {
          color: '#FFFFFF !important',
        },
      },
    },
  },
})(Button);

export default ButtonForm;
