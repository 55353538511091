import React, {useEffect} from 'react';

import {Card, CardContent, CardHeader, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import {useDispatch} from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {green, orange, red} from '@material-ui/core/colors';
import moment from 'moment';
import Spinner from '../../Components/Spinner';
import {useGetDataBackend} from '../../ApiBackend';
import BoxInfo from '../../Components/BoxInfo';
import {setPath} from '../../actions/pathAction';

export default function ESStatus() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('ES Status'));
  }, [dispatch]);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }));

  const {
    data: {es = []},
    loading,
    error,
  } = useGetDataBackend('monitoring/es');

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <BoxInfo title={<b>ES status</b>}>
          <div
            style={{
              margin: '1rem',
              display: 'grid',
              gridGap: '1rem',
              gridTemplateColumns: 'repeat(auto-fit,minmax(240px,1fr))',
            }}
          >
            <ESStatusCard
              color={(es.length && es[1]) || (loading && 'loading') ||
                  error}
            />
            <ESStatusCard color="loading" />
            <ESStatusCard color="green" />
            <ESStatusCard color="yellow" />
            <ESStatusCard color="red" />
            <ESStatusCard color="xpto" />
          </div>
        </BoxInfo>
      </Grid>
    </Grid>
  );
}

export function ESStatusCard({color, timestamp}) {
  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
    avatarLoading: {
      backgroundColor: 'transparent',
    },
    avatarTimes: {
      backgroundColor: red[500],
    },
    avatarCheck: {
      backgroundColor: green[500],
    },
    avatarDanger: {
      backgroundColor: orange[500],
    },
  }))();
  let subheader;
  let avatar;
  if (color === 'loading') {
    subheader = 'Loading...';
    avatar = (
      <Avatar className={classes.avatarLoading}>
        <Spinner />
      </Avatar>
    );
  } else if (color === 'red') {
    subheader = 'Requests cannot be done.';
    avatar = (
      <Avatar className={classes.avatarTimes}>
        <FontAwesomeIcon icon={faTimes} />
      </Avatar>
    );
  } else if (color === 'yellow') {
    subheader = 'Requests can be slow.';
    avatar = (
      <Avatar className={classes.avatarDanger}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </Avatar>
    );
  } else if (color === 'green') {
    subheader = 'OK';
    avatar = (
      <Avatar className={classes.avatarCheck}>
        <FontAwesomeIcon icon={faCheck} />
      </Avatar>
    );
  } else {
    subheader = 'Unknown...';
    avatar = <Avatar className={classes.avatarTimes}>?</Avatar>;
  }
  return (
    <Card variant="outlined">
      <CardHeader title="ElasticSearch" subheader={subheader}
        avatar={avatar} />
      <CardContent>
          @ {moment(timestamp).format('DD-MM-YYYY HH:mm:ss')}
      </CardContent>
    </Card>
  );
}
