import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
  MenuItem,
  Table as TableMui,
  TableBody,
  TableCell,
  TableRow, Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import Moment from 'react-moment';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import {faCertificate} from '@fortawesome/free-solid-svg-icons/faCertificate';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {RenderAction} from '../Action';
import {RenderDomainLink, RenderDomainWithList} from '../Domain';
import {ServiceRender} from '../Service';
import {RenderCategory} from '../Category';
import Table from '../../Components/Table';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';
import RedTextField from '../../core/components/inputs/RedTextField';

/**
 *
 * @param{[]} selected
 * @return {JSX.Element}
 * @constructor
 */
export function RenderCategoryValue(selected = []) {
  const small = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  if (selected.length === 0) {
    return <>
      <FontAwesomeIcon
        icon={faCertificate}
        style={{margin: '0 5px'}}
      />{' '}
      All categories
    </>;
  }
  return <div style={{display: 'flex', flexDirection: 'row'}}>
    {selected.map((value, i) => {
      const categoryKey = 'category-' + i;
      return (
        <RenderCategory
          key={categoryKey}
          list={value}
          withLabel={!small}
          style={{margin: '0 10px'}}
        />
      );
    })}
  </div>;
}

/**
 *
 * @param{array} cat
 * @param{function} handleChange
 * @return {JSX.Element}
 * @constructor
 */
function CategorySelection({cat, handleChange}) {
  return <RedTextField
    select={true}
    SelectProps={
      {
        displayEmpty: true,
        autoWidth: true,
        multiple: true,
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
        renderValue: RenderCategoryValue,
      }
    }
    value={cat}
    onChange={handleChange}
  >
    {[
      'adware',
      'anonymizers',
      'blacklist',
      'blacklist-root',
      'dga',
      'malware',
      'phishing',
      'reputation',
      'whitelist',
    ].map((list) => (
      <MenuItem key={list} value={list}>
        <RenderCategory list={list}/>
      </MenuItem>
    ))}
  </RedTextField>;
}

CategorySelection.propTypes = {
  cat: PropTypes.array,
  handleChange: PropTypes.func,
};
/**
 *
 * @param{string} title
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export default function PublicRequestsTable({title = 'Requests', ...props}) {
  const [timeRange, selectedService, services] = useSelector((state) => [
    state.time_range,
    state.selectedService,
    state.services,
  ]);

  const [data, setOpen] = useState(false);
  const small = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const columns = [
    {
      title: (
        <div style={{marginTop: 10}}>
          <b> Service </b>
        </div>
      ),
      data: 'clientname',
      render: function ClientCell(el) {
        return <ServiceRender hash={el}/>;
      },
      sortable: false,
      searchable: false,
      hide: small || selectedService !== 'all' || services.length <= 1,
    },
    {
      name: <b>Domain</b>,
      data: 'domain',
      sortable: true,
      searchable: true,
      render: function DomainCell(el, row) {
        let action;

        if (small) {
          action = row.action;
          action = action === 'PASSTHRU' ? action : action && 'blocked';
          action = <RenderAction action={action || ''} withLabel={false}/>;
        }
        return <>
          <RenderDomainWithList domain={[el]} list={row.list}
            action={action} motive={row.motive}/>
        </>;
      },
    },
    {
      name: <b>Requested</b>,
      data: '@timestamp',
      render: function RequestedCell(el) {
        return <Moment format="DD-MM-YYYY HH:mm:ss">{el}</Moment>;
      },
      sortable: true,
      searchable: false,
      styles: {
        width: 130,
      },
      hide: small,
    },
    {
      name: <b>Action</b>,
      options: [
        {label: <em>All</em>, value: ''},
        {label: <RenderAction action="blocked"/>, value: 'blocked'},
        {label: <RenderAction action="PASSTHRU"/>, value: 'allowed'},
      ],
      render: function ActionCell(el, row) {
        const action = el === 'PASSTHRU' ? el : el && 'blocked';
        const {
          motive,
        } = row;
        return <div style={{display: 'flex'}}>
          <RenderAction action={action}/>
          {row?.motive.every((e) => e) && <>
            &nbsp;
            <RenderDomainLink
              domain={motive[1]}
              label={` by ${motive[0]}`}
              tooltip={`More info: ${motive[1]}`}/>
          </>}
        </div>;
      },
      data: 'action',
      styles: {
        width: 150,
      },
      hide: small,
    },
    {
      title: '',
      sortable: false,
      searchable: false,
      data: 'doh_info',
      render: function IconCell(dInfo, row) {
        return dInfo && <Tooltip title={'Host: ' + dInfo['client.domain']}>
          <IconButton onClick={() => null} size="small">
            <FontAwesomeIcon icon={faUser}/>
          </IconButton>
        </Tooltip>;
      },
      styles: {
        width: 38,
      },
      hide: small,
    },
    {
      title: '',
      sortable: false,
      searchable: false,
      render: function IconCell(_, row) {
        return <Tooltip title={'More Request Info'}>
          <IconButton onClick={() => setOpen(row)} size="small">
            <FontAwesomeIcon icon={faInfoCircle}/>
          </IconButton>
        </Tooltip>;
      },
      styles: {
        width: 56,
      },
    },
  ];

  const [cat, setCat] = useState(
    props?.extradata?.list ? [props?.extradata?.list] : [],
  );


  const handleChange = (event) => {
    setCat(event.target.value);
  };

  const newTitle = <CategorySelection
    handleChange={handleChange}
    cat={cat}
  />;

  return (
    <>
      <Table
        columns={columns}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <b style={{margin: 'auto 0'}}>{title}</b> {newTitle}
          </div>
        }
        url="requests"
        extradata={{
          ...props?.extradata,
          t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
          clientname: selectedService,
          list: cat,
        }}
      />
      {data && <DetailRowDialog data={data} setOpen={setOpen}/>}
    </>
  );
}
PublicRequestsTable.propTypes = {
  title: PropTypes.string,
  extradata: PropTypes.object,
};

/**
 *
 * @param{object} data
 * @param{function} setOpen
 * @return {JSX.Element}
 * @constructor
 */
function DetailRowDialog({data, setOpen}) {
  const action =
    data.action === 'PASSTHRU' ? data.action : data.action && 'blocked';
  return (
    <Dialog open={!!data}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <RenderDomainLink domain={data.domain}/>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Service</b>
              </TableCell>
              <TableCell>
                <ServiceRender hash={data.clientname}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Action</b>
              </TableCell>
              <TableCell>
                <RenderAction action={action}/>
              </TableCell>
            </TableRow>
            {data.list && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>List</b>
                </TableCell>
                <TableCell>
                  <RenderCategory list={data.list}/>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Requested</b>
              </TableCell>
              <TableCell>
                {moment(data['@timestamp']).format('DD-MM-YYYY HH:mm:ss')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Answers</b>
              </TableCell>
              <TableCell>
                {data?.answers?.map((answer, i) => (
                  <p style={{textWrap: 'nowrap'}}
                    key={data.domain + 'answer' + i}>
                    <Tooltip title={'DNS Name'}>
                      <span>{answer.name}</span>
                    </Tooltip>&nbsp;
                    <Tooltip title={'DNS TTL'}>
                      <span>{answer.ttl}</span>
                    </Tooltip>&nbsp;
                    <Tooltip title={'DNS Class'}>
                      <span>{answer.class}</span>
                    </Tooltip>&nbsp;
                    <Tooltip title={'DNS Type'}>
                      <span>{answer.type}</span>
                    </Tooltip>&nbsp;
                    <Tooltip title={'DNS Data'}>
                      <span>{answer.data}</span>
                    </Tooltip></p>))}
                {data?.answers?.length === 0 && <p>-</p>}
              </TableCell>
            </TableRow>
            {data?.doh_info && (<>
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>Host</b>
                </TableCell>
                <TableCell>
                  {data.doh_info['client.domain']}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>Host IP</b>
                </TableCell>
                <TableCell>
                  {data.doh_info['client.nat.ip']}
                </TableCell>
              </TableRow>
            </>)}
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'start'}}>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}}/>
          Close
        </ButtonForm>
      </DialogActions>
    </Dialog>
  );
}

DetailRowDialog.propTypes = {
  data: PropTypes.object,
  setOpen: PropTypes.func,
};
