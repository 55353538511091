import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {useSelector} from 'react-redux';
import {faChartArea} from '@fortawesome/free-solid-svg-icons/faChartArea';
import {Tooltip as MouseTooltip, useMediaQuery} from '@material-ui/core';
import moment from 'moment';
import {colorMap, RenderCategory} from '../Category';
import {useGetDataBackend} from '../../ApiBackend';
import BoxInfo from '../../Components/BoxInfo';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';

export default function AllCategoriesChart({domain}) {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };
  if (domain) {
    requestBody.domain = domain;
  }
  const [body, setBody] = useState(requestBody);
  useEffect(() => {
    let update = true;
    const newRequestBody = {
      t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
      clientname: selectedService,
    };
    if (domain) {
      newRequestBody.domain = domain;
    }
    if (update) {
      setBody(newRequestBody);
    }
    return () => {
      update = false;
    };
  }, [timeRange, selectedService, domain]);

  const {data, loading, error} = useGetDataBackend(
      'requests/histogram_cats',
      body,
  );
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon icon={faChartArea} /> Categories Overtime
        </b>
      }
    >
      <div style={{display: 'flex', flex: 1, height: 315}}>
        {(loading || error) && (
          <div style={{display: 'flex', margin: 'auto'}}>
            <div style={{margin: 'auto', color: '#BE131A'}}>
              <FontAwesomeIcon icon={faSpinner} spin
                style={{fontSize: 40}} />
            </div>
          </div>
        )}

        {!(loading || error) &&
          (!data.overtime || !data.overtime.some((b) => b.total)) && (
          <div style={{display: 'flex', flex: 1, height: '100%'}}>
            <div
              style={{
                margin: 'auto',
                fontSize: 16,
                color: 'rgba(70, 70, 70, 0.6)',
              }}
            >
                  There are no Requests
            </div>
          </div>
        )}

        {!(loading || error) &&
          data.overtime &&
          data.overtime.some((b) => b.total) && <Chart data={data} />}
      </div>
    </BoxInfo>
  );
}

function Chart({data}) {
  const newData = {...data};
  newData.overtime = newData.overtime.map((d) => {
    return {...d, time_format: moment(d.time).format(d.time_format)};
  });
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [hideCats, setHideCats] = useState({
    adware: Object.entries(newData.count.requests).length !== 1,
  });

  function formatter(value, name) {
    return [
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
        }}
      >
        <RenderCategory list={name} /> {value}{' '}
      </div>,
      '',
    ];
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '80%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isXsmall ? 'center' : 'start',
          width: '100%',
          flexWrap: 'wrap',
          // padding: is_xsmall?"10px 10px":"10px 130px 0 60px",
          padding: '10px 10px',
        }}
      >
        {!isXsmall && <span>Requests:</span>}{' '}
        {Object.entries(newData.count.requests).map(([list, hits], i) => {
          const style = hideCats[list] ? {color: 'gray'} : {};
          const tooltipKey = `cats-${i}`;
          return (
            <MouseTooltip
              key={tooltipKey}
              title={`${
                        hideCats[list] ? 'Click to show' : 'Click to hide'
              } in chart`}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                style={{
                  display: 'inline-flex',
                  cursor: 'pointer',
                  margin: '0 5px',
                }}
                onClick={() =>
                  setHideCats((prev) => {
                    return {...prev, [list]: !prev[list]};
                  })
                }
              >
                <RenderCategory list={list} style={style} />
                {hits}
              </div>
            </MouseTooltip>
          );
        })}
      </div>
      <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
        <div style={{width: isXsmall ? '100%' : '75%', flex: 1}}>
          <ResponsiveContainer>
            <AreaChart
              data={newData.overtime || []}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <defs>
                {Object.entries(colorMap).map(([list, {color}]) => (
                  <linearGradient
                    key={list + color}
                    id={`color${list + color}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color} stopOpacity={0} />
                  </linearGradient>
                ))}
              </defs>
              <XAxis dataKey="time_format" />
              <YAxis />
              <Tooltip filterNull separator="" formatter={formatter} />
              {Object.keys(newData.count.requests).map((list) => {
                let [l] = list.split('-');
                if (list === 'blacklist-root') {
                  l = list;
                }
                const {color} = colorMap[l];
                return (
                  !hideCats[l] && (
                    <Area
                      stackId="1"
                      key={list}
                      type="monotone"
                      name={l}
                      stroke={color}
                      dataKey={list}
                      fill={`url(#color${l + color})`}
                    />
                  )
                );
              })}
            </AreaChart>
          </ResponsiveContainer>
        </div>
        {!isXsmall && (
          <div style={{width: '110px', margin: '0 10px'}}>
                Unique Domains
            {Object.entries(newData.count.domains).
                map(([list, hits], i) => {
                  const categoryKey = `category-${i}`;
                  return (
                    <div
                      key={categoryKey}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <RenderCategory list={list} withLabel={false} />
                      {hits}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
}
