import {Link, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faPlus} from '@fortawesome/free-solid-svg-icons';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import punycode from 'punycode/punycode';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import BoxInfo from '../../Components/BoxInfo';
import {RenderCategory} from '../Category';
import {
  displayConfirmation,
  displayError,
} from '../../ui-components/displayMsg';
import {usePutDataBackend} from '../../ApiBackend';
import RedTextField from '../../core/components/inputs/RedTextField';

const Quickbar = withRouter((props) => {
  const classes = makeStyles((theme) => ({
    root: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      background: 'none',
      border: 'none',
      marginLeft: -10,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      minWidth: 50,
      minHeight: 50,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }))();
  const [domain, setDomain] = useState('');
  const [comment, setComment] = useState('');

  const [subDomain, setSubDomain] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [list, setList] = useState();

  const [service] = useSelector((state) => [state.selectedService]);
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const onClick = (l) => (e) => {
    e.preventDefault();

    if (domain && l) {
      setList(l);
      displayConfirmation(
          <>
            Do you really want to add the domain {domain} in{' '}
            {
              <RenderCategory
                style={{display: 'inline-flex', lineHeight: '14px'}}
                list={l}
              />
            }
            ?
            <RedTextField
              autoFocus={true}
              style={{marginTop: 20}}
              placeholder="Please insert here a motive for this decision."
              label="Insert a motive for this decision."
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              onBlur={({target: {value}}) => setComment(value)}
            />
          </>,
          'ADD',
          'CANCEL',
          () => setSubmitting(true),
      );
    }
  };

  return (
    <BoxInfo
      title={
        <Paper variant="outlined" className={classes.root}>
          <RedIconButton
            disabled={!domain}
            className={classes.iconButton}
            aria-label="domain info"
            TooltipProps={{title: 'Domain info'}}
          >
            <Link
              to={`domains/${domain}`}
              style={{color: 'inherit', fontSize: '1.5rem'}}
            >
              <div className="fa-layers">
                <FontAwesomeIcon
                  icon={faEye}
                  transform="shrink-2 top-2 left-2"
                />
                <FontAwesomeIcon
                  icon={faPlus}
                  color="black"
                  transform="shrink-6 right-4 down-4"
                />
              </div>
            </Link>
          </RedIconButton>
          <form
            onSubmit={() => domain &&
                      !props.history.push(`/domains/${domain}`)}
          >
            <InputBase
              autoFocus
              className={classes.input}
              placeholder="Domain"
              inputProps={{'aria-label': 'search domain'}}
              onChange={({target: {value}}) => setDomain(value)}
              defaultValue={domain}
            />
          </form>
          {!isXsmall && (
            <>
              <Divider
                className={classes.divider}
                orientation="vertical"
                style={{marginLeft: 'auto'}}
              />
              <FormControlLabel
                color="default"
                style={{marginRight: 0}}
                control={
                  <Checkbox
                    checked={subDomain}
                    color="default"
                    onChange={({target: {checked}}) =>
                      setSubDomain(checked)
                    }
                  />
                }
                label="Sub domains"
                labelPlacement="start"
              />

              <Divider className={classes.divider}
                orientation="vertical" />

              <RedIconButton
                onClick={onClick('whitelist')}
                TooltipProps={{title: 'Add to whitelist'}}
              >
                <RenderCategory list="whitelist" withLabel={false} />
              </RedIconButton>
              <Divider className={classes.divider}
                orientation="vertical" />

              <RedIconButton
                onClick={onClick('blacklist')}
                TooltipProps={{title: 'Add to whitelist'}}
              >
                <RenderCategory list="blacklist" withLabel={false} />
              </RedIconButton>
            </>
          )}
        </Paper>
      }
    >
      {submitting && (
        <AddDomain
          {...{
            domain,
            service,
            list,
            sub_domain: subDomain,
            setSubmitting,
            comment,
          }}
        />
      )}
    </BoxInfo>
  );
});

function AddDomain({setSubmitting, list, ...props}) {
  const domain = punycode.toASCII(props.domain);
  const {loading, error} = usePutDataBackend(`list/${list}`, {
    ...props,
    domain,
  });
  console.log(loading, error);

  useEffect(() => {
    if (!loading && !error) {
      setSubmitting(false);
    } else if (error) {
      displayError('Error adding domain:', error);
      setSubmitting(false);
    }
  }, [loading, error, setSubmitting]);

  return <></>;
}

export default Quickbar;
