import React, {useEffect, useRef, useState} from 'react';
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import JoditEditor from 'jodit-react';
import {faEdit, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';
import BoxInfo from '../../Components/BoxInfo';
import {useGetDataBackend, usePostDataBackend} from '../../ApiBackend';
import {displayError} from '../../ui-components/displayMsg';
import Spinner from '../../Components/Spinner';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import configs from '../../configs';

export default function BlockedPageInfo({service: s}) {
  const [service, setService] = useState(s);
  const [editMode, setEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pips, setPips] = useState('');
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [originalPips, setOriginalPips] = useState('');
  const [saving, setSaving] = useState(false);
  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const token = keycloak.tokenParsed;

  const roles =
      token.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];

  const showBlockedPageEditor = !(roles.includes('user') || operator.personify);

  const {
    data,
    loading,
    error,
  } = useGetDataBackend(`services/block-page/${service}`, {refresh});

  useEffect(() => {
    setService(s);
  }, [s]);

  useEffect(() => {
    if (data.blockpage) {
      setPips(data.blockpage.pips.join(', '));
      setOriginalPips(data.blockpage.pips.join(', '));
      setContent(data.blockpage.content);
      setOriginalContent(data.blockpage.content);
    }
  }, [data]);
  return (
    <BoxInfo
      title={
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {/* <b style={{margin: "auto 0"}}>Block Page Customization</b> */}
          <b style={{margin: 'auto 0'}}>Public Ips</b>
          {!editMode && (
            <RedIconButton
              TooltipProps={{title: 'Edit'}}
              onClick={() => setEditMode(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </RedIconButton>
          )}
          {editMode && (
            <span>
              <RedIconButton
                TooltipProps={{title: 'Save'}}
                onClick={() => setSaving(true)}
                disabled={saving}
              >
                <FontAwesomeIcon icon={faSave} />
              </RedIconButton>
              <RedIconButton
                TooltipProps={{title: 'Cancel'}}
                onClick={() =>
                  setContent(originalContent) ||
                      setPips(originalPips) ||
                      setEditMode(false)
                }
                disabled={saving}
              >
                <FontAwesomeIcon icon={faTimes} />
              </RedIconButton>
            </span>
          )}
        </div>
      }
    >
      <Table>
        <TableBody>
          {showBlockedPageEditor && (
            <TableRow>
              <TableCell>
                {loading && <Spinner />}
                {error && 'Information not available.'}

                {!loading && !error && (
                  <Editor
                    content={content}
                    readonly={!editMode}
                    setContent={setContent}
                  />
                )}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              {loading && <Spinner />}
              {error && 'Information not available.'}

              {!loading && !error && !editMode && `Public ips: ${pips}`}
              {!loading && !error && editMode && (
                <TextField
                  value={pips}
                  onChange={({target: {value}}) => setPips(value)}
                  fullWidth={true}
                  multiline
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                                Public ips:
                      </InputAdornment>
                    ),
                  }}
                  label={<>&nbsp;</>}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {saving && (
        <SavingBlockPage
          {...{
            pips,
            content,
            service,
            handleClose: (_) => setEditMode(false),
            setSaving,
            setRefresh,
          }}
        />
      )}
    </BoxInfo>
  );
}

function Editor({content, setContent = () => false, readonly = false}) {
  const jsEditor = useRef(null);

  let config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    buttons:
        'source,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,table,link,|,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
    extraButtons: [
      {
        name: 'Service',
        exec: (editor) =>
          editor.s.insertHTML(
              '<span class=\'request-service\'>{SERVICENAME}</span>',
          ),
      },
      {
        name: 'Domain',
        exec: (editor) =>
          editor.s.insertHTML(
              '<span class=\'request-domain\'>{DOMAIN}</span>'),
      },
      {
        name: 'Category',
        exec: (editor) =>
          editor.s.insertHTML(
              '<span class=\'request-cat\'>{CATEGORY}</span>'),
      },
    ],
  };
  if (readonly) {
    config = {
      // sourceEditorCDNUrlsJS: [
      //     './js/ace/ace.min.js'
      // ],
      // beautifyHTMLCDNUrlsJS: [
      //     './js/js-beautify/beautify.min.js',
      //     './js/js-beautify/beautify-html.min.js'
      // ],
      toolbar: false,
      readonly: true,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
    };
  }

  const onBlur = (event) => {
    const editorValue = event.target.innerHTML;
    setContent(editorValue);
  };

  return (
    <JoditEditor
      onBlur={onBlur}
      ref={jsEditor}
      value={content}
      config={config}
      tabIndex={0} // tabIndex of textarea
      // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(_) => false}
    />
  );
}

function SavingBlockPage({
  pips,
  content,
  service,
  setSaving,
  handleClose = () => false,
  setRefresh,
}) {
  const {loading, error} = usePostDataBackend('services/blockpage', {
    pips,
    content,
    service,
  });

  useEffect(() => {
    if (!loading) {
      setSaving(false);
    }
    if (!loading && !error) {
      handleClose();
      setRefresh((o) => o + 1);
    }
    if (error) {
      displayError(`Error to customize block page:`, error.msg);
    }
  }, [loading, error, handleClose, setSaving, setRefresh]);

  return <></>;
}
