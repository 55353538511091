import React, {useEffect, useState} from 'react';
import {
  faComment,
  faPencilAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {faComment as faNoComment} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import RedIconButton from '../core/components/buttons/RedIconButton';
import RedButton from '../core/components/buttons/RedButton';
import RedTextField from '../core/components/inputs/RedTextField';
import {useSelector} from 'react-redux';
import {
  deleteFetch,
  postFetch,
  putFetch,
  useGetDataBackend,
} from '../ApiBackend';
import {displayError} from '../ui-components/displayMsg';
import {RenderCategory} from './Category';

CommentsButtonList.propTypes = {
  _id: PropTypes.string,
  usercomment: PropTypes.string,
  domain: PropTypes.string,
  list: PropTypes.string,
};
/**
 *
 * @param{string} domainId
 * @param{string} userComment
 * @param{string} domain
 * @param{string} list
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export default function CommentsButtonList({
  _id: domainId,
  usercomment: userComment,
  domain,
  list,
}) {
  const [lastComment, setLastComment] = useState(userComment || '');

  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  return (
    <>
      <RedIconButton
        onClick={() => setOpen(true)}
        size="small"
        TooltipProps={{
          title: <>{lastComment || 'Click to add new comments.'}</>,
        }}
      >
        <FontAwesomeIcon
          icon={lastComment ? faComment : faNoComment}
          color="inherit"
        />
      </RedIconButton>
      <Dialog open={open} onClose={() => setOpen(false)} scroll="paper">
        <DialogTitle>
          <b>{domain}</b>&apos;s Comments
          in <RenderCategory list={list} style={{display: 'inline-flex'}}/>
        </DialogTitle>
        <DialogContent>
          <List dense={true}>
            <NewListItemComment
              list={list}
              domainId={domainId}
              onFinishSave={() => setRefresh((r) => r + 1)}/>
            <CommentsList
              list={list}
              domainId={domainId}
              onChangeFirst={(newFirstComment) =>
                setLastComment(newFirstComment)}
              refresh={refresh}
            />
          </List>
        </DialogContent>
        <DialogActions>
          <RedButton onClick={() => setOpen(false)}>Close</RedButton>
        </DialogActions>
      </Dialog>
    </>
  );
}


/**
 *
 * @param{string} domainId
 * @param{function} onChangeFirst
 * @param{number} refresh
 * @param{string} list
 * @return {JSX.Element}
 * @constructor
 */
function CommentsList({domainId, onChangeFirst, refresh, list}) {
  const [comments, setComments] = useState([]);
  const result = useGetDataBackend(
      `list/${list}/${encodeURI(domainId)}/comments`,
      {refresh});
  const {data, loading, error} = result;

  useEffect(() => {
    if (!loading && !error) {
      setComments(data['comments']);
    }
  }, [data]);

  useEffect(() => {
    onChangeFirst(comments?.[0]?._source?.description);
  }, [comments, onChangeFirst, refresh]);

  /**
   *
   * @param{object} deletedComment
   */
  function onDelete(deletedComment) {
    setComments((currentComments) =>
      [...currentComments].filter((currentComment) => {
        const [{_id: currentId}, {_id: deletedId}] = [
          currentComment,
          deletedComment,
        ];
        return currentId !== deletedId;
      }),
    );
  }

  /**
   *
   * @param{object} editableComment
   */
  function onEdit(editableComment) {
    setComments((currentComments) => {
      return [...currentComments].map((currentComment) => {
        const [{_id: currentId}, {_id: deletedId}] = [
          currentComment,
          editableComment,
        ];
        return currentId === deletedId ? editableComment : currentComment;
      });
    });
  }

  return (
    <>
      {!!comments.length &&
        comments.map((comment, i) => {
          const {_id, _source: commentInfo} = comment;
          return (
            <React.Fragment key={_id}>
              <Divider/>
              <ListItemComment
                onDelete={onDelete}
                onEdit={onEdit}
                first={i === 0}
                id={_id}
                list={list}
                domainId={domainId}
                comment={comment}
                {...commentInfo}
              />
            </React.Fragment>
          );
        })}
    </>
  );
}

CommentsList.propTypes = {
  domainId: PropTypes.string,
  onChangeFirst: PropTypes.func,
  refresh: PropTypes.number,
  list: PropTypes.string,
};

/**
 *
 * @param{function} onFinishSave
 * @param{string} domainId
 * @param{string} list
 * @return {JSX.Element}
 * @constructor
 */
function NewListItemComment({onFinishSave, domainId, list}) {
  const {tokenParsed: {email}} = useSelector((state) => state.keycloak);
  const [newComment, setNewComment] = useState('');
  const [addedComment, setAddedComment] = useState('');

  useEffect(() => {
    if (addedComment) {
      postFetch(`list/${list}/${encodeURI(domainId)}/comment`, {
        'comment': addedComment,
        '@timestamp': moment().format('x'),
      }).then(() => {
        onFinishSave(addedComment);
        setAddedComment('');
        setNewComment('');
      }).catch((error) => {
        error.text().then((m) => displayError('Error adding comment', m));
        setAddedComment('');
      });
    }
  }, [addedComment, domainId]);

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FontAwesomeIcon icon={faUser}/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={email}
          secondary={'now'}
        />
      </ListItem>
      <ListItem>
        <RedTextField
          disabled={!!addedComment}
          placeholder="Please insert a new comment."
          label="Insert a new comment."
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={newComment}
          onChange={({target: {value}}) => setNewComment(value)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          style={{textAlign: 'right'}}
          secondary={
            <RedButton variant="contained" size="small"
              disabled={!!addedComment}
              onClick={() => {
                setAddedComment(newComment);
              }}
              aria-label="comments">
              Save
            </RedButton>
          }
        />
      </ListItem>
    </>
  );
}

NewListItemComment.propTypes = {
  onFinishSave: PropTypes.func,
  domainId: PropTypes.string,
  list: PropTypes.string,
};

/**
 *
 * @param{object} props
 * @param{string} props.username
 * @return {JSX.Element}
 * @constructor
 */
function ListItemComment(props) {
  const {username} = props;
  const [keycloak] = useSelector((state) => [
    state.keycloak,
  ]);
  const {preferred_username: preferredUsername} = keycloak.tokenParsed;
  if (username === preferredUsername) {
    return <EditableListItemComment {...props} />;
  }
  return <NonEditableListItemComment {...props} />;
}

ListItemComment.propTypes = {
  username: PropTypes.string,
};

/**
 *
 * @param{string} username
 * @param{string} description
 * @param{string| number} ts
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function NonEditableListItemComment({
  username,
  description,
  '@timestamp': ts,
  ...props
}) {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar> <FontAwesomeIcon icon={faUser}/> </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={username}
          secondary={moment(ts, 'x').format('DD-MM-YYYY HH:mm:ss')}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          secondary={description}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          style={{textAlign: 'right', visibility: 'hidden'}}
          secondary={
            <RedButton size="small" aria-label="">
              &nbsp;
            </RedButton>
          }
        />
      </ListItem>
    </>
  );
}

NonEditableListItemComment.propTypes = {
  'username': PropTypes.string,
  'description': PropTypes.string,
  '@timestamp': PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

/**
 *
 * @param{string} username
 * @param{string} description
 * @param{number|string} ts
 * @param{string} id
 * @param{string} list
 * @param{string} domainId
 * @param{function} onEdit
 * @param{function} onDelete
 * @param{object} comment
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function EditableListItemComment({
  username,
  description,
  '@timestamp': ts,
  id,
  list,
  domainId,
  onEdit,
  onDelete,
  comment,
  ...props
}) {
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editableComment, setEditableComment] = useState(description);
  const [deletedComment, setDeletedComment] = useState(false);
  const [editedComment, setEditedComment] = useState(false);

  useEffect(() => {
    if (deletedComment) {
      deleteFetch(`list/${list}/${encodeURI(domainId)}/comment/${id}`, {
        id,
        ts: moment().format('x'),
      }).then(() => {
        onDelete(comment);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [deletedComment]);

  useEffect(() => {
    if (editedComment) {
      putFetch(`list/${list}/${encodeURI(domainId)}/comment/${id}`, {
        id,
        editedComment,
        ts: moment().format('x'),
      }).then(() => {
        setEditedComment(false);
        onEdit({
          _id: comment._id,
          _source: {
            ...comment._source,
            description: editableComment,
          },
        });
        setShowEditConfirmation(false);
      }).catch(() => {
        setEditedComment(false);
      });
    } else {
      setEditableComment(description);
    }
  }, [editedComment, description, comment]);


  const ComponentEditConfirmation = (
    <>
      <RedButton
        disabled={!!editedComment}
        onClick={() => {
          setShowEditConfirmation(false);
          setEditableComment(description);
        }}
        size="small"
        style={{float: 'right'}}
        aria-label="edit-cancel"
      >
        Cancel
      </RedButton>
      <RedButton
        disabled={!!editedComment}
        onClick={() => setEditedComment(editableComment)}
        size="small"
        variant="contained"
        style={{float: 'right'}}
        aria-label="save-comment"
      >
        Save
      </RedButton>
    </>
  );

  const ComponentDeleteConfirmation = (
    <>
      Are you sure? &nbsp;
      <RedButton
        disabled={!!deletedComment}
        onClick={() => {
          setDeletedComment(true);
        }}
        size="small"
        variant="contained"
        aria-label="yes-delete-comment"
      >
        Delete
      </RedButton>
      <RedButton
        disabled={!!deletedComment}
        onClick={() => setShowDeleteConfirmation(false)}
        size="small"
        aria-label="delete-cancel"
      >
        Cancel
      </RedButton>
    </>
  );

  const DeleteButton = (
    <>
      <RedButton
        onClick={() => setShowDeleteConfirmation(true)}
        size="small"
        aria-label="delete-comment"
      >
        Delete
      </RedButton>
    </>
  );

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FontAwesomeIcon icon={faUser}/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={username}
          secondary={moment(ts, 'x').format('DD-MM-YYYY HH:mm:ss')}
        />
        <ListItemSecondaryAction>
          <RedIconButton
            disabled={showEditConfirmation || showDeleteConfirmation}
            onClick={() => setShowEditConfirmation(true)}
            TooltipProps={{title: 'Edit comment'}}
          >
            <FontAwesomeIcon icon={faPencilAlt}/>
          </RedIconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        {showEditConfirmation && (
          <RedTextField
            placeholder="Please insert here a motive for this decision."
            label="Insert a motive for this decision."
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={editableComment}
            onChange={({target: {value}}) => setEditableComment(value)}
            onBlur={({target: {value}}) => setEditableComment(value)}
          />
        )}
        {!showEditConfirmation && (
          <ListItemText style={{whiteSpace: 'pre-wrap'}}
            secondary={editableComment}
          />
        )}
      </ListItem>
      <ListItem>
        {!showEditConfirmation && (
          <ListItemText
            style={{textAlign: 'right'}}
            secondary={
              showDeleteConfirmation ?
                ComponentDeleteConfirmation :
                DeleteButton
            }
          />
        )}
        {showEditConfirmation && (
          <ListItemText
            style={{textAlign: 'right'}}
            secondary={ComponentEditConfirmation}
          />
        )}
      </ListItem>
    </>
  );
}

EditableListItemComment.propTypes = {
  'username': PropTypes.string,
  'description': PropTypes.string,
  '@timestamp': PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  'id': PropTypes.string,
  'list': PropTypes.string,
  'domainId': PropTypes.string,
  'onEdit': PropTypes.func,
  'onDelete': PropTypes.func,
  'comment': PropTypes.object,
};
