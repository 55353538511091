import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Moment from 'react-moment';
import {
  MenuItem,
  Table as TableMui,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons/faAsterisk';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';
import {RenderCategory} from '../Category';
import {RenderDomainLink, RenderDomainWithList} from '../Domain';
import {RenderAction} from '../Action';
import {ServiceRender} from '../Service';
import Table from '../../Components/Table';
import RedTextField from '../../core/components/inputs/RedTextField';
import {RenderCategoryValue} from './PublicRequestsTable';
import {useGetDataBackend} from '../../ApiBackend';

/**
 *
 * @param{array} cat
 * @param{function} handleChange
 * @param{boolean} withLabel
 * @return {JSX.Element}
 * @constructor
 */
function CategorySelection({cat, handleChange, withLabel}) {
  return <RedTextField
    select={true}
    SelectProps={
      {
        displayEmpty: true,
        autoWidth: true,
        multiple: true,
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
        renderValue: RenderCategoryValue,
      }
    }
    value={cat}
    onChange={handleChange}
  >
    {[
      'adware',
      'anonymizers',
      'blacklist',
      'dga',
      'malware',
      'phishing',
      'reputation',
    ].map((list) => (
      <MenuItem key={list} value={list}>
        <RenderCategory list={list}/>
      </MenuItem>
    ))}
  </RedTextField>;
}

CategorySelection.propTypes = {
  cat: PropTypes.array,
  handleChange: PropTypes.func,
  withLabel: PropTypes.bool,
};
/**
 *
 * @param{object} extradata
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export default function InternalRequestsTable({extradata = {}, ...props}) {
  const [timeRange, selectedService, services] = useSelector((state) => [
    state.time_range,
    state.selectedService,
    state.services,
  ]);
  const [data, setOpen] = useState(false);
  const [slaves, setSlaves] = useState([]);
  const xs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const small = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const medium = useMediaQuery((theme) => theme.breakpoints.only('md'));

  const {
    data: data2,
  } = useGetDataBackend('internal_requests/slaves', {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  });
  useEffect(() => {
    Array.isArray(data2?.slaves) && setSlaves(data2?.slaves);
    !Array.isArray(data2?.slaves) && setSlaves([]);
  }, [data2]);

  const columns = [
    {
      title: (
        <div style={{marginTop: 10}}>
          <b> Service </b>
        </div>
      ),
      data: 'clientname',
      render: function ClientCell(el) {
        return <ServiceRender hash={el}/>;
      },
      hide: xs || small || selectedService !== 'all' || services.length <= 1,
    },
    {
      name: <b>Domain</b>,
      data: 'domain',
      sortable: true,
      searchable: true,
      render: function DomainCell(el, row) {
        return (
          <RenderDomainWithList domain={[el.slice(0, -1)]} list={row.list}/>
        );
      },
    },
    {
      name: <b>DNS Server Host</b>,
      data: 'hostname',
      options: [
        {label: <em>All</em>, value: ''},
        ...slaves.map((d) => ({label: d, value: d})),
      ],
      sortable: true,
      searchable: true,
      styles: {
        width: 180,
      },
      hide: xs || small || medium,
    },
    {
      name: <b>DNS Server IP</b>,
      data: 'ip',
      sortable: true,
      searchable: true,
      styles: {
        width: 180,
      },
      hide: xs || small,
    },
    {
      name: <b>Client IP</b>,
      data: 'client_ip',
      sortable: true,
      searchable: true,
      styles: {
        width: 180,
      },
      hide: xs,
    },
    {
      name: <b>Requested</b>,
      data: '@timestamp',
      render: function RequestedCell(el) {
        return <Moment format="DD-MM-YYYY HH:mm:ss">{el}</Moment>;
      },
      sortable: true,
      searchable: false,
      styles: {
        width: 130,
      },
      hide: xs,
    },
    {
      name: <b>Info</b>,
      options: [
        {label: <em>All</em>, value: ''},
        {label: <RenderAction action="no_answers"/>, value: 'no_answers'},
        {label: <RenderAction action="blocked"/>, value: 'blocked'},
        {label: <RenderAction action="resolved"/>, value: 'allowed'},
      ],
      render: function ActionCell(el, row) {
        return <RenderAction action={row.action}/>;
      },
      data: 'action',
      styles: {
        width: 150,
      },
      hide: xs || small || medium,
    },
    {
      title: '',
      sortable: false,
      searchable: false,
      render: function InfoCell(_, row) {
        return <IconButton onClick={() => setOpen(row)} size="small">
          <FontAwesomeIcon icon={faInfoCircle}/>
        </IconButton>;
      },
      styles: {
        width: 56,
      },
      hide: !(xs || small || medium),
    },
  ];

  const [cat, setCat] = useState(
    props?.extradata?.list ? [props?.extradata?.list] : [],
  );
  const handleChange = (event) => {
    setCat(event.target.value);
  };
  const newTitle = <CategorySelection
    cat={cat}
    withLabel={!(xs || small || medium)}
    handleChange={handleChange}
  />;

  return (
    <>
      <Table
        {...props}
        columns={columns}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <b style={{margin: 'auto 0'}}>Requests</b>
            <div>
              {newTitle}
              {cat.length > 0 && (
                <Tooltip title="Only Blocked Domains will be shown.">
                  <span>
                    <FontAwesomeIcon icon={faAsterisk}/>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        }
        url="internal_requests"
        extradata={{
          ...extradata,
          t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
          clientname: selectedService,
          list: cat,
        }}
      />
      {data && <DetailRowDialog data={data} setOpen={setOpen}/>}
    </>
  );
}
InternalRequestsTable.propTypes = {
  extradata: PropTypes.object,
};

/**
 *
 * @param{object} data
 * @param{function} setOpen
 * @return {JSX.Element}
 * @constructor
 */
function DetailRowDialog({data, setOpen}) {
  return (
    <Dialog open={!!data}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <RenderDomainLink domain={data.domain.slice(0, -1)}/>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Service</b>
              </TableCell>
              <TableCell>
                <ServiceRender hash={data.clientname}/>
              </TableCell>
            </TableRow>
            {data.list && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>List</b>
                </TableCell>
                <TableCell>
                  <RenderCategory list={data.list}/>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>DNS Server</b>
              </TableCell>
              <TableCell>{data.ip}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Client IP</b>
              </TableCell>
              <TableCell>{data.client_ip}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Requested</b>
              </TableCell>
              <TableCell>
                {moment(data['@timestamp']).format('DD-MM-YYYY HH:mm:ss')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Info</b>
              </TableCell>
              <TableCell>
                <RenderAction action={data.action}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'start'}}>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}}/>
          Close
        </ButtonForm>
      </DialogActions>
    </Dialog>
  );
}

DetailRowDialog.propTypes = {
  data: PropTypes.object,
  setOpen: PropTypes.func,
};
