import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {setPath} from '../../actions/pathAction';
import Lists from './Lists';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function SimplePolicy() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Service Policy'));
  }, [dispatch]);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingTop: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid item xs={12}>
        <Lists />
      </Grid>
    </Grid>
  );
}
