import React, {useState} from 'react';
import {useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {SuperDateTimePickerRange}
from '../../DNS8Components/Inputs/SuperDateTimePickerRange';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function SandBoxPage() {
  const [period, setPeriod] = useState(['l15m']);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();


  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      />
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <h1>Teste</h1>
        <SuperDateTimePickerRange period={period} setPeriod={setPeriod} />
        <hr />


      </Grid>
    </Grid>
  );
}


