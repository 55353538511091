import React, {useEffect, useState} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {AlertCreateStepSelectType} from './sections/AlertCreateStepSelectType';
import {
  AlertCreateStepSelectRateTrigger,
} from './sections/AlertCreateStepSelectRateTrigger';
import {
  AlertCreateStepSelectChannel,
} from './sections/AlertCreateStepSelectChannel';
import {
  AlertCreateStepConfirmation,
} from './sections/AlertCreateStepConfirmation';
import {AlertDescription} from './sections/AlertDescription';
import {useStyles} from './styles';
import validator from 'validator';
import {Link, withRouter} from 'react-router-dom';
import {usePostDataBackend} from '../../ApiBackend';
import {useSelector} from 'react-redux';
import {displayError} from '../../ui-components/displayMsg';
import PropTypes from 'prop-types';


/**
 *
 * @param{function} negativeSideEffect
 * @param{function} sideEffect
 * @param{object} doc
 * @return {Element}
 * @constructor
 */
function UpdatingAlert({negativeSideEffect, sideEffect, doc}) {
  const [selectedService] = useSelector((state) => [state.selectedService]);
  const {loading, error} = usePostDataBackend('alerts/', {
    ...doc,
    service: doc.service ?? selectedService,
  });
  useEffect(() => {
    if (!loading && error) {
      displayError(`Error to update alert:`, error);
      negativeSideEffect();
    } else if (!loading) {
      sideEffect();
    }
  }, [negativeSideEffect, sideEffect, loading, error]);

  return <></>;
}

UpdatingAlert.propTypes = {
  negativeSideEffect: PropTypes.func,
  sideEffect: PropTypes.func,
  doc: PropTypes.object,
};

/**
 *
 * @param{number} step
 * @param{*} alert
 * @return {boolean}
 */
const navEnable = (step, alert) => {
  if (step === 1 && alert?.type === 'category' && alert?.category) {
    return true;
  }
  if (step === 1 && alert?.type === 'ip' && alert?.ip) {
    return true;
  }
  if (step === 1 && alert?.type === 'domain' && alert?.domain) {
    return true;
  }
  if (step === 2 &&
    alert?.events &&
    validator.isInt(alert.events, {gt: 0}) &&
    alert?.frequency) {
    return true;
  }
  if (step === 3 &&
    alert?.channel === 'telegram' &&
    alert?.chatId &&
    alert?.botToken) {
    return true;
  }
  if (step === 3 &&
    alert?.channel === 'email' &&
    alert?.emails) {
    return true;
  }
  if (step === 3 &&
    alert?.channel === 'teams' &&
    alert?.team &&
    alert?.tokenA &&
    alert?.tokenB &&
    alert?.tokenC) {
    return true;
  }
  return step === 4;
};

/**
 *
 * @param{object} alert
 * @returns {{}|{emails: (*|*[]), service: (*), channel: string, id, type: (string), events: string | undefined, frequency: (string)}}
 */
function cleanAlert(alert) {
  if (!alert) return {};
  const {
    clientname,
    list: category,
    time,
    _id: id,
    num_events: events,
    email: emails,
    domain_ip,
    ...rest
  } = alert;
  const frequency = time?.includes('minutes') ? 'minute' : 'hour';
  const type = category?.includes('domain') ? 'domain' :
    category?.includes('ip') ? 'ip' : 'category';
  const channel = emails ? 'email' : null;
  const service = Array.isArray(clientname) ? clientname?.[0] : clientname;
  return {
    service,
    type,
    [type]: domain_ip ?? category,
    frequency,
    events: events?.toString(),
    channel,
    emails: emails?.split(', ') ?? [],
    ...rest,
    id,
  };
}

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function CreateAlert(props) {
  const alert = props?.location?.state ?? null;
  const classes = useStyles();
  const [newAlert, setNewAlert] = useState(cleanAlert(alert));
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log(newAlert);

  const onSubmit = () => {
    setIsSubmitting(true);
  };


  return (<>
    <Grid container
      direction="row"
      justifyContent="space-between"
      style={{width: '100%', height: '100%', overflow: 'auto'}}>
      <Grid item lg={9} xs={12}
        style={{padding: 32, flex: 1, height: '100%', overflow: 'auto'}}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{width: '100%', minHeight: '100%', flex: 1, gap: '15px'}}
        >
          <Grid item className={classes.section}>

            {step === 1 &&
              <AlertCreateStepSelectType alert={newAlert}
                setAlert={setNewAlert}/>}
            {step === 2 &&
              <AlertCreateStepSelectRateTrigger alert={newAlert}
                setAlert={setNewAlert}/>}
            {step === 3 &&
              <AlertCreateStepSelectChannel alert={newAlert}
                setAlert={setNewAlert}/>}
            {step === 4 &&
              <AlertCreateStepConfirmation alert={newAlert}
                setAlert={setNewAlert}/>}
          </Grid>
          <Grid item className={classes.progressButtonsWrapper}>
            <Link to={'/settings/alerts'}>
              <ButtonForm
                className={'noUpper sec'}
                variant={'outlined'}
                type="button"
              >
                Cancel
              </ButtonForm>
            </Link>
            <div>
              <ButtonForm
                className={'noUpper'}
                variant={'outlined'}
                type="button"
                disabled={step === 1}
                onClick={() => {
                  setStep((step) => step >= 1 ? step - 1 : step);
                }}
              >
                Back
              </ButtonForm>
              {isSubmitting ? (<ButtonForm
                className={'noUpper sec'}
                variant={'outlined'}
                type="button"
              >
                <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
              </ButtonForm>) : (<ButtonForm
                className={'noUpper'}
                variant={'outlined'}
                type="button"
                disabled={!navEnable(step, newAlert) || isSubmitting}
                onClick={() => {
                  if (step < 4) {
                    setStep((step) => step + 1);
                  } else {
                    onSubmit(newAlert);
                  }
                }}
              >
                {step === 4 ? 'Create' : 'Next'}
              </ButtonForm>)}
            </div>
          </Grid>
        </Grid>


      </Grid>
      <Hidden mdDown>
        <Grid item lg={3}
          style={{backgroundColor: 'rgb(241,241,241)', padding: 32}}>
          <AlertDescription alert={newAlert} step={step}/>
        </Grid>
      </Hidden>
    </Grid>
    {isSubmitting && (
      <UpdatingAlert
        doc={{
          ...newAlert,
          number: newAlert?.events,
          list: newAlert.type === 'category' ? newAlert?.category : newAlert.type,
          mails: newAlert?.emails?.join(','),
          frequency: newAlert?.frequency + 's: 1',
          service: newAlert?.service,
          domain_ip: (newAlert?.domain ?? newAlert.ip ?? ['']).join(','),
        }}
        negativeSideEffect={(_) => setIsSubmitting(false)}
        sideEffect={() => props.history.push('/settings/alerts')}
      />
    )}
  </>);
}

CreateAlert.propTypes = {
  history: PropTypes.object,
};
export default withRouter(CreateAlert);
