import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

// TODO: replace this component - is slow with big children
/**
 * Component for show large text
 *
 * @param {JSX.Element} children
 * @param {string} more
 * @param {string} less
 * @param {number} lines
 * @return {JSX.Element}
 * @constructor
 */
export default function ReadMore({
  children,
  more = 'Show more',
  less = 'Show less',
  lines = 3,
}) {
  const [{expanded, truncated}, setState] = useState({
    expanded: false,
    truncated: false,
  });

  const toggleLines = (e) => {
    e.preventDefault();
    setState({expanded: !expanded, truncated});
  };
  const buttonStyles = {
    textDecoration: 'none',
    color: '#BE131A',
    float: 'right',
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
  };
  return <div>
    <Truncate
      lines={!expanded && lines}
      ellipsis={
        <>
          <span>... </span>
          <br />
          <button type="button" style={buttonStyles}
            onClick={toggleLines}>
            {more}
          </button>
        </>
      }
      // onTruncate={handleTruncate}
    >
      {children}
    </Truncate>
    {!truncated && expanded && (
      <>
        <br />
        <button type="button" style={buttonStyles} onClick={toggleLines}>
          {less}
        </button>
      </>
    )}
  </div>;
}
ReadMore.defaultProps = {
  lines: 3,
  more: 'Show more',
  less: 'show less',
};
ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
};
