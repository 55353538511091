import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RedTabs from '../../Components/RedTabs';
import Alerts from './Alerts';
import Reports from './Reports';
import {setPath} from '../../actions/pathAction';
import ServiceInfo from './ServiceInfo';
import configs from '../../configs';
import PropTypes from 'prop-types';

/**
 *
 * @param{object} props
 * @return {Element}
 * @constructor
 */
export default function Settings(props) {
  const tab = props?.match?.params?.subPath ?? '';
  let tabId = 0;
  const [{tokenParsed}] = useSelector((state) => [state.keycloak]);

  const roles =
    tokenParsed.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles ||
    [];
  let tabs = [];
  if (roles.includes('soc-operator')) {
    tabs = [
      {
        label: 'Service',
        page: ServiceInfo,
      },
    ];
  } else if (
    roles.includes('root') ||
    roles.includes('ms-operator') ||
    roles.includes('user')
  ) {
    tabId = {
      alerts: 0,
      service: 1,
      reports: 2,
    }?.[tab] ?? 0;
    tabs = [
      {
        label: 'Alerts',
        page: Alerts,
        url: '/settings/alerts',
      },
      {
        label: 'Service',
        page: ServiceInfo,
        url: '/settings/service',
      },

      {
        label: 'Reports',
        page: Reports,
        url: '/settings/reports',
      },
    ];
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Settings'));
  }, [dispatch]);
  return <RedTabs tabs={tabs} tabId={tabId}/>;
}
Settings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subPath: PropTypes.string,
    }),
  }),
};
