import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import RedTabs from '../../Components/RedTabs';

import Lists from './Lists';
import Categories from './Categories';
import {setPath} from '../../actions/pathAction';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Policy() {
  const tabs = [
    {
      label: 'Lists',
      page: Lists,
    },
    {
      label: 'Categories',
      page: Categories,
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Policy'));
  }, [dispatch]);
  return <RedTabs tabs={tabs} />;
}
