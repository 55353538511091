export const setOperatorData = (clients = []) => {
  return {
    type: 'SET_OPERATOR',
    payload: clients,
  };
};

export const personifyOperator = (client = false) => {
  return {
    type: 'SET_OPERATOR_CLIENT',
    payload: client,
  };
};
