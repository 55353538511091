import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {useMediaQuery} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {NavLink} from 'react-router-dom';
import {ReactComponent as MenuCollapseIcon} from '../icons/collapsed menu.svg';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${64}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    'width': drawerWidth,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    '& .MuiList-root': {
      'marginTop': 'auto',
      'marginBottom': 'auto',
      '&:nth-of-type(1)': {
        paddingTop: theme.spacing(4),
        marginTop: 0,
      },
      '&:nth-of-type(2)': {
        marginBottom: 0,
        paddingBottom: 0,
      },
    },
    '& .MuiListItem-root': {
      'padding': '13px 20px',
      'color': 'white',
      '&.active': {
        background: '#333333',
        paddingLeft: 16,
        borderLeft: '4px solid #ED1C24',
      },
      '&:hover': {
        background: '#333333',
      },
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 64,
    [theme.breakpoints.up('sm')]: {
      width: 64,
    },
  },
  drawerToolbar: {
    background: '#333333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    height: 56,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  toolbar: {
    background: 'white',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: 56,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 56,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  copyright: {
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: 'rgb(134,134,134)',
    textOverflow: 'ellipsis',
  },
}));

export default function Menus({lateralItems, logo, ToolBar, ...props}) {
  const classes = useStyles();
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(!smallDevices);

  useEffect(() => {
    setOpen(!smallDevices);
  }, [smallDevices, setOpen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggleMobileClose = () => {
    if (smallDevices) {
      setOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={clsx(classes.toolbar)}>
          {smallDevices && (
            <IconButton onClick={handleDrawerOpen}>
              <MenuCollapseIcon style={{width: 24}} />
            </IconButton>
          )}
          {ToolBar}
        </Toolbar>
      </AppBar>
      <Drawer
        // anchor={"left"}
        open={open}
        variant={smallDevices ? 'temporary' : 'permanent'}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        PaperProps={{style: {background: '#464646'}}}
      >
        <div className={classes.drawerToolbar}>
          {open && (
            <IconButton onClick={handleDrawerClose}>
              <FontAwesomeIcon
                style={{fontSize: 24, color: '#868686'}}
                icon={faBars}
              />
            </IconButton>
          )}
          {!open && (
            <IconButton onClick={handleDrawerOpen}>
              <MenuCollapseIcon
                style={{width: 24, maxHeight: 24, color: '#868686'}}
              />
            </IconButton>
          )}
          {logo}
        </div>
        {lateralItems.map((items, gi) => {
          const key = `lateral-itens-${gi}`;
          return (
            <List key={key} onClick={handleToggleMobileClose}>
              {items.map(
                  (
                      {label, icon: Icon, url, full_icon: fi, ...itemProps},
                      index,
                  ) => {
                    const listItemKey = `list-item-${index}`;
                    return (
                      <ListItem
                        key={listItemKey}
                        component={NavLink}
                        to={url}
                        {...itemProps}
                      >
                        <ListItemIcon
                          style={{minWidth: 24, marginRight: 20}}>
                          {Icon && (
                            <Icon style={{
                              width: '24px',
                              height: '24px',
                            }} />
                          )}
                          {fi}
                        </ListItemIcon>
                        <ListItemText primary={label} />
                      </ListItem>
                    );
                  },
              )}
            </List>
          );
        })}
        <Typography variant="caption" className={classes.copyright}>
          {`@${new Date().getFullYear()} LAYER8`}
        </Typography>
      </Drawer>
      <main
        className={classes.content}
        style={{
          height: '100vh',
          paddingTop: 56,
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {props.children}
      </main>
    </div>
  );
}
