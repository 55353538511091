import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  faBuilding,
  faCog,
  faGlobeEurope,
  faHeartbeat,
  faSuitcase,
  faTachometerAlt,
  faUsers,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMediaQuery} from '@material-ui/core';
import {ReactComponent as DomainsIcon} from '../icons/buildings.svg';
import {ReactComponent as RequestsIcon} from '../icons/requests.svg';
import {ReactComponent as EventsIcon} from '../icons/events.svg';
import {ReactComponent as ReleaseNotesIcon} from '../icons/release_notes.svg';
import Menus from '../Components/Menus';

import NewToolBar from './NewToolBar';
import configs from '../configs';
import PropTypes from 'prop-types';

const menuItems = (operator, roles, settings, release) => {
  if (operator && !operator.personify && roles.includes('ms-operator')) {
    return [
      [
        {
          label: 'DNS8 Status',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faHeartbeat}
            />
          ),
          url: '/dns8status',
        },
        {
          label: 'Domain info',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faBuilding}
            />
          ),
          url: '/domains',
        },
        {
          label: 'Global Policy',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faGlobeEurope}
            />
          ),
          url: '/global_policy',
        },
        {
          label: 'Users',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faUsers}
            />
          ),
          url: '/users_management',
        },
        {
          label: 'Services',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faSuitcase}
            />
          ),
          url: '/services_management',
        },
      ],
      [release, settings],
    ];
  }
  if (operator && !operator.personify && roles.includes('soc-operator')) {
    return [
      [
        {
          label: 'Domain info',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faBuilding}
            />
          ),
          url: '/domains',
        },
        {
          label: 'Global Policy',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faGlobeEurope}
            />
          ),
          url: '/global_policy',
        },
        {
          label: 'Service Policy',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faWrench}
            />
          ),
          url: '/policy',
        },
      ],
      [release, settings],
    ];
  }
  if (operator && !operator.personify && roles.includes('root')) {
    return [
      [
        {
          label: 'DNS8 Status',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faHeartbeat}
            />
          ),
          url: '/dns8status',
        },
        {
          label: 'Domain info',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faBuilding}
            />
          ),
          url: '/domains',
        },
        {
          label: 'Global Policy',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faGlobeEurope}
            />
          ),
          url: '/global_policy',
        },
        {
          label: 'Users',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faUsers}
            />
          ),
          url: '/users_management',
        },
        {
          label: 'Services',
          full_icon: (
            <FontAwesomeIcon
              fixedWidth
              style={{fontSize: 24, color: '#868686', width: 24}}
              icon={faSuitcase}
            />
          ),
          url: '/services_management',
        },
      ],
      [release, settings],
    ];
  }
  return [
    [
      {
        label: 'Dashboard',
        full_icon: (
          <FontAwesomeIcon
            fixedWidth
            style={{fontSize: 24, color: '#868686', width: 24}}
            icon={faTachometerAlt}
          />
        ),
        url: '/dashboard',
      },
      // {label: "Policy", "icon": ManageListsIcon, url: "/policy"},
      {
        label: 'Policy',
        full_icon: (
          <FontAwesomeIcon
            fixedWidth
            style={{fontSize: 24, color: '#868686', width: 24}}
            icon={faWrench}
          />
        ),
        url: '/policy',
      },
      {label: 'Domains', icon: DomainsIcon, url: '/domains'},
      {label: 'Requests', icon: RequestsIcon, url: '/requests'},
      {label: 'Threats', icon: EventsIcon, url: '/threats'},
      // {
      //     label: "Service Info",
      //     "icon": ServiceIcon,
      //     url: "/services",
      // },
    ],
    [release, settings],
  ];
};

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function DNS8Logo() {
  return (
    <Link to="/" style={{margin: 'auto', marginRight: 0, height: '100%'}}>
      <img
        style={{height: '100%'}}
        src={`${configs.DNS8_FRONTEND.base_url}/img/dns8_white.png`}
        alt="DNS8"
      />
    </Link>
  );
}

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export default function DNS8Menus(props) {
  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const token = keycloak.tokenParsed;

  const roles =
      token.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];

  let settings = {
    label: 'Settings',
    full_icon: (
      <FontAwesomeIcon
        fixedWidth
        style={{fontSize: 24, color: '#868686', width: 24}}
        icon={faCog}
      />
    ),
    url: '/settings',
  };
  let release = {
    label: 'Release notes',
    icon: ReleaseNotesIcon,
    url: '/releases',
  };
  if (isSmall) {
    settings = {
      ...settings,
      onClick: () => keycloak.accountManagement(),
      component: 'div',
      style: {cursor: 'pointer'},
    };
    release = {
      ...release,
      style: {display: 'none'},
    };
  }

  const items = menuItems(operator, roles, settings, release);
  return (
    <Menus lateralItems={items} logo={<DNS8Logo />} ToolBar={<NewToolBar />}>
      {props.children}
    </Menus>
  );
}
DNS8Menus.propTypes={
  children: PropTypes.any,
};
