import React from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {ServiceHeader} from './ServiceHeader';
import CategoriesList from './CategoriesList';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Categories() {
  const [selectedService, services, operator] = useSelector((state) => [
    state.selectedService,
    state.services,
    state.operator,
  ]);

  let servs = services;

  if (operator && operator.personify) {
    servs = services.filter((s) => operator.personify[s.hash]);
  }

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      {servs.reduce((acc, serv) => {
        if (
          selectedService.length &&
              ![serv.hash, 'all'].includes(selectedService)
        ) {
          return acc;
        }
        const {
          mqps: limit,
          servicename,
          expire: serviceExpireDate,
          hash,
        } = serv;
        const frag = (
          <React.Fragment key={hash}>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: padding / 2,
                paddingRight: padding / 2,
              }}
            >
              <Divider style={{marginTop: 104}} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: padding / 2,
                paddingRight: padding / 2,
              }}
            >
              <ServiceHeader
                limit={limit}
                serviceExpireDate={serviceExpireDate}
                servicename={servicename}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: padding / 2,
                paddingRight: padding / 2,
              }}
            >
              <CategoriesList hash={hash} />
            </Grid>
          </React.Fragment>
        );
        acc.push(frag);
        return acc;
      }, [])}
      <Grid item xs={12} style={{height: padding, margin: 'auto'}} />
    </Grid>
  );
}
