import React from 'react';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function NotFoundPage() {
  return <p>Not Found</p>;
}

