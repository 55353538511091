import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon, Radio,
} from '@material-ui/core';
import {SelectionButton, Text} from '@l8/storybook';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCertificate, faGlobe} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {selectionButtonStyles, useStyles, useStylesCheckBox} from '../styles';
import PropTypes from 'prop-types';
import {RenderCategory} from '../../../DNS8Components/Category';
import validator from 'validator';
import IPCIDR from 'ip-cidr';
import SelectMultipleSearch
from '../../../DNS8Components/Inputs/SelectMultipleSearch';

/**
 *
 * @param{string} domain
 * @return {boolean}
 */
function domainValidation(domain) {
  const cleanDomain = domain.trim().toLowerCase();
  const domainRegex = /^(\*\.)?((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]))\.)+((xn--[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?)|([a-zA-Z]{2,}))$/;
  return domainRegex.test(cleanDomain);
}

/**
 *
 * @param{obj} alert
 * @param{function} setAlert
 * @return {JSX.Element}
 * @constructor
 */
export function AlertCreateStepSelectType({alert, setAlert}) {
  const classes = useStyles();
  return <>
    <Grid container style={{overflow: 'auto'}}>


      <Grid item className={classes.section}>

        <Box>
          <Text label="Alert For" textWeight="bold" textSize="20px"/>
          <Text
            label="Choose an alert type"
            textSize="14px"
            textColor="secondary"
          />
        </Box>
        <Grid item className={classes.section}>
          <Grid container direction="row" style={{gap: 16}}>
            <Grid item>
              <SelectionButton style={selectionButtonStyles}
                icon={<FontAwesomeIcon icon={faCertificate}/>}
                text="Create an alert for category type detection"
                title="Category"
                active={alert?.type === 'category'}
                onClick={() => setAlert(setTypeAlert('category'))}
              />
            </Grid>
            <Grid item>
              <SelectionButton style={selectionButtonStyles}
                icon={<FontAwesomeIcon icon={faGlobe}/>}
                text="Create an alert for detection request domain"
                title="Domain"
                active={alert?.type === 'domain'}
                onClick={() => setAlert(setTypeAlert('domain'))}
              />
            </Grid>
            <Grid item>
              <SelectionButton style={selectionButtonStyles}
                icon={<Avatar style={{
                  backgroundColor: 'transparent',
                  color: 'inherit',
                  border: '2px solid currentcolor',
                }}>IP</Avatar>}
                text="Create an alert for detection an ip resolution"
                title="IP"
                active={alert?.type === 'ip'}
                onClick={() => setAlert(setTypeAlert('ip'))}
              />
            < /Grid>
          </Grid>
        </Grid>
      </Grid>
      {alert?.type === 'category' && (<Grid item className={classes.section}
        style={{paddingTop: 40}}>
        <Box>
          <Text label="Category to Detect" textWeight="bold"
            textSize="20px"/>
          <Text
            label="Choose a detection category for your alert"
            textSize="14px"
            textColor="secondary"
          />
        </Box>
        <CheckboxCategoriesList alert={alert} setAlert={setAlert}/>

      </Grid>)}
      {alert?.type === 'domain' && (<Grid item className={classes.section}
        style={{paddingTop: 40}}>
        <Box>
          <Text label="Domain to Detect" textWeight="bold"
            textSize="20px"/>
          <Text
            label="Write the domain you want to detect"
            textSize="14px"
            textColor="secondary"
          />
        </Box>
        <Box>
          <SelectMultipleSearch
            InputProps={{style: {'background-color': 'white'}}}
            value={alert?.domain ?? []}
            tokenValidator={domainValidation}
            onChange={({target: {value}}) => setAlert((currentAlert) => ({
              ...currentAlert,
              domain: value,
            }))}
            limit={256}
            placeholder="ex: *.example.pt"
          />

        </Box>
      </Grid>)}
      {alert?.type === 'ip' && (<Grid item className={classes.section}
        style={{paddingTop: 40}}>
        <Box>
          <Text label="Ips to Detect" textWeight="bold"
            textSize="20px"/>
          <Text
            label="Write the ips you want to detect"
            textSize="14px"
            textColor="secondary"
          />
        </Box>
        <Box>
          <SelectMultipleSearch
            InputProps={{style: {'background-color': 'white'}}}
            ChipProps={{style: {'width': 'unset'}}}
            value={alert.ip ?? []}
            tokenValidator={(t) =>
              validator.isIP(t) || validator.isIPRange(t)
            }
            onChange={({target: {value}}) => setAlert((currentAlert) => ({
              ...currentAlert,
              ip: value,
            }))}
            limit={256}
            countValues={(v) =>
              v.reduce((acc, value) => {
                const [, mask] = value.split('/');
                if (validator.isIP(value)) {
                  return acc + 1;
                }
                if (validator.isIPRange(value) && mask * 1 >
                  23) {
                  const cidr = new IPCIDR(value);
                  return acc + cidr.toArray().length;
                }
                return acc;
              }, 0)
            }
            placeholder="ex: 127.0.0.1/30"
          />
        </Box>
      </Grid>)}
    </Grid>
  </>;
}

AlertCreateStepSelectType.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
};


const categories =
  {
    'malware': 'The IPs/Domains listed are known for hosting malware, botnets, worms, drones, or somehow participating in related cyber crime activities.',
    'phishing': 'The domains listed are known for trying to deceive users to obtain theirs informations.',
    'reputation': 'The IPs/Domains listed have somehow bad reputation. There is no clear evidence of attack or malware.',
    'anonymizers': 'The IPs/Domains listed are known for hosting anonymizers, proxies, VPNs, or TOR exit nodes.',
    'dga': 'The Domains lists in this category are generated by algorithms.',
    'adware': 'The Domains listed are known for hosting ads and tracking services.',
    'blacklist': 'Domains listed in your blacklist.',
    'blacklist-root': 'Domains added by our SOC service.',
  };

function setTypeAlert(value) {
  return (currentAlert) => {
    delete currentAlert.category;
    delete currentAlert.domain;
    delete currentAlert.ip;
    return ({...currentAlert, type: value});
  };
}

/**
 *
 * @param{obj} alert
 * @param{function} setAlert
 * @return {JSX.Element}
 * @constructor
 */
function CheckboxCategoriesList({alert, setAlert}) {
  const classes = useStylesCheckBox();
  const [checked, setChecked] = React.useState(alert?.category ?? '');

  const handleToggle = (value) => () => {
    setChecked(value);
    setAlert((currentAlert) => ({
      ...currentAlert,
      category: value,
    }));
  };

  return (

    <List disablePadding={true}>
      {Object.entries(categories).map(([value, description]) => {
        const labelId = `checkbox-list-label-${value}`;

        return (<ListItem key={value} role={undefined} button
          className={classes.root}
          selected={checked === value}
          onClick={handleToggle(value)}>
          <ListItemIcon>
            <Radio
              edge="start"
              checked={checked === value}
              tabIndex={-1}
              disableRipple
              inputProps={{'aria-labelledby': labelId}}
            />
          </ListItemIcon>
          <div style={{
            display: 'inline-flex', width: '100%', gap: 15,
          }}>
            <RenderCategory style={{minWidth: 100}} list={value}/>
            <Text
              label={description}
              textSize="12px"
              textColor="primary"
            />
          </div>
        </ListItem>);
      })}
    </List>

  );
}

CheckboxCategoriesList.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
};
