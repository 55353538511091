import {Box, Grid} from '@material-ui/core';
import {Text} from '@l8/storybook';
import React from 'react';
import {useStyles} from '../styles';
import PropTypes from 'prop-types';
import {RenderCategory} from '../../../DNS8Components/Category';


/**
 *
 * @param{obj} alert
 * @param{function} setAlert
 * @return {JSX.Element}
 * @constructor
 */
export function AlertCreateStepConfirmation({alert, setAlert}) {
  const classes = useStyles();
  return <>
    <Grid container style={{overflow: 'auto'}} spacing={5}>
      <Grid item className={classes.section}>
        <Box>
          <Text label="Confirmation" textWeight="bold" textSize="20px"/>
          <Text
            label="Confirm your alert setup before create."
            textSize="14px"
            textColor="secondary"
          />
        </Box>
      </Grid>
      <Grid item className={classes.section}>
        <Box>
          {alert.type === 'category' && <>
            <Text label={'Category detected that will trigger the alert'}
              textWeight="bold"
              textSize="16px"/>
            {translate[alert.category] ??
              <Text label={alert.category}
                textColor="secondary"
                textSize="14px"/>}
          </>}
          {alert.type === 'domain' && <>
            <Text
              label={'Domains requested that will trigger the alert'}
              textWeight="bold"
              textSize="16px"/>
            <Text label={alert.domain.join(', ')}
              textColor="secondary"
              textSize="14px"/>
          </>}
          {alert.type === 'ip' && <>
            <Text label={'IPS detected that will trigger the alert'} textWeight="bold"
              textSize="16px"/>
            <Text label={alert.ip.join(', ')}
              textColor="secondary"
              textSize="14px"/>
          </>}
        </Box>
        <Box>
          <Text label={'Rate of Events that will trigger the alert'}
            textWeight="bold" textSize="16px"/>
          <Text label={`${alert.events} by ${alert.frequency}`} textSize="14px"
            textColor="secondary"/>
        </Box>
        <Box>
          {alert.channel === 'email' && <>
            <Text label={'Emails to send the alert.'} textWeight="bold"
              textSize="16px"/>
            <Text label={alert.emails.join(', ')} textSize="14px"
              textColor="secondary"/>
          </>}
        </Box>
      </Grid>
    </Grid>
  </>;
}

const translate = {
  'email': 'The alert will be sent to the Emails you indicate.',
  'malware': <RenderCategory style={{minWidth: 100}} list={'malware'}/>,
  'phishing': <RenderCategory style={{minWidth: 100}} list={'phishing'}/>,
  'reputation': <RenderCategory style={{minWidth: 100}} list={'reputation'}/>,
  'anonymizers': <RenderCategory style={{minWidth: 100}} list={'anonymizers'}/>,
  'dga': <RenderCategory style={{minWidth: 100}} list={'dga'}/>,
  'adware': <RenderCategory style={{minWidth: 100}} list={'adware'}/>,
  'blacklist': <RenderCategory style={{minWidth: 100}} list={'blacklist'}/>,
  'blacklist-root': <RenderCategory style={{minWidth: 100}}
    list={'blacklist-root'}/>,


};

AlertCreateStepConfirmation.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
};
