import React, {useState, forwardRef} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {ButtonBase, Tooltip} from '@material-ui/core';
import PropTypes from 'prop-types';

const AntTabs = withStyles({
  root: {
    'minHeight': 58,
    '& > *': {
      margin: 'auto',
    },
    '& .MuiTabs-flexContainer': {
      margin: '4px auto',
    },
    '&  .MuiBadge-root': {
      '&:first-child button': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },
      '&:last-child button': {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      },
    },
    '& .MuiTab-root': {
      'backgroundColor': '#464646',
      'color': 'white',
      'minHeight': 24,
      'textTransform': 'none',
      'opacity': 1,
      'minWidth': 120,
      '&.Mui-disabled': {
        opacity: 0.5,
      },

      '&.Mui-selected': {
        backgroundColor: '#BE131A',
        boxShadow: '0px 3px 6px #00000033',
      },
    },
  },
})(Tabs);

const useStyles = makeStyles((theme) => ({
  tabsSelector: {marginTop: theme.spacing(4)},
}));

/**
 *
 * @param{number} index
 * @return {{'aria-controls': string, id: string}}
 */
function tabProps(index) {
  return {
    'id': `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

/**
 *
 * @param{number} index
 * @return {{'aria-controls': string, id: string}}
 * @constructor
 */
function pageProps(index) {
  return {
    'id': `tabpanel-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

/**
 *
 * @param{Element} Page
 * @param{number} value
 * @param{number} index
 * @param{string} url
 * @return {React.JSX.Element|null}
 * @constructor
 */
function TabPanel({Page, value, index, url}) {
  return (value === index && <Page url={url} {...pageProps(index)} />) || null;
}
TabPanel.propTypes = {
  Page: PropTypes.elementType,
  value: PropTypes.number,
  index: PropTypes.number,
  url: PropTypes.string,
};

/**
 *
 * @param{Object[]} tabs
 * @param{string} tabs[].label
 * @param{boolean} tabs[].disabled
 * @param{number} tabId
 * @return {Element}
 * @constructor
 */
export default function RedTabs({tabs, tabId = 0}) {
  const classes = useStyles();
  const [currentTabId, setTab] = useState(tabId);
  const tabsProps = {centered: true};
  return (
    <>
      <AntTabs
        {...tabsProps}
        className={classes.tabsSelector}
        TabIndicatorProps={{style: {height: 0}}}
        value={currentTabId}
        onChange={(_, value) => setTab(value)}
      >
        {tabs.map(({label, disabled}, tabIndex) => (
          <Tab
            key={label}
            disabled={!!disabled}
            component={forwardRef((props, ref) => (
              <Badge
                variant={(disabled && 'dot') || 'standard'}
                color="secondary"
              >
                <WrapperTab
                  disabled={disabled}
                  wrapping={<ButtonBase {...props} ref={ref} />}
                />
              </Badge>
            ))}
            label={label}
            {...tabProps(tabIndex)}
          />
        ))}
      </AntTabs>

      {tabs.map(({url='', page}, tabIndex) => {
        const key = `tab-${tabIndex}`;
        return (
          <TabPanel
            key={key}
            value={currentTabId}
            index={tabIndex}
            Page={page}
            url={url}
          />
        );
      })}
    </>
  );
}
RedTabs.propTypes = {
  tabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        disabled: PropTypes.bool,
      } ),
  ),
  tabId: PropTypes.number,
};

/**
 *
 * @param{boolean} disabled
 * @param{*} wrapping
 * @return {React.JSX.Element|*}
 * @constructor
 */
function WrapperTab({disabled, wrapping}) {
  if (disabled) {
    return (
      <Tooltip title={disabled} placement="top">
        <span>{wrapping}</span>
      </Tooltip>
    );
  }
  return wrapping;
}
WrapperTab.propTypes = {
  disabled: PropTypes.bool,
  wrapping: PropTypes.element,
};
