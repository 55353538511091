import {withStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import {refType} from '@material-ui/utils';

const RedTextField = withStyles({
  root: {

    '& label.MuiInputLabel-marginDense.withStartAdornment': {
      transform: 'translate(21px, 21px) scale(1);',
    },
    '& label.MuiInputLabel-shrink.withStartAdornment': {
      transform: 'translate(0, 1.5px) scale(0.75);',
    },
    '& label.Mui-focused': {
      'color': '#BE131A',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#BE131A',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#BE131A',
    },
    '& .MuiInputLabel-root': {
      'color': '#464646',
      'fontSize': 'inherit',
      '&.Mui-disabled': {
        color: '#464646',
      },
    },
    '& .MuiInputBase-root': {
      'fontSize': 'inherit',
    },

    '& .MuiInput-underline': {
      '&:after': {
        borderBottomColor: '#BE131A',
      },
    },
    '& .MuiOutlinedInput-root .MuiSelect-nativeInput[placeholder][value=""]': {
      padding: '18.5px 14px',
    },
    ['& .MuiOutlinedInput-marginDense' +
    ' .MuiSelect-nativeInput[placeholder][value=""]']: {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    '& .MuiSelect-nativeInput[placeholder][value=""]': {
      'color': 'currentColor',
      'opacity': 0.42,
      'transition': 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'font': 'inherit',
      'width': '100%',
      'border': 0,
      'height': '1.1876em',
      'margin': 0,
      'display': 'block',
      'padding': '6px 0 7px',
      'min-width': '0',
      'background': 'none',
      'box-sizing': 'content-box',
      'animation-name': 'mui-auto-fill-cancel',
      'letter-spacing': 'inherit',
      'animation-duration': '10ms',
      '-webkit-tap-highlight-color': 'transparent',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
})(TextField);

// clone from https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TextField/TextField.js
// for auto-completing
RedTextField.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element will be focused during the first mount.
   */
  autoFocus: PropTypes.bool,
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense
   * for this component.
   */
  color: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * The default value of the `input` element.
   */
  defaultValue: PropTypes.any,
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the label will be displayed in an error state.
   */
  error: PropTypes.bool,
  /**
   * Props applied to the [`FormHelperText`](/api/form-helper-text/) element.
   */
  FormHelperTextProps: PropTypes.object,
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The helper text content.
   */
  helperText: PropTypes.node,
  /**
   * @ignore
   */
  hiddenLabel: PropTypes.bool,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible
   * for screen readers.
   */
  id: PropTypes.string,
  /**
   * Props applied to the [`InputLabel`](/api/input-label/) element.
   */
  InputLabelProps: PropTypes.object,
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps: PropTypes.object,
  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/api/filled-input/),
   * [`OutlinedInput`](/api/outlined-input/) or [`Input`](/api/input/)
   * component depending on the `variant` prop value.
   */
  InputProps: PropTypes.object,
  /**
   * Pass a ref to the `input` element.
   */
  inputRef: refType,
  /**
   * The label content.
   */
  label: PropTypes.node,
  /**
   * If `dense` or `normal`, will adjust vertical spacing of this and contained
   * components.
   */
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  /**
   * If `true`, a textarea element will be rendered instead of an input.
   */
  multiline: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, the label is displayed as required and the `input` element` will
   * be required.
   */
  required: PropTypes.bool,
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Render a [`Select`](/api/select/) element while passing the Input element
   * to `Select` as `input` parameter.
   * If this option is set you must pass the options of the select as children.
   */
  select: PropTypes.bool,
  /**
   * Props applied to the [`Select`](/api/select/) element.
   */
  SelectProps: PropTypes.object,
  /**
   * The size of the text field.
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  type: PropTypes.string,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.any,
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default RedTextField;
