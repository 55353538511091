import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {ThemeProvider} from '@material-ui/core/styles';
import {createTheme} from '@material-ui/core/styles';
import {Banner, useKeycloak} from './Secured';
import DNS8Menus from './DNS8Components/DNS8Menus';
import DNS8Routing from './DNS8Components/DNS8Routing';
import configs from './configs';

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
  },
});

/**
 * App
 *
 * @return {JSX.Element}
 * @constructor
 */
function App() {
  const [authenticated, showLogo] = useKeycloak();

  return (
    <ThemeProvider theme={theme}>
      {showLogo && <Banner />}
      {!showLogo && authenticated && (
        <BrowserRouter basename={configs.DNS8_FRONTEND.base_url}>
          <Grid container alignItems="stretch">
            <DNS8Menus>
              <DNS8Routing />
            </DNS8Menus>
          </Grid>
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
}

export default App;
