export const loggedIn = () => {
  return {
    type: 'LOGGED_IN',
  };
};

export const loggedOut = () => {
  return {
    type: 'LOGGED_OUT',
  };
};

export const setKeycloak = (keycloak = null) => {
  return {
    type: 'SET_KEYCLOAK',
    payload: keycloak,
  };
};

export const setKeycloakError = (error = null) => {
  return {
    type: 'SET_KEYCLOAK_ERROR',
    payload: error,
  };
};
