import React, {useEffect, useState} from 'react';
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  useTheme,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCertificate,
  faHeartbeat,
  faSuitcase,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {green, orange, red} from '@material-ui/core/colors';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import {useGetDataBackend} from '../../ApiBackend';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import {ESStatusCard} from './ESStatus';
import {useKeycloakServices} from './ServicesManagement';
import BoxInfo from '../../Components/BoxInfo';
import {setPath} from '../../actions/pathAction';
import PropTypes from 'prop-types';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function DNS8Status2() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('DNS8 Status'));
  }, [dispatch]);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        sm={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Alerts/>
      </Grid>
    </Grid>
  );
}

/**
 *
 * @return {(boolean|*)[]|boolean[]}
 */
function useProblemKeycloakServices() {
  const [groups, loadingServices] = useKeycloakServices();
  if (loadingServices) {
    return [false];
  }
  const filteredGroups = groups.reduce((acc, g) => {
    const group = JSON.parse(g.attributes?.group_info[0] || '');
    const exp = moment(group.expire, 'DD-MM-YYYY');
    const now = moment();
    const diff = exp.diff(now, 'months');
    if (diff < 3) {
      acc.push(group);
    }
    return acc;
  }, []).sort(
      (a, b) => moment(a.expire, 'DD-MM-YYYY') - moment(b.expire, 'DD-MM-YYYY'),
  );
  return [!!filteredGroups.length, filteredGroups];
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function Alerts() {
  const [view, setView] = useState('all');

  const {
    data: {es = []},
    loading,
    error,
  } = useGetDataBackend('monitoring/es');

  const {
    data: {certs = []},
    loading: loadingCertificates,
    error: errorCeritificates,
  } = useGetDataBackend('monitoring/certificates');

  const problematicCertificates = certs.filter((cert) => {
    const months = moment(cert[1], 'x').diff(moment(), 'month');
    return months <= 4;
  });

  const [
    hasServicesProblems,
    problematicServices = [],
  ] = useProblemKeycloakServices();

  return (
    <BoxInfo
      title={
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {view === 'all' && <b>All Alerts</b>}
          {view === 'system' && <b>System Status</b>}
          {view === 'data' && <b>Data Alerts</b>}
          {view === 'service' && <b>Service status</b>}
          {view === 'certificates' && <b>Certificate status</b>}
          <div>
            <RedIconButton
              TooltipProps={{title: 'Show All Alerts'}}
              disabled={view === 'all'}
              onClick={() => setView('all')}
            >
              All
            </RedIconButton>
            <RedIconButton
              TooltipProps={{title: 'Show System Status'}}
              disabled={view === 'system'}
              onClick={() => setView('system')}
            >
              {(!loading && !error && !es.includes('green') && (
                <Badge variant="dot" color="error">
                  <FontAwesomeIcon icon={faHeartbeat}/>
                </Badge>
              )) || <FontAwesomeIcon icon={faHeartbeat}/>}
            </RedIconButton>
            <RedIconButton
              TooltipProps={{title: 'Show Certificate Status'}}
              disabled={view === 'certificates'}
              onClick={() => setView('certificates')}
            >
              {(!loadingCertificates && !errorCeritificates &&
                problematicCertificates.length && (
                <Badge variant="dot" color="error">
                  <FontAwesomeIcon icon={faCertificate}/>
                </Badge>
              )) || <FontAwesomeIcon icon={faCertificate}/>}
            </RedIconButton>
            <RedIconButton
              TooltipProps={{title: 'Show Service Alerts'}}
              disabled={view === 'service'}
              onClick={() => setView('service')}
            >
              {(hasServicesProblems && (
                <Badge variant="dot" color="error">
                  <FontAwesomeIcon icon={faSuitcase}/>
                </Badge>
              )) || <FontAwesomeIcon icon={faSuitcase}/>}
            </RedIconButton>
          </div>
        </div>
      }
    >
      <div
        style={{
          margin: '1rem',
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: 'repeat(auto-fit,minmax(240px,1fr))',
        }}
      >
        {view === 'all' && (
          <AllAlerts
            color={(es.length && es[1]) || (loading && 'loading') ||
              error}
            services={problematicServices}
            certificates={problematicCertificates}
          />
        )}

        {view === 'system' && <SystemStatus/>}
        {view === 'service' && <ServiceStatus/>}
        {view === 'certificates' && <CertificatesStatus/>}
      </div>
    </BoxInfo>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function SystemStatus() {
  const [timeRange] = useSelector((state) => [state.time_range]);
  const {
    data: {es = []},
    loading,
    error,
  } = useGetDataBackend('monitoring/es', {time_range: timeRange});
  const data = es.map((d) => {
    return {...d, time_format: moment(d.time).format(d.format_time)};
  });
  return (
    <>
      <div style={{gridColumn: '1 / span 1'}}>
        <ESStatusCard
          color={
            (es.length &&
              Object.entries(es[es.length - 1]).find((e) => e[1] === 1)[0]) ||
            (loading && 'loading') ||
            error
          }
          timestamp={es.length && es[es.length - 1].time}
        />
      </div>
      <div style={{gridColumn: '2 / -1'}}/>
      <div style={{gridColumn: '1 / -1'}}>
        <h3>ES Status Overtime</h3>
      </div>
      <div style={{gridColumn: '1 / -1', height: '264px'}}>
        {!!es.length && (
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false}/>
              <XAxis dataKey="time_format"/>
              <Tooltip/>

              <Bar
                stackId={1}
                type="monotone"
                name="green"
                fill="#bada55"
                dataKey="green"
              />
              <Bar
                stackId={1}
                type="monotone"
                name="red"
                fill="#BE131A"
                dataKey="red"
              />
              <Bar
                stackId={1}
                type="monotone"
                name="yellow"
                fill="#edae30"
                dataKey="yellow"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function ServiceStatus() {
  const [groups] = useKeycloakServices();
  const filteredGroups = groups.reduce((acc, g) => {
    const group = JSON.parse(g.attributes?.group_info[0] || '');
    acc.push(group);
    return acc;
  }, []).sort((a, b) => {
    if (a.expire && !b.expire) {
      return -1;
    }
    if (b.expire && !a.expire) {
      return 1;
    }
    if (!b.expire && !a.expire) {
      return 0;
    }
    return moment(a.expire, 'DD-MM-YYYY') - moment(b.expire, 'DD-MM-YYYY');
  });
  return (
    <>
      {!!filteredGroups.length &&
        filteredGroups.map((g) => (
          <ServiceStatusCard key={g.hash} service={g}/>
        ))}
    </>
  );
}

/**
 *
 * @return {*[]}
 * @constructor
 */
function CertificatesStatus() {
  const {
    data: {certs = []},
  } = useGetDataBackend('monitoring/certificates');
  const components = [];
  if (certs.length) {
    certs.forEach((c) =>
      components.push(<CertificateStatusCard key={c[0]} name={c[0]}
        expiration={c[1]}/>),
    );
  }

  return components;
}

/**
 *
 * @param color
 * @param services
 * @param certificates
 * @return {*[]}
 * @constructor
 */
function AllAlerts({color, services, certificates}) {
  const components = [];
  if (['red', 'yellow'].includes(color)) {
    components.push(
        <h3 key="header-system-status" style={{gridColumn: '1 / -1'}}>
        System Status
        </h3>,
    );
    components.push(<ESStatusCard key="es_status" color={color}/>);
  }
  if (services.length) {
    components.push(
        <h3 key="header-service-status" style={{gridColumn: '1 / -1'}}>
        Service Status
        </h3>,
    );
    services.forEach((s) =>
      components.push(<ServiceStatusCard key={s.hash} service={s}/>),
    );
  }

  if (certificates.length) {
    components.push(
        <h3 key="header-service-status" style={{gridColumn: '1 / -1'}}>
        Certificate Status
        </h3>,
    );
    certificates.forEach((c) =>
      components.push(<CertificateStatusCard key={c[0]} name={c[0]}
        expiration={c[1]}/>),
    );
  }

  return components;
}

/**
 *
 * @param{string} name
 * @param{string|number} expiration
 * @return {JSX.Element}
 * @constructor
 */
function CertificateStatusCard({name, expiration}) {
  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
    avatarLoading: {
      backgroundColor: 'transparent',
    },
    avatarDarkRed: {
      backgroundColor: red[700],
    },
    avatarRed: {
      backgroundColor: red[500],
    },
    avatarGreen: {
      backgroundColor: green[500],
    },
    avatarOrange: {
      backgroundColor: orange[500],
    },
  }))();
  let subheader;
  let avatar;
  let description;
  const exp = moment(expiration, 'x');
  const expDate = moment(expiration, 'x').format('DD-MM-YYYY');
  const monthDiff = exp.diff(moment(), 'months');
  const diff = exp.diff(moment());

  if (monthDiff <= 0 && diff <= 0) {
    subheader = 'EXPIRED';
    avatar = (
      <Avatar className={classes.avatarDarkRed}>
        <FontAwesomeIcon icon={faTimes}/>
      </Avatar>
    );
    description = `Expired @ ${expDate}`;
  } else if (monthDiff < 1) {
    subheader = `ABOUT TO EXPIRE`;
    avatar = (
      <Avatar className={classes.avatarRed}>
        <FontAwesomeIcon icon={faClock}/>
      </Avatar>
    );
    description = `Cert expires @ ${expDate}`;
  } else if (monthDiff < 4) {
    subheader = `CLOSE TO EXPIRE`;
    avatar = (
      <Avatar className={classes.avatarOrange}>
        <FontAwesomeIcon icon={faClock}/>
      </Avatar>
    );
    description = `Cert expires @ ${expDate}`;
  } else {
    subheader = `OK`;
    avatar = (
      <Avatar className={classes.avatarGreen}>
        <FontAwesomeIcon icon={faClock}/>
      </Avatar>
    );
    description = `Cert without expiration`;
    if (expiration) {
      description = `Cert expires @ ${expDate}`;
    }
  }
  return (
    <Card variant="outlined">
      <CardHeader title={name} subheader={subheader}
        avatar={avatar}/>
      <CardContent>{description}</CardContent>
    </Card>
  );
}

CertificateStatusCard.propTypes = {
  name: PropTypes.string,
  expiration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

/**
 *
 * @param{any} service
 * @return {JSX.Element}
 * @constructor
 */
function ServiceStatusCard({service}) {
  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
    avatarLoading: {
      backgroundColor: 'transparent',
    },
    avatarDarkRed: {
      backgroundColor: red[700],
    },
    avatarRed: {
      backgroundColor: red[500],
    },
    avatarGreen: {
      backgroundColor: green[500],
    },
    avatarOrange: {
      backgroundColor: orange[500],
    },
  }))();
  let subheader;
  let avatar;
  let description;
  const exp = moment(service.expire, 'DD-MM-YYYY');
  const now = moment();
  const monthDiff = exp.diff(now, 'months');
  const diff = exp.diff(now);

  if (monthDiff <= 0 && diff <= 0) {
    subheader = 'EXPIRED';
    avatar = (
      <Avatar className={classes.avatarDarkRed}>
        <FontAwesomeIcon icon={faTimes}/>
      </Avatar>
    );
    description = `Expired @ ${service.expire}`;
  } else if (monthDiff < 1) {
    subheader = `ABOUT TO EXPIRE`;
    avatar = (
      <Avatar className={classes.avatarRed}>
        <FontAwesomeIcon icon={faClock}/>
      </Avatar>
    );
    description = `Service expires @ ${service.expire}`;
  } else if (monthDiff < 3) {
    subheader = `CLOSE TO EXPIRE`;
    avatar = (
      <Avatar className={classes.avatarOrange}>
        <FontAwesomeIcon icon={faClock}/>
      </Avatar>
    );
    description = `Service expires @ ${service.expire}`;
  } else {
    subheader = `OK`;
    avatar = (
      <Avatar className={classes.avatarGreen}>
        <FontAwesomeIcon icon={faClock}/>
      </Avatar>
    );
    description = `Service without expiration`;
    if (service.expire) {
      description = `Service expires @ ${service.expire}`;
    }
  }
  return (
    <Card variant="outlined">
      <CardHeader title={service.name} subheader={subheader}
        avatar={avatar}/>
      <CardContent>{description}</CardContent>
    </Card>
  );
}

ServiceStatusCard.propTypes = {
  service: PropTypes.object,
};
