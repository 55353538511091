import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import BoxInfo from '../../Components/BoxInfo';
import Spinner from '../../Components/Spinner';
import ReadMore from '../../ui-components/ReadMore';

const useStyles = makeStyles((_) => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  body: {
    padding: 16,
  },
}));

export function ServiceBlockInfo({title, loading, info}) {
  const classes = useStyles();
  return (
    <BoxInfo title={<span className={classes.title}>{title}</span>}>
      <div className={classes.body}>
        {!loading && <ReadMore>{info || '-'}</ReadMore>}
        {loading && <Spinner />}
      </div>
    </BoxInfo>
  );
}
