import React from 'react';
import {Link} from 'react-router-dom';
import {Tooltip} from '@material-ui/core';
import punycode from 'punycode';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExchangeAlt,
  faGlobeEurope,
} from '@fortawesome/free-solid-svg-icons';

import {colorMap} from './Category';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import PropTypes from 'prop-types';

/**
 *
 * @param{string} domain
 * @return {string}
 */
export function renderDomain(domain) {
  return punycode.toASCII(domain);
}

/**
 *
 * @param{string} domain
 * @param{{}} style
 * @param{any} tooltip
 * @param{string} label
 * @return {JSX.Element}
 * @constructor
 */
export function RenderDomainLink({domain, style, tooltip, label}) {
  let d = renderDomain(domain);
  if (d === '') {
    d = '.';
    tooltip = 'Root Server';
    return tooltip && <span>
      <Tooltip title={tooltip}>
        <span>{label ?? d}</span>
      </Tooltip>
    </span>;
  }
  return (
    <Link
      style={{
        textDecoration: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        color: 'inherit',
        ...style,
      }}
      to={`/domains/${d}`}
    >
      {!tooltip && d}
      {tooltip && (
        <Tooltip title={tooltip}>
          <span>{label ?? d}</span>
        </Tooltip>
      )}
    </Link>
  );
}

RenderDomainLink.propTypes = {
  domain: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.any,
  label: PropTypes.string,
};

/**
 *
 * @param{string} domain
 * @param{{}} style
 * @param{any} tooltip
 * @return {JSX.Element}
 * @constructor
 */
export function RenderDomainInternalLink({domain, style, tooltip}) {
  const d = renderDomain(domain);
  return (
    <Link
      style={{
        textDecoration: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        color: 'inherit',
        ...style,
      }}
      to={`/domains/${d}/i`}
    >
      {!tooltip && d}
      {tooltip && (
        <Tooltip title={tooltip}>
          <span>{d}</span>
        </Tooltip>
      )}
    </Link>
  );
}

RenderDomainInternalLink.propTypes = {
  domain: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.any,
};

/**
 *
 * @param{string|array} domain
 * @param{string} list
 * @param{array} domains
 * @param{string} action
 * @param{any} style
 * @return {JSX.Element}
 * @constructor
 */
export function RenderDomainWithList({
  domain,
  list = '',
  domains = [],
  action,
  style,
  motive,
}) {
  const [l, service] = list.split('-');
  const globalList = service === 'root';
  let validated = false;
  if (list.includes('validated')) {
    validated = true;
  }

  const {color, icon, text} = colorMap[l] || {
    color: 'transparent',
    icon: null,
    text: '',
  };
  const filteredDomains = domains.filter((d) => d);
  const sameDomain =
    filteredDomains.length === 0 ||
    (filteredDomains.length === 1 && filteredDomains[0] === domain[0]);

  const TooltipText = () => {
    if (globalList) {
      return <>Global {text}</>;
    } else if (validated) {
      return <>{text} Validated</>;
    }
    return <>{text}</>;
  };
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {!icon && <div
        style={{
          color,
          icon,
          margin: '0 5px',
          width: (globalList || validated) ? 30 : 15,
          display: 'flex',
        }}
      ></div>}
      {icon && <Tooltip title={<TooltipText/>}>
        <div
          style={{
            color,
            icon,
            margin: '0 5px',
            width: (globalList || validated) ? 30 : 15,
            display: 'flex',
          }}
        >
          {icon}
          {globalList && (
            <FontAwesomeIcon
              icon={faGlobeEurope}
              color="#000"
              style={{margin: 'auto'}}
            />
          )}
          {validated && (
            <FontAwesomeIcon
              icon={faCheck}
              color={color}
              style={{margin: 'auto'}}
            />
          )}
        </div>
      </Tooltip>}
      {action && <div style={{margin: '0 5px', width: 15}}>{action}</div>}
      <RenderDomainLink
        domain={domain[0]}
        tooltip={`More info: ${domain[0]}`}
        style={style}
      />

      {!sameDomain && (
        <div style={{marginLeft: 10}}>
          <Tooltip interactive
            title={<DomainList domains={filteredDomains}/>}>
            <span>
              <FontAwesomeIcon icon={faExchangeAlt}/>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

RenderDomainWithList.propTypes = {
  domain: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  list: PropTypes.string,
  domains: PropTypes.array,
  action: PropTypes.string,
  style: PropTypes.object,
};

/**
 *
 * @param{string[]} domains
 * @return {JSX.Element}
 * @constructor
 */
function DomainList({domains}) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', fontSize: 12}}>
      <div style={{fontSize: 12, padding: '0 0  5px 0'}}>
        Original Requests:
      </div>
      {domains.map((d) => (
        <Link
          key={d}
          style={{
            color: 'white',
            fontSize: 12,
            padding: '5px 0',
            textDecoration: 'none',
          }}
          className="truncate-link"
          to={`domains/${d}`}
        >
          {d}
        </Link>
      ))}
    </div>
  );
}

DomainList.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.string),
};
