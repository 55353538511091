const timeRangeReducer = (state = 'l15m', action) => {
  switch (action.type) {
    case 'SET_TIME_RANGE':
      return action.payload;
    default:
      return state;
  }
};

export default timeRangeReducer;
