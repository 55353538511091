import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {
  ButtonBase, Divider,
  InputBase,
  MenuItem as MuiMenuItem, MenuList, Popper,
  Select,
} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import RedButton from '../../core/components/buttons/RedButton';
import PropTypes from 'prop-types';
import {
  createTheme as createMuiTheme, makeStyles, ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import RedTextField from '../../core/components/inputs/RedTextField';
import InputAdornment from '@material-ui/core/InputAdornment';


const formats = [
  'D',
  'DD',
  'DD-M',
  'DD-MM',
  'DD-MM-YY',
  'DD-MM-YYYY',
  'DD-MM-YYYY H',
  'DD-MM-YYYY HH',
  'DD-MM-YYYY HH:m',
  'DD-MM-YYYY HH:mm',
  'DD-MM-YYYY HH:mm:s',
  'DD-MM-YYYY HH:mm:ss',
  'DD-MM-YYYY HH:mm:ss.S',
  'DD-MM-YYYY HH:mm:ss.SS',
  'DD-MM-YYYY HH:mm:ss.SSS'];
const format = formats[formats.length - 1];

export const TIME_OPTIONS = [
  {value: 'l15m', label: 'Last 15 minutes'},
  {value: 'l30m', label: 'Last 30 minutes'},
  {value: 'l1h', label: 'Last 1 hour'},
  {value: 'l4h', label: 'Last 4 hours'},
  {value: 'l12h', label: 'Last 12 hours'},
  {value: 'l24h', label: 'Last 24 hours'},
  {value: 'today', label: 'Today'},
  {value: 'yesterday', label: 'Yesterday'},
  {value: 'l7d', label: 'Last 7 days'},
];

const useStyles = makeStyles((theme) => ({

  popper: {
    'zIndex': 1,
    '& .MuiTab-root': {
      textTransform: 'capitalize',
    },
    '&[x-placement*="bottom"] $arrow': {
      'top': 0,
      'left': 0,
      'marginTop': '-0.9em',
      'width': '3em',
      'height': '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: 'transparent transparent ' +
          `${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      'bottom': 0,
      'left': 0,
      'marginBottom': '-0.9em',
      'width': '3em',
      'height': '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: theme.palette.background.paper +
          ' transparent transparent transparent',
      },
    },
    '&[x-placement*="right"] $arrow': {
      'left': 0,
      'marginLeft': '-0.9em',
      'height': '3em',
      'width': '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: 'transparent' +
          ` ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      'right': 0,
      'marginRight': '-0.9em',
      'height': '3em',
      'width': '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: 'transparent transparent transparent ' +
          `${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    'position': 'absolute',
    'fontSize': 7,
    'width': '3em',
    'height': '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        color: 'inherit',
      },
      daySelected: {
        'backgroundColor': '#BE131A',
        'color': 'white',
        '&:hover': {
          backgroundColor: '#BE131A',
        },
      },
      dayDisabled: {
        color: 'inherit',
        textDecoration: 'line-through',
      },
      current: {
        color: 'inherit',
      },
    },
  },
});

const MenuItemCenter = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MuiMenuItem);

/**
 *
 * @param{*} children
 * @return {JSX.Element}
 */
function DisplayDate({children}) {
  return <span
    style={{
      maxWidth: 150,
      whiteSpace: 'nowrap',
    }}
  >
    {children}
  </span>;
}

DisplayDate.propTypes = {
  children: PropTypes.any,
};

/**
 *
 * @param{*} children
 * @param{int} value
 * @param{int} index
 * @param{object} other
 * @return {JSX.Element}
 * @constructor
 */
function TabPanel({children, value, index, ...other}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.number,
  index: PropTypes.number,
  other: PropTypes.any,
};

/**
 *
 * @param{array} period
 * @param{function} setPeriod
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
export function SuperDateTimePickerRange({period, setPeriod, ...props}) {
  const [selectedRange, setSelectedRange] = useState(Boolean(period?.[1]));
  const [start, setStart] = useState(period[0]);
  const [anchorStartDate, setAnchorStartDate] = useState(null);
  const [anchorEndDate, setAnchorEndDate] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(period[0], moment.defaultFormat, true).isValid() ?
      moment(period[0]).format(format) :
      period[0],
  );
  const [endDate, setEndDate] = useState(
    moment(period[1], moment.defaultFormat, true).isValid() ?
      moment(period[1]).format(format) :
      (period?.[1] || 'now'),
  );

  const handleChange = (event) => {
    setStart(event.target.value);
    setStartDate(event.target.value);
    setEndDate('now');
    setSelectedRange(false);
    setAnchorStartDate(null);
    setAnchorEndDate(null);
    setPeriod([event.target.value]);
  };


  const handleClick = (setAnchorEl) => (event) => {
    event.persist();
    setSelectedRange(true);
    setAnchorEl((anchorEl) => anchorEl ? null : event.currentTarget);
    if (setAnchorEndDate === setAnchorEl) {
      setAnchorStartDate(null);
    } else {
      setAnchorEndDate(null);
    }
  };

  /**
   *
   * @param{string} any
   * @param{function} callback
   * @return {moment.Moment}
   */
  function momentWithRelative(any, callback = (m) => m) {
    if (any && moment(any, formats, true).isValid()) {
      return moment(any, formats);
    } else if (/l\d+[dhms]?/.test(any)) {
      const [, v, u] = /l(\d+)([dhms])?/.exec(any);
      return moment().subtract(v, u);
    } else if (any === 'now') {
      return moment();
    } else if (any === 'today') {
      return callback(moment());
    } else if (any === 'yesterday') {
      return callback(moment().subtract(1, 'day'));
    } else {
      return callback(moment());
    }
  }

  /**
   *
   * @param{string} date
   * @return {Moment}
   */
  function min(date) {
    if (moment(date, formats, true).isValid()) {
      return moment(date, formats).endOf('minutes');
    } else if (/l\d+[dhms]?/.test(date)) {
      const [, v, u] = /l(\d)+([dhms])?/.exec(date);
      return moment().subtract(v, u).endOf(u);
    } else {
      return moment().endOf('minutes');
    }
  }

  /**
   *
   * @param{string} date
   * @return {Moment}
   */
  function max(date) {
    if (moment(date, formats, true).isValid()) {
      return moment(date, formats).startOf('minutes');
    } else if (/l\d+[dhms]?/.test(date)) {
      const [, v, u] = /l(\d)+([dhms])?/.exec(date);
      return moment().subtract(v, u).startOf(u);
    } else {
      return moment().subtract(30, 'd').startOf('minutes');
    }
  }

  /**
   *
   * @param{string} date
   * @return {string}
   */
  function printDate(date) {
    if (moment(date, format, true).isValid()) {
      return date;
    } else if (/l\d+[dhms]?/.test(date)) {
      const [, v, u] = /l(\d+)([dhms])?/.exec(date);
      const units = {
        s: 'second',
        m: 'minute',
        h: 'hour',
        d: 'day',
      };
      return `Last ${v} ${units[u]}` + (v > 1 ? 's' : '');
    } else if (['now', 'today', 'yesterday'].includes(date)) {
      return date.charAt(0).toUpperCase() + date.slice(1);
    } else {
      return date;
    }
  }

  const onClickSearch = () => {
    let sDate = startDate;
    let eDate = endDate;
    if (moment(startDate, format, true).isValid()) {
      sDate = moment(startDate, format).format();
    }
    if (moment(eDate, format, true).isValid()) {
      eDate = moment(eDate, format).format();
    }
    setPeriod([
      sDate,
      eDate,
    ]);
    setAnchorStartDate(null);
    setAnchorEndDate(null);
  };
  let isDisabled;
  {
    const startMoment = momentWithRelative(startDate,
        (m) => m.startOf('day'));
    const endMoment = momentWithRelative(endDate, (m) => m.endOf('day'));
    const minMoment = moment().subtract(7, 'days').startOf('day');
    isDisabled = startMoment < minMoment || startMoment > endMoment;
  }
  return <div style={{
    'display': 'flex',
    'width': 455,
    'border': '1px solid #ddd',
    'padding': 4,
    ...props?.style,
  }}>
    <StaticPeriodSelect onChange={handleChange} defaultValue={start}/>

    <Divider orientation="vertical" flexItem/>
    <ButtonBase
      style={{
        flex: 1,
        justifyContent: selectedRange ? 'end' : 'start',
        padding: '0 5px',
      }}
      onClick={handleClick(setAnchorStartDate)}>
      <DisplayDate>
        {!selectedRange && printDate(start)}
        {selectedRange && printDate(startDate)}
      </DisplayDate>
    </ButtonBase>
    {!selectedRange && <ButtonBase
      style={{justifyContent: 'end', padding: '0 5px', marginLeft: 'auto'}}
      onClick={() => {
        setSelectedRange(true);
      }}>
      <DisplayDate>
        Show dates
      </DisplayDate>
    </ButtonBase>}
    <InputDateTimeWithPopover
      anchorEl={anchorStartDate}
      label={'Start Date'}
      date={momentWithRelative(startDate, (m) => m.startOf('day'))}
      setDate={setStartDate}
      datePickerProps={{
        minDate: moment().subtract(7, 'days').endOf('minutes'),
        maxDate: min(endDate),
      }}
    />
    {selectedRange && <div style={{
      width: 25,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    }}>
      <FontAwesomeIcon icon={faArrowRight} color={'#d32f2f'}/>
    </div>
    }

    {selectedRange && <ButtonBase
      style={{
        flex: 1,
        justifyContent: 'start',
        padding: '0 5px',
      }}
      onClick={handleClick(setAnchorEndDate)}>
      <DisplayDate>
        {printDate(endDate)}
      </DisplayDate>
    </ButtonBase>}
    <InputDateTimeWithPopover
      anchorEl={anchorEndDate}
      label={'End Date'}
      date={momentWithRelative(endDate, (m) => m.endOf('day'))}
      datePickerProps={{
        minDate: max(startDate),
        maxDate: moment().endOf('minutes'),
      }}
      setDate={setEndDate}/>
    {selectedRange &&
      <RedButton size={'small'} variant={'contained'}
        disabled={isDisabled}
        onClick={onClickSearch}
      >
        Search
      </RedButton>}
  </div>
  ;
}

SuperDateTimePickerRange.propTypes = {
  period: PropTypes.array,
  setPeriod: PropTypes.func,
  style: PropTypes.object,
};

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function MenuItem(props) {
  const {selected = false} = props;

  //
  const ref = useRef(null);
  useEffect(() => {
    if (selected) {
      ref.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [selected]);
  return <MenuItemCenter ref={ref} {...props} />;
}

MenuItem.propTypes = {
  selected: PropTypes.bool,
};

/**
 *
 * @param{any} anchorEl
 * @param{moment} date
 * @param{function} setDate
 * @param{object} datePickerProps
 * @param{string} label
 * @return {JSX.Element}
 * @constructor
 */
function InputDateTimeWithPopover({
  anchorEl, date = moment(), setDate, datePickerProps = {},
  label = 'Date',
}) {
  const [tabValue, setTabValue] = useState(0);
  const [arrowRef, setArrowRef] = useState(null);
  const [relativeValue, setRelativeValue] = useState(0);
  const [relativeUnit, setRelativeUnit] = useState('s');
  const [absoluteDate, setAbsoluteDate] = useState(date);
  const [textFieldDate, changeTextFieldDate] = useState(
      absoluteDate.format(format));
  const classes = useStyles();

  const hour = absoluteDate.hour();
  const minute = absoluteDate.minute() >= 30 ? 1 : 0;
  const changeHours = (h, m) => () => {
    const newDate = moment(absoluteDate).set({
      hour: h,
      minute: m,
      second: 0,
      millisecond: 0,
    });
    setAbsoluteDate(newDate);
    changeTextFieldDate(newDate.format(format));
    setDate(newDate.format(format));
  };

  const changeDay = (mom) => {
    setAbsoluteDate(mom);
    changeTextFieldDate(mom.format(format));
    setDate(mom.format(format));
  };

  useEffect(() => {
    if (!anchorEl) {
      setAbsoluteDate(date);
      changeTextFieldDate(date.format(format));
    }
  }, [anchorEl, date]);

  return <>

    <Popper
      style={{
        zIndex: 1200,
      }}
      placement="bottom"
      className={classes.popper}
      open={!!anchorEl}
      anchorEl={anchorEl}
      disablePortal={false}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <span className={classes.arrow} ref={setArrowRef}/>
      <Paper square>
        <div style={{color: '#BE131A'}}>
          <Tabs
            variant={'fullWidth'}
            value={tabValue}
            indicatorColor="primary"
            textColor="inherit"
            onChange={(event, newValue) => setTabValue(newValue)}
            style={{borderBottom: '1px solid #aaa'}}
            TabIndicatorProps={{
              style: {background: '#BE131A'},
            }}
          >
            <Tab label="Absolute"/>
            <Tab label="Relative"/>
            <Tab label="Now"/>
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <Box p={1}>
            <Grid container spacing={1} direction={'column'}>
              <Grid item>
                <Grid container direction="row">
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disableToolbar={true}
                        orientation="landscape"
                        autoOk
                        variant="static"
                        openTo="date"
                        value={absoluteDate}
                        onChange={changeDay}
                        {...datePickerProps}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                  <HourSelect
                    changeHours={changeHours}
                    currentHour={hour}
                    currentMinute={minute}
                  />

                </Grid>
              </Grid>
              <Grid item>
                <RedTextField variant={'outlined'}
                  helperText="Expected format DD-MM-YYYY HH:mm:ss.SSS"
                  size={'small'}
                  style={{display: 'flex'}}
                  onChange={({target: {value}}) => {
                    if (moment(value, formats, true).isValid()) {
                      setAbsoluteDate(moment(value, formats, true));
                      setDate(moment(value, formats, true).format(format));
                    }
                    changeTextFieldDate(value);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start">
                      {label}
                    </InputAdornment>,
                  }}
                  value={
                    textFieldDate
                  }
                >
                </RedTextField>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box p={1}>
            <Grid container spacing={1} direction={'column'}>
              <Grid item>
                <Grid container spacing={1} direction="row">
                  <Grid item xs={6}>
                    <RedTextField
                      variant={'outlined'}
                      size={'small'}
                      style={{display: 'flex'}}
                      value={relativeValue}
                      onChange={({target: {value}}) => {
                        setRelativeValue(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RelativeAgoSelect
                      value={relativeUnit}
                      onChange={({target: {value}}) => {
                        setRelativeUnit(value);
                      }}
                    />

                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RedButton variant={'contained'} size={'small'}
                  onClick={() => {
                    setDate(`l${relativeValue}${relativeUnit}`);
                  }}
                  fullWidth={true}>Set {label} </RedButton>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Box p={1}>
            <Grid container spacing={1} direction={'column'}>

              <Grid item>
                <RedButton variant={'contained'} size={'small'}
                  onClick={() => {
                    setDate('now');
                  }}
                  fullWidth={true}>Set {label} to now </RedButton>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Paper>
    </Popper>
  </>
  ;
}

InputDateTimeWithPopover.propTypes = {
  anchorEl: PropTypes.any,
  date: PropTypes.instanceOf(moment),
  setDate: PropTypes.func,
  datePickerProps: PropTypes.object,
  label: PropTypes.string,
};

/**
 *
 * @param{{}} props
 * @return {JSX.Element}
 * @constructor
 */
function RelativeAgoSelect(props) {
  return <RedTextField
    variant={'outlined'}
    size={'small'}
    style={{display: 'flex'}}
    select={true}
    {...props}
  >
    {[
      ['seconds ago', 's'],
      ['minutes ago', 'm'],
      ['hours ago', 'h'],
      ['days ago', 'd'],
    ].map(([text, value]) => (
      <MenuItemCenter key={value} value={value}>
        {text}
      </MenuItemCenter>))
    }
  </RedTextField>;
}

/**
 *
 * @param{function} changeHours
 * @param{int} hour
 * @param{int} minute
 * @return {JSX.Element}
 * @constructor
 */
function HourSelect({changeHours, currentHour: hour, currentMinute: minute}) {
  return <MenuList
    autoFocusItem={false}
    variant="selectedMenu"
    dense={true}
    style={{
      overflow: 'auto',
      maxHeight: 305,
      flex: 1,
    }}>
    {
      [...Array(24).keys()].map((i) => {
        return [0, 30].map((j) => {
          const label = [i, j].map((l) => String(l).padStart(2, '0')).join(':');
          return <MenuItem
            onClick={changeHours(i, j)}
            selected={hour === i && minute === j}
            key={label}>
            {label}
          </MenuItem>;
        });
      })
    }
  </MenuList>;
}

HourSelect.propTypes = {
  changeHours: PropTypes.func,
  currentHour: PropTypes.number,
  currentMinute: PropTypes.number,
};

/**
 *
 * @param{function} handleChange
 * @param{string} start
 * @return {JSX.Element}
 * @constructor
 */
function StaticPeriodSelect({onChange: handleChange, defaultValue: start}) {
  return <Select
    displayEmpty={true}
    style={{width: 50}}
    renderValue={() => <>&nbsp;&nbsp;{<FontAwesomeIcon icon={faClock}/>}</>}
    labelId="static-period-selection"
    id="static-period-selection"
    input={<InputBase/>}
    value={
      TIME_OPTIONS.map(({value}) => value).find((e) => e === start) ||
      ''
    }
    onChange={handleChange}
  >
    {[...TIME_OPTIONS].map(({label, value}) => (
      <MenuItemCenter key={value} value={value}>
        {label}
      </MenuItemCenter>
    ))}
  </Select>;
}

StaticPeriodSelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};
