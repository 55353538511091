import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import configs from './configs';
import {loggedIn, loggedOut, setKeycloak} from './actions/loginActions';
import {setOperatorData} from './actions/operatorAction';
import {setServicesInfo} from './actions/servicesAction';

/**
 *
 * @param{string} token
 * @return {Promise<*>}
 */
async function getUserInfo(token) {
  const controller = new AbortController();
  const {signal} = controller;
  const init = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json',
    },
    signal,
  };
  const {user_info: userInfo} = await fetch(
      `${configs.DNS8_BACKEND.url}users/info`,
      init,
  ).then((data) => data.json());
  return userInfo;
}

/**
 *
 * @return {boolean[]}
 */
export function useKeycloak() {
  const [authenticated, setAuth] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (window.Keycloak === undefined) {
        window.location = configs.KEYCLOAK_CONFIGS.url;
        throw new Error('Can\'t find Keycloak on the global scope');
      }
      const keycloak = new window.Keycloak(configs.KEYCLOAK_CONFIGS);
      keycloak.init(configs.KEYCLOAK_INIT).then(async (auth) => {
        setShowLogo(!!auth);

        keycloak.onTokenExpired = () => {
          // eslint-disable-next-line no-console
          console.log(
              'Expired Token at',
              new Date(keycloak.tokenParsed.exp * 1000),
          );
          // eslint-disable-next-line no-console
          console.log(
              'Expired Refresh Token at',
              new Date(keycloak.refreshTokenParsed?.exp * 1000),
          );
          keycloak.logout();
        };

        dispatch(setKeycloak(keycloak));
        if (!auth) {
          setAuth(false);
          setShowLogo(false);
          dispatch(loggedOut());
          keycloak.logout();
        } else {
          const token = keycloak.tokenParsed;

          const roles =
            token.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles ||
            [];
          const isUser = roles.some((r) => ['user'].includes(r));
          const isOperator = roles.some((r) =>
            ['root', 'soc-operator', 'ms-operator'].includes(r),
          );
          if (isOperator || isUser) {
            const userInfo = await getUserInfo(keycloak.token);
            if (isOperator) {
              dispatch(setOperatorData(userInfo.groups));
            }
            dispatch(loggedIn());
            const clientid = configs.KEYCLOAK_CONFIGS.clientId;
            const services = userInfo.groups_info.reduce((acc, g) => {
              if (userInfo.groups.includes(`/${clientid}/${g.hash}`)) {
                acc.push({
                  clientname: '',
                  hash: g.hash,
                  servicename: g.name,
                  expire: g.expire,
                  mqps: g.mqps,
                  internal: g.internal,
                });
              }
              return acc;
            }, []);
            services.sort((a, b)=> {
              return a.servicename.localeCompare(b.servicename);
            });

            dispatch(setServicesInfo(services));
          } else {
            dispatch(loggedOut());
          }
        }
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    } catch (exp) {
      // eslint-disable-next-line no-console
      console.log(exp.toString());
    }
  }, [dispatch]);

  useEffect(() => {
    if (showLogo) {
      setTimeout(() => {
        setAuth(showLogo);
        setShowLogo(false);
      }, 1510);
    }
  }, [showLogo]);

  return [authenticated, showLogo];
}

const animeLogoStyles = makeStyles({
  root: {
    'display': 'inline-block',
    'position': 'relative',

    'fontFamily': 'MagistralC',
    'fontSize': 40,

    '&.anime': {
      'animation-delay': 0,
      'transition': 'background-color .75s ease-in-out',
      'background-color': 'white',
    },
    '& .anim-opacity': {
      'opacity': 1,
      '&.animate': {
        'animation-delay': '.175s',
        'transition': 'opacity .12s ease-in-out',
        'opacity': 0.8,
      },
    },
    '& .anim-opacity2': {
      'opacity': 0.1,
      'width': 0,
      'overflow': 'hidden',
      '&.animate': {
        'width': '100%',
        'animation-delay': '.25s',
        'transition': 'opacity 1.25s ease-in-out, width 1.25s ease-out',
        'opacity': 1,
      },
    },

    '& .anim-width': {
      'display': 'inline-block',
      'width': '100%',
      'whiteSpace': 'nowrap',
      'overflow': 'hidden',

      'backgroundColor': 'inherit',
      'right': 0,
      'top': 0,
      'height': '100%',
      'position': 'absolute',
      'borderLeft': '2px solid gray',
      'borderTopRightRadius': '30px',
      'borderBottomRightRadius': '30px',

      '&.animate': {
        'animation-delay': '.25s',
        'transition': 'width 1.25s ease-out',
        'width': 0,
      },
    },
  },
});

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function Logo() {
  const layerDiv = useRef(null);
  const outerScroll = useRef(null);
  const innerScroll = useRef(null);
  const outerLogo = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      outerLogo.current.classList.add('animate');
      layerDiv.current.classList.add('animate');
      outerScroll.current.classList.add('animate');
      innerScroll.current.classList.add('animate');
    }, 10);
  }, []);

  const classes = animeLogoStyles();
  return (
    <div ref={outerLogo} className={classes.root}>
      <div ref={layerDiv} className="anim anim-opacity2 r-center">
        <span style={{color: 'black'}}>LAYER</span>
        <span style={{color: 'red'}}>8</span>
      </div>
      <div
        ref={outerScroll}
        className="anim anim-opacity w-100"
        style={{backgroundColor: 'inherit'}}
      >
        <div ref={innerScroll} className="anim anim-width">
          &nbsp;
        </div>
      </div>
    </div>
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export function Banner() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        backgroundColor: '#F9F9F9',
      }}
    >
      <div style={{margin: 'auto'}}>
        <Logo/>
      </div>
    </div>
  );
}
