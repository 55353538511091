const operatorReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_OPERATOR':
      if (state === false) {
        return {
          clients: action.payload,
          personify: false,
        };
      }
      if (action.payload.length !== state.clients.length) {
        return {
          clients: action.payload,
          personify: false,
        };
      }
      if (action.payload.some((c) => !state.clients.includes(c))) {
        return {
          clients: action.payload,
          personify: false,
        };
      }
      return state;

    case 'SET_OPERATOR_CLIENT':
      return {...state, personify: action.payload};
    default:
      return state;
  }
};

export default operatorReducer;
