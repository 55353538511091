import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import React from 'react';
import {TIME_OPTIONS_FUNCTIONS} from '../TopMenu/time_options';
import {useGetDataBackend} from '../../ApiBackend';
import {RenderDomainInternalLink, RenderDomainLink} from '../Domain';
import BoxInfo from '../../Components/BoxInfo';
import {TableWithList} from '../../Components/Table';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function TopInvalid() {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };
  const internal = useGetDataBackend('services/has_internal', {
    clientname: selectedService,
  });
  const {data, loading, error} = useGetDataBackend(
      'requests/topInvalid',
      requestBody,
  );
  const style = {color: '#464646'};
  const cols = [
    {
      title: <b>Domain</b>,
      render: function DomainCell(d) {
        if (!(internal.loading || internal.error) && internal.data.total) {
          return (
            <RenderDomainInternalLink
              domain={d}
              tooltip={`More info: ${d}`}
              style={style}
            />
          );
        }
        return (
          <RenderDomainLink
            domain={d}
            tooltip={`More info: ${d}`}
            style={style}
          />
        );
      },
    },
    {
      title: <b>Requests</b>,
      styles: {
        width: 80,
        textAlign: 'right',
      },
      render: function RenderNumber(d) {
        return <div style={{width: 'auto', textAlign: 'right'}}>{d}</div>;
      },
    },
  ];
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon icon={faTimesCircle} /> Top Invalid
        </b>
      }
    >
      <TableWithList
        cols={cols}
        loading={loading}
        list={data?.topInvalid}
        error={error}
      />
    </BoxInfo>
  );
}
