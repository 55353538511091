import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {FormControl} from '@material-ui/core';
import RedTextField from '../../core/components/inputs/RedTextField';
import RedIconButton from '../../core/components/buttons/RedIconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
  },
  list: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function ServiceTransferList({
  currentServices = [],
  all_services: allServ = [],
  handleChange = (_) => _,
  disabled = false,
}) {
  const [allServices, setAllServices] = useState(
      allServ.map((sJson) => {
        const service = JSON.parse(sJson.attributes.group_info[0]);
        return {
          ...service,
          servicename: service.name,
        };
      }),
  );

  // useEffect(() => {
  //   setAllServices(
  //       allServ.map((sJson) => {
  //         const service = JSON.parse(sJson.attributes.group_info[0]);
  //         return {
  //           ...service,
  //           servicename: service.name,
  //         };
  //       }),
  //   );
  // }, [allServ]);

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([...currentServices]);
  const [right, setRight] = React.useState(
      not(
          allServices.map((s) => s.hash),
          currentServices,
      ),
  );
  const [search, setSearch] = useState('');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const displayServices = allServices.reduce((acc, s) => {
    if (s.servicename.indexOf(search) !== -1) {
      acc.push(s.hash);
    }
    return acc;
  }, []);

  useEffect(() => {
    setLeft([...currentServices]);
    setRight(
        not(
            allServices.map((s) => s.hash),
            currentServices,
        ),
    );
  }, [currentServices, allServices]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    handleChange(not(left, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    handleChange([...left.concat(rightChecked)]);
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            color="default"
            size="small"
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !==
                    0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
                    numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || disabled}
            inputProps={{'aria-label': 'all items selected'}}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          if (!displayServices.includes(value)) {
            return false;
          }
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  color="default"
                  size="small"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disabled={disabled}
                  disableRipple
                  inputProps={{'aria-labelledby': labelId}}
                />
              </ListItemIcon>
              <span style={{whiteSpace: 'nowrap'}}>
                {allServices.find((s) => s.hash === value).servicename}
              </span>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <FormControl style={{width: '100%', marginBottom: '8px'}}>
        <RedTextField
          style={{
            width: 'calc(50% - 32px)',
            marginLeft: 'auto',
          }}
          value={search}
          variant="outlined"
          size="small"
          label="Search Service"
          onChange={({target: {value}}) => setSearch(value)}
        />
      </FormControl>
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item style={{width: 'calc(50% - 32px)'}}>
          {customList('All Services', right)}
        </Grid>
        <Grid item style={{width: 64}}>
          <Grid container direction="column" alignItems="center">
            <RedIconButton
              TooltipProps={{title: 'Add Service'}}
              disabled={rightChecked.length === 0 || disabled}
              onClick={handleCheckedLeft}
            >
              <KeyboardArrowRight />
            </RedIconButton>
            <RedIconButton
              TooltipProps={{title: 'Remove Service'}}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0 || disabled}
            >
              <KeyboardArrowLeft />
            </RedIconButton>
          </Grid>
        </Grid>
        <Grid item style={{width: 'calc(50% - 32px)'}}>
          {customList(<b>Active Services</b>, left)}
        </Grid>
      </Grid>
    </>
  );
}
