import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight,
  faArrowRight,
  faBan, faCheckCircle,
  faCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {useSelector} from 'react-redux';

import {faChartArea} from '@fortawesome/free-solid-svg-icons/faChartArea';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import {
  ButtonBase,
  Tooltip as MouseTooltip,
  useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';
import * as TIME_CONST from '../TopMenu/time_options';
import {useGetDataBackend} from '../../ApiBackend';
import BoxInfo from '../../Components/BoxInfo';

const TIME_OPTIONS_FUNCTIONS = TIME_CONST.TIME_OPTIONS_FUNCTIONS;

/**
 *
 * @param{string} domain
 * @return {{data: {}, loading: boolean, error: null}}
 */
function useHistogramData(domain) {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const requestBody = {
    t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
    clientname: selectedService,
  };
  if (domain) {
    requestBody.domain = domain;
  } else {
    delete requestBody.domain;
  }

  const [body, setBody] = useState(requestBody);
  useEffect(() => {
    let update = true;
    const newRequestBody = {
      t_r: TIME_OPTIONS_FUNCTIONS[timeRange] || timeRange,
      clientname: selectedService,
    };
    if (domain) {
      newRequestBody.domain = domain;
    } else {
      delete newRequestBody.domain;
    }
    if (update) {
      setBody(newRequestBody);
    }
    return () => {
      update = false;
    };
  }, [timeRange, selectedService, domain]);

  const [infoPrev, blockedInfoPrev, invalidInfoPrev] =
    useGetPreDataRequest(domain);
  const {data, loading, error} = useGetDataBackend(
      'requests/histogram',
      body,
  );
  return {
    data, loading, error,
    infoPrev, blockedInfoPrev, invalidInfoPrev,
  };
}

/**
 *
 * @param{string} domain
 * @return {JSX.Element}
 * @constructor
 */
export default function AllRequestsChart({domain}) {
  const {
    data,
    loading,
    error,
    infoPrev,
    blockedInfoPrev,
    invalidInfoPrev,
  } = useHistogramData(
      domain,
  );
  return (
    <BoxInfo
      title={
        <b>
          <FontAwesomeIcon icon={faChartArea}/> Requests Overtime
        </b>
      }
    >
      <div style={{display: 'flex', flex: 1, height: 315}}>
        {(loading || error) && (
          <div style={{display: 'flex', margin: 'auto'}}>
            <div style={{margin: 'auto', color: '#BE131A'}}>
              <FontAwesomeIcon icon={faSpinner} spin
                style={{fontSize: 40}}/>
            </div>
          </div>
        )}

        {!(loading || error) &&
          !data.first && (
          <div style={{display: 'flex', flex: 1, height: '100%'}}>
            <div
              style={{
                margin: 'auto',
                fontSize: 16,
                color: 'rgba(70, 70, 70, 0.6)',
              }}
            >
                There are no Requests
            </div>
          </div>
        )}

        {!(loading || error) &&
          data.first && (
          <Chart
            data={data}
            infoPrev={infoPrev}
            blockedInfoPrev={blockedInfoPrev}
            invalidInfoPrev={invalidInfoPrev}
          />
        )}
      </div>
    </BoxInfo>
  );
}
AllRequestsChart.propTypes = {
  domain: PropTypes.string,
};

/**
 *
 * @param{string} domain
 * @return {{data: {}, loading: boolean, error: null}[]}
 */
function useGetPreDataRequest(domain = undefined) {
  const [timeRange, selectedService] = useSelector((state) => [
    state.time_range,
    state.selectedService,
  ]);
  const [tr0, tr1] = TIME_CONST.PREV_TIME_OPTIONS[timeRange] || [];
  const [links, setLinks] = useState(['', '', '']);
  const body = {
    't_r[0]': tr0,
    't_r[1]': tr1,
    'clientname': selectedService,
  };
  if (domain) {
    body.domain = domain;
  }
  const requestsCount = useGetDataBackend(links[0], body);

  const blockedCount = useGetDataBackend(links[1], body);

  const invalidCount = useGetDataBackend(links[2], body);

  useEffect(() => {
    if (TIME_CONST.PREV_TIME_OPTIONS[timeRange]) {
      setLinks(
          ['requests/count', 'requests/countBlocked', 'requests/countInvalid']);
    } else {
      setLinks(['', '', '']);
    }
    return () => setLinks(['', '', '']);
  }, [timeRange]);

  return [
    requestsCount,
    blockedCount,
    invalidCount,
  ];
}

/**
 *
 * @param{*} data
 * @param{*} infoPrev
 * @param{*} blockedInfoPrev
 * @param{*} invalidInfoPrev
 * @return {JSX.Element}
 * @constructor
 */
function Chart({data: newData, infoPrev, blockedInfoPrev, invalidInfoPrev}) {
  newData.overtime = newData.overtime.map((d) => {
    return {...d, time_format: moment(d.time).format(d.time_format)};
  });
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [requestsType, setRequestsVisibility] = useState({
    req: false,
    blocked: false,
    invalid: false,
    allowed: false,
  });
  let blockedPercent = null;
  let blockedIcon;

  if (newData?.count?.requests?.blocked && blockedInfoPrev?.data?.count) {
    blockedPercent =
      Math.round(
          (newData.count.requests.blocked / blockedInfoPrev.data.count - 1) *
        1000,
      ) / 10;
    if (blockedPercent > 0) {
      blockedIcon = (
        <FontAwesomeIcon
          icon={faArrowRight}
          color="inherit"
          transform={{rotate: -45}}
        />
      );
    } else if (blockedPercent < 0) {
      blockedIcon = (
        <FontAwesomeIcon
          icon={faArrowRight}
          color="inherit"
          transform={{rotate: 45}}
        />
      );
    } else {
      blockedPercent = undefined;
    }
  } else if (newData?.count?.requests?.blocked) {
    blockedPercent = Infinity;
    blockedIcon = (
      <FontAwesomeIcon
        icon={faArrowRight}
        color="inherit"
        transform={{rotate: -45}}
      />
    );
  }

  let percent = undefined;
  let requestIcon;
  if (newData?.count?.requests?.total) {
    if ((infoPrev?.data?.count_allowed >= 0)) {
      percent = newData.count.requests.allowed / infoPrev.data.count_allowed;
      percent -= 1;
      percent = Math.round(percent * 1000) / 10;
    } else if ((newData?.count?.requests?.allowed >= 0)) {
      percent = Infinity;
    } else if (infoPrev?.data?.count >= 0) {
      percent =
        Math.round(
            (newData.count.requests.total / infoPrev.data.count - 1) * 1000,
        ) / 10;
    } else {
      percent = Infinity;
    }
    if (percent > 0) {
      requestIcon = (
        <FontAwesomeIcon
          icon={faArrowRight}
          color="inherit"
          transform={{rotate: -45}}
        />
      );
    } else if (percent < 0) {
      requestIcon = (
        <FontAwesomeIcon
          icon={faArrowRight}
          color="inherit"
          transform={{rotate: 45}}
        />
      );
    } else {
      percent = undefined;
    }
  }

  let invalidPercent = undefined;
  let invalidIcon;
  if (newData?.count?.requests?.invalid && invalidInfoPrev?.data?.count) {
    invalidPercent =
      Math.round(
          (newData.count.requests.invalid / invalidInfoPrev.data.count - 1) *
        1000,
      ) / 10;
    if (invalidPercent > 0) {
      invalidIcon = (
        <FontAwesomeIcon
          icon={faArrowRight}
          color="inherit"
          transform={{rotate: -45}}
        />
      );
    } else if (invalidPercent < 0) {
      invalidIcon = (
        <FontAwesomeIcon
          icon={faArrowRight}
          color="inherit"
          transform={{rotate: 45}}
        />
      );
    } else {
      invalidPercent = undefined;
    }
  } else if (newData?.count?.requests?.invalid) {
    invalidPercent = Infinity;
    invalidIcon = (
      <FontAwesomeIcon
        icon={faArrowRight}
        color="inherit"
        transform={{rotate: -45}}
      />
    );
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          width: '80%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            padding: '10px 10px 0',
          }}
        >
          {!(newData.count.requests.allowed >= 0) && <MouseTooltip
            title={`${
              requestsType.req ? 'Click to show' : 'Click to hide'
            } in chart`}
          >
            <ButtonBase
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'inherit',
                cursor: 'pointer',
              }}
              onClick={() => {
                setRequestsVisibility(
                    (prev) => ({...prev, req: !prev.req}));
              }}
            >
              <div
                style={{
                  margin: 'auto 5px auto auto',
                  fontSize: 22,
                  color: '#1870B4',
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  style={requestsType.req ? {color: 'gray'} : {}}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{fontSize: '.7rem'}}>Requests</div>
                <div
                  style={{
                    fontSize: '1.1rem',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {newData.count.requests.total}
                  {!isNaN(percent) && !isXsmall && (
                    <div style={{
                      fontSize: 12,
                      margin: 'auto 5px auto 10px',
                    }}>
                      ({requestIcon} {percent}%)
                    </div>
                  )}
                </div>
              </div>
            </ButtonBase>
          </MouseTooltip>}
          {(newData.count.requests.allowed >= 0) && <MouseTooltip
            title={`${
              requestsType.req ? 'Click to show' : 'Click to hide'
            } in chart`}
          >
            <ButtonBase
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'inherit',
                cursor: 'pointer',
              }}
              onClick={() => {
                setRequestsVisibility(
                    (prev) => ({...prev, allowed: !prev.allowed}));
              }}
            >
              <div
                style={{
                  margin: 'auto 5px auto auto',
                  fontSize: 22,
                  color: 'green',
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={requestsType.allowed ? {color: 'gray'} : {}}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{fontSize: '.7rem'}}>
                  Allowed
                  {!isXsmall && <span> Requests</span>}
                </div>
                <div
                  style={{
                    fontSize: '1.1rem',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {newData.count.requests.allowed}
                  {!isNaN(percent) && !isXsmall && (
                    <div style={{
                      fontSize: 12,
                      margin: 'auto 5px auto 10px',
                    }}>
                      ({requestIcon} {percent}%)
                    </div>
                  )}
                </div>
              </div>
            </ButtonBase>
          </MouseTooltip>}
          {!!newData.count.requests.blocked && (
            <MouseTooltip
              title={`${
                requestsType.blocked ? 'Click to show' : 'Click to hide'
              } in chart`}
            >
              <ButtonBase
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: 'inherit',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setRequestsVisibility((prev) => ({
                    ...prev,
                    blocked: !prev.blocked,
                  }));
                }}
              >
                <div
                  style={{
                    margin: 'auto 5px auto auto',
                    fontSize: 22,
                    color: '#BE131A',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBan}
                    mask={faCircle}
                    transform="shrink-6"
                    style={requestsType.blocked ? {color: 'gray'} : {}}
                  />
                </div>
                <div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '.7rem'}}>
                      Blocked {!isXsmall && <span>Requests</span>}
                    </div>
                    <div
                      style={{
                        fontSize: '1.1rem',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {newData.count.requests.blocked}
                      {!isNaN(blockedPercent) && !isXsmall && (
                        <div
                          style={{
                            fontSize: 12,
                            margin: 'auto 5px auto 10px',
                          }}
                        >
                          ({blockedIcon} {blockedPercent}%)
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </ButtonBase>
            </MouseTooltip>
          )}
          {!!newData.count.requests.invalid && (
            <MouseTooltip
              title={`${
                requestsType.invalid ? 'Click to show' : 'Click to hide'
              } in chart`}
            >
              <ButtonBase
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: 'inherit',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setRequestsVisibility((prev) => ({
                    ...prev,
                    invalid: !prev.invalid,
                  }));
                }}
              >
                <div
                  style={{
                    margin: 'auto 5px auto auto',
                    fontSize: 22,
                    color: 'rgb(237,174,48)',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={requestsType.invalid ? {color: 'gray'} : {}}
                  />
                </div>
                <div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '.7rem'}}>
                      Invalid {!isXsmall && <span>Requests</span>}
                    </div>
                    <div
                      style={{
                        fontSize: '1.1rem',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {newData.count.requests.invalid}
                      {!isNaN(invalidPercent) && !isXsmall && (
                        <div
                          style={{
                            fontSize: 12,
                            margin: 'auto 5px auto 10px',
                          }}
                        >
                          ({invalidIcon} {invalidPercent}%)
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </ButtonBase>
            </MouseTooltip>
          )}
        </div>
        <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
          <div style={{width: isXsmall ? '100%' : '75%', flex: 1}}>
            <ResponsiveContainer>
              <AreaChart
                data={newData.overtime || []}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <defs>
                  <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="green" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="green" stopOpacity={0}/>
                  </linearGradient>
                  <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1870B4" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#1870B4" stopOpacity={0}/>
                  </linearGradient>
                  <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#BE131A" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#BE131A" stopOpacity={0}/>
                  </linearGradient>
                  <linearGradient id="colorYellow" x1="0" y1="0" x2="0"
                    y2="1">
                    <stop offset="5%" stopColor="#edae30" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#edae30" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="time_format"/>
                <YAxis/>
                <Tooltip/>

                {!requestsType.req && newData.count.requests.total && (
                  <Area
                    type="monotone"
                    name="Requested"
                    stroke="#1870B4"
                    dataKey="requested"
                    fill="url(#colorBlue)"
                  />
                )}
                {!requestsType.allowed && newData.count.requests.allowed && (
                  <Area
                    stackId={1}
                    type="monotone"
                    name="Allow"
                    stroke="green"
                    dataKey="allowed"
                    fill="url(#colorGreen)"
                  />
                )}
                {!requestsType.blocked && newData.count.requests.blocked && (
                  <Area
                    stackId={1}
                    type="monotone"
                    name="Blocked"
                    stroke="#BE131A"
                    dataKey="blocked"
                    fill="url(#colorRed)"
                  />
                )}
                {!requestsType.invalid && newData.count.requests.invalid && (
                  <Area
                    stackId={1}
                    type="monotone"
                    name="Invalid"
                    stroke="#edae30"
                    dataKey="invalid"
                    fill="url(#colorYellow)"
                  />
                )}
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {!isXsmall && (
            <div style={{width: '130px'}}>
              Unique Domains
              {!(newData.count.domains.allowed >= 0) && (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div
                    style={{
                      margin: 'auto 5px',
                      width: 16,
                      fontSize: '16px',
                      color: '#1870B4',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      mask={faCircle}
                      transform="shrink-6"
                    />
                  </div>
                  <div style={{margin: 'auto 0'}}>
                    <div style={{fontSize: '14px'}}>
                      {newData.count.domains.total}
                    </div>
                  </div>
                </div>)}
              {(newData.count.domains.allowed >= 0) && (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div
                    style={{
                      margin: 'auto 5px',
                      width: 16,
                      fontSize: '16px',
                      color: 'green',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                    />
                  </div>
                  <div style={{margin: 'auto 0'}}>
                    <div style={{fontSize: '14px'}}>
                      {newData.count.domains.allowed}
                    </div>
                  </div>
                </div>)}
              {!!newData.count.domains.blocked && (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div
                    style={{
                      margin: 'auto 5px',
                      width: 16,
                      fontSize: '16px',
                      color: '#BE131A',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBan}
                      mask={faCircle}
                      transform="shrink-6"
                    />
                  </div>
                  <div style={{margin: 'auto 0'}}>
                    <div style={{fontSize: '14px'}}>
                      {newData.count.domains.blocked}
                    </div>
                  </div>
                </div>
              )}
              {!!newData.count.domains.invalid && (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div
                    style={{
                      margin: 'auto 5px',
                      width: 16,
                      fontSize: '16px',
                      color: 'rgb(237, 174, 48)',
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle}/>
                  </div>
                  <div style={{margin: 'auto 0'}}>
                    <div style={{fontSize: '14px'}}>
                      {newData.count.domains.invalid}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

Chart.propTypes = {
  data: PropTypes.object,
  infoPrev: PropTypes.any,
  blockedInfoPrev: PropTypes.any,
  invalidInfoPrev: PropTypes.any,
};
