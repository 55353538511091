import {toastr} from 'react-redux-toastr';
import React from 'react';

/**
 *
 * @param{array} array
 * @return {React.Fragment}
 * @constructor
 */
function multipleMessages(array) {
  return array.reduce(
      (r, i) =>
        (r && (
          <>
            {r}
            <br />
            {i}
          </>
        )) || <>{i}</>,
  );
}

/**
 * Modal to display errors
 * @param {string} title
 * @param {JSXElement|string[]} error
 * @return {null}
 */
export function displayError(title, error) {
  toastr.clean();
  let e=error;
  if (Array.isArray(e)) {
    e = multipleMessages(e);
  } else if (typeof e !== 'string') {
    e = 'Unknown Error';
  }
  toastr.error(title, {
    component: <>{e}</>,
  });
  return null;
}
/**
 * Modal to display success message
 * @param {string} title
 * @param {string|array} m
 * @return {null}
 */
export function displaySuccess(title, m) {
  toastr.clean();
  let message = m;
  if (Array.isArray(message)) {
    message = multipleMessages(message);
  } else if (typeof message !== 'string') {
    message = 'Unknown Error';
  }
  toastr.success(title, {
    component: <>{message}</>,
  });
  return null;
}

/**
 * Remove Modal
 */
export function clearMsg() {
  toastr.clean();
}

/**
 * Show Model to confirm an action
 * @param {JSX.Element|string} msg
 * @param {string} okText
 * @param {string} cancelText
 * @param {function} onOk
 * @param {function} onCancel
 * @param {{}} opt
 */
export function displayConfirmation(
    msg,
    okText,
    cancelText,
    onOk,
    onCancel=undefined,
    opt = {},
) {
  const options = {
    okText,
    cancelText,
    onOk,
    onCancel,
    buttons: [
      {
        cancel: true,
      },
      {
        ok: true,
      },
    ],
    ...opt,
  };

  toastr.confirm(msg, options);
}

