import * as type from '../actions/actionTypes';

const pageModeReducer = (state = '', action) => {
  switch (action.type) {
    case type.CHANGE_PAGE_MODE:
      return action.payload;
    default:
      return state;
  }
};

export default pageModeReducer;
